import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAuth } from "@/hooks/useAuth";
import Input from "@/components/Input";
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import Loader from "@/components/Loader";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { MAX_STRING_LENGTH, OTP_LENGTH } from "@/lib/constants";
import { getUser, updateUser } from "@/apis/users";
import { verifyEmail, createVerifyEmail } from "@/apis/settings/email_changes";

import { USER_INITIAL_VALUE, USER_VALIDATION_SCHEMA } from "./constants";

const AccountPage = () => {
  const { user, setUser } = useAuth();
  const [pageLoader, setPageLoader] = useState(true);
  const [emailChanged, setEmailChanged] = useState(false);
  const [otp, setOtp] = useState();
  const [emailVerificationAlert, setEmailVerificationAlert] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [otpVerificationToken, setOtpVerificationToken] = useState();
  const { handleSubmit, formState, setValue, getValues, reset } = useForm({
    resolver: yupResolver(USER_VALIDATION_SCHEMA),
    defaultValues: USER_INITIAL_VALUE,
  });

  useEffect(() => {
    getUserResponse();
  }, []);

  const getUserResponse = async () => {
    try {
      const { data } = await getUser(user.id);
      setPageLoader(false);
      reset(data.user);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateUserResponse = async (formState) => {
    try {
      setBtnLoader(true);
      const { data } = await updateUser(user.id, {
        user: {
          name: formState.name,
        },
      });

      if (emailChanged) {
        createVerifyEmailResponse(formState);
      } else {
        showToastrSuccess("Account setting has been updated successfully.");
      }
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const createVerifyEmailResponse = async (formState) => {
    try {
      const { data } = await createVerifyEmail({
        verification_token: { email: formState.email },
      });
      showToastrSuccess(data.message);
      setOtpVerificationToken(data.verification_token.token);
      setEmailVerificationAlert(true);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateVerifyEmailResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await verifyEmail({
        verification_token: {
          otp: otp,
          token: otpVerificationToken,
          email: getValues("email"),
        },
      });
      setUser(data.user);
      showToastrSuccess(data.message);
      setOtp("");
      setEmailVerificationAlert(false);
      if (getValues("email") === data?.user.email) {
        setEmailChanged(false);
      }
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-settings-page-wrapper bg-inverted-hover">
      <div className="pb-settings-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-12 w-full max-w-3xl">
          <form
            className="flex flex-col items-end gap-6 w-full max-w-md mx-auto"
            onSubmit={handleSubmit(updateUserResponse)}
          >
            <Input
              id="name"
              label="Full Name"
              name="name"
              defaultValue={formState.defaultValues.name}
              placeholder="Full Name"
              maxLength={MAX_STRING_LENGTH}
              onChange={(e) => setValue("name", e.target.value)}
              error={formState.errors.email?.message}
              required
              bodyBackground="inverted-hover"
            />

            <Input
              id="email"
              label="Email Address"
              name="email"
              type="email"
              defaultValue={formState.defaultValues.email}
              placeholder="Email Address"
              maxLength={MAX_STRING_LENGTH}
              onChange={(e) => {
                if (e.target.value !== user.email) {
                  setEmailChanged(true);
                } else {
                  setEmailChanged(false);
                }
                setValue("email", e.target.value);
              }}
              error={formState.errors.email?.message}
              required
              bodyBackground="inverted-hover"
            />

            <Button
              type="submit"
              label="Save changes"
              loading={btnLoader}
              bodyBackground="inverted-hover"
            />
          </form>

          <Modal
            open={emailVerificationAlert}
            onClose={() => setEmailVerificationAlert(false)}
            title="Email Verification"
          >
            <div className="flex flex-col w-full gap-10">
              <div className="flex flex-col w-full gap-6">
                <Input
                  id="otp"
                  name="otp"
                  maxLength={OTP_LENGTH}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  helpText="You will receive an email with an otp. Please enter the same otp here to verify!"
                  required
                />
              </div>

              <div className="flex gap-4">
                <Button
                  type="submit"
                  label="Verify"
                  loading={btnLoader}
                  onClick={() => updateVerifyEmailResponse()}
                  style="inverted"
                  bodyBackground="primary"
                />
                <Button
                  label="Cancel"
                  style="transparent"
                  onClick={() => setEmailVerificationAlert(false)}
                  bodyBackground="primary"
                />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
