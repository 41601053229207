import React from "react";
import classnames from "classnames";

const Switch = ({
  name,
  label,
  error,
  helpText,
  disabled = false,
  style = "primary",
  bodyBackground = "inverted",
  className = "",
  noBorder = false,
  ...otherProps
}) => {
  const STYLES = {
    primary: "bg-disabled before:bg-inverted checked:bg-primary",
    inverted: "bg-muted before:bg-primary checked:bg-inverted-hover",
  };

  const RING_STYLES = {
    inverted: "focus:shadow-secondary-ring-inverted-bg",
    primary: "focus:shadow-secondary-ring-primary-bg",
    "inverted-hover": "focus:shadow-secondary-ring-inverted-hover-bg",
  };

  return (
    <div className={classnames("flex flex-col w-full gap-2", className)}>
      {!!label && (
        <label htmlFor={name} className="pb-input-label">
          {label}
        </label>
      )}

      <input
        id={name}
        type="checkbox"
        disabled={disabled}
        className={classnames(
          "pb-switch relative inline-block appearance-none rounded-full overflow-hidden outline-none border-none cursor-pointer shrink-0 ",
          STYLES[style],
          !noBorder && RING_STYLES[bodyBackground],
          {
            "!cursor-not-allowed checked:bg-disabled": disabled,
          }
        )}
        {...otherProps}
      />

      {!!error && <p className="pb-error-text">{error}</p>}
      {!!helpText && <p className="pb-help-text">{helpText}</p>}
    </div>
  );
};

export default Switch;
