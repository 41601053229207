import React, { useState, useEffect } from "react";
import { useDebounce } from "@uidotdev/usehooks";

import {
  getDisciplines,
  getDiscipline,
  createDiscipline,
  updateDiscipline,
} from "@/apis/super_admin/disciplines";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import Pagination from "@/components/Pagination";
import Loader from "@/components/Loader";
import BlankState from "@/components/BlankState";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import Input from "@/components/Input";

import Table from "./Table";

const Disciplines = () => {
  const [disciplineList, setDisciplineList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [disciplineModal, setDisciplineModal] = useState(false);
  const [disciplineName, setDisciplineName] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [detailLoader, setDetailLoader] = useState(false);
  const [disciplineId, setDisciplineId] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (pageIndex) {
      getDisciplinesAPI();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (debouncedSearchTerm || debouncedSearchTerm === "") {
      getDisciplinesAPI();
    }
  }, [debouncedSearchTerm]);

  const getDisciplinesAPI = async () => {
    try {
      const { data } = await getDisciplines(pageIndex, searchTerm);
      setDisciplineList(data.disciplines);
      setTotalRecords(data.total_records);
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const handleDisciplineSubmit = async (e) => {
    e.preventDefault();

    try {
      setBtnLoader(true);

      if (disciplineId) {
        const { data } = await updateDiscipline(disciplineId, {
          discipline: { name: disciplineName },
        });
        showToastrSuccess(data.message);
      } else {
        const { data } = await createDiscipline({
          discipline: { name: disciplineName },
        });
        showToastrSuccess(data.message);
      }

      handleModalClose();
      getDisciplinesAPI();
    } catch (error) {
      showToastrError(error.data.response.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const getDisciplineAPI = async (disciplineId) => {
    try {
      setDetailLoader(true);
      const { data } = await getDiscipline(disciplineId);
      setDisciplineName(data.discipline.name);
      setDetailLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const handleModalClose = () => {
    setDisciplineModal(false);
    setDisciplineName("");
    setDisciplineId();
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-settings-page-wrapper bg-inverted-hover">
      <div className="pb-settings-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-6 md:gap-10 w-full max-w-[var(--layout-max-width)]">
          <div className="flex items-center justify-between">
            <h1 className="text-heading font-bold">Disciplines</h1>

            <Button
              label="Add New"
              onClick={() => setDisciplineModal(true)}
              bodyBackground="inverted-hover"
            />
          </div>

          <Input
            placeholder="Search by name"
            value={searchTerm}
            onChange={(e) => {
              setPageIndex(1);
              setSearchTerm(e.target.value);
            }}
            bodyBackground="inverted-hover"
          />

          {disciplineList.length > 0 ? (
            <Table
              disciplines={disciplineList}
              getDisciplinesAPI={getDisciplinesAPI}
              setDisciplineModal={setDisciplineModal}
              getDisciplineAPI={getDisciplineAPI}
              disciplineId={disciplineId}
              setDisciplineId={setDisciplineId}
            />
          ) : (
            <BlankState>
              <div className="flex flex-col items-center gap-4 w-full">
                <h5 className="text-title text-center">
                  There are no disciplines to show.
                </h5>

                {!searchTerm && (
                  <Button
                    label="Create a new discipline"
                    bodyBackground="inverted-hover"
                    onClick={() => setDisciplineModal(true)}
                  />
                )}
              </div>
            </BlankState>
          )}

          <Pagination
            count={totalRecords}
            pageSize={10}
            pageNo={pageIndex}
            navigate={setPageIndex}
          />

          <Modal
            open={disciplineModal}
            onClose={() => handleModalClose()}
            title={disciplineId ? "Edit Discipline" : "New Discipline"}
          >
            {detailLoader ? (
              <Loader />
            ) : (
              <div className="flex flex-col w-full gap-10">
                <div className="flex flex-col w-full gap-6">
                  <form
                    id="discipline-form"
                    onSubmit={(e) => handleDisciplineSubmit(e)}
                  >
                    <Input
                      id="displine_name"
                      label="Name"
                      name="displine_name"
                      placeholder="Discipline Name"
                      value={disciplineName}
                      onChange={(e) => setDisciplineName(e.target.value)}
                      style="primary"
                      bodyBackground="primary"
                      required
                    />
                  </form>
                </div>

                <div className="flex gap-4">
                  <Button
                    form="discipline-form"
                    type="submit"
                    label="Save"
                    loading={btnLoader}
                    style="inverted"
                    bodyBackground="primary"
                  />
                  <Button
                    type="button"
                    style="transparent"
                    label="Cancel"
                    onClick={() => handleModalClose()}
                    bodyBackground="primary"
                  />
                </div>
              </div>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Disciplines;
