import axios from "axios";

const getDefaultSettingsUrl = () => {
  return `/api/v1/super_admin/default_settings`;
};

export const getDefaultSetting = () => {
  return axios.get(getDefaultSettingsUrl());
};

export const createDefaultSetting = (payload) => {
  return axios.post(getDefaultSettingsUrl(), payload);
};

export const updateDefaultSetting = (payload) => {
  return axios.put(getDefaultSettingsUrl(), payload);
};
