import React, { useState, useEffect } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import InfiniteScroll from "react-infinite-scroll-component";

import { useAuth } from "@/hooks/useAuth";
import { getTakeList } from "@/apis/discoveries";
import { showToastrError } from "@/lib/commons";
import TakeCard from "@/components/TakeCard";
import Loader from "@/components/Loader";
import InfiniteLoader from "@/components/InfiniteLoader";

import CTACard from "./CTACard";

const HomePage = () => {
  const { user } = useAuth();
  const [takeList, setTakeList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    getTakeListAPI();
    document.getElementById("beacon-container")?.remove();
  }, []);

  const getTakeListAPI = async () => {
    try {
      const { data } = await getTakeList(pageIndex + 1);
      setTakeList([...takeList, ...data.takes]);
      setTotalRecords(data.total_records);
      setPageIndex(pageIndex + 1);
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const removeTakeFromList = (takeId) => {
    setTakeList(takeList.filter((take) => take.id !== takeId));
    setTotalRecords(totalRecords - 1);
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-page-wrapper bg-inverted">
      <div className="pb-page-container flex flex-col gap-10 w-full p-6 mb-[var(--footer-height)]">
        <InfiniteScroll
          dataLength={takeList.length}
          next={getTakeListAPI}
          hasMore={totalRecords > takeList.length}
          loader={<InfiniteLoader />}
          scrollThreshold={0.5}
          className="!overflow-hidden"
        >
          {user ? (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 1: 1, 767: 2, 1279: 3, 1535: 4 }}
            >
              <Masonry gutter="1.75rem">
                {takeList.map((take) => (
                  <TakeCard
                    key={take.id}
                    take={take}
                    removeTakeFromList={removeTakeFromList}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          ) : (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 1: 1, 767: 2, 1279: 3, 1535: 4 }}
            >
              <Masonry gutter="1.75rem">
                <CTACard />

                {takeList.map((take) => (
                  <TakeCard
                    key={take.id}
                    take={take}
                    removeTakeFromList={removeTakeFromList}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default HomePage;
