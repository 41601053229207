import React, { useState, useRef } from "react";

import Loader from "@/components/Loader";
import { PBIcons } from "@/components/PBIcons";
import { createAttachment } from "@/apis/take_header_attachments";
import { showToastrError } from "@/lib/commons";
import EmptyTakeHeader from "@/assets/images/empty-take-header.jpg";
import { MAX_IMAGE_SIZE } from "@/lib/constants";

const CoverHeader = ({ setValue, formState }) => {
  const [coverImage, setCoverImage] = useState();
  const imageRef = useRef();

  const handleChange = (event) => {
    const file = event.target.files[0];
    let reader = new FileReader();

    reader.onload = (e) => {
      if (file.size > MAX_IMAGE_SIZE) {
        showToastrError("Attachment size exceeds the allowable limit (5 MB).");
      } else {
        let imageData = {
          file_data: e.target.result,
          file_name: file.name,
          imageLoader: true,
        };

        setCoverImage(imageData);
        createAttachmentAPI(imageData);
      }
    };
    reader.readAsDataURL(file);
  };

  const createAttachmentAPI = async (imageData) => {
    try {
      const { data } = await createAttachment(formState.id, {
        attachment: { ...imageData },
      });
      setValue("cover_image_attributes", data.attachment);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setCoverImage(null);
    }
  };

  return (
    <div className="relative w-full rounded">
      {coverImage?.imageLoader && (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-30 text-inverted transition-all backdrop-blur-sm rounded">
          <Loader />
        </div>
      )}

      <img
        src={
          coverImage?.file_data ||
          formState.cover_image_attributes?.attachment_url ||
          EmptyTakeHeader
        }
        className="h-full w-full rounded"
      />

      <div className="absolute right-3 bottom-3 cursor-pointer">
        <div
          className="flex items-center gap-1 px-2 py-1.5 rounded-sm bg-inverted hover:bg-inverted-hover shadow-lg transition-all duration-300"
          onClick={() => imageRef.current.click()}
        >
          <PBIcons icon="Attachment" />
          <span className="hidden sm:block">Edit cover image</span>
          <span className="sm:hidden">Edit</span>
        </div>

        <input
          ref={imageRef}
          id="file_data"
          name="file_data"
          type="file"
          className="h-full w-full absolute opacity-0 top-0 left-0 -z-10"
          onChange={(event) => handleChange(event)}
          accept="image/png, image/gif, image/jpeg, image/webp, image/jpg, image/avif"
        />
      </div>
    </div>
  );
};

export default CoverHeader;
