import React, { useState } from "react";
import classnames from "classnames";

import { PBIcons } from "@/components/PBIcons";
import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";

const ImagesMenu = ({
  value,
  setValue,
  setSingleAttachmentChangeModal,
  editGallery,
  galleryView,
  getBase64ImageFromUrl,
  fullWidth,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const IMAGES_LIST = {
    single: "Single Images",
    thumbnail: "Thumbnail Gallery",
    grid: "Grid Gallery",
  };

  const handleClick = (type) => {
    setValue(type);
    setMenuOpen(false);

    if (type == "single" && editGallery) {
      setSingleAttachmentChangeModal(true);
      getBase64ImageFromUrl();
      setValue(galleryView);
    }
  };

  return (
    <Menu
      open={menuOpen}
      setOpen={setMenuOpen}
      width="200"
      position="bottom-start"
      style="inverted"
      fullWidth
      menuTrigger={
        <div
          className="flex items-center justify-between gap-2 px-1 py-0.5"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {IMAGES_LIST[value]}

          <div
            className={classnames("pb-dropdown-trigger-icon", {
              active: menuOpen,
              inactive: !menuOpen,
            })}
          >
            <PBIcons icon="ChevronDown" />
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-1">
        <MenuItem
          label="Single Images"
          selected={value === "single"}
          onClick={() => handleClick("single")}
          style="inverted"
        />

        <MenuItem
          label="Thumbnail Gallery"
          selected={value === "thumbnail"}
          onClick={() => handleClick("thumbnail")}
          style="inverted"
        />

        <MenuItem
          label="Grid Gallery"
          selected={value === "grid"}
          onClick={() => handleClick("grid")}
          style="inverted"
        />
      </div>
    </Menu>
  );
};

export default ImagesMenu;
