import React, { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";

import { setAuthHeaders, resetAuthTokens } from "@/apis/axios";
import {
  ROOT_ROUTE,
  DISCOVERIES_ROUTE,
  BRANDING_SETTINGS_ROUTE,
} from "@/lib/routes";
import { AUTH_TOKEN } from "@/lib/constants";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [showBeacon, setShowBeacon] = useState(false);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (token, data) => {
    localStorage.setItem(AUTH_TOKEN, token);
    setUser(data);
    await setAuthHeaders();

    // TODO: Move routes to constant
    if (data.first_time_enter) {
      navigate(BRANDING_SETTINGS_ROUTE, { replace: true });
    } else {
      navigate(DISCOVERIES_ROUTE, { replace: true });
    }
  };

  // call this function to sign out logged in user
  const logout = async () => {
    navigate(ROOT_ROUTE, { replace: true });
    localStorage.removeItem(AUTH_TOKEN);
    setUser(null);
    localStorage.removeItem("user");
    await resetAuthTokens();
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      setUser,
      showBeacon,
      setShowBeacon,
    }),
    [user, showBeacon, setShowBeacon]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
