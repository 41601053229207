import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import AnimateHeight from "react-animate-height";

import { createConnectSubscription } from "@/apis/connected_subscriptions";
import { getUserPlans } from "@/apis/settings/user_plans";

import { showToastrError } from "@/lib/commons";
import Button from "@/components/Button";
import LinkButton from "@/components/LinkButton";

import Loader from "./Loader";
import PlanCard from "./PlanCard";
import { JOIN_ROUTE } from "@/lib/routes";

const UserPlanModal = ({ user, subscriptionId, signUpAlert }) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [planLoader, setPlanLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState();
  const [subscribed, setSubscribed] = useState(false);
  const [userPlans, setUserPlans] = useState([]);

  useEffect(() => {
    getUserPlansAPI();

    if (queryParams.success) {
      setSubscribed(true);
    }

    if (queryParams.failure) {
      showToastrError("Payment has been failed. Please try again.");
    }
  }, []);

  const isStripeRequired = () => {
    return userPlans.find((el) => el.id == selectedPlanId)?.paid_plan;
  };

  const createConnectSubscriptionAPI = async () => {
    try {
      setBtnLoader(true);
      const freePlan = userPlans.find((el) => !el.paid_plan);

      if (freePlan?.id == selectedPlanId) {
        setSubscribed(true);
      } else {
        const { data } = await createConnectSubscription({
          connected_subscription: {
            user_plan_id: selectedPlanId,
            subscription_id: subscriptionId || queryParams.subscription_id,
            redirect_to: location.pathname,
          },
        });

        window.location.href = data.url;
      }
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const getUserPlansAPI = async () => {
    try {
      const { data } = await getUserPlans(user.id);
      const firstPaidPlan = data.user_plans.find((el) => el.paid_plan);
      setSelectedPlanId(firstPaidPlan?.id);
      setUserPlans(data.user_plans);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setPlanLoader(false);
    }
  };

  const DEFAULT_MESSAGE =
    "Subscribe for free or choose a subscription plan for extra content. You will get lots of cool photos and some more other things too.";

  return (
    <div className="flex flex-col items-center w-full px-6 py-2 relative min-h-fit">
      <div className="flex flex-col items-center w-full gap-10">
        <div className="flex flex-col items-center w-full gap-6">
          <div className="flex flex-col items-center w-full gap-6">
            <div className="overflow-hidden rounded-full">
              <img
                src={user.logo_url}
                alt="artist icon"
                className="object-cover h-12 w-12"
              />
            </div>

            <div className="text-heading font-bold text-center">
              {user.display_name}
            </div>
          </div>

          {subscribed ? (
            <AnimateHeight duration={300} height={subscribed ? "auto" : 0}>
              <p className="text-center">
                Thanks for subscribing!{" "}
                {signUpAlert && (
                  <span>
                    As you have signed up to a paid subscription, you'll need a
                    PencilBooth account of your own to view paid content on the
                    web. You can do that{" "}
                    <a href={JOIN_ROUTE} className="underline">
                      here
                    </a>{" "}
                    if you wish. Emails with paid content will come through as
                    normal.
                  </span>
                )}
              </p>
            </AnimateHeight>
          ) : (
            <AnimateHeight duration={300} height={!subscribed ? "auto" : 0}>
              <div className="flex flex-col items-center max-w-[527px] w-full">
                <p className="text-center">
                  {user.custom_subscription_message || DEFAULT_MESSAGE}
                </p>
              </div>
            </AnimateHeight>
          )}
        </div>

        <AnimateHeight duration={300} height={!subscribed ? "auto" : 0}>
          <div className="flex flex-col items-center w-full gap-6">
            <div className="flex flex-col md:flex-row gap-6 w-full">
              {planLoader ? (
                <div className="flex justify-center items-center w-full h-24">
                  <Loader />
                </div>
              ) : (
                userPlans.map((plan) => {
                  return (
                    <PlanCard
                      key={plan.id}
                      plan={plan}
                      selectedPlanId={selectedPlanId}
                      setSelectedPlanId={setSelectedPlanId}
                    />
                  );
                })
              )}
            </div>

            <div className="flex flex-col items-center w-full gap-4 font-soehne-mono">
              {isStripeRequired() && (
                <span>You'll be taken to Stripe to complete your payment</span>
              )}

              <Button
                onClick={() => createConnectSubscriptionAPI()}
                type="submit"
                label="Subscribe"
                loading={btnLoader}
                style="inverted"
                bodyBackground="primary"
              />
            </div>
          </div>
        </AnimateHeight>
      </div>

      {subscribed && user.last_take_id && (
        <AnimateHeight
          duration={300}
          height={subscribed && user.last_take_id ? "auto" : 0}
        >
          <LinkButton
            to={`/takes/${user.last_take_id}`}
            label="View the latest take"
            style="inverted"
            bodyBackground="primary"
          />
        </AnimateHeight>
      )}
    </div>
  );
};

export default UserPlanModal;
