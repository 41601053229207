import React from "react";

import MenuItem from "@/components/MenuItem";
import Divider from "@/components/Divider";
import Switch from "@/components/Switch";
import Tooltip from "@/components/Tooltip";
import { PBIcons } from "@/components/PBIcons";

const DefaultMenu = ({
  index,
  linkRoll,
  swapElement,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-1">
        <Tooltip message="Move up">
          {linkRoll.in_top_position ? (
            <MenuItem
              icon={
                <PBIcons
                  icon="ArrowUp"
                  className="text-muted cursor-not-allowed"
                />
              }
            />
          ) : (
            <MenuItem
              icon={<PBIcons icon="ArrowUp" />}
              onClick={() => swapElement(index - 1)}
            />
          )}
        </Tooltip>

        <Tooltip message="Move down">
          {linkRoll.in_bottom_position ? (
            <MenuItem
              icon={
                <PBIcons
                  icon="ArrowDown"
                  className="text-muted cursor-not-allowed"
                />
              }
            />
          ) : (
            <MenuItem
              icon={<PBIcons icon="ArrowDown" />}
              onClick={() => swapElement(index + 1)}
            />
          )}
        </Tooltip>
      </div>

      <Divider />

      <MenuItem
        label="Link"
        icon={<PBIcons icon="Link" />}
        onClick={() => handleEditClick()}
      />

      <MenuItem
        label="Delete"
        icon={<PBIcons icon="Delete" />}
        onClick={() => handleDeleteClick()}
      />
    </div>
  );
};

export default DefaultMenu;
