import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "@/components/Button";
import { PBIcons } from "@/components/PBIcons";

const Navbar = ({ takeId, updateBtnLoader }) => {
  const navigate = useNavigate();

  const handleExit = () => {
    navigate(`/takes/${takeId}/edit`);
  };

  return (
    <nav className="flex items-center w-full px-6 bg-inverted fixed z-[100] top-0 right-0 left-0">
      <div className="flex justify-between w-full gap-2.5 py-4">
        <div className="flex items-center gap-4">
          <Button
            label="Save and back"
            icon={<PBIcons icon="ArrowLeft" />}
            style="inverted"
            labelClassName="hidden md:block"
            loading={updateBtnLoader}
            onClick={() => handleExit()}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
