import React from "react";
import {
  useFloating,
  useInteractions,
  useTransitionStyles,
  useClick,
  offset,
} from "@floating-ui/react";
import classnames from "classnames";
import { useClickAway } from "@uidotdev/usehooks";

import { PBIcons } from "@/components/PBIcons";

const Menu = ({
  open,
  setOpen,
  style = "primary",
  borderRadius = true,
  position = "bottom-end",
  width = "200",
  menuTrigger,
  fullWidth,
  className = "",
  hoverEffect = true,
  border = true,
  children,
}) => {
  const menuRef = useClickAway(() => {
    setOpen(false);
  });

  const { refs, floatingStyles, context } = useFloating({
    placement: position,
    open: open,
    onOpenChange: setOpen,
    middleware: [offset(5)],
  });

  const click = useClick(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click]);
  const { isMounted, styles } = useTransitionStyles(context, {
    duration: 0,
  });

  const STYLES = {
    primary: "hover:bg-inverted-hover",
    inverted: "hover:bg-primary-hover",
    transparent: "hover:bg-inverted-hover",
  };

  const BORDER_STYLES = {
    primary: "border-primary-border",
    inverted: "border-muted",
    transparent: "border-transparent",
  };

  const NORMAL_STYLES = {
    primary: "bg-inverted",
    inverted: "bg-primary",
    transparent: "bg-inverted",
  };

  const HOVERED_STYLES = {
    primary: "bg-inverted-hover",
    inverted: "bg-primary-hover",
    transparent: "bg-inverted-hover",
  };

  const MENU_STYLES = {
    primary: "bg-primary text-inverted",
    inverted: "bg-inverted text-primary",
    transparent: "bg-primary text-inverted",
  };

  return (
    <div className={fullWidth ? "flex w-full" : "flex w-fit"} ref={menuRef}>
      <div
        className={classnames(
          `p-1 transition-all duration-300 cursor-pointer border border-solid ${
            fullWidth ? "w-full" : "w-fit"
          } ${
            hoverEffect && (open ? HOVERED_STYLES[style] : NORMAL_STYLES[style])
          } ${borderRadius ? "rounded-sm" : "rounded-none"} ${
            border ? BORDER_STYLES[style] : "border-transparent"
          } ${hoverEffect && STYLES[style]}`,
          className
        )}
        onClick={() => setOpen(!open)}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {menuTrigger ? menuTrigger : <PBIcons icon="Menu" />}
      </div>

      {isMounted && (
        <div
          ref={refs.setFloating}
          className={`flex flex-col gap-1 px-2 py-4 rounded-sm ${MENU_STYLES[style]} z-50`}
          style={{ ...floatingStyles, ...styles, width: `${width}px` }}
          {...getFloatingProps()}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Menu;
