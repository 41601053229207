import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import { toggleArchive } from "@/apis/takes";
import { showToastrSuccess, showToastrError } from "@/lib/commons";

const ItemMenu = ({
  take,
  setTakeId,
  setScheduleAlert,
  setDeleteAlertOpen,
  getTakeListResponse,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [visibilityModal, setVisibilityModal] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const updateTakeAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await toggleArchive(take.id);
      getTakeListResponse();
      showToastrSuccess(data.message);
      setVisibilityModal(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const visibilityConfirmText = () => {
    return take.exclude_from_visually_archive
      ? "Show in archive"
      : "Hide from archive";
  };

  return (
    <>
      <Menu open={open} setOpen={setOpen} style="transparent">
        {!take.non_editable && (
          <MenuItem
            label="Edit"
            onClick={() => navigate(`/takes/${take.id}/edit`)}
          />
        )}

        {take.scheduled && (
          <MenuItem
            label="Cancel schedule"
            onClick={() => {
              setTakeId(take.id);
              setScheduleAlert(true);
            }}
          />
        )}

        {take.sent_completed && (
          <MenuItem
            label={visibilityConfirmText()}
            onClick={() => {
              setVisibilityModal(true);
            }}
          />
        )}

        <MenuItem
          label="Delete"
          onClick={() => {
            setTakeId(take.id);
            setDeleteAlertOpen(true);
          }}
        />
      </Menu>

      <Modal
        open={visibilityModal}
        onClose={() => setVisibilityModal(false)}
        title={visibilityConfirmText()}
        className="text-left"
      >
        <div className="flex flex-col w-full gap-10">
          <div className="flex flex-col w-full gap-6">
            <p>
              Are you sure you want to {visibilityConfirmText().toLowerCase()}?
            </p>
          </div>

          <div className="flex gap-4">
            <Button
              type="button"
              label="Yes, Proceed"
              loading={btnLoader}
              style="inverted"
              onClick={() => updateTakeAPI()}
              bodyBackground="primary"
            />

            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => setVisibilityModal(false)}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ItemMenu;
