import React, { useState } from "react";
import { Link } from "react-router-dom";

import { destroyTake, cancelScheduledTake } from "@/apis/takes";
import { showToastrSuccess, showToastrError } from "@/lib/commons";

import Modal from "@/components/Modal";
import Button from "@/components/Button";

import ItemMenu from "./ItemMenu";

const Table = ({ takes, getTakeListResponse }) => {
  const [takeId, setTakeId] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [scheduleAlert, setScheduleAlert] = useState(false);

  const destroyTakeAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await destroyTake(takeId);
      showToastrSuccess(data.message);
      getTakeListResponse();
      setDeleteAlertOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const cancelScheduledTakeAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await cancelScheduledTake(takeId);
      showToastrSuccess(data.message);
      getTakeListResponse();
      setScheduleAlert(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="pb-data-table">
        <thead>
          <tr>
            <th className="w-[35%] min-w-[250px]">Subject</th>
            <th className="w-[10%] min-w-[120px]">Status</th>
            <th className="w-[10%] min-w-[80px]">Archive</th>
            <th className="w-[10%] min-w-[80px]">Sent</th>
            <th className="w-[10%] min-w-[80px]">Opened</th>
            <th className="w-[10%] min-w-[80px]">Clicked</th>
            <th className="w-[10%] min-w-[120px]">Unsubscribed</th>
            <th className="w-[10%] min-w-[80px]">Bounced</th>
            <th className="w-[5%] min-w-[50px]"></th>
          </tr>
        </thead>
        <tbody>
          {takes.map((take) => {
            return (
              <tr key={take.id}>
                <td>
                  <Link
                    to={
                      take.status === "Draft"
                        ? `/takes/${take.id}/edit`
                        : `/takes/${take.id}`
                    }
                  >
                    {take.subject}
                  </Link>
                </td>
                <td>{take.status}</td>
                <td>
                  {take.exclude_from_visually_archive ? "Hidden" : "Visible"}
                </td>
                <td>{take.sent}</td>
                <td>{take.opened}</td>
                <td>{take.clicked}</td>
                <td>{take.unsubscribed}</td>
                <td>{take.bounced}</td>
                <td className="!text-right">
                  <ItemMenu
                    take={take}
                    setTakeId={setTakeId}
                    getTakeListResponse={getTakeListResponse}
                    setScheduleAlert={setScheduleAlert}
                    setDeleteAlertOpen={setDeleteAlertOpen}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal
        open={scheduleAlert}
        onClose={() => setScheduleAlert(false)}
        title="Cancel Schedule"
      >
        <div className="flex flex-col w-full gap-10">
          <div className="flex flex-col w-full gap-6">
            <p>Are you sure you want to cancel the schedule?</p>
          </div>

          <div className="flex gap-4">
            <Button
              type="button"
              label="Yes, Cancel Schedule"
              loading={btnLoader}
              style="inverted"
              onClick={() => cancelScheduledTakeAPI()}
              bodyBackground="primary"
            />

            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => setScheduleAlert(false)}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={deleteAlertOpen}
        onClose={() => setDeleteAlertOpen(false)}
        title="Delete Take"
      >
        <div className="flex flex-col w-full gap-10">
          <div className="flex flex-col w-full gap-6">
            <p>Are you sure you want to delete the take?</p>
          </div>

          <div className="flex gap-4">
            <Button
              type="button"
              label="Yes, Delete"
              loading={btnLoader}
              style="inverted"
              onClick={() => destroyTakeAPI()}
              bodyBackground="primary"
            />
            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => setDeleteAlertOpen(false)}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Table;
