import React from "react";

import { PBIcons } from "@/components/PBIcons";

const PriceInfo = ({ title }) => {
  return (
    <div className="flex items-start gap-2 w-full">
      <PBIcons icon="Check" className="shrink-0" />
      <p className="text-title">{title}</p>
    </div>
  );
};

export default PriceInfo;
