import React from "react";

import { useAuth } from "@/hooks/useAuth";

const LogoHeader = () => {
  const { user } = useAuth();

  return (
    <div className="rounded-full overflow-hidden h-24 w-24">
      <img
        className="h-full w-full object-cover"
        src={user.logo_url}
        alt="User Logo"
      />
    </div>
  );
};

export default LogoHeader;
