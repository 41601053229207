import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { showToastrSuccess } from "@/lib/commons";
import { ORDINALIZED_DATE_FORMAT } from "@/lib/constants";
import { PBIcons } from "@/components/PBIcons";

import ItemMenu from "./ItemMenu";

const Table = ({ users }) => {
  dayjs.extend(advancedFormat);
  const [emailCopied, setEmailCopied] = useState(false);

  useEffect(() => {
    if (emailCopied) {
      showToastrSuccess("Info copied to clipboard!");
      setEmailCopied(false);
    }
  }, [emailCopied]);

  return (
    <div className="overflow-x-auto">
      <table className="pb-data-table">
        <thead>
          <tr>
            <th className="w-[30%] min-w-[300px]">Name</th>
            <th className="w-[20%] min-w-[120px]">URL</th>
            <th className="w-[25%] min-w-[80px]">Email</th>
            <th className="w-[20%] min-w-[80px]">Last Take</th>
            <th className="w-[5%] min-w-[50px]"></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return (
              <tr key={user.id}>
                <td>
                  <Link to={`/super_admin/users/${user.id}`}>
                    <div className="flex items-center gap-3">
                      <div className="h-10 w-10 overflow-hidden rounded-full border-2 border-black shrink-0">
                        <img
                          src={user.logo_url}
                          className="aspect-square object-cover"
                          alt="user logo"
                        />
                      </div>
                      <div className="flex flex-col gap-1">
                        <span>{user.name}</span>

                        <div className="flex items-center gap-4 text-small text-muted">
                          <span className="flex items-center gap-1.5">
                            <PBIcons icon="People" size={16} />
                            <span>{user.subscriber_size}</span>
                          </span>

                          <span className="flex items-center gap-1.5">
                            <PBIcons icon="Email" size={20} />
                            <span>{user.takes_count}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </td>
                <td>
                  <div className="flex items-center gap-2">
                    <span>/{user.url_slug}</span>
                    <CopyToClipboard
                      text={`${window.location.host}/${user.url_slug}`}
                      onCopy={() => setEmailCopied(true)}
                    >
                      <PBIcons
                        icon="Clipboard"
                        size={16}
                        className="cursor-pointer"
                      />
                    </CopyToClipboard>
                  </div>
                </td>
                <td>
                  <div className="flex items-center gap-2">
                    <span>{user.email}</span>
                    <CopyToClipboard
                      text={user.email}
                      onCopy={() => setEmailCopied(true)}
                    >
                      <PBIcons
                        icon="Clipboard"
                        size={16}
                        className="cursor-pointer"
                      />
                    </CopyToClipboard>
                  </div>
                </td>
                <td>
                  {user.last_take_sent_at
                    ? dayjs(user.last_take_sent_at).format(
                        ORDINALIZED_DATE_FORMAT
                      )
                    : ""}
                </td>
                <td className="!text-right">
                  <ItemMenu user={user} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
