import React, { useState, useEffect } from "react";

const Carousel = ({ autoPlay, images, setDataLoaded }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [carouselImages, setCarouselImages] = useState([]);
  const [firstImage, setFirstImage] = useState({});
  const [transitionEnabled, setTransitionEnabled] = useState(true);
  const [imgsLoaded, setImgsLoaded] = useState(false);

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.url;
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image.url);
          }, 2000);

        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(carouselImages.map((image) => loadImage(image)))
      .then(() => {
        setImgsLoaded(true);
        setTimeout(() => {
          setDataLoaded(true);
        }, 50);
      })
      .catch((err) => console.log("Failed to load images", err));
  }, []);

  useEffect(() => {
    setFirstImage(images[0]);
    setCarouselImages([images[images.length - 1], ...images, images[0]]);
  }, [images]);

  useEffect(() => {
    currentIndex > 1 && setTransitionEnabled(true);
  }, [currentIndex]);

  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [autoPlay]);

  const handleTransitionEnd = () => {
    if (currentIndex === carouselImages.length - 1) {
      setTransitionEnabled(false);
      setCurrentIndex(1);
    }
  };

  const aspectRatio = firstImage.width / firstImage.height;

  if (!imgsLoaded) {
    return null;
  }

  return (
    <div className="pb-carousel">
      <div className="pb-carousel-container">
        {carouselImages.map((image, index) => {
          return (
            <div
              key={index}
              className="pb-carousel-item"
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                aspectRatio: aspectRatio,
                transition: transitionEnabled ? undefined : "none",
              }}
              onTransitionEnd={() => handleTransitionEnd()}
            >
              <img
                src={image.attachment_url}
                className="w-full h-full object-cover"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
