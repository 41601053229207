import React, { useState, useEffect } from "react";

import { PBIcons } from "@/components/PBIcons";
import Input from "@/components/Input";
import { isValidUrl } from "@/lib/utils";
import AsyncPaginateSelect from "@/components/AsyncPaginateSelect";

const ImageForm = ({
  images,
  setImages,
  imageIndex,
  changeImage,
  getTagsAPI,
  createTagAPI,
  tagList,
  tagListLoad,
  setSelectedImage,
  deleteImage,
  setDeleteImage,
}) => {
  const [image, setImage] = useState({ gallery_tags: [] });

  useEffect(() => {
    if (deleteImage !== undefined) {
      let position =
        deleteImage === 0 && images.length > 1
          ? 1
          : deleteImage === images.length
          ? deleteImage - 1
          : 0;

      setSelectedImage(position);
      setImage(images[position] || {});
      setDeleteImage();
    } else {
      setImage(images[imageIndex] || {});
    }
  }, [deleteImage, imageIndex]);

  const leftClickDisabled = () => {
    return imageIndex === 0;
  };

  const rightClickDisabled = () => {
    return imageIndex + 1 === images.length;
  };

  const addValue = (attr, value) => {
    const updatedImages = images.map((att, index) => {
      if (imageIndex === index) {
        if (attr === "link") {
          if (!isValidUrl(value)) {
            return {
              ...att,
              [attr]: value,
              linkError: "Please add valid link!",
            };
          } else {
            return {
              ...att,
              [attr]: value,
              linkError: "",
            };
          }
        } else {
          return { ...att, [attr]: value };
        }
      } else {
        if (attr === "hero_image" && value === true) {
          return { ...att, hero_image: false };
        } else {
          return { ...att };
        }
      }
    });
    setImages(updatedImages);
  };

  const createTagApiResponse = async (option) => {
    const newTag = await createTagAPI(option);
    addValue("gallery_tag_ids", [...(image.gallery_tag_ids || []), newTag]);
    setImage({
      ...image,
      gallery_tag_ids: [...(image.gallery_tag_ids || []), newTag],
    });
  };

  const tagListWithGalleryTags = [
    ...tagList,
    ...(image.gallery_tags || []),
  ].reduce((unique, o) => {
    if (!unique.some((obj) => obj.label === o.label && obj.value === o.value)) {
      unique.push(o);
    }
    return unique;
  }, []);

  return (
    <div className="flex flex-col gap-8 w-full h-fit p-4 md:p-8 rounded bg-option-card-background">
      <div className="flex">
        <div className="flex items-center justify-center md:pl-6 md:pr-5 shrink-0">
          <PBIcons
            icon="ChevronLeft"
            className={
              leftClickDisabled()
                ? "text-muted cursor-not-allowed"
                : "text-inverted cursor-pointer"
            }
            onClick={() =>
              changeImage(leftClickDisabled() ? imageIndex : imageIndex - 1)
            }
          />
        </div>

        <div className="flex justify-center items-center w-full rounded">
          <img
            src={image.file_data || image.attachment_url}
            className="w-full object-contain aspect-[3/2] rounded"
          />
        </div>

        <div className="flex items-center justify-center md:pl-5 md:pr-6 shrink-0">
          <PBIcons
            icon="ChevronRight"
            className={
              rightClickDisabled()
                ? "text-muted cursor-not-allowed"
                : "text-inverted cursor-pointer"
            }
            onClick={() =>
              changeImage(rightClickDisabled() ? imageIndex : imageIndex + 1)
            }
          />
        </div>
      </div>

      <div className="flex flex-col gap-4 w-full">
        <Input
          label="Link"
          placeholder="http://www.example.com"
          value={image.link || ""}
          onChange={(e) => {
            addValue("link", e.target.value);
            setImage({ ...image, link: e.target.value });
          }}
          bodyBackground="option-card"
          style="option-card"
        />

        <Input
          label="Alt Text"
          placeholder="Add alt text"
          value={image.alt_text || ""}
          onChange={(e) => {
            addValue("alt_text", e.target.value);
            setImage({ ...image, alt_text: e.target.value });
          }}
          bodyBackground="option-card"
          style="option-card"
        />

        <AsyncPaginateSelect
          isMulti
          cacheOptions
          isSearchable
          isClearable
          isCreatable
          debounceTimeout={300}
          isLoading={tagListLoad}
          id="tags"
          name="tags"
          label="Tags"
          placeholder="Select a tag"
          strategy="fixed"
          style="option-card"
          bodyBackground="option-card"
          value={tagListWithGalleryTags?.filter((tag) =>
            image.gallery_tag_ids?.includes(tag.value)
          )}
          onChange={(opt) => {
            setImage({
              ...image,
              gallery_tag_ids: opt.map((tag) => tag.value),
            });
            if (opt?.length > 0) {
              addValue(
                "gallery_tag_ids",
                opt.map((tag) => tag.value)
              );
            } else {
              addValue("gallery_tag_ids", []);
            }
          }}
          loadOptions={getTagsAPI}
          defaultOptions={tagList}
          additional={{ page: 1 }}
          onCreateOption={(option) => createTagApiResponse(option)}
        />

        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => {
            setImage({
              ...image,
              hero_image: !image.hero_image,
            });
            addValue("hero_image", !image.hero_image);
          }}
        >
          <PBIcons icon="Attachment" />
          <span>
            {image.hero_image ? "Remove from Hero Image" : "Set as hero image"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImageForm;
