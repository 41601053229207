import React from "react";
import { components } from "react-select";
import { withAsyncPaginate, AsyncPaginate } from "react-select-async-paginate";
import Creatable from "react-select/creatable";
import classnames from "classnames";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const STRATEGIES = {
  default: "default",
  fixed: "fixed",
};

const CustomInput = (props) => {
  return <components.Input {...props}></components.Input>;
};

const AsyncPaginateSelect = ({
  name,
  label,
  error,
  helpText,
  isCreatable = false,
  strategy = STRATEGIES.default,
  bodyBackground = "inverted",
  style = "inverted",
  ...otherProps
}) => {
  let Parent = AsyncPaginate;

  if (isCreatable) {
    Parent = CreatableAsyncPaginate;
  }

  const portalProps = strategy === STRATEGIES.fixed && {
    menuPortalTarget: document.body,
    styles: { menuPortal: (base) => ({ ...base, zIndex: 999999 }) },
    menuPosition: "fixed",
  };

  const STYLES = {
    primary: "bg-primary text-inverted placeholder:text-primary-hover",
    inverted: "bg-inverted text-primary placeholder:text-muted",
    "option-card":
      "bg-option-card-background text-inverted placeholder:text-disabled-background",
  };

  const BORDER_STYLES = {
    primary: "border-muted",
    inverted: "border-primary-border",
    "option-card": "border-muted",
  };

  const RING_STYLES = {
    inverted: "focus-within:shadow-secondary-ring-inverted-bg",
    primary: "focus-within:shadow-secondary-ring-primary-bg",
    "inverted-hover": "focus-within:shadow-secondary-ring-inverted-hover-bg",
    "option-card": "focus-within:shadow-secondary-ring-option-card-bg",
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      {!!label && (
        <label htmlFor={name} className="pb-input-label">
          {label}
        </label>
      )}

      <Parent
        id={name}
        className={classnames(
          "pb-select-container rounded-sm transition-all duration-300",
          STYLES[style],
          BORDER_STYLES[style],
          RING_STYLES[bodyBackground]
        )}
        classNamePrefix="pb-select"
        components={{
          Input: CustomInput,
        }}
        {...portalProps}
        {...otherProps}
      />

      {!!error && <p className="pb-error-text">{error}</p>}
      {!!helpText && <p className="pb-help-text">{helpText}</p>}
    </div>
  );
};

export default AsyncPaginateSelect;
