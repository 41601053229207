import React from "react";

import Loader from "@/components/Loader";

const InfiniteLoader = () => {
  return (
    <div className="flex justify-center items-center gap-2 w-full mt-10">
      <Loader />
      <span>Loading...</span>
    </div>
  );
};

export default InfiniteLoader;
