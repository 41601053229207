import React from "react";
import classnames from "classnames";

const BlankState = ({ style = "primary", children }) => {
  const STYLES = {
    primary: "bg-inverted border-primary-hover",
    inverted: "bg-inverted-hover border-muted",
  };

  return (
    <div
      className={classnames(
        "flex flex-col justify-center items-center w-full h-60 p-3 rounded-sm border border-dashed",
        STYLES[style]
      )}
    >
      {children}
    </div>
  );
};

export default BlankState;
