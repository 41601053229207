import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import pluralize from "pluralize";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import { getTake, updateTake, fetchStripeSubscription } from "@/apis/takes";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { useAuth } from "@/hooks/useAuth";
import Loader from "@/components/Loader";
import Button from "@/components/Button";
import Switch from "@/components/Switch";
import FollowYourself from "@/components/FollowYourSelf";
import AudienceImportModal from "@/components/AudienceImportModal";
import { PREVIOUS_TAKE_ROUTE } from "@/lib/routes";
import { SUBSCRIBER_TYPES, MINIMUM_EMAIL_COUNT } from "@/lib/constants";

import Navbar from "./Navbar";
import StepCard from "./StepCard";
import RecipientDropdown from "./RecipientDropdown";
import Input from "./Input";
import BundleModal from "./BundleModal";
import ScheduleModal from "./ScheduleModal";
import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";
import { PBIcons } from "@/components/PBIcons";

const SendTakePage = () => {
  dayjs.extend(utc);
  dayjs.extend(advancedFormat);

  const { takeId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [takeDetail, setTakeDetail] = useState();
  const [pageLoader, setPageLoader] = useState(true);
  const [takeUser, setTakeUser] = useState();
  const [takeDetailLoad, setTakeDetailLoad] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [bundleModal, setBundleModal] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [audienceImportModalOpen, setAudienceImportModalOpen] = useState(false);
  const [excludeFromArchive, setExcludeFromArchive] = useState(true);
  const [sendNowBtnLoader, setSendNowBtnLoader] = useState(false);
  const [updateBtnLoader, setUpdateBtnLoader] = useState(false);
  const [scheduleBtnLoader, setScheduleBtnLoader] = useState(false);
  const [followYourselfModalOpen, setFollowYourselfModalOpen] = useState(false);

  const { handleSubmit, formState, setValue, reset, watch } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: INITIAL_VALUE,
    shouldValidate: true,
    shouldDirty: true,
    mode: "onChange",
  });

  useEffect(() => {
    getSubscriptionAPI();
  }, []);

  useEffect(() => {
    if (takeId) {
      getTakeAPI();
    }
  }, [takeId]);

  useEffect(() => {
    if (takeDetail?.id && takeUser?.id) {
      if (takeDetail.publish_type == "web_only") {
        if (takeDetail.sent) {
          setCurrentStep(4);
        } else if (!!takeDetail.subject && hasSubscribers()) {
          setCurrentStep(3);
        } else if (hasSubscribers()) {
          setCurrentStep(2);
        } else {
          setCurrentStep(1);
        }
      } else {
        if (
          !!takeDetail.subject_preview &&
          !!takeDetail.subject &&
          isTakeSendable() &&
          hasSubscribers()
        ) {
          setCurrentStep(stepNumber(5));
        } else if (!!takeDetail.subject_preview && hasSubscribers()) {
          setCurrentStep(stepNumber(4));
        } else if (!!takeDetail.subject && hasSubscribers()) {
          setCurrentStep(stepNumber(3));
        } else if (hasSubscribers()) {
          setCurrentStep(stepNumber(2));
        } else {
          setCurrentStep(1);
        }
      }
    }
  }, [takeDetail, takeUser]);

  const getSubscriptionAPI = async () => {
    try {
      const { data } = await fetchStripeSubscription();
      setTakeUser({
        ...user,
        takes_count: data.takes_count,
        free_subscriber_size: data.free_subscriber_size,
        paid_subscriber_size: data.paid_subscriber_size,
        free_email_count: data.user_free_email_count,
        paid_email_count: data.user_paid_email_count,
      });

      if (data.free_subscriber_size !== 0 || data.paid_subscriber_size !== 0) {
        setCurrentStep(2);
      }

      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getTakeAPI = async () => {
    try {
      // TODO: Why need to send true in the api call
      const { data } = await getTake(takeId, true);

      if (
        data.take.non_editable ||
        data.take.schedule ||
        data.take.attachments_attributes.lenght == 0
      ) {
        navigate(PREVIOUS_TAKE_ROUTE);
        showToastrError("You can't edit this take.");
      }

      setTakeDetail(data.take);

      if (!data.take.sent && data.take.publish_type == "email_only") {
        setExcludeFromArchive(false);
      } else {
        setExcludeFromArchive(data.take.exclude_from_visually_archive);
      }

      reset({ ...data.take });

      if (data.take.sent) {
        setValue("update", true);
      }

      setTakeDetailLoad(false);
    } catch (error) {
      if (error.response.status === 404) navigate("/not_found");
      showToastrError(error.response.data.errors);
    }
  };

  const updateTakeAPI = async () => {
    try {
      let payload = { ...watch(), cover_image_attributes: {} };

      if (watch().scheduled_at) {
        payload = {
          ...payload,
          scheduled_at: dayjs(watch().scheduled_at).utc().format(),
        };
      }

      if (toggleVisible()) {
        payload = {
          ...payload,
          exclude_from_visually_archive: excludeFromArchive,
        };
      }

      const { data } = await updateTake(watch().id, { take: payload });
      setTakeDetail({ ...takeDetail, ...data.take });

      if (data.take.sent_at && !watch().update) {
        navigate(PREVIOUS_TAKE_ROUTE);
        showToastrSuccess(
          data.take.publish_type == "web_only"
            ? "Take Published!"
            : "Take Sent!"
        );
      }

      if (data.take.scheduled_at && !watch().update) {
        navigate(`/takes/${data.take.id}/edit`);
        showToastrSuccess("Take Scheduled!");
      }

      if (watch().update && watch().update_clicked) {
        navigate(`/takes/${data.take.id}/edit`);
        showToastrSuccess("Take Updated!");
      }

      if (data.take.under_review) {
        navigate(PREVIOUS_TAKE_ROUTE);
        showToastrError(
          "Hello! We're just manually reviewing your take at the moment. Please hold tight take will be sent shortly."
        );
      }
    } catch (error) {
      if (error.response.data.errors == "Attachments can't be blank") {
        setValue("schedule", false);
        setValue("send_now", false);
      }
      showToastrError(error.response.data.errors);
    } finally {
      setScheduleBtnLoader(false);
      setUpdateBtnLoader(false);
      setSendNowBtnLoader(false);
      setScheduleModalOpen(false);
    }
  };

  const handleSubmitWithStatus = () => {
    if (watch().update) {
      setUpdateBtnLoader(true);
      handleTakeSubmit("update");
    } else if (watch().schedule) {
      handleTakeSubmit("schedule");
    } else if (watch().draft) {
      setUpdateBtnLoader(true);
      handleTakeSubmit("draft");
    } else {
      handleTakeSubmit("send_now");
    }
  };

  const handleTakeSubmit = async (action) => {
    switch (action) {
      case "update":
        setValue("draft", false);
        setValue("schedule", false);
        setValue("send_now", false);
        setValue("update", true);
        break;
      case "draft":
        setValue("draft", true);
        setValue("schedule", false);
        setValue("send_now", false);
        setValue("update", false);
        break;
      case "schedule":
        setValue("draft", false);
        setValue("schedule", true);
        setValue("send_now", false);
        setValue("update", false);
        break;
      default:
        setValue("draft", false);
        setValue("schedule", false);
        setValue("send_now", true);
        setValue("update", false);
    }

    handleSubmit(updateTakeAPI());
  };

  const subscriberSize = () => {
    if (watch().subscribers_type === SUBSCRIBER_TYPES.free) {
      return takeUser.free_subscriber_size;
    } else if (watch().subscribers_type === SUBSCRIBER_TYPES.paid) {
      return takeUser.paid_subscriber_size;
    } else {
      return Number(
        takeUser.free_subscriber_size + takeUser.paid_subscriber_size
      );
    }
  };

  const getShareType = () => {
    if (takeDetail.publish_type == "web_and_email") {
      return "Web and Email";
    } else if (takeDetail.publish_type == "email_only") {
      return "Email only";
    } else if (takeDetail.publish_type == "web_only") {
      return "Web only";
    }
  };

  const totalLeftEmail = () => {
    return takeUser.free_email_count + takeUser.paid_email_count;
  };

  const extraEmailCount = () => {
    return subscriberSize() - totalLeftEmail();
  };

  const bellowMinimumEmail = () => {
    return extraEmailCount() < MINIMUM_EMAIL_COUNT;
  };

  const purchaseEmailCount = () => {
    return bellowMinimumEmail() ? MINIMUM_EMAIL_COUNT : extraEmailCount();
  };

  const isTakeSendable = () => {
    return takeUser.vip || totalLeftEmail() >= subscriberSize();
  };

  const hasSubscribers = () => {
    return (
      takeUser.free_subscriber_size > 0 || takeUser.paid_subscriber_size > 0
    );
  };

  const totalAmount = () => {
    return (0.003 * purchaseEmailCount()).toFixed(2);
  };

  const sendDisplayable = () => {
    if (takeDetail.publish_type == "web_only") {
      return currentStep >= 2;
    } else {
      return currentStep >= stepNumber(4);
    }
  };

  const sendDisabled = () => {
    if (takeDetail.publish_type == "web_only" && takeDetail.sent) {
      return currentStep != 4;
    } else if (takeDetail.publish_type == "web_only") {
      return currentStep != 3;
    } else {
      return currentStep !== stepNumber(5);
    }
  };

  const stepNumber = (number) => {
    return toggleVisible() ? number + 1 : number;
  };

  const showTitle = () => {
    return takeDetail.sent || takeDetail.publish_type == "web_only";
  };

  const subjectPlaceholder = () => {
    if (showTitle()) {
      return "Enter the title of your take";
    } else {
      return "Enter the subject line for your take";
    }
  };

  const subjectInfo = () => {
    if (takeDetail.sent) {
      return "This will update the title in your archive only.";
    } else {
      if (takeDetail.publish_type == "web_only") {
        return "This will appear in your visual archive.";
      } else {
        return "This will appear in the subject line of the email.";
      }
    }
  };

  const toggleVisible = () => {
    return takeDetail.publish_type == "email_only" || takeDetail.sent;
  };

  const getPageTitle = () => {
    if (takeDetail.sent) {
      return "Edit Take";
    } else if (takeDetail.publish_type == "web_only") {
      return "Publish Take";
    } else {
      return "Send Take";
    }
  };

  if (!takeUser || takeDetailLoad || pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-page-wrapper bg-inverted">
      <Navbar takeId={takeId} updateBtnLoader={updateBtnLoader} />

      <div className="pb-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-8 w-full max-w-[var(--take-width)]">
          <div className="flex justify-between items-center gap-2 w-full">
            <h2 className="text-heading font-bold">{getPageTitle()}</h2>

            <span className="px-2 py-1 rounded-sm text-label text-inverted bg-muted">
              {getShareType()}
            </span>
          </div>

          <div className="flex flex-col items-center w-full border-b border-solid border-primary-border">
            <StepCard
              step="1"
              title="Visibility"
              active={currentStep == 1}
              completed={currentStep > 1}
            >
              <div className="flex flex-col w-full gap-6">
                {!hasSubscribers() ? (
                  <div className="flex flex-col w-full gap-6">
                    <h3 className="text-heading font-bold">
                      You have no subscribers
                    </h3>

                    <p>
                      If you are migrating from another platform, you can import
                      a CSV of your subscribers here or manage your audience
                      manually.
                    </p>

                    <div className="flex items-center gap-4">
                      <Button
                        label="Import"
                        icon={<PBIcons icon="Import" />}
                        onClick={() => setAudienceImportModalOpen(true)}
                      />

                      <Link to="/subscribers">
                        <Button label="Manage Subscribers" style="inverted" />
                      </Link>
                    </div>
                  </div>
                ) : takeUser.paid_subscription_enabled ? (
                  <RecipientDropdown
                    takeUser={takeUser}
                    formState={watch()}
                    setValue={setValue}
                    handleSubmitWithStatus={handleSubmitWithStatus}
                  />
                ) : (
                  <h3 className="text-heading font-bold">
                    All subscribers ({subscriberSize()})
                  </h3>
                )}

                <p>Send to paid subscribers, free subscribers, or both.</p>
              </div>
            </StepCard>

            {toggleVisible() && (
              <StepCard
                step="2"
                title="Visual Archive"
                active={currentStep == 2}
                completed={currentStep > 2}
              >
                <div className="flex flex-col w-full gap-6">
                  <div className="flex items-center gap-3 w-full">
                    <h3 className="text-title lg:text-heading font-bold">
                      Would you like this to appear in your archive?
                    </h3>

                    <Switch
                      checked={!excludeFromArchive}
                      onChange={() =>
                        setExcludeFromArchive(!excludeFromArchive)
                      }
                      disabled={currentStep < 2}
                      className="!w-fit"
                    />
                  </div>

                  <p>
                    This will {excludeFromArchive && "not"} appear in your
                    visual archive.
                  </p>
                </div>
              </StepCard>
            )}

            <StepCard
              step={stepNumber(2)}
              title={showTitle() ? "Title" : "Subject"}
              active={currentStep == stepNumber(2)}
              completed={currentStep > stepNumber(2)}
              disabled={takeDetail.sent && excludeFromArchive}
            >
              <div className="flex flex-col w-full gap-6">
                {currentStep < stepNumber(2) ? (
                  <h3 className="text-title lg:text-heading font-bold">
                    {subjectPlaceholder()}
                  </h3>
                ) : (
                  <Input
                    name="subject"
                    placeholder={subjectPlaceholder()}
                    setValue={setValue}
                    value={watch().subject}
                    readOnly={takeDetail.sent && excludeFromArchive}
                    handleSubmitWithStatus={handleSubmitWithStatus}
                  />
                )}

                <p>{subjectInfo()}</p>
              </div>
            </StepCard>

            {takeDetail.publish_type != "web_only" && (
              <>
                <StepCard
                  step={stepNumber(3)}
                  title="Preview"
                  active={currentStep == stepNumber(3)}
                  disabled={takeDetail.sent}
                  completed={currentStep > stepNumber(3)}
                >
                  <div className="flex flex-col w-full gap-6">
                    {currentStep < stepNumber(3) ? (
                      <h3 className="text-title lg:text-heading font-bold">
                        Enter the preview
                      </h3>
                    ) : (
                      <Input
                        name="subject_preview"
                        placeholder="Enter the preview"
                        setValue={setValue}
                        readOnly={takeDetail.sent}
                        value={watch().subject_preview}
                        handleSubmitWithStatus={handleSubmitWithStatus}
                      />
                    )}

                    <p>
                      This will appear after the subject line in some email
                      clients.
                    </p>
                  </div>
                </StepCard>

                {!takeDetail.sent && (
                  <StepCard
                    step={stepNumber(4)}
                    title="Review and send"
                    active={currentStep == stepNumber(4)}
                    completed={currentStep > stepNumber(4)}
                  >
                    {!hasSubscribers() ? (
                      <div className="flex flex-col w-full gap-6">
                        <h3 className="text-heading font-bold">
                          You have {totalLeftEmail()}{" "}
                          {pluralize("email", totalLeftEmail())} left in your
                          plan
                        </h3>
                        <p>Pay for extra emails if you need them.</p>
                      </div>
                    ) : (
                      <div className="flex flex-col w-full gap-6">
                        <h3 className="text-heading font-bold">
                          {subscriberSize()}{" "}
                          {pluralize("email", subscriberSize())}
                        </h3>

                        {takeUser.vip ? (
                          <p>You have unlimited email credits.</p>
                        ) : (
                          <p>
                            You have {totalLeftEmail()} email{" "}
                            {pluralize("credit", totalLeftEmail())}. You need{" "}
                            {extraEmailCount()} email{" "}
                            {pluralize("credit", extraEmailCount())} to send.
                          </p>
                        )}

                        {!isTakeSendable() && currentStep == stepNumber(4) && (
                          <div className="flex flex-col gap-2 w-full rounded p-6 bg-[#E5E7F5]">
                            <div className="flex items-start justify-between gap-2 w-full">
                              <h4 className="text-title font-bold">
                                Additional email credits required to send
                              </h4>

                              <Button
                                label="Pay Now"
                                suffixIcon={<PBIcons icon="ArrowRight" />}
                                onClick={() => setBundleModal(true)}
                                className="shrink-0"
                                bodyBackground="inverted-hover"
                              />
                            </div>

                            <div className="flex flex-col gap-2 w-full">
                              <div className="flex items-end gap-1 w-full font-bold !leading-none">
                                <div>
                                  <sup
                                    style={{
                                      fontFeatureSettings: "sups",
                                      fontSize: "32px",
                                    }}
                                  >
                                    $
                                  </sup>
                                  <span className="text-[48px]">
                                    {totalAmount()}
                                  </span>
                                </div>

                                <span className="pb-1">
                                  {purchaseEmailCount()} emails
                                </span>
                              </div>

                              <p className="text-small">.003c per email</p>
                            </div>

                            {bellowMinimumEmail() && (
                              <div className="flex items-start gap-2 w-full px-2 py-1 mt-3 rounded border border-solid border-[#AFB4E0]">
                                <PBIcons icon="Notice" className="shrink-0" />
                                <p className="text-small font-bold">
                                  Minimum transaction {MINIMUM_EMAIL_COUNT}{" "}
                                  emails. Extra email credits will be saved on
                                  your account for next time.
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </StepCard>
                )}
              </>
            )}
          </div>

          {sendDisplayable() && !takeDetail.sent && (
            <div className="flex items-center gap-4 w-full">
              <Button
                label={
                  takeDetail.publish_type === "web_only" ? "Publish" : "Send"
                }
                disabled={sendDisabled()}
                loading={sendNowBtnLoader}
                onClick={() => {
                  setSendNowBtnLoader(true);
                  handleTakeSubmit();
                }}
              />

              <Button
                label={
                  takeDetail.publish_type === "web_only"
                    ? "Schedule publish"
                    : "Schedule send"
                }
                disabled={sendDisabled()}
                style="inverted"
                loading={scheduleBtnLoader}
                onClick={() => setScheduleModalOpen(true)}
              />
            </div>
          )}

          {takeDetail.sent && (
            <Button
              label="Update"
              disabled={sendDisabled()}
              loading={sendNowBtnLoader}
              onClick={() => {
                setSendNowBtnLoader(true);
                setValue("update_clicked", true);
                handleTakeSubmit("update");
              }}
            />
          )}
        </div>
      </div>

      <BundleModal
        take={takeDetail}
        open={bundleModal}
        setOpen={setBundleModal}
        emailCount={purchaseEmailCount()}
        totalAmount={totalAmount()}
      />

      <ScheduleModal
        modalOpen={scheduleModalOpen}
        setModalOpen={setScheduleModalOpen}
        formState={watch()}
        setValue={setValue}
        errors={formState.errors}
        handleTakeSubmit={handleTakeSubmit}
        scheduleBtnLoader={scheduleBtnLoader}
        setScheduleBtnLoader={setScheduleBtnLoader}
      />

      <AudienceImportModal
        uploadModalOpen={audienceImportModalOpen}
        setUploadModalOpen={setAudienceImportModalOpen}
      />

      <FollowYourself
        modalOpen={followYourselfModalOpen}
        setModalOpen={setFollowYourselfModalOpen}
        updateState={getSubscriptionAPI}
        userId={takeUser.id}
        email={takeUser.email}
      />
    </div>
  );
};

export default SendTakePage;
