import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";

import { PBIcons } from "@/components/PBIcons";
import { MAX_IMAGE_SIZE } from "@/lib/constants";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
  transition: "box-shadow",
  cursor: "pointer",
  padding: "48px 10px",
  width: "100%",
  borderWidth: "1px",
  borderStyle: "dashed",
  borderColor: "var(--color-white)",
  borderRadius: "var(--default-radius)",
};

const focusedStyle = {
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "var(--color-inverted)",
};

const acceptStyle = {
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "var(--color-secondary)",
};

const rejectStyle = {
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "var(--color-danger)",
};

const cursorNotAllowedStyle = {
  cursor: "not-allowed",
};

const formatErrorMessage = (code, msg) => {
  if (code == "too-many-files") {
    return "Maximum of 12 images can be added to a gallery";
  } else if (code == "file-too-large") {
    return "Attachment size exceeds the allowable limit (5 MB).";
  } else {
    return msg;
  }
};

const MultiUploader = ({ handleChange, uploadInProgress }) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
    fileRejections,
  } = useDropzone({
    maxSize: MAX_IMAGE_SIZE,
    minFiles: 12,
    maxFiles: 12,
    multiple: true,
    accept: {
      "image/*": [".jpeg", ".png", ".gif", ".webp", ".avif", ".jpg"],
    },
    onDrop: (acceptedFiles) => {
      handleChange(acceptedFiles);
    },
    noClick: uploadInProgress(),
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragActive ? focusedStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(uploadInProgress() ? cursorNotAllowedStyle : {}),
      ...(fileRejections.length > 0 ? rejectStyle : {}),
    }),
    [
      isFocused,
      isDragAccept,
      isDragReject,
      isDragActive,
      uploadInProgress(),
      fileRejections,
    ]
  );

  return (
    <div className="flex flex-col items-center gap-5 w-full max-w-[var(--take-width)]">
      <p className="text-small text-center">
        Maximum of 12 images can be added to a gallery
      </p>

      <div className="flex flex-col gap-2 w-full">
        <div {...getRootProps({ style })} className="text-muted">
          <input multiple {...getInputProps()} />

          <div className="flex flex-col justify-start items-center gap-4">
            <PBIcons icon="Plus" className="text-inverted" />

            <p className="text-center">
              Drag and drop or <span className="underline">browse</span> to add
              images
            </p>
          </div>
        </div>

        {fileRejections?.length > 0 && (
          <p className="text-danger">
            {fileRejections[0].errors.map((e) =>
              formatErrorMessage(e.code, e.message)
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default MultiUploader;
