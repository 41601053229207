import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import classnames from "classnames";

import { PBIcons } from "@/components/PBIcons";
import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";
import Divider from "@/components/Divider";
import { useAuth } from "@/hooks/useAuth";
import { getSubscribeUrl } from "@/lib/utils";
import { PROFILE_SETTINGS_ROUTE, REVIEW_TAKES_ADMIN_ROUTE } from "@/lib/routes";

const UserDropdown = ({ style, setFeedbackModalOpen, setEmailCopied }) => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Menu
      open={menuOpen}
      setOpen={setMenuOpen}
      width="200"
      position="bottom-end"
      style={style}
      border={false}
      menuTrigger={
        <div
          className="flex items-center gap-1 px-1 py-0.5"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <div className="flex items-center max-w-[200px] gap-2">
            <span className="line-clamp-1">{user.display_name}</span>
            <img
              src={user.logo_url}
              className="w-5 h-5 rounded-full object-cover shrink-0"
            />
          </div>

          <div
            className={classnames("pb-dropdown-trigger-icon", {
              active: menuOpen,
              inactive: !menuOpen,
            })}
          >
            <PBIcons icon="ChevronDown" />
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-1">
        <MenuItem
          label="Settings"
          style={style}
          onClick={() => navigate(PROFILE_SETTINGS_ROUTE)}
        />

        <MenuItem
          label="Copy signup link"
          style={style}
          onClick={() => {
            copyToClipboard(getSubscribeUrl(user.url_slug));
            setEmailCopied(true);
          }}
        />

        {user.superadmin && (
          <>
            <Divider style={style} />
            <MenuItem
              label="Super Admin"
              style={style}
              onClick={() => navigate(REVIEW_TAKES_ADMIN_ROUTE)}
            />
          </>
        )}

        <Divider style={style} />

        <MenuItem
          label="Request a Feature"
          style={style}
          onClick={() => setFeedbackModalOpen(true)}
        />

        <Link to={"https://pencilbooth.helpscoutdocs.com/"} target="_blank">
          <MenuItem label="Help" style={style} />
        </Link>

        <MenuItem label="Logout" style={style} onClick={() => logout()} />
      </div>
    </Menu>
  );
};

export default UserDropdown;
