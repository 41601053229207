import React from "react";

const FeatureCard = ({ title, description }) => {
  return (
    <div className="flex flex-col gap-8 w-full">
      <h3
        className="text-heading font-medium
"
      >
        {title}
      </h3>
      <p>{description}</p>
    </div>
  );
};

export default FeatureCard;
