import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { createSession } from "@/apis/sessions";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { MAX_STRING_LENGTH } from "@/lib/constants";
import Input from "@/components/Input";
import Button from "@/components/Button";
import VerificationToken from "@/components/TokenVerification";
import { LOGIN_INITIAL_VALUE, LOGIN_VALIDATION_SCHEMA } from "./constants";
import { JOIN_ROUTE } from "@/lib/routes";

const LoginPage = () => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [verificationToken, setVerificationToken] = useState();

  const { handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(LOGIN_VALIDATION_SCHEMA),
    defaultValues: LOGIN_INITIAL_VALUE,
  });

  const createSessionAPI = async (formState) => {
    try {
      setBtnLoader(true);
      const { data } = await createSession({
        session: { email: formState.email },
      });
      showToastrSuccess(data.message);
      setVerificationToken(data.token);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  if (verificationToken) {
    return <VerificationToken token={verificationToken} />;
  }

  return (
    <div className="pb-page-wrapper bg-inverted">
      <div className="pb-page-container flex justify-center items-center w-full p-6">
        <div className="flex flex-col items-center gap-6 max-w-[var(--auth-screen-width)]">
          <h1 className="text-title">Login</h1>

          <p className="text-center">
            Don't have an account? Go on and{" "}
            <Link to={JOIN_ROUTE} className="underline">
              claim your Booth
            </Link>{" "}
            now. All accounts include 500 free emails per month, and there are
            no subscription plans to sign up for or pay monthly. You simply pay
            for what you use, when you use it.
          </p>

          <form
            onSubmit={handleSubmit(createSessionAPI)}
            className="flex flex-col items-end gap-6 w-full"
          >
            <Input
              label="Email address"
              name="email"
              maxLength={MAX_STRING_LENGTH}
              placeholder="Email address"
              error={formState.errors.email?.message}
              onChange={(e) => setValue("email", e.target.value)}
              required
            />

            <Button type="submit" label="Login" loading={btnLoader} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
