import React from "react";
import classnames from "classnames";

import { PBIcons } from "@/components/PBIcons";
import { CURRENCY_SYMBOL } from "@/lib/constants";

const PlanCard = ({ plan, selectedPlanId, setSelectedPlanId }) => {
  const currencySymbol = (currency) => {
    if (CURRENCY_SYMBOL[currency] !== undefined) {
      return CURRENCY_SYMBOL[currency];
    } else {
      return "";
    }
  };

  const priceWithCurrency = () => {
    if (plan.interval === "free") {
      return "Free";
    } else {
      return `${currencySymbol(plan.currency)}${plan.unit_amount}/${
        plan.interval
      }`;
    }
  };

  return (
    <div
      className={`flex flex-col gap-4 w-full rounded-sm p-4 divide-y-[1px] transition-all duration-300 cursor-pointer ${
        selectedPlanId == plan.id
          ? "shadow-secondary-ring-primary-bg bg-inverted divide-primary-border text-primary"
          : "bg-option-card-background divide-muted text-inverted"
      }`}
      onClick={() => setSelectedPlanId(plan.id)}
    >
      <div className="flex flex-col w-full gap-3">
        <div className="flex justify-between gap-3">
          <span className="text-title font-bold">{plan.name}</span>

          {selectedPlanId == plan.id ? (
            <PBIcons icon="RadioFill" size={24} />
          ) : (
            <PBIcons icon="Radio" size={24} className="text-muted" />
          )}
        </div>
        <span
          className={`inline-block rounded-full py-1 px-3 border border-solid text-small font-bold w-fit transition-all duration-300 ${
            selectedPlanId == plan.id
              ? "bg-secondary text-inverted border-secondary"
              : "bg-inverted text-primary border-inverted"
          }`}
        >
          {priceWithCurrency()}
        </span>
      </div>

      {plan.benefits?.length > 0 && (
        <div className="flex flex-col w-full gap-4 pt-4">
          {plan.benefits.map((benefit, index) => {
            return (
              <div key={index} className="flex w-full gap-2">
                <div className="shrink-0">
                  <PBIcons icon="Tick" />
                </div>

                <span>{benefit}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PlanCard;
