import * as Yup from "yup";

import { MAX_STRING_LENGTH } from "@/lib/constants";

export const MESSAGE_INITIAL_VALUE = {
  welcome_message: "",
};

export const MESSAGE_VALIDATION_SCHEMA = Yup.object({
  welcome_message: Yup.string()
    .max(350, "Welcome message must be at most 350 characters.")
    .nullable(),
});

export const LINK_TREE_INITIAL_VALUE = {
  link_trees_attributes: [{ serial: 1, name: "", url: "" }],
};

export const LINK_TREE_VALIDATION_SCHEMA = Yup.object().shape({
  link_trees_attributes: Yup.array().of(
    Yup.object().shape({
      _destroy: Yup.boolean().nullable(),
      name: Yup.string().when("_destroy", {
        is: (val) => val === true,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) =>
          schema
            .required("Name can't be empty")
            .max(
              MAX_STRING_LENGTH,
              `Name must be at most ${MAX_STRING_LENGTH} characters.`
            ),
      }),
      url: Yup.string().when("_destroy", {
        is: (val) => val === true,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) =>
          schema
            .required("URL can't be empty")
            .max(
              MAX_STRING_LENGTH,
              `URL must be at most ${MAX_STRING_LENGTH} characters.`
            ),
      }),
    })
  ),
});

export const SOCIAL_LINKS_INITIAL_VALUE = {
  facebook_url: "",
  instagram_url: "",
  you_tube_url: "",
  twitter_url: "",
  dribble_url: "",
  behance_url: "",
  threads_url: "",
  tiktok_url: "",
};
