import React from "react";
import classnames from "classnames";

const BlankPlaceholder = ({ className }) => {
  return (
    <div
      className={classnames(
        "w-full h-12 rounded-sm bg-inverted-hover",
        className
      )}
    ></div>
  );
};

export default BlankPlaceholder;
