import React, { useRef } from "react";
import classnames from "classnames";

const Input = ({
  name,
  value,
  label = "",
  type = "text",
  helpText = "",
  error = "",
  className = "",
  prefix = "",
  suffix = "",
  bodyBackground = "inverted",
  style = "inverted",
  inputRef = useRef(null),
  ...otherProps
}) => {
  const STYLES = {
    primary: "bg-primary text-inverted placeholder:text-primary-hover",
    inverted: "bg-inverted text-primary placeholder:text-muted",
    "option-card":
      "bg-option-card-background text-inverted placeholder:text-disabled-background",
  };

  const BORDER_STYLES = {
    primary: "border-muted",
    inverted: "border-primary-border",
    "option-card": "border-muted",
  };

  const RING_STYLES = {
    inverted: "focus-within:shadow-secondary-ring-inverted-bg",
    primary: "focus-within:shadow-secondary-ring-primary-bg",
    "inverted-hover": "focus-within:shadow-secondary-ring-inverted-hover-bg",
    "option-card": "focus-within:shadow-secondary-ring-option-card-bg",
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      {!!label && (
        <label htmlFor={name} className="pb-input-label">
          {label}
        </label>
      )}

      <div
        className={classnames(
          "flex border border-solid rounded-sm transition-all duration-300",
          STYLES[style],
          BORDER_STYLES[style],
          RING_STYLES[bodyBackground],
          className
        )}
      >
        {prefix && (
          <span
            className={classnames(
              "pl-3 py-3 rounded-l-sm transition-all duration-300 shrink-0",
              {
                "text-muted": !(value && value.length > 0),
              }
            )}
          >
            {prefix}
          </span>
        )}

        <input
          ref={inputRef}
          id={name}
          name={name}
          type={type}
          value={value}
          className={classnames(
            "w-full py-3 outline-none grow",
            { "pl-3 rounded-l-sm": !prefix, "pr-3 rounded-r-sm": !suffix },
            STYLES[style],
            className
          )}
          {...otherProps}
        />

        {suffix && (
          <span
            className={classnames(
              "pr-3 py-3 rounded-r-sm transition-all duration-300 shrink-0",
              {
                "text-muted": !(value && value.length > 0),
              }
            )}
          >
            {suffix}
          </span>
        )}
      </div>

      {!!error && <p className="pb-error-text">{error}</p>}
      {!!helpText && <p className="pb-help-text">{helpText}</p>}
    </div>
  );
};

export default Input;
