import axios from "axios";

import { AUTH_TOKEN } from "@/lib/constants";

axios.defaults.baseURL = "/";

export const setAuthHeaders = async () => {
  axios.defaults.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const authToken = localStorage.getItem(AUTH_TOKEN);

  if (authToken) {
    axios.defaults.headers["Authorization"] = `Bearer ${authToken}`;
  }
};

export const resetAuthTokens = async () => {
  delete axios.defaults.headers["Authorization"];
};

const handleSuccessResponse = (response) => {
  return response;
};

const handleErrorResponse = (error, logout) => {
  if (error.response?.status === 401) {
    logout();
    // add alert for logout
  } else {
    // add alert for error
  }
  return Promise.reject(error);
};

export const registerIntercepts = (logout) => {
  axios.interceptors.response.use(handleSuccessResponse, (error) =>
    handleErrorResponse(error, logout)
  );
};
