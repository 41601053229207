import React from "react";

import EmptyTakeHeader from "@/assets/images/empty-take-header.jpg";

const Banner = ({ take, mobileView = false }) => {
  return (
    <div className="flex flex-col items-center w-full max-w-[var(--take-width)]">
      <div className="flex flex-col items-center gap-6 w-full">
        {take.header_image_type == "logo" ? (
          <div
            className={`rounded-full overflow-hidden ${
              mobileView ? "h-16 w-16" : "h-16 w-16 md:h-24 md:w-24"
            }`}
          >
            <img
              className="h-full w-full object-cover"
              src={take.user.logo_url}
              alt="User Logo"
            />
          </div>
        ) : (
          <img
            src={take.cover_image_attributes?.attachment_url || EmptyTakeHeader}
            className="h-full w-full rounded"
          />
        )}

        {take.user_title && (
          <h2
            className={`text-center font-bold ${
              mobileView ? "text-mobile-heading-xl" : "text-heading-xl"
            }`}
          >
            {take.user_title}
          </h2>
        )}
      </div>
    </div>
  );
};

export default Banner;
