import axios from "axios";

const getTakesUrl = (id, logged_in) => {
  if (id) {
    if (logged_in) {
      return `/api/v1/takes/${id}?logged_in=${logged_in}`;
    } else {
      return `/api/v1/takes/${id}`;
    }
  }

  return `/api/v1/takes`;
};

export const getTakeList = (pageIndex) => {
  return axios.get(`${getTakesUrl()}?page=${pageIndex}`);
};

// TODO: logged_in required?
export const getTake = (takeId, logged_in = false) => {
  return axios.get(getTakesUrl(takeId, logged_in));
};

export const createTake = (payload) => {
  return axios.post(getTakesUrl(), payload);
};

export const updateTake = (takeId, payload) => {
  return axios.put(getTakesUrl(takeId), payload);
};

export const destroyTake = (takeId) => {
  return axios.delete(getTakesUrl(takeId));
};

export const fetchStripeSubscription = () => {
  return axios.get(`/api/v1/takes/stripe_subscription`);
};

export const cancelScheduledTake = (takeId) => {
  return axios.get(`/api/v1/takes/${takeId}/cancel_schedule`);
};

export const toggleArchive = (takeId) => {
  return axios.put(`/api/v1/takes/${takeId}/toggle_archive`);
};
