import axios from "axios";

export const createAttachment = (takeId, payload, { onUploadProgress }) => {
  const config = {
    onUploadProgress: (progressEvent) => {
      const progress = parseInt(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );

      if (progress == 100) {
        onUploadProgress(90);
      } else {
        onUploadProgress(progress);
      }
    },
  };

  return axios.post(
    `/api/v1/takes/${takeId}/multiple_take_attachments`,
    payload,
    config
  );
};

export const updateAttachment = (takeId, payload) => {
  return axios.put(
    `/api/v1/takes/${takeId}/multiple_take_attachments`,
    payload
  );
};
