import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { createFeedbackSurvey } from "@/apis/feedback_surveys";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import Modal from "@/components/Modal";
import Textarea from "@/components/Textarea";
import Button from "@/components/Button";

import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";

const FeedbackForm = ({ modalOpen, setModalOpen }) => {
  const [btnLoader, setBtnLoader] = useState(false);
  const { handleSubmit, formState, setValue, watch } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: INITIAL_VALUE,
  });

  const sendFeedbackSurvey = async (formState) => {
    try {
      setBtnLoader(true);
      const { data } = await createFeedbackSurvey({
        feedback_survey: {
          message: formState.message,
        },
      });

      setModalOpen(false);
      showToastrSuccess(data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <Modal
      size="medium"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      title="Contact Us"
    >
      <div className="flex flex-col w-full gap-10">
        <form
          id="feedback-form"
          onSubmit={handleSubmit(sendFeedbackSurvey)}
          className="flex flex-col w-full gap-6"
        >
          <p>Have a question or feature request? Drop us a line here.</p>

          <Textarea
            id="message"
            name="message"
            value={watch().message}
            onChange={(e) => setValue("message", e.target.value)}
            error={formState.errors.message?.message}
            bodyBackground="primary"
            style="primary"
          />
        </form>

        <div className="flex gap-4">
          <Button
            form="feedback-form"
            type="submit"
            label="Send"
            loading={btnLoader}
            style="inverted"
            bodyBackground="primary"
          />
          <Button
            type="button"
            style="transparent"
            label="Cancel"
            onClick={() => setModalOpen(false)}
            bodyBackground="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackForm;
