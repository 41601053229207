import React from "react";

const Intro = ({ take }) => {
  return (
    <div className="flex flex-col w-full max-w-[var(--take-width)]">
      <p
        dangerouslySetInnerHTML={{
          __html: take.intro?.split("\n")?.join("<br/>"),
        }}
      />
    </div>
  );
};

export default Intro;
