import axios from "axios";

const userUrl = () => {
  return "/api/v1/super_admin/users";
};

export const getUsers = (
  pageIndex,
  searchTermString,
  searchTermNumber,
  minSearchDate,
  maxSearchDate
) => {
  let searchQuery = "";

  if (searchTermString?.length > 0)
    searchQuery = `search_string=${encodeURIComponent(searchTermString)}`;
  if (searchTermNumber?.length > 0)
    searchQuery += `&search_number=${searchTermNumber}`;
  if (minSearchDate) searchQuery += `&search_date_gteq=${minSearchDate}`;
  if (maxSearchDate) searchQuery += `&search_date_lteq=${maxSearchDate}`;

  return axios.get(`${userUrl()}?page=${pageIndex}&${searchQuery}`);
};

export const getUser = (userId) => {
  return axios.get(`${userUrl()}/${userId}`);
};

export const updateUser = (userId, payload) => {
  return axios.put(`${userUrl()}/${userId}`, payload);
};

export const destroyUser = (userId) => {
  return axios.delete(`${userUrl()}/${userId}`);
};
