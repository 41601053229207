import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { updateUser } from "@/apis/users";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { useAuth } from "@/hooks/useAuth";
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import Input from "@/components/Input";

import {
  LINK_TREE_INITIAL_VALUE,
  LINK_TREE_VALIDATION_SCHEMA,
} from "./constants";

const BioLinkModal = ({ bioModalOpen, setBioModalOpen, getUserAPI }) => {
  const { user } = useAuth();
  const [btnLoader, setBtnLoader] = useState(false);

  const { handleSubmit, setValue, watch, reset } = useForm({
    resolver: yupResolver(LINK_TREE_VALIDATION_SCHEMA),
    defaultValues:
      user.link_trees_attributes?.length === 0
        ? LINK_TREE_INITIAL_VALUE
        : { link_trees_attributes: user.link_trees_attributes },
  });

  useEffect(() => {
    if (user.link_trees_attributes.length == 0) {
      reset({ link_trees_attributes: [{ serial: 1, name: "", link: "" }] });
    } else {
      reset({
        link_trees_attributes: user.link_trees_attributes.map((link, index) => {
          if (link.id !== undefined) {
            return { ...link, serial: index + 1 };
          }
        }),
      });
    }
  }, [user, bioModalOpen]);

  const handleChange = (e, serial, attr) => {
    let array = watch().link_trees_attributes;

    array = array.map((link) => {
      if (link.serial == serial) {
        return { ...link, [attr]: e.target.value };
      } else {
        return link;
      }
    });
    setValue("link_trees_attributes", array);
  };

  const handleAdd = () => {
    let array = watch().link_trees_attributes;
    let serial = array?.length > 0 ? array[array.length - 1].serial + 1 : 1;

    setValue("link_trees_attributes", [
      ...array,
      { serial: serial, name: "", url: "" },
    ]);
  };

  const handleDelete = (serial) => {
    let array = watch().link_trees_attributes;

    array = array.map((link) => {
      if (link.serial == serial) {
        return { ...link, _destroy: true };
      } else {
        return link;
      }
    });
    setValue("link_trees_attributes", array);
  };

  const updateUserResponse = async (formState) => {
    try {
      setBtnLoader(true);
      const { data } = await updateUser(user.id, {
        user: {
          id: user.id,
          link_trees_attributes: formState.link_trees_attributes,
        },
      });
      showToastrSuccess(data.message);
      setBioModalOpen(false);
      getUserAPI();
    } catch (error) {
      showToastrError("invalid link!");
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <Modal
      size="large"
      open={bioModalOpen}
      onClose={() => setBioModalOpen(false)}
      title="Bio Links"
    >
      <div className="flex flex-col w-full gap-10">
        <form
          id="bio-link-form"
          onSubmit={handleSubmit(updateUserResponse)}
          className="flex flex-col w-full gap-8"
        >
          <div className="flex flex-col w-full gap-6">
            {watch()
              .link_trees_attributes?.filter(
                (linkTree) => linkTree._destroy != true
              )
              ?.map((linkTree, index) => {
                return (
                  <div className="flex flex-col w-full gap-3" key={index}>
                    <Input
                      id={`link_trees_attributes.${index}.name`}
                      name={`link_trees_attributes.${index}.name`}
                      label="Link Title"
                      className="bg-inverted-hover"
                      value={linkTree.name}
                      onChange={(e) => handleChange(e, linkTree.serial, "name")}
                      required
                      bodyBackground="primary"
                      style="primary"
                      placeholder="Enter a title"
                    />
                    <Input
                      label="Link URL"
                      className="bg-inverted-hover"
                      id={`link_trees_attributes.${index}.url`}
                      name={`link_trees_attributes.${index}.url`}
                      value={linkTree.url}
                      onChange={(e) => handleChange(e, linkTree.serial, "url")}
                      required
                      bodyBackground="primary"
                      style="primary"
                      placeholder="Enter a link"
                    />
                    <span
                      className="text-small text-danger hover:text-danger-hover transition-all duration-300 cursor-pointer"
                      onClick={() => handleDelete(linkTree.serial)}
                    >
                      Remove
                    </span>
                  </div>
                );
              })}
          </div>

          <div className="flex w-full justify-end">
            <span
              className="text-inverted hover:text-inverted-hover transition-all duration-300 cursor-pointer"
              onClick={() => handleAdd()}
            >
              Add another link
            </span>
          </div>
        </form>

        <div className="flex gap-4">
          <Button
            type="submit"
            form="bio-link-form"
            label="Save changes"
            style="inverted"
            loading={btnLoader}
            bodyBackground="primary"
          />

          <Button
            type="button"
            label="Cancel"
            style="transparent"
            onClick={() => setBioModalOpen(false)}
            bodyBackground="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default BioLinkModal;
