import React from "react";

import Header from "@/pages/TakeDetails/Header";
import Banner from "@/pages/TakeDetails/Banner";
import Intro from "@/pages/TakeDetails/Intro";
import ImageCard from "@/pages/TakeDetails/ImageCard";
import GridCard from "@/pages/TakeDetails/GridCard";
import ThumbnailCard from "@/pages/TakeDetails/ThumbnailCard";
import BRoll from "@/pages/TakeDetails/BRoll";
import Outro from "@/pages/TakeDetails/Outro";
import Follow from "@/pages/TakeDetails/Follow";
import Friends from "@/pages/TakeDetails/Friends";
import TakeFooter from "@/pages/TakeDetails/TakeFooter";

import ChromeWindow from "./ChromeWindow";
import MobileWindow from "./MobileWindow";

const BrowserView = ({ take, mobileView, paidView }) => {
  const WindowComponent = ({ children }) => {
    if (mobileView) {
      return <MobileWindow>{children}</MobileWindow>;
    } else {
      return <ChromeWindow>{children}</ChromeWindow>;
    }
  };

  return (
    <WindowComponent>
      <div
        className={`flex flex-col items-center gap-12 w-full bg-inverted overflow-y-auto ${
          mobileView
            ? "pt-6 max-h-[calc(100dvh-170px)] md:!max-h-[calc(100dvh-210px)]"
            : "pt-6 md:pt-8"
        }`}
      >
        <div
          className={`flex flex-col items-center gap-12 w-full ${
            mobileView ? "px-4" : "px-4 md:px-8"
          }`}
        >
          <Header take={take} />
          <Banner take={take} mobileView={mobileView} />
          {!!take.intro && <Intro take={take} />}
        </div>

        {take.attachments_attributes.map((image) => {
          if (image.view_type == "single") {
            return (
              <ImageCard
                key={image.id}
                image={image}
                mobileView={mobileView}
                paidView={paidView}
                take={take}
              />
            );
          } else if (image.view_type == "thumbnail") {
            return (
              <ThumbnailCard
                key={image.id}
                image={image}
                mobileView={mobileView}
                paidView={paidView}
                gallery={image.gallery_attachments_attributes}
                take={take}
              />
            );
          } else if (image.view_type == "grid") {
            return (
              <GridCard
                key={image.id}
                image={image}
                mobileView={mobileView}
                paidView={paidView}
                gallery={image.gallery_attachments_attributes}
                take={take}
              />
            );
          }
        })}

        <div
          className={`flex flex-col items-center gap-12 w-full ${
            mobileView ? "px-4" : "px-4 md:px-8"
          }`}
        >
          {take.link_rolls_enabled && (
            <BRoll take={take} mobileView={mobileView} paidView={paidView} />
          )}

          {!!take.outro && <Outro take={take} />}

          <Follow take={take} mobileView={mobileView} />

          {take.subscriptions.length > 0 && (
            <Friends
              subscriptions={take.subscriptions}
              mobileView={mobileView}
            />
          )}
        </div>

        <TakeFooter mobileView={mobileView} />
      </div>
    </WindowComponent>
  );
};

export default BrowserView;
