import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAuth } from "@/hooks/useAuth";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { OTP_LENGTH } from "@/lib/constants";
import { LOGIN_ROUTE } from "@/lib/routes";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { verifyToken } from "@/apis/verification_tokens";
import { TOKEN_INITIAL_VALUE, TOKEN_VALIDATION_SCHEMA } from "./constants";

const VerificationToken = ({ token }) => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [btnLoader, setBtnLoader] = useState(false);
  const { handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(TOKEN_VALIDATION_SCHEMA),
    defaultValues: TOKEN_INITIAL_VALUE,
  });

  const verifyTokenResponse = async (formState) => {
    try {
      setBtnLoader(true);
      const { data } = await verifyToken({
        verification_token: {
          otp: formState.otp,
          token: token,
        },
      });

      await login(data.token, data.user);
      showToastrSuccess(data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);
      if (error.response.status === 403) {
        navigate(LOGIN_ROUTE, { replace: true });
      }
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="pb-page-container flex justify-center items-center bg-inverted">
      <div className="flex flex-col items-center gap-6 w-full max-w-[327px]">
        <h1 className="text-title">
          Please enter your one-time password which has been emailed to you.
        </h1>
        <form
          onSubmit={handleSubmit(verifyTokenResponse)}
          className="flex flex-col items-end gap-6 w-full"
        >
          <Input
            id="otp"
            label="Verification Code"
            name="otp"
            maxLength={OTP_LENGTH}
            placeholder="Enter OTP"
            error={formState.errors.otp?.message}
            onChange={(e) => setValue("otp", e.target.value)}
            required
          />

          <Button type="submit" label="Submit" loading={btnLoader} />
        </form>
      </div>
    </div>
  );
};

export default VerificationToken;
