import React from "react";

const WebsiteCard = ({ linkRoll }) => {
  return (
    <div className="flex flex-col gap-2.5 w-full p-4 rounded-sm bg-inverted-hover">
      {!!linkRoll.image_src && (
        <img src={linkRoll.image_src} className="w-full h-auto" />
      )}

      <a
        className="text-[#356fc6]"
        href={linkRoll.formatted_link}
        target="_blank"
      >
        <span className="line-clamp-1 text-small">{linkRoll.link}</span>
      </a>
    </div>
  );
};

export default WebsiteCard;
