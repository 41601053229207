import React from "react";

import Button from "@/components/Button";
import OptionCard from "@/components/OptionCard";

import SingleImage from "@/assets/images/single-image.png";
import ThumbnailImage from "@/assets/images/thumbnail-image.png";
import GridImage from "@/assets/images/grid-image.png";

const OptionForm = ({
  setOpen,
  selectedOption,
  setSelectedOption,
  setEditGallery,
}) => {
  return (
    <div className="flex flex-col w-full gap-10">
      <div className="flex flex-col w-full gap-10">
        <p>How would you like your images to appear?</p>

        <div className="flex flex-col md:flex-row items-center md:items-start justify-center w-full gap-10">
          <OptionCard
            title="Single Images"
            description="Images stacked like you might see in a typical long form blog post"
            image={SingleImage}
            selected={selectedOption === "single"}
            handleOnClick={() => setSelectedOption("single")}
          />
          <OptionCard
            title="Thumbnail Gallery"
            description="Images are shown in a traditional gallery with thumbnails and maintain their original aspect ratios."
            notice="Not compatible with email."
            image={ThumbnailImage}
            selected={selectedOption === "thumbnail"}
            handleOnClick={() => setSelectedOption("thumbnail")}
          />
          <OptionCard
            title="Grid Gallery"
            description="Images are cropped to fit a square grid gallery."
            image={GridImage}
            selected={selectedOption === "grid"}
            handleOnClick={() => setSelectedOption("grid")}
          />
        </div>
      </div>

      <div className="flex gap-4">
        <Button
          type="submit"
          label="Continue"
          style="inverted"
          onClick={() => selectedOption && setEditGallery(true)}
          bodyBackground="primary"
        />

        <Button
          label="Cancel"
          style="transparent"
          onClick={() => {
            setOpen(false);
            setSelectedOption();
            setEditGallery();
          }}
          bodyBackground="primary"
        />
      </div>
    </div>
  );
};

export default OptionForm;
