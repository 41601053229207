import React from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import { ROOT_ROUTE } from "@/lib/routes";

const NavItem = ({
  link = ROOT_ROUTE,
  label = "Home",
  style = "primary",
  ...otherProps
}) => {
  const STYLES = {
    primary: "text-primary bg-inverted hover:bg-inverted-hover",
    inverted: "text-inverted bg-primary hover:bg-primary-hover",
  };

  return (
    <NavLink
      to={link}
      className={({ isActive, isPending }) =>
        classnames(
          "flex justify-center items-center px-2 py-1.5 transition-all duration-300 rounded-sm cursor-pointer w-fit",
          STYLES[style],
          { "bg-inverted-hover": isActive }
        )
      }
      {...otherProps}
    >
      {label}
    </NavLink>
  );
};

export default NavItem;
