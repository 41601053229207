import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";

const ItemMenu = ({
  discipline,
  setDisciplineId,
  setDeleteAlert,
  setDisciplineModal,
  getDisciplineAPI,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Menu open={open} setOpen={setOpen} style="transparent">
      <MenuItem
        label="Edit"
        onClick={() => {
          getDisciplineAPI(discipline.id);
          setDisciplineId(discipline.id);
          setDisciplineModal(true);
        }}
      />
      <MenuItem
        label="Delete"
        onClick={() => {
          setDisciplineId(discipline.id);
          setDeleteAlert(true);
        }}
      />
    </Menu>
  );
};

export default ItemMenu;
