import Alt from "./assets/alt.svg?react";
import ArrowDown from "./assets/arrow-down.svg?react";
import ArrowLeft from "./assets/arrow-left.svg?react";
import ArrowRight from "./assets/arrow-right.svg?react";
import ArrowUp from "./assets/arrow-up.svg?react";
import ArrowUpRight from "./assets/arrow-up-right.svg?react";
import Attachment from "./assets/attachment.svg?react";
import Back from "./assets/back.svg?react";
import Behance from "./assets/behance.svg?react";
import Bluesky from "./assets/bluesky.svg?react";
import Browser from "./assets/browser.svg?react";
import Check from "./assets/check.svg?react";
import Checkbox from "./assets/checkbox.svg?react";
import CheckboxFill from "./assets/checkbox-fill.svg?react";
import ChevronDown from "./assets/chevron-down.svg?react";
import ChevronLeft from "./assets/chevron-left.svg?react";
import ChevronRight from "./assets/chevron-right.svg?react";
import Clipboard from "./assets/clipboard.svg?react";
import Close from "./assets/close.svg?react";
import Delete from "./assets/delete.svg?react";
import Desktop from "./assets/desktop.svg?react";
import Download from "./assets/download.svg?react";
import Dribble from "./assets/dribble.svg?react";
import Email from "./assets/email.svg?react";
import EyeClose from "./assets/eye-close.svg?react";
import Eye from "./assets/eye.svg?react";
import Facebook from "./assets/facebook.svg?react";
import Free from "./assets/free.svg?react";
import Gallery from "./assets/gallery.svg?react";
import Hamburger from "./assets/hamburger.svg?react";
import Import from "./assets/import.svg?react";
import Instagram from "./assets/instagram.svg?react";
import Link from "./assets/link.svg?react";
import Linkedin from "./assets/linkedin.svg?react";
import Mastodon from "./assets/mastodon.svg?react";
import Menu from "./assets/menu.svg?react";
import Minus from "./assets/minus.svg?react";
import Mobile from "./assets/mobile.svg?react";
import Notice from "./assets/notice.svg?react";
import Paid from "./assets/paid.svg?react";
import Pencil from "./assets/pencil.svg?react";
import People from "./assets/people.svg?react";
import Plus from "./assets/plus.svg?react";
import QuestionMark from "./assets/question-mark.svg?react";
import Radio from "./assets/radio.svg?react";
import RadioFill from "./assets/radio-fill.svg?react";
import Search from "./assets/search.svg?react";
import Tag from "./assets/tag.svg?react";
import Threads from "./assets/threads.svg?react";
import Tick from "./assets/tick.svg?react";
import Tiktok from "./assets/tiktok.svg?react";
import Twitter from "./assets/twitter.svg?react";
import WidthFull from "./assets/width-full.svg?react";
import WidthNormal from "./assets/width-normal.svg?react";
import WidthStreched from "./assets/width-streched.svg?react";
import Youtube from "./assets/youtube.svg?react";

export const icons = {
  Alt,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpRight,
  Attachment,
  Back,
  Behance,
  Bluesky,
  Browser,
  Check,
  Checkbox,
  CheckboxFill,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Clipboard,
  Close,
  Delete,
  Desktop,
  Download,
  Dribble,
  Email,
  EyeClose,
  Eye,
  Facebook,
  Free,
  Gallery,
  Hamburger,
  Import,
  Instagram,
  Link,
  Linkedin,
  Mastodon,
  Menu,
  Minus,
  Mobile,
  Notice,
  Paid,
  Pencil,
  People,
  Plus,
  QuestionMark,
  Radio,
  RadioFill,
  Search,
  Tag,
  Threads,
  Tick,
  Tiktok,
  Twitter,
  WidthFull,
  WidthNormal,
  WidthStreched,
  Youtube,
};
