import React from "react";
import classnames from "classnames";

import { PBIcons } from "@/components/PBIcons";
import { usePagination } from "@/helpers/utils";
import { DOTS } from "@/helpers/constants";

const Pagination = ({
  count = 0,
  pageNo,
  navigate,
  pageSize,
  siblingCount = 1,
}) => {
  const paginationRange = usePagination({
    pageNo,
    count,
    siblingCount,
    pageSize,
  });

  if (pageNo === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (!isLastPage) navigate(pageNo + 1);
  };

  const onPrevious = () => {
    if (!isFirstPage) navigate(pageNo - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  const isFirstPage = pageNo === 1;
  const isLastPage = pageNo === lastPage;

  return (
    <nav aria-label="Pagination Navigation" role="navigation">
      <ul className="pb-pagination-wrapper">
        <li
          tabIndex={0}
          className={classnames("pb-pagination-item pb-pagination-arrow", {
            disabled: isFirstPage,
          })}
          onClick={onPrevious}
        >
          <PBIcons icon="ChevronLeft" />
        </li>

        {paginationRange.map((pageNumber, index) => {
          const isActive = pageNumber === pageNo;

          if (pageNumber === DOTS) {
            return (
              <li
                className="pb-pagination-item pb-pagination-item--dots"
                key={index}
              >
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={index}
              tabIndex={0}
              title={
                isActive
                  ? `Current Page, Page ${pageNumber}`
                  : `Goto Page ${pageNumber}`
              }
              className={classnames("pb-pagination-item", {
                active: isActive,
              })}
              onClick={() => navigate(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}

        <li
          tabIndex={0}
          className={classnames("pb-pagination-item pb-pagination-arrow", {
            disabled: isLastPage,
          })}
          onClick={onNext}
        >
          <PBIcons icon="ChevronRight" />
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
