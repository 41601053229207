import * as Yup from "yup";

import { OTP_LENGTH } from "@/lib/constants";

export const TOKEN_INITIAL_VALUE = {
  otp: "",
};

export const TOKEN_VALIDATION_SCHEMA = Yup.object({
  otp: Yup.string()
    .required("OTP can't be blank.")
    .matches(/^[0-9]{6}$/, `OTP must be exactly ${OTP_LENGTH} digits.`),
});
