import React, { useState } from "react";

import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";

const ItemMenu = ({ audience, setAudience, setAlertOpen }) => {
  const [open, setOpen] = useState(false);

  return (
    <Menu open={open} setOpen={setOpen} style="transparent">
      <MenuItem
        label={audience.unsubscribed ? "Resubscribe" : "Unsubscribe"}
        onClick={() => {
          setAudience(audience);
          setAlertOpen(true);
        }}
      />
    </Menu>
  );
};

export default ItemMenu;
