import React from "react";

import { PBIcons } from "@/components/PBIcons";

const OptionCard = ({
  title,
  subTitle,
  description,
  notice,
  image,
  selected,
  handleOnClick,
}) => {
  return (
    <div className="flex flex-col gap-4 w-52">
      <img
        src={image}
        className={`w-full rounded cursor-pointer transition-all duration-300 ${
          selected && "shadow-secondary-ring-primary-bg"
        }`}
        onClick={handleOnClick}
      />

      <div className="flex flex-col w-full gap-1">
        <h2 className="font-bold">{title}</h2>
        {!!subTitle && <p className="text-small">{subTitle}</p>}
        {!!description && (
          <p className="text-small text-muted">{description}</p>
        )}

        {!!notice && (
          <div className="flex gap-2 w-full px-2 py-1 rounded bg-primary-hover text-inverted">
            <PBIcons icon="Notice" />
            <p className="text-small font-bold">{notice}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OptionCard;
