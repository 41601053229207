import React from "react";

import SidebarNavItem from "@/components/SidebarNavItem";
import {
  ACCOUNT_SETTINGS_ROUTE,
  BILLING_SETTINGS_ROUTE,
  BRANDING_SETTINGS_ROUTE,
  EMBED_SETTINGS_ROUTE,
  PROFILE_SETTINGS_ROUTE,
  PAID_SUBSCRIPTION_ROUTE,
  PAID_BOOTH_ROUTE,
} from "@/lib/routes";

const Sidebar = () => {
  return (
    <nav className="pb-sidebar flex flex-row lg:flex-col items-center lg:items-start gap-1 w-full bg-inverted lg:bg-inverted-hover px-6 py-2.5 lg:py-6 fixed z-30 top-0 left-0">
      <SidebarNavItem path={PROFILE_SETTINGS_ROUTE} label="Profile" />
      <SidebarNavItem
        path={BRANDING_SETTINGS_ROUTE}
        label="Branding + Avatar"
      />
      <SidebarNavItem path={EMBED_SETTINGS_ROUTE} label="Embed Settings" />
      <SidebarNavItem path={BILLING_SETTINGS_ROUTE} label="Billing" />
      <SidebarNavItem
        path={PAID_SUBSCRIPTION_ROUTE}
        label="Paid Subscription"
      />
      <SidebarNavItem path={PAID_BOOTH_ROUTE} label="Your Subscriptions" />
      <SidebarNavItem path={ACCOUNT_SETTINGS_ROUTE} label="Name & Email" />
    </nav>
  );
};

export default Sidebar;
