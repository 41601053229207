import React, { useState } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import Modal from "@/components/Modal";
import Button from "@/components/Button";
import { updateTake } from "@/apis/super_admin/takes";
import { showToastrError, showToastrSuccess } from "@/lib/commons";

const ApprovalBanner = ({ takeDetail, setTakeDetail }) => {
  dayjs.extend(utc);
  dayjs.extend(advancedFormat);

  const [btnLoader, setBtnLoader] = useState(false);
  const [approvalConfirmAlert, setApprovalConfirmAlert] = useState(false);
  const [rejectConfirmAlert, setRejectConfirmAlert] = useState(false);

  const updateTakeResponse = async (approvalState) => {
    try {
      setBtnLoader(true);
      const { data } = await updateTake(takeDetail.id, {
        take: {
          [approvalState]: dayjs().utc().format(),
          under_review: false,
        },
      });
      setTakeDetail({ ...takeDetail, under_review: false });
      showToastrSuccess(data.message);
      setApprovalConfirmAlert(false);
      setRejectConfirmAlert(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex justify-between py-4 space-x-2">
      <Button
        label="Approve"
        type="submit"
        onClick={() => setApprovalConfirmAlert(true)}
      />

      <Button
        label="Reject"
        type="submit"
        style="danger"
        onClick={() => setRejectConfirmAlert(true)}
      />

      <Modal
        open={approvalConfirmAlert}
        onClose={() => setApprovalConfirmAlert(false)}
        title="Approve Take"
      >
        <p>Are you sure you want to approve the take?</p>
        <div className="flex gap-4">
          <Button
            type="submit"
            label="Approve"
            loading={btnLoader}
            style="inverted"
            bodyBackground="primary"
            onClick={() => updateTakeResponse("approved_at")}
          />
          <Button
            type="button"
            style="transparent"
            label="Cancel"
            onClick={() => setApprovalConfirmAlert(false)}
            bodyBackground="primary"
          />
        </div>
      </Modal>

      <Modal
        open={rejectConfirmAlert}
        onClose={() => setRejectConfirmAlert(false)}
        title="Reject Take"
      >
        <p>Are you sure you want to reject the take?</p>
        <div className="flex gap-4">
          <Button
            type="submit"
            label="Reject"
            loading={btnLoader}
            style="inverted"
            bodyBackground="primary"
            onClick={() => updateTakeResponse("rejected_at")}
          />
          <Button
            type="button"
            style="transparent"
            label="Cancel"
            onClick={() => setRejectConfirmAlert(false)}
            bodyBackground="primary"
          />
        </div>
      </Modal>
    </div>
  );
};

export default ApprovalBanner;
