import React, { useState, useEffect } from "react";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import { useForm } from "react-hook-form";

import Loader from "@/components/Loader";
import {
  getSubscriberFormSetting,
  updateSubscriberFormSetting,
} from "@/apis/settings/embed_subscriber_form_settings";
import { showToastrSuccess, showToastrError } from "@/lib/commons";

import ColorPicker from "@/components/ColorPicker";
import Switch from "@/components/Switch";
import Input from "@/components/Input";
import Textarea from "@/components/Textarea";
import Button from "@/components/Button";

import { SUBSCRIBER_FORM_INITIAL_VALUE } from "./constants";

const EmbededFormPage = () => {
  const PB_AVATAR = `${window.location.origin}/images/common/pb-avatar.png`;

  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const [pageLoader, setPageLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [subscribeFormAlert, setSubscribeFormAlert] = useState(false);
  const [textCopied, setTextCopied] = useState(false);

  const { handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: SUBSCRIBER_FORM_INITIAL_VALUE,
  });

  useEffect(() => {
    getSubscriberFormSettingResponse();
  }, []);

  useEffect(() => {
    if (textCopied) {
      setTimeout(() => setTextCopied(false), 1000);
    }
  }, [textCopied]);

  const getSubscriberFormSettingResponse = async () => {
    try {
      const { data } = await getSubscriberFormSetting();
      setPageLoader(false);
      reset(data.subscriber_form_setting);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateSubscriberFormSettingResponse = async (formState) => {
    try {
      setBtnLoader(true);
      const { data } = await updateSubscriberFormSetting({
        subscriber_form_setting: { ...formState },
      });
      getSubscriberFormSettingResponse();
      setSubscribeFormAlert(true);
      showToastrSuccess(data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const getAvatarLink = () => {
    if (watch().show_avatar) {
      return `data-avatar="${
        watch().user_logo_url ? watch().user_logo_url : PB_AVATAR
      }"`;
    }
  };

  const getDisplayName = () => {
    if (watch().show_display_name) {
      return `data-display-name="${
        watch().user_display_name
      }" data-display-name-color="${watch().display_name_color}"`;
    }
  };

  const getFontDetails = () => {
    if (watch().use_website_font) {
      return `data-use-my-website-font="true"`;
    }
  };

  const getEmbededText = () => {
    return `<div ${getEmbeddedFormAttributes()}></div><script async src=${
      window.location.origin
    }/scripts/embed.js></script>`;
  };

  const getEmbeddedFormAttributes = () => {
    return [
      'style="display: none"',
      'class="pb-subscribe-form"',
      `data-org="${watch().user_id}"`,
      getAvatarLink(),
      getDisplayName(),
      `data-description="${watch().message}"`,
      `data-description-color="${watch().message_color}"`,
      `data-email-input-placeholder="${watch().email_input_placeholder}"`,
      `data-submit-button-label="${watch().submit_button_label}"`,
      `data-submit-button-text-color="${watch().submit_button_text_color}"`,
      `data-submit-button-background-color="${watch().submit_button_color}"`,
      `data-confirmation-title="${watch().confirmation_message_title}"`,
      `data-confirmation-msg="${watch().confirmation_message}"`,
      `data-background-color="${watch().background_color}"`,
      getFontDetails(),
    ]
      .filter((item) => item)
      .join(" ");
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-settings-page-wrapper bg-inverted-hover">
      <div className="pb-settings-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-12 w-full max-w-3xl">
          <form
            className="flex flex-col items-end gap-6 w-full max-w-md mx-auto"
            onSubmit={handleSubmit(updateSubscriberFormSettingResponse)}
          >
            <p>
              If you know your way around a bit of code and wish to embed your
              booth sign-up form on another website, you can use this page to
              generate a code snippet for that purpose.
            </p>
            <Switch
              id="show_avatar"
              label="Show Avatar"
              name="show_avatar"
              helpText="Show your avatar in the subscribe form."
              checked={watch().show_avatar}
              onChange={() => setValue("show_avatar", !watch().show_avatar)}
              bodyBackground="inverted-hover"
            />

            <Switch
              id="show_display_name"
              label="Show Display Name"
              name="show_display_name"
              helpText="Show your display name in the subscribe form."
              checked={watch().show_display_name}
              onChange={() =>
                setValue("show_display_name", !watch().show_display_name)
              }
              bodyBackground="inverted-hover"
            />

            <ColorPicker
              id="display_name_color"
              label="Display Name Color"
              name="display_name_color"
              defaultValue="inherit"
              helpText="Change the text color of the display name. Leave it as `inherit` to automatically get the text color from your website."
              value={watch().display_name_color}
              onChange={(e) => setValue("display_name_color", e)}
            />

            <Textarea
              id="message"
              label="Message"
              name="message"
              placeholder="Subscribe to my PencilBooth newsletter to get regular updates directly to your inbox."
              helpText="Add a message above the Email input in the subscribe form."
              value={watch().message}
              onChange={(e) => setValue("message", e.target.value)}
              bodyBackground="inverted-hover"
            />

            <ColorPicker
              id="message_color"
              label="Message Color"
              name="message_color"
              defaultValue="inherit"
              helpText="Change the text color of the message. Leave it as `inherit` to automatically get the text color from your website."
              value={watch().message_color}
              onChange={(e) => setValue("message_color", e)}
            />

            <ColorPicker
              id="background_color"
              label="Background Color"
              name="background_color"
              helpText="Change the background color of the subscribe form. Leave it as `inherit` to automatically get the background color from your website."
              defaultValue="inherit"
              value={watch().background_color}
              onChange={(e) => setValue("background_color", e)}
            />

            <Input
              id="email_input_placeholder"
              label="Email Input Palceholder"
              name="email_input_placeholder"
              placeholder="Enter Place Holder"
              helpText="The placeholder used in the email input text box."
              value={watch().email_input_placeholder}
              onChange={(e) =>
                setValue("email_input_placeholder", e.target.value)
              }
              bodyBackground="inverted-hover"
            />

            <Input
              id="submit_button_label"
              label="Submit Button Label"
              name="submit_button_label"
              placeholder="Subscribe"
              helpText="The text shown in the submit button."
              value={watch().submit_button_label}
              onChange={(e) => setValue("submit_button_label", e.target.value)}
              bodyBackground="inverted-hover"
            />

            <ColorPicker
              id="submit_button_color"
              label="Submit Button Color"
              name="submit_button_color"
              helpText="Change the submit button color."
              defaultValue="inherit"
              value={watch().submit_button_color}
              onChange={(e) => setValue("submit_button_color", e)}
            />

            <ColorPicker
              id="submit_button_text_color"
              label="Submit Button Text Color"
              name="submit_button_text_color"
              helpText="Change the submit button text color."
              defaultValue="inherit"
              value={watch().submit_button_text_color}
              onChange={(e) => setValue("submit_button_text_color", e)}
            />

            <Input
              id="confirmation_message_title"
              label="Confirmation Message Title"
              name="confirmation_message_title"
              placeholder="Enter Place Holder"
              helpText="The message title shown to the user when they successfully subscribe to your booth."
              value={watch().confirmation_message_title}
              onChange={(e) =>
                setValue("confirmation_message_title", e.target.value)
              }
              bodyBackground="inverted-hover"
            />

            <Textarea
              id="confirmation_message"
              label="Confirmation Message"
              name="confirmation_message"
              placeholder="Thank you for subscribing!"
              helpText="The message shown to the user when they successfully subscribe to your booth."
              value={watch().confirmation_message}
              onChange={(e) => setValue("confirmation_message", e.target.value)}
              bodyBackground="inverted-hover"
            />

            <Switch
              id="use_website_font"
              label="Use My Website Font"
              name="use_website_font"
              helpText="If you would like to use your website's default fonts, enable this toggle."
              checked={watch().use_website_font}
              onChange={() =>
                setValue("use_website_font", !watch().use_website_font)
              }
              bodyBackground="inverted-hover"
            />

            <Button
              type="submit"
              label="Save changes"
              loading={btnLoader}
              bodyBackground="inverted-hover"
            />
          </form>

          <div className="flex flex-col w-full gap-6 divide-y divide-y-muted p-5 bg-inverted rounded-sm">
            <div className="flex flex-col w-full gap-4">
              <h3 className="text-title">Embed Instructions</h3>

              {subscribeFormAlert && (
                <div className="p-3 rounded-sm bg-[#e4f8f0] text-small text-[#1ea97c]">
                  The code snippet below has changed. Please ensure the updated
                  snippet is copied to your website to see the new changes.
                </div>
              )}

              <p className="p-3 bg-inverted-hover rounded-sm">
                Please copy & paste this code snippet at all locations where you
                want the subscribe form to appear.
              </p>

              <div className="p-5 border border-solid border-muted rounded-sm relative text-muted break-words">
                {getEmbededText()}

                <div className="absolute right-3 top-3">
                  <Button
                    label={textCopied ? "Copied" : "Copy"}
                    onClick={() => {
                      copyToClipboard(getEmbededText());
                      setTextCopied(true);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full gap-4 pt-6">
              <h3 className="text-title">Advanced Styling</h3>

              <p className="p-3 bg-inverted-hover rounded-sm">
                If you'd like to make your subscribe form look even more
                amazing, you can style the elements added to your page using the
                following CSS classes. If you're unsure about making changes or
                can't find the appropriate code, it's always a good idea to
                consult with a web developer or someone with technical
                expertise.
              </p>

              <div className="p-5 border border-solid border-muted rounded-sm relative px-6 py-4 text-muted">
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 font-bold text-left">
                        CSS Class Name
                      </th>
                      <th className="py-3 font-bold text-left">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">.pb-subscribe-form</th>
                      <td className="py-3 text-left">
                        The subscribe form container element
                      </td>
                    </tr>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">.pb-form</th>
                      <td className="py-3 text-left">
                        The <code>&lt;form&gt;</code> element
                      </td>
                    </tr>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">.pb-avatar</th>
                      <td className="py-3 text-left">
                        The Avatar, only if it's enabled
                      </td>
                    </tr>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">.pb-display-name</th>
                      <td className="py-3 text-left">
                        The Display Name, if it's enabled
                      </td>
                    </tr>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">.pb-message</th>
                      <td className="py-3 text-left">The Message text</td>
                    </tr>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">.pb-form-group</th>
                      <td className="py-3 text-left">
                        The container wrapping the form input and button
                      </td>
                    </tr>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">.pb-email-input</th>
                      <td className="py-3 text-left">
                        The email input element
                      </td>
                    </tr>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">.pb-button</th>
                      <td className="py-3 text-left">The submit button</td>
                    </tr>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">
                        .pb-success-message-wrapper
                      </th>
                      <td className="py-3 text-left">
                        The confirmation message wrapper
                      </td>
                    </tr>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">
                        .pb-success-message-icon
                      </th>
                      <td className="py-3 text-left">
                        The confirmation message icon
                      </td>
                    </tr>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">
                        .pb-success-message-title
                      </th>
                      <td className="py-3 text-left">
                        The confirmation message title
                      </td>
                    </tr>
                    <tr className="border-b border-[#e8e8e8]">
                      <th className="py-3 text-left">.pb-success-message</th>
                      <td className="py-3 text-left">
                        The confirmation message
                      </td>
                    </tr>
                    <tr>
                      <th className="py-3 text-left">.pb-error-message</th>
                      <td className="py-3 text-left">The error message</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmbededFormPage;
