import React, { useEffect, useState } from "react";

const TakeSingleImage = ({ image, setDataLoaded }) => {
  const [carouselImages, setCarouselImages] = useState([]);
  const [imgsLoaded, setImgsLoaded] = useState(false);
  const aspectRatio = image.width / image.height;

  useEffect(() => {
    setCarouselImages([image]);
  }, []);

  useEffect(() => {
    const loadImage = (img) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = img.url;
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(img.url);
          }, 2000);

        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(carouselImages.map((img) => loadImage(img)))
      .then(() => {
        setImgsLoaded(true);
        setTimeout(() => {
          setDataLoaded(true);
        }, 50);
      })
      .catch((err) => console.log("Failed to load images", err));
  }, []);

  if (!imgsLoaded) {
    return null;
  }

  return (
    <div className="flex justify-end items-center relative rounded-[10px] overflow-hidden">
      <img
        src={image.attachment_url}
        className="w-full h-full object-cover"
        style={{ aspectRatio: aspectRatio }}
      />
    </div>
  );
};

export default TakeSingleImage;
