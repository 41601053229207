import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import browserImageSize from "browser-image-size";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";

import Button from "@/components/Button";
import Modal from "@/components/Modal";
import {
  createAttachment as createGalleryAttachment,
  updateAttachment as updateGalleryAttachment,
  destroyAttachment,
} from "@/apis/gallery_attachments";
import { createTake } from "@/apis/takes";
import { createAttachment as createMultipleAttachment } from "@/apis/multiple_take_attachments";
import { getTags, createTag } from "@/apis/tags";
import { showToastrError } from "@/lib/commons";
import { dropDownListGenerator, getUnique, sanitizeLink } from "@/lib/utils";

import ImagesMenu from "./ImagesMenu";
import MultiUploader from "./MultiUploader";
import SingleUploader from "./SingleUploader";
import ImageCard from "./ImageCard";
import ImageForm from "./ImageForm";

const UploadForm = ({
  takeId,
  take,
  attachmentId,
  galleryView,
  setOpen,
  selectedOption,
  images,
  setImages,
  setValue,
  attachments,
  setSelectedOption,
  setEditGallery,
  getTakeAPI,
}) => {
  const navigate = useNavigate();
  const [btnLoader, setBtnLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [deleteImage, setDeleteImage] = useState();
  const [tagList, setTagList] = useState([]);
  const [tagListLoad, setTagListLoad] = useState(false);
  const [singleAttachmentChangeModal, setSingleAttachmentChangeModal] =
    useState(false);
  const [progress, setProgress] = useState(1);

  useEffect(() => {
    getTagsAPI();
  }, []);

  useEffect(() => {
    if (attachmentId) setSelectedOption(galleryView);
  }, [attachmentId]);

  const handleChange = (acceptedFiles) => {
    const files = Array.from(acceptedFiles);
    let imageList = [];
    let serial =
      selectedOption === "single"
        ? attachments[attachments.length - 1]?.position || 0
        : images[images.length - 1]?.position || 0;

    files.map((file) => {
      let reader = new FileReader();
      const newUuid = uuid();

      browserImageSize(file).then((size) => {
        reader.onload = (e) => {
          let image = {
            file_data: e.target.result,
            file_name: file.name,
            imageLoader: true,
            tempId: newUuid,
            height: size.height,
            width: size.width,
            style: "normal",
            view_type: selectedOption,
            gallery_tags: [],
            gallery_tag_ids: [],
          };
          imageList.push(image);
          if (files.length === imageList.length) {
            setImages([
              ...images,
              ...imageList.map((image, index) => {
                return { ...image, position: serial + index + 1 };
              }),
            ]);
          }
        };
        reader.readAsDataURL(file);
      });
    });
  };

  const uploadInProgress = () => {
    return images.some((att) => att.imageLoader === true);
  };

  const linkAdditionError = () => {
    return images.some((att) => att.linkError?.length > 0);
  };

  const createAttachmentResponse = (newTakeId) => {
    if (!newTakeId) {
      createTakeAPI().then(async (newTakeId) => {
        createAttachmentAPI(newTakeId);
      });
    } else {
      createAttachmentAPI();
    }
  };

  const createTakeAPI = async () => {
    try {
      const { data } = await createTake({ take: take });

      navigate(`/takes/${data.take.id}/edit`, {
        state: {
          pageLoad: false,
        },
      });

      return data.take.id;
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const createAttachmentAPI = (newTakeId = takeId) => {
    if (linkAdditionError()) {
      showToastrError("Link is invaild.");
    } else {
      if (selectedOption === "single") {
        createMultipleAttachmentAPI(newTakeId);
      } else if (selectedOption === "grid" || selectedOption === "thumbnail") {
        if (attachmentId) {
          updateGalleryAttachmentAPI(newTakeId);
        } else {
          createGalleryAttachmentAPI(newTakeId);
        }
      }
    }
  };

  const createGalleryAttachmentAPI = async (newTakeId) => {
    try {
      setBtnLoader(true);
      let payload = {
        gallery_attachment: {
          ...images[0],
          link: sanitizeLink(images[0].link) || null,
          position: (attachments[attachments.length - 1]?.position || 0) + 1,
          view_type: selectedOption,
          gallery_attachments_attributes: images
            .filter((image) => !image._destroy)
            .map((att) => {
              return { ...att, link: sanitizeLink(att.link) || null };
            }),
        },
      };
      const { data } = await createGalleryAttachment(newTakeId, payload, {
        onUploadProgress: setProgress,
      });
      setValue("attachments_attributes", [...attachments, data.attachment]);
      setEditGallery(false);
      setOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const updateGalleryAttachmentAPI = async (newTakeId) => {
    try {
      setBtnLoader(true);
      let payload = {
        gallery_attachment: {
          id: attachmentId,
          view_type: selectedOption,
          gallery_attachments_attributes: images.map((att) => {
            return { ...att, link: sanitizeLink(att.link) || null };
          }),
        },
      };
      const { data } = await updateGalleryAttachment(
        newTakeId,
        attachmentId,
        payload,
        { onUploadProgress: setProgress }
      );
      const updatedAttachments = attachments.map((att) => {
        if (att.id === attachmentId) {
          return { ...data.attachment };
        } else {
          return { ...att };
        }
      });

      setValue("attachments_attributes", updatedAttachments);
      setEditGallery(false);
      setOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const createMultipleAttachmentAPI = async (newTakeId) => {
    try {
      setBtnLoader(true);
      const { data } = await createMultipleAttachment(
        newTakeId,
        {
          multiple_take_attachments: {
            attachments_attributes: images.map((image) => {
              return {
                ...image,
                view_type: selectedOption,
                link: sanitizeLink(image.link) || null,
              };
            }),
          },
        },
        { onUploadProgress: setProgress }
      );
      setValue("attachments_attributes", data.attachments); // ReLoad array of attachments
      setEditGallery(false);
      setOpen(false);
      setImages([]);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const getTagsAPI = async (search, _loadedOptions, additional) => {
    try {
      setTagListLoad(true);
      const pageNumber = additional?.page || 1;
      const { data } = await getTags(search, pageNumber);
      const responseData = dropDownListGenerator(data.tags, "name");
      const dataList = getUnique([...tagList, ...responseData], "value");

      setTagList(dataList);

      return {
        options: responseData,
        hasMore: data.total_records > dataList.length,
        additional: {
          page: pageNumber + 1,
        },
      };
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setTagListLoad(false);
    }
  };

  const createTagAPI = async (tagName) => {
    try {
      const { data } = await createTag({ tag: { name: tagName } });
      setTagList([
        {
          label: data.tag.name,
          value: data.tag.id,
        },
        ...tagList,
      ]);
      return data.tag.id;
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const clearDestroyImage = () => {
    setImages(
      images.map((img) => {
        if (img._destroy == 1) {
          return { ...img, _destroy: 0 };
        } else {
          return img;
        }
      })
    );
  };

  const getBase64ImageFromUrl = async () => {
    var imageArrayWithBase64 = [];
    images
      .sort((a, b) => a.position - b.position)
      .map(async (image) => {
        var res = await fetch(image.attachment_url);
        var blob = await res.blob();
        var reader = new FileReader();

        reader.onload = async (e) => {
          let imageWithBase64 = { ...image, file_data: reader.result };
          imageArrayWithBase64.push(imageWithBase64);

          if (images.length === imageArrayWithBase64.length) {
            setImages(imageArrayWithBase64);
          }
        };

        reader.readAsDataURL(blob);
      });
  };

  const changeGalleryType = async () => {
    try {
      setBtnLoader(true);
      await destroyAttachment(takeId, attachmentId);
      await getTakeAPI();
      setEditGallery(false);
      setSingleAttachmentChangeModal(false);
      setImages([]);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const cleanFileDateFromImageArray = () => {
    setImages(
      images.map((img) => {
        return { ...img, file_data: null };
      })
    );
  };

  return (
    <div className="relative flex flex-col items-center w-full gap-10">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex gap-4 w-full justify-between items-start">
          <h3 className="text-title font-bold">Edit Gallery</h3>

          <div className="flex items-center gap-4">
            <div className="hidden sm:block">
              <ImagesMenu
                editGallery={takeId && attachmentId}
                value={selectedOption}
                setValue={setSelectedOption}
                getBase64ImageFromUrl={getBase64ImageFromUrl}
                galleryView={galleryView}
                setSingleAttachmentChangeModal={setSingleAttachmentChangeModal}
              />
            </div>

            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => {
                setEditGallery(false);
                setOpen(false);
                clearDestroyImage();
              }}
              bodyBackground="primary"
            />

            <Button
              label={btnLoader ? `${progress}%...` : "Save"}
              style="inverted"
              loading={btnLoader}
              bodyBackground="primary"
              onClick={() => createAttachmentResponse(takeId)}
            />
          </div>
        </div>

        <div className="flex w-full sm:hidden">
          <ImagesMenu
            editGallery={takeId && attachmentId}
            value={selectedOption}
            setValue={setSelectedOption}
            getBase64ImageFromUrl={getBase64ImageFromUrl}
            galleryView={galleryView}
            setSingleAttachmentChangeModal={setSingleAttachmentChangeModal}
          />
        </div>
      </div>

      {images.filter((image) => !image._destroy).length > 0 ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-16 lg:gap-24">
          <div className="flex flex-col gap-5 w-full">
            <p className="text-small">
              Maximum of 12 images can be added to a gallery
            </p>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-5">
              {images.filter((image) => !image._destroy).length < 12 && (
                <SingleUploader handleChange={handleChange} />
              )}

              {images
                .filter((image) => !image._destroy)
                .map((image, index) => (
                  <ImageCard
                    key={index}
                    index={index}
                    image={image}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    images={images}
                    setImages={setImages}
                    setDeleteImage={setDeleteImage}
                  />
                ))}
            </div>
          </div>

          <ImageForm
            images={images}
            setImages={setImages}
            imageIndex={selectedImage}
            changeImage={setSelectedImage}
            getTagsAPI={getTagsAPI}
            createTagAPI={createTagAPI}
            tagList={tagList}
            tagListLoad={tagListLoad}
            setSelectedImage={setSelectedImage}
            deleteImage={deleteImage}
            setDeleteImage={setDeleteImage}
          />
        </div>
      ) : (
        <MultiUploader
          handleChange={handleChange}
          uploadInProgress={uploadInProgress}
        />
      )}

      <Modal
        open={singleAttachmentChangeModal}
        onClose={() => setSingleAttachmentChangeModal(false)}
        title="Change to Single"
      >
        <div className="flex flex-col w-full gap-10">
          <p>
            Are you sure you want to change gallery type to single? Once changed
            to single, you'll not be able to convert it to thumbnail or gird.
          </p>

          <div className="flex gap-4">
            <Button
              type="submit"
              label="Yes, Proceed"
              style="inverted"
              bodyBackground="primary"
              loading={btnLoader}
              onClick={() => changeGalleryType()}
            />
            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => {
                cleanFileDateFromImageArray();
                setSingleAttachmentChangeModal(false);
              }}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UploadForm;
