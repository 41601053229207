import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "@/hooks/useAuth";
import { LOGIN_ROUTE, DISCOVERIES_ROUTE } from "@/lib/routes";
import { showToastrError } from "@/lib/commons";
import AppNavbar from "@/components/AppNavbar";
import AdminSidebar from "@/components/AdminSidebar";
import {
  LOGIN_REQUIRED_MESSAGE,
  ACCESS_REQUIRED_MESSAGE,
} from "@/lib/constants";

const AdminLayout = () => {
  const { user } = useAuth();

  if (!user) {
    showToastrError(LOGIN_REQUIRED_MESSAGE);
    return <Navigate to={LOGIN_ROUTE} replace={true} />;
  }

  if (!user.superadmin) {
    showToastrError(ACCESS_REQUIRED_MESSAGE);
    return <Navigate to={DISCOVERIES_ROUTE} replace={true} />;
  }

  return (
    <div className="bg-inverted">
      <AppNavbar />
      <AdminSidebar />
      <Outlet />
    </div>
  );
};

export default AdminLayout;
