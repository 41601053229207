import React from "react";
import classnames from "classnames";

const Textarea = ({
  name,
  label,
  helpText,
  error,
  rows = 3,
  className = "",
  bodyBackground = "inverted",
  style = "inverted",
  ...otherProps
}) => {
  const STYLES = {
    primary: "bg-primary text-inverted placeholder:text-primary-hover",
    inverted: "bg-inverted text-primary placeholder:text-muted",
    "option-card":
      "bg-option-card-background text-inverted placeholder:text-disabled-background",
  };

  const BORDER_STYLES = {
    primary: "border-muted",
    inverted: "border-primary-border",
    "option-card": "border-muted",
  };

  const RING_STYLES = {
    inverted: "focus-within:shadow-secondary-ring-inverted-bg",
    primary: "focus-within:shadow-secondary-ring-primary-bg",
    "inverted-hover": "focus-within:shadow-secondary-ring-inverted-hover-bg",
    "option-card": "focus-within:shadow-secondary-ring-option-card-bg",
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      {!!label && (
        <label htmlFor={name} className="pb-input-label">
          {label}
        </label>
      )}

      <textarea
        id={name}
        name={name}
        rows={rows}
        className={classnames(
          "block rounded-sm p-3 border border-solid outline-none transition-all duration-300",
          STYLES[style],
          BORDER_STYLES[style],
          RING_STYLES[bodyBackground],
          className
        )}
        {...otherProps}
      />

      {!!error && <p className="pb-error-text">{error}</p>}
      {!!helpText && <p className="pb-help-text">{helpText}</p>}
    </div>
  );
};

export default Textarea;
