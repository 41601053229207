import React from "react";

import { SUBSCRIBER_TYPES } from "@/lib/constants";
import { useAuth } from "@/hooks/useAuth";

const EmailWindow = ({ take, mobileView, children }) => {
  const { user } = useAuth();

  const subscriberSize = () => {
    if (take.subscribers_type === SUBSCRIBER_TYPES.free) {
      return `Free Subscribers (${user.free_subscriber_size})`;
    } else if (take.subscribers_type === SUBSCRIBER_TYPES.paid) {
      return `Paid Subscribers (${user.paid_subscriber_size})`;
    } else {
      return `All Subscribers (${Number(
        user.free_subscriber_size + user.paid_subscriber_size
      )})`;
    }
  };

  return (
    <div
      className={`flex flex-col items-center gap-4 w-full bg-inverted rounded-md pb-font-helvetica ${
        mobileView ? "max-w-[var(--mobile-screen-width)]" : "p-4"
      }`}
    >
      <div className="flex items-center gap-8 w-full px-4 pt-4">
        <div className="rounded-full overflow-hidden h-16 w-16">
          <img
            className="h-full w-full object-cover"
            src={take.user.logo_url}
            alt="User Logo"
          />
        </div>

        <div className="flex flex-col">
          <span className="text-title line-clamp-1">
            From: {take.user.display_name}
          </span>{" "}
          <span className="text-title line-clamp-1 text-muted">
            To: {subscriberSize()}
          </span>
        </div>
      </div>

      <div
        className={`flex flex-col items-center w-full bg-inverted-hover ${
          mobileView ? "" : "px-4 py-6"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default EmailWindow;
