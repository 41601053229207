import React from "react";

import WebsiteCard from "./WebsiteCard";
import PostCard from "./PostCard";

const LinkCard = ({ linkRoll }) => {
  const url = linkRoll.link;
  const twitterUrl = url.includes("x.com") || url.includes("twitter.com");
  const instagramUrl = url.includes("instagram.com");
  const threadUrl = url.includes("threads.net");

  if (twitterUrl || instagramUrl || threadUrl) {
    return (
      <PostCard linkRoll={linkRoll} cardType={linkRoll.social_media_username} />
    );
  } else {
    return <WebsiteCard linkRoll={linkRoll} />;
  }
};

export default LinkCard;
