import * as Yup from "yup";

import { MAX_STRING_LENGTH } from "@/lib/constants";

export const INITIAL_VALUE = {
  email: "",
};

export const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .required("Email can't be blank.")
    .max(
      MAX_STRING_LENGTH,
      `Display name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});
