import React, { useState } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Link, useLocation } from "react-router-dom";

import {
  updateStripeDetails,
  cancelStripeSubscription,
  destroySubscription,
} from "@/apis/settings/paid_booths";
import Modal from "@/components/Modal";
import { showToastrError } from "@/lib/commons";
import Button from "@/components/Button";
import { ORDINALIZED_DATE_FORMAT, DEFAULT_PAGE_SIZE } from "@/lib/constants";
import BlankState from "@/components/BlankState";
import Pagination from "@/components/Pagination";

import ItemMenu from "./ItemMenu";

const Table = ({
  subscriptionsList,
  getPaidBoothsResponse,
  totalRecords,
  pageIndex,
  setPageIndex,
}) => {
  dayjs.extend(advancedFormat);

  const location = useLocation();
  const [cancelStripeSubscriptionAlert, setCancelStripeSubscriptionAlert] =
    useState(false);
  const [unsubscribeSubscriptionAlert, setUnsubscribeSubscriptionAlert] =
    useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState();
  const [btnLoader, setBtnLoader] = useState(false);

  const updateStripeDetailsResponse = async (subscriptionId) => {
    try {
      const { data } = await updateStripeDetails(
        subscriptionId,
        location.pathname
      );
      window.location.href = data.checkout_url;
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const cancelStripeSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      await cancelStripeSubscription(selectedSubscriptionId);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
      setSelectedSubscriptionId();
      setCancelStripeSubscriptionAlert(false);
    }
  };

  const destroySubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      await destroySubscription(selectedSubscriptionId);
      await getPaidBoothsResponse();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
      setSelectedSubscriptionId();
      setUnsubscribeSubscriptionAlert(false);
    }
  };

  const renderSubscription = (subscription) => {
    if (subscription.plan_type === "Free") {
      return <span>Free</span>;
    } else {
      return (
        <div className="flex flex-col w-full">
          <span>{`${subscription.plan_amount} / ${subscription.plan_type}`}</span>
          <span className="pb-help-text">
            {`${subscription.plan_ending ? "Ends On" : "Renews On"} ${dayjs(
              subscription.plan_end_date
            ).format(ORDINALIZED_DATE_FORMAT)}`}
          </span>
        </div>
      );
    }
  };

  const handleCancel = (subscription) => {
    setSelectedSubscriptionId(subscription.id);
    setCancelStripeSubscriptionAlert(true);
  };

  const handleUnsubscribe = (subscription) => {
    setSelectedSubscriptionId(subscription.id);
    setUnsubscribeSubscriptionAlert(true);
  };

  return (
    <>
      <div className="overflow-x-auto">
        {subscriptionsList.length > 0 ? (
          <table className="pb-data-table">
            <thead>
              <tr>
                <th className="w-[50%] min-w-[300px]">Booth Name</th>
                <th className="w-[55%] min-w-[300px]">Subscription</th>
                <th className="w-[15%] min-w-[50px]"></th>
              </tr>
            </thead>
            <tbody>
              {subscriptionsList.map((subscription) => {
                return (
                  <tr key={subscription.id}>
                    <td>
                      <Link to={`/archive/${subscription.user_url_slug}`}>
                        {subscription.user_name}
                      </Link>
                    </td>
                    <td>{renderSubscription(subscription)}</td>
                    <td>
                      <ItemMenu
                        handleUpdate={() =>
                          updateStripeDetailsResponse(subscription.id)
                        }
                        handleCancel={() => handleCancel(subscription)}
                        handleUnsubscribe={() =>
                          handleUnsubscribe(subscription)
                        }
                        subscription={subscription}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <BlankState>
            <div className="flex flex-col gap-2 w-full">
              <p className="text-title font-bold text-center">
                There are no booths to show
              </p>
            </div>
          </BlankState>
        )}
      </div>

      {subscriptionsList.length > 0 && (
        <Pagination
          count={totalRecords}
          pageSize={DEFAULT_PAGE_SIZE}
          pageNo={pageIndex}
          navigate={setPageIndex}
        />
      )}

      <Modal
        open={cancelStripeSubscriptionAlert}
        onClose={() => setCancelStripeSubscriptionAlert(false)}
        title="Cancel Stripe Subscription"
      >
        <div className="flex flex-col w-full gap-10">
          Are you sure you want to cancel the stripe subscription?
          <div className="flex gap-4">
            <Button
              label="Yes"
              loading={btnLoader}
              type="submit"
              style="inverted"
              bodyBackground="primary"
              onClick={() => cancelStripeSubscriptionResponse()}
            />
            <Button
              type="button"
              label="Cancel"
              style="transparent"
              onClick={() => setCancelStripeSubscriptionAlert(false)}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={unsubscribeSubscriptionAlert}
        onClose={() => setUnsubscribeSubscriptionAlert(false)}
        title="Unsubscribe Booth"
      >
        <div className="flex flex-col w-full gap-10">
          Are you sure you want to unsubscribe the booth?
          <div className="flex gap-4">
            <Button
              label="Yes"
              loading={btnLoader}
              type="submit"
              style="inverted"
              bodyBackground="primary"
              onClick={() => destroySubscriptionResponse()}
            />
            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => setUnsubscribeSubscriptionAlert(false)}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Table;
