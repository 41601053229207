import React, { useState } from "react";
import dayjs from "dayjs";

import { SCHEDULED_DATE_FORMAT } from "@/lib/constants";
import Callout from "@/components/Callout";
import { cancelScheduledTake } from "@/apis/takes";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import Modal from "@/components/Modal";
import Button from "@/components/Button";

const Notifications = ({ user, formState, getTakeAPI }) => {
  const [scheduleAlert, setScheduleAlert] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const cancelScheduledTakeAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await cancelScheduledTake(formState.id);
      showToastrSuccess(data.message);
      getTakeAPI();
      setScheduleAlert(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const scheduleAlertMessage = (
    <div className="flex">
      <p>{`Your take has been scheduled at ${dayjs(
        formState.scheduled_at
      ).format(SCHEDULED_DATE_FORMAT)}.`}</p>
      <p
        className="cursor-pointer underline pl-2"
        onClick={() => setScheduleAlert(true)}
      >
        Cancel Schedule
      </p>
    </div>
  );

  return (
    <div className="flex flex-col w-full gap-3 max-w-[var(--take-width)]">
      {formState.schedule && (
        <Callout message={scheduleAlertMessage} dismissable={true} />
      )}

      {user.under_review && (
        <Callout
          message="Hello! We're just manually reviewing your account at the moment.
              Please hold tight and you'll be able to send your take shortly."
          dismissable={true}
          style="secondary"
        />
      )}

      {formState.under_review && (
        <Callout
          message="Hello! We're just manually reviewing your take at the moment.
              Please hold tight take will be sent shortly."
          dismissable={true}
          style="secondary"
        />
      )}

      {formState.sent && !formState.attachments_attributes?.length > 0 && (
        <Callout
          message="Please add an image to display this take in archive."
          style="danger"
        />
      )}

      <Modal
        open={scheduleAlert}
        onClose={() => setScheduleAlert(false)}
        title="Cancel Schedule"
      >
        <div className="flex flex-col w-full gap-10">
          <div className="flex flex-col w-full gap-6">
            <p>Are you sure you want to cancel the schedule?</p>
          </div>

          <div className="flex gap-4">
            <Button
              type="button"
              label="Yes, Cancel Schedule"
              loading={btnLoader}
              style="inverted"
              onClick={() => cancelScheduledTakeAPI()}
              bodyBackground="primary"
            />

            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => setScheduleAlert(false)}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Notifications;
