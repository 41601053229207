import React, { useState, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { importSubscriptionList, downloadCsv } from "@/apis/subscriptions";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import FileInput from "@/components/FileInput";

import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";
import { PBIcons } from "../PBIcons";
import Modal from "../Modal";
import Button from "../Button";
import Loader from "../Loader";

const AudienceImportModal = ({ uploadModalOpen, setUploadModalOpen }) => {
  const [downloadBtnLoader, setDownloadBtnLoader] = useState(false);
  const [submitBtnLoader, setSubmitBtnLoader] = useState(false);

  const fileInput = useRef(null);

  const { handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: INITIAL_VALUE,
  });

  const handleChange = (event) => {
    const file = event.target.files[0];

    let reader = new FileReader();

    reader.onloadend = (e) => {
      setValue("file_data", e.target.result);
      setValue("file_name", file.name);
    };

    reader.readAsDataURL(file);
  };

  const importAudienceListResponse = async (formValue) => {
    try {
      setSubmitBtnLoader(true);
      await importSubscriptionList({
        subscription: {
          file_data: formValue.file_data,
          file_name: formValue.file_name,
          caption: formValue.file_name,
        },
      });
      fileInput.current.value = "";
      showToastrSuccess(
        "You will receive an email once the import is complete. After that, refresh the page to check the updated count."
      );
      setUploadModalOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setSubmitBtnLoader(false);
    }
  };

  const getCsvDownloadResponse = async () => {
    try {
      setDownloadBtnLoader(true);
      const { data } = await downloadCsv();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `example_import_subscriptions.csv`;
      link.click();
      setDownloadBtnLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  return (
    <Modal
      size="large"
      open={uploadModalOpen}
      onClose={() => setUploadModalOpen(false)}
      title="Import your Audience"
    >
      <div className="flex flex-col w-full gap-10">
        <form
          id="import-form"
          onSubmit={handleSubmit(importAudienceListResponse)}
          className="flex flex-col w-full gap-6"
        >
          <p>
            To help migrate your existing contacts, download the CSV template
            below and then simply paste your contacts in. Save as a new CSV file
            then select and upload it to immediately import.
          </p>

          <p>
            If you need a hand, we can migrate your existing list for you -
            simply email your exported contacts to{" "}
            <a className="underline" href="mailto:import@pencilbooth.com">
              import@pencilbooth.com
            </a>
            .
          </p>

          <button
            className="text-[16px] hover:underline flex items-center gap-2"
            onClick={() => getCsvDownloadResponse()}
          >
            {downloadBtnLoader ? <Loader /> : <PBIcons icon="Download" />}

            <span>Download Sample Import CSV</span>
          </button>

          <FileInput
            inputRef={fileInput}
            label="Upload Subscription List"
            type="file"
            id="file_data"
            name="file_data"
            onChange={(e) => handleChange(e)}
            accept="text/csv"
            error={formState.errors.file_data?.message}
            required
            bodyBackground="primary"
            style="primary"
          />
        </form>

        <div className="flex items-start gap-3 bg-primary-hover p-3 rounded-sm">
          <PBIcons icon="Notice" className="shrink-0" />

          <div className="flex flex-col gap-6 w-full text-small">
            <p>
              To avoid your PencilBooth messages being flagged as Spam, we
              suggest you send a message on your current platform to let your
              audience know before you send your first take to let them know
              they have been subscribed to your PencilBooth, and give them a
              chance to opt out. You can manually remove subscribers on your
              Audience dashboard if needed.
            </p>
            <p>
              As a best practice when it comes to contacts you are bringing over
              from another service, we would suggest not sending an existing
              newsletter from another platform concurrently with your
              PencilBooth takes, as this will probably cause mass unsubscribes
              and potential abuse reports.
            </p>
            <p>
              If you need a hand, just drop us a line at{" "}
              <a className="underline" href="mailto:import@pencilbooth.com">
                import@pencilbooth.com
              </a>
              .
            </p>
          </div>
        </div>

        <div className="flex justify-end w-full gap-4">
          <Button
            form="import-form"
            type="submit"
            label="Upload"
            loading={submitBtnLoader}
            style="inverted"
            bodyBackground="primary"
          />

          <Button
            type="button"
            style="transparent"
            label="Cancel"
            onClick={() => setUploadModalOpen(false)}
            bodyBackground="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default AudienceImportModal;
