import React, { useState, useCallback, useEffect } from "react";
import { EditorContent, useEditor, BubbleMenu } from "@tiptap/react";
import Document from "@tiptap/extension-document";
import History from "@tiptap/extension-history";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Paragraph from "@tiptap/extension-paragraph";
import Underline from "@tiptap/extension-underline";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import HardBreak from "@tiptap/extension-hard-break";
import TextAlign from "@tiptap/extension-text-align";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Placeholder from "@tiptap/extension-placeholder";

import LinkModal from "./LinkModal";
import BubbleMenuOptions from "./BubbleMenuOptions";

const TextEditor = ({
  className = "content-editor",
  placeholder,
  content,
  setEditorContent,
  onBlur,
  onFocus = () => null,
  textClass,
  extensionList,
}) => {
  const editor = useEditor({
    autocorrect: false,
    enablePasteRules: false,
    enableInputRules: false,
    extensions: [
      Document,
      History,
      Text,
      Paragraph.configure({
        HTMLAttributes: {
          class: textClass ? textClass : "text-left",
        },
      }),
      extensionList.includes("Bold") && Bold,
      extensionList.includes("Underline") && Underline,
      extensionList.includes("Italic") && Italic,
      extensionList.includes("HardBreak") && HardBreak,
      extensionList.includes("Link") &&
        Link.configure({
          openOnClick: false,
          linkOnPaste: false,
          HTMLAttributes: {
            class: "text-[#0000EE] underline",
          },
        }),
      extensionList.includes("TextAlign") &&
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
      extensionList.includes("ListItem") &&
        ListItem.configure({
          HTMLAttributes: {
            class: "py-0.5 md:py-1",
          },
          keepAttributes: false,
        }),
      extensionList.includes("BulletList") &&
        BulletList.configure({
          HTMLAttributes: {
            class: "list-disc pl-6",
          },
          keepMarks: true,
          keepAttributes: true,
          itemTypeName: "listItem",
        }),
      extensionList.includes("OrderedList") &&
        OrderedList.configure({
          HTMLAttributes: {
            class: "list-decimal pl-6",
          },
          keepMarks: true,
          keepAttributes: true,
          itemTypeName: "listItem",
        }),
      Placeholder.configure({
        placeholder: placeholder,
      }),
    ],
    content: content,
    onBlur: async ({ editor }) => {
      await setEditorContent(
        editor.getHTML() === '<p class="text-left"></p>'
          ? null
          : editor.getHTML().replace(/<p class="text-left"><\/p>/g, "<br>")
      );
      await onBlur();
    },
    onFocus: async () => {
      onFocus();
    },
  });

  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [linkSaveLoader, setLinkSaveLoader] = useState(false);
  const [url, setUrl] = useState("");

  const closeModal = useCallback(() => {
    setLinkModalOpen(false);
    setUrl("");
  }, []);

  const openModal = useCallback(() => {
    setUrl(editor.getAttributes("link").href);
    setLinkModalOpen(true);
  }, [editor]);

  const saveLink = useCallback(() => {
    setLinkSaveLoader(true);
    if (url) {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url, target: "_blank" })
        .run();
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    editor.commands.blur();
    setLinkSaveLoader(false);
    closeModal();
  }, [editor, url, closeModal]);

  const removeLink = useCallback(() => {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    closeModal();
  }, [editor, closeModal]);

  if (!editor) {
    return null;
  }

  return (
    <div className="editor-container">
      <EditorContent editor={editor} className={className} />

      <BubbleMenu
        pluginKey="bubbleMenuText"
        className="bubble-menu-dark"
        tippyOptions={{ duration: 150 }}
        editor={editor}
        shouldShow={({ editor, view, state, oldState, from, to }) => {
          // only show if range is selected.
          return from !== to;
        }}
      >
        <BubbleMenuOptions
          editor={editor}
          openModal={openModal}
          extensionList={extensionList}
        />
      </BubbleMenu>

      <BubbleMenu
        pluginKey="bubbleMenuLink"
        className="bubble-menu-dark"
        tippyOptions={{ duration: 150 }}
        editor={editor}
        shouldShow={({ editor, view, state, oldState, from, to }) => {
          // only show the bubble menu for links.
          return from === to && editor.isActive("link");
        }}
      >
        <div className="flex gap-3 p-1">
          <span
            className="text-inverted hover:text-inverted-hover cursor-pointer transition-all duration-300"
            onClick={openModal}
          >
            Edit
          </span>

          <span
            className="text-danger hover:text-danger-hover cursor-pointer transition-all duration-300"
            onClick={removeLink}
          >
            Remove
          </span>
        </div>
      </BubbleMenu>

      <LinkModal
        url={url}
        isOpen={linkModalOpen}
        closeModal={closeModal}
        setUrl={setUrl}
        onSaveLink={saveLink}
        linkSaveLoader={linkSaveLoader}
      />
    </div>
  );
};

export default TextEditor;
