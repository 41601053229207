import React, { useState } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { ORDINALIZED_DATE_FORMAT } from "@/lib/constants";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { updateSubscription } from "@/apis/subscriptions";
import Modal from "@/components/Modal";
import Button from "@/components/Button";

import ItemMenu from "./ItemMenu";

import {
  PAID_CUSTOMER_WARNING,
  RESUBSCRIBE_AUDIENCE,
  UNSUBSCRIBE_AUDIENCE,
  PAID_CUSTOMER_REMINDER,
} from "./constants";

const Table = ({ audiences, getAudienceListAPI }) => {
  dayjs.extend(advancedFormat);

  const [audience, setAudience] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const updateSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateSubscription(audience.id, {
        subscription: { unsubscribed: !audience.unsubscribed },
      });
      showToastrSuccess(data.message);
      setAlertOpen(false);
      setAudience();
      getAudienceListAPI();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const getAlertMessage = () => {
    if (!audience) return null;

    if (audience.upgraded_to_paid) {
      return PAID_CUSTOMER_WARNING;
    } else if (audience.unsubscribed) {
      return RESUBSCRIBE_AUDIENCE;
    } else if (!audience.unsubscribed) {
      return `${UNSUBSCRIBE_AUDIENCE} ${
        audience.upgraded_to_paid && !audience.plan_ending
          ? PAID_CUSTOMER_REMINDER
          : ""
      }`;
    }
  };

  const getAlertTitle = () => {
    if (!audience) return null;

    if (audience.unsubscribed) {
      return "Resubscribe";
    } else {
      return "Unsubscribe";
    }
  };

  const renderSubscription = (subscription) => {
    if (subscription.unsubscribed) {
      return <span></span>;
    } else if (subscription.plan_type === "Free") {
      return <span>Free</span>;
    } else {
      return (
        <div className="flex flex-col gap-2">
          <span>{`${subscription.plan_amount} / ${subscription.plan_type}`}</span>
          <span className="text-label text-muted uppercase">
            {`${subscription.plan_ending ? "Ends On" : "Renews On"} ${dayjs(
              subscription.plan_end_date
            ).format(ORDINALIZED_DATE_FORMAT)}`}
          </span>
        </div>
      );
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="pb-data-table">
        <thead>
          <tr>
            <th className="w-[35%] min-w-[250px]">Email</th>
            <th className="w-[15%] min-w-[150px]">Last Email Status</th>
            <th className="w-[15%] min-w-[140px]">Status</th>
            <th className="w-[15%] min-w-[140px]">Date Subscribed</th>
            <th className="w-[15%] min-w-[140px]">Plan Detail</th>
            <th className="w-[5%] min-w-[50px]"></th>
          </tr>
        </thead>
        <tbody>
          {audiences.map((audience) => {
            return (
              <tr key={audience.id}>
                <td>{audience.email}</td>
                <td>{audience.last_email_status}</td>
                <td>{audience.status}</td>
                <td>
                  {audience.created_at &&
                    dayjs(audience.created_at).format("DD MMM YYYY")}
                </td>
                <td>{renderSubscription(audience)}</td>
                <td className="!text-right">
                  <ItemMenu
                    audience={audience}
                    setAudience={setAudience}
                    setAlertOpen={setAlertOpen}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>

        <Modal
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          title={getAlertTitle()}
        >
          <div className="flex flex-col w-full gap-10">
            <div className="flex flex-col w-full gap-6">
              {getAlertMessage()}
            </div>

            <div className="flex gap-4">
              {!audience?.upgraded_to_paid && (
                <Button
                  type="submit"
                  label="Yes, Proceed"
                  loading={btnLoader}
                  onClick={() => updateSubscriptionResponse()}
                  style="inverted"
                  bodyBackground="primary"
                />
              )}

              <Button
                label="Cancel"
                style="transparent"
                onClick={() => setAlertOpen(false)}
                bodyBackground="primary"
              />
            </div>
          </div>
        </Modal>
      </table>
    </div>
  );
};

export default Table;
