import React, { useState } from "react";
import dayjs from "dayjs";

import { destroyDiscipline } from "@/apis/super_admin/disciplines";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import { STANDARD_DATE_FORMAT } from "@/lib/constants";

import ItemMenu from "./ItemMenu";

const Table = ({
  disciplineId,
  setDisciplineId,
  disciplines,
  getDisciplinesAPI,
  setDisciplineModal,
  getDisciplineAPI,
}) => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);

  const destroyDisciplineAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await destroyDiscipline(disciplineId);
      showToastrSuccess(data.message);
      getDisciplinesAPI();
      handleAlertClose();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const handleAlertClose = () => {
    setDeleteAlert(false);
    setDisciplineId();
  };

  return (
    <div className="overflow-x-auto">
      <table className="pb-data-table">
        <thead>
          <tr>
            <th className="w-[35%] min-w-[300px]">Name</th>
            <th className="w-[10%] min-w-[80px]">Created At</th>
            <th className="w-[5%] min-w-[50px]"></th>
          </tr>
        </thead>
        <tbody>
          {disciplines.map((discipline) => {
            return (
              <tr key={discipline.id}>
                <td>{discipline.name}</td>
                <td>
                  {dayjs(discipline.created_at).format(STANDARD_DATE_FORMAT)}
                </td>
                <td className="!text-right">
                  <ItemMenu
                    discipline={discipline}
                    setDisciplineId={setDisciplineId}
                    setDeleteAlert={setDeleteAlert}
                    setDisciplineModal={setDisciplineModal}
                    getDisciplineAPI={getDisciplineAPI}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal
        open={deleteAlert}
        onClose={() => handleAlertClose()}
        title="Delete Discipline"
      >
        <div className="flex flex-col w-full gap-10">
          <div className="flex flex-col w-full gap-6">
            <p>Are you sure you want to delete the discipline?</p>
          </div>

          <div className="flex gap-4">
            <Button
              type="button"
              label="Yes, Delete"
              loading={btnLoader}
              style="inverted"
              onClick={() => destroyDisciplineAPI()}
              bodyBackground="primary"
            />
            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => handleAlertClose()}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Table;
