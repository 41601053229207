import React from "react";
import Flatpickr from "react-flatpickr";

import Input from "@/components/Input";
import Button from "@/components/Button";
import Modal from "@/components/Modal";

const ScheduleModal = ({
  modalOpen,
  setModalOpen,
  formState,
  setValue,
  errors,
  handleTakeSubmit,
  scheduleBtnLoader,
  setScheduleBtnLoader,
}) => {
  const flatPickrOption = {
    enableTime: true,
    dateFormat: "Y-m-d h:i K",
    minDate: "today",
    wrap: true,
    allowInput: true,
    disableMobile: true,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setScheduleBtnLoader(true);
    setValue("schedule", true);
    handleTakeSubmit("schedule");
  };

  const handleCancel = () => {
    setModalOpen(false);
    setValue("scheduled_at", null);
    setValue("schedule", false);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={(e) => handleCancel(e)}
      title="Schedule your take!"
    >
      <div className="flex flex-col w-full gap-10">
        <form
          id="schedule-form"
          className="flex flex-col w-full gap-6"
          onSubmit={(e) => handleSubmit(e)}
        >
          <p>
            Enter the day and time (in your local time-zone) and your take will
            get automatically sent then.
          </p>

          <Flatpickr
            data-input
            id="scheduled_at"
            options={flatPickrOption}
            value={formState.scheduled_at}
            onChange={([date]) => {
              setValue("schedule", true);
              setValue("scheduled_at", date);
            }}
          >
            <Input
              data-input
              name="scheduled_at"
              id="scheduled_at"
              placeholder="Enter date and time"
              error={Boolean(errors.scheduled_at) && errors.scheduled_at}
              bodyBackground="primary"
              style="primary"
              autoComplete="off"
              required
            />
          </Flatpickr>
        </form>

        <div className="flex gap-4">
          <Button
            form="schedule-form"
            type="submit"
            label="Yes, Proceed"
            loading={scheduleBtnLoader}
            style="inverted"
            bodyBackground="primary"
          />

          <Button
            type="button"
            label="Cancel"
            style="transparent"
            onClick={() => handleCancel()}
            bodyBackground="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ScheduleModal;
