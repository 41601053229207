import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ring } from "ldrs";

import { AuthProvider } from "@/hooks/useAuth";

import HomeLayout from "@/layouts/HomeLayout";
import AppLayout from "@/layouts/AppLayout";
import SettingLayout from "@/layouts/SettingLayout";
import BlankLayout from "@/layouts/BlankLayout";
import CommonLayout from "@/layouts/CommonLayout";
import DashboardLayout from "@/layouts/DashboardLayout";
import AdminLayout from "@/layouts/AdminLayout";

import LoginPage from "@/pages/Login";
import JoinPage from "@/pages/Join";
import AboutPage from "@/pages/About";
import PrivacyPage from "@/pages/Privacy";
import TermsPage from "@/pages/Terms";
import PrivacyProformaPage from "@/pages/PrivacyProforma";
import ReviewTakeListPage from "@/pages/SuperAdmin/ReviewTakes";
import NonFeaturedTakeListPage from "@/pages/SuperAdmin/NonFeaturedTakes";
import UserListPage from "@/pages/SuperAdmin/Users";
import UserEditPage from "@/pages/SuperAdmin/Users/EditUser";
import DisciplinePage from "@/pages/SuperAdmin/Disciplines";
import DefaultSettingPage from "@/pages/SuperAdmin/DefaultSetting";

import SearchPage from "@/pages/Search";
import DiscoverPage from "@/pages/Discoveries";
import TakeDetailsPage from "@/pages/TakeDetails";
import ArchivePage from "@/pages/Archive";

import SubscriberPage from "@/pages/Dashboard/Subscribers";
import CreateTakePage from "@/pages/Dashboard/CreateTake";
import PreviewTakePage from "@/pages/Dashboard/PreviewTake";
import SendTakePage from "@/pages/Dashboard/SendTake";
import PreviousTakesPage from "@/pages/Dashboard/PreviousTakes";
import SubscriberFormPage from "@/pages/SubscriberForm";

import AccountPage from "@/pages/Dashboard/Settings/Account";
import ProfilePage from "@/pages/Dashboard/Settings/Profile";
import BrandingPage from "@/pages/Dashboard/Settings/Branding";
import BillingPage from "@/pages/Dashboard/Settings/Billing";
import EmbededFormPage from "@/pages/Dashboard/Settings/EmbededForm";
import PaidSubscriptionPage from "./pages/Dashboard/Settings/PaidSubscription";
import PaidBoothPage from "./pages/Dashboard/Settings/PaidBooths";

import SubscribeAcknowledgement from "@/pages/SubscribeAcknowledgement";
import UnsubscribeAcknowledgement from "@/pages/UnsubscribeAcknowledgement";
import NotFoundPage from "@/pages/NotFound";
import SubscriptionFailurePage from "@/pages/SubscriptionFailure";

import {
  ROOT_ROUTE,
  ABOUT_ROUTE,
  LOGIN_ROUTE,
  JOIN_ROUTE,
  PRIVACY_ROUTE,
  TERMS_ROUTE,
  DISCOVERIES_ROUTE,
  SUBSCRIBER_ROUTE,
  PREVIOUS_TAKE_ROUTE,
  ARCHIVE_ROUTE,
  TAKE_CREATE_ROUTE,
  TAKE_EDIT_ROUTE,
  TAKE_PREVIEW_ROUTE,
  TAKE_SEND_ROUTE,
  SEARCH_ROUTE,
  TAKE_DETAILS_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  PROFILE_SETTINGS_ROUTE,
  BRANDING_SETTINGS_ROUTE,
  BILLING_SETTINGS_ROUTE,
  EMBED_SETTINGS_ROUTE,
  PAID_SUBSCRIPTION_ROUTE,
  PRIVACY_PRO_FORMA_ROUTE,
  REVIEW_TAKES_ADMIN_ROUTE,
  NON_FEATURED_TAKES_ADMIN_ROUTE,
  USERS_ADMIN_ROUTE,
  USERS_EDIT_ADMIN_ROUTE,
  DISCIPLINES_ADMIN_ROUTE,
  DEFAULT_SETTINGS_ADMIN_ROUTE,
  PAID_BOOTH_ROUTE,
  SUBSCRIBER_FORM_ROUTE,
  NOT_FOUND_ROUTE,
} from "@/lib/routes";

const App = () => {
  ring.register();

  return (
    <AuthProvider>
      <ToastContainer />

      <Routes>
        <Route element={<AppLayout />}>
          <Route path="*" element={<NotFoundPage />} />
          <Route
            path={SUBSCRIBER_FORM_ROUTE}
            element={<SubscriberFormPage />}
          />

          <Route element={<HomeLayout />}>
            <Route path={ROOT_ROUTE} element={<DiscoverPage />} />
            <Route path={LOGIN_ROUTE} element={<LoginPage />} />
            <Route path={JOIN_ROUTE} element={<JoinPage />} />
          </Route>

          <Route element={<DashboardLayout />}>
            <Route path={DISCOVERIES_ROUTE} element={<DiscoverPage />} />
            <Route path={SUBSCRIBER_ROUTE} element={<SubscriberPage />} />
            <Route path={PREVIOUS_TAKE_ROUTE} element={<PreviousTakesPage />} />
          </Route>

          <Route element={<BlankLayout />}>
            <Route path={TAKE_CREATE_ROUTE} element={<CreateTakePage />} />
            <Route path={TAKE_EDIT_ROUTE} element={<CreateTakePage />} />
            <Route path={TAKE_PREVIEW_ROUTE} element={<PreviewTakePage />} />
            <Route path={TAKE_SEND_ROUTE} element={<SendTakePage />} />
          </Route>

          <Route element={<CommonLayout />}>
            <Route path={ABOUT_ROUTE} element={<AboutPage />} />
            <Route path={SEARCH_ROUTE} element={<SearchPage />} />
            <Route path={ARCHIVE_ROUTE} element={<ArchivePage />} />
            <Route path={TAKE_DETAILS_ROUTE} element={<TakeDetailsPage />} />
            <Route
              element={<UnsubscribeAcknowledgement />}
              path="/unsubscribed/success"
            />
            <Route
              element={<SubscriptionFailurePage />}
              path="/unsubscribed/failure"
            />
            <Route
              element={<SubscribeAcknowledgement />}
              path="/subscribed/success"
            />
            <Route
              element={<SubscriptionFailurePage />}
              path="/subscribed/failure"
            />
            <Route path={PRIVACY_ROUTE} element={<PrivacyPage />} />
            <Route path={TERMS_ROUTE} element={<TermsPage />} />
            <Route
              path={PRIVACY_PRO_FORMA_ROUTE}
              element={<PrivacyProformaPage />}
            />
            <Route element={<NotFoundPage />} path={NOT_FOUND_ROUTE} />
          </Route>

          <Route element={<SettingLayout />}>
            <Route path={ACCOUNT_SETTINGS_ROUTE} element={<AccountPage />} />
            <Route path={PROFILE_SETTINGS_ROUTE} element={<ProfilePage />} />
            <Route path={BRANDING_SETTINGS_ROUTE} element={<BrandingPage />} />
            <Route path={BILLING_SETTINGS_ROUTE} element={<BillingPage />} />
            <Route path={EMBED_SETTINGS_ROUTE} element={<EmbededFormPage />} />
            <Route
              path={PAID_SUBSCRIPTION_ROUTE}
              element={<PaidSubscriptionPage />}
            />
            <Route path={PAID_BOOTH_ROUTE} element={<PaidBoothPage />} />
          </Route>

          <Route element={<AdminLayout />}>
            <Route
              path={REVIEW_TAKES_ADMIN_ROUTE}
              element={<ReviewTakeListPage />}
            />
            <Route
              path={NON_FEATURED_TAKES_ADMIN_ROUTE}
              element={<NonFeaturedTakeListPage />}
            />
            <Route path={USERS_ADMIN_ROUTE} element={<UserListPage />} />
            <Route path={USERS_EDIT_ADMIN_ROUTE} element={<UserEditPage />} />
            <Route
              path={DISCIPLINES_ADMIN_ROUTE}
              element={<DisciplinePage />}
            />
            <Route
              path={DEFAULT_SETTINGS_ADMIN_ROUTE}
              element={<DefaultSettingPage />}
            />
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default App;
