import React, { useState } from "react";

import Input from "@/components/Input";
import Button from "@/components/Button";
import Switch from "@/components/Switch";
import { isValidUrl } from "@/lib/utils";

const EditLinkMenu = ({
  setOpen,
  formState,
  setImageValue,
  handleSave,
  btnLoader,
}) => {
  const [linkError, setLinkError] = useState();

  return (
    <div className="flex flex-col px-2 gap-y-4">
      <form
        id="link-form"
        className="flex flex-col w-full gap-y-3"
        onSubmit={(e) => {
          e.preventDefault();
          if (formState.link?.length > 0 && !isValidUrl(formState.link)) {
            setLinkError("Link is not valid.");
          } else {
            setLinkError("");
            handleSave();
          }
        }}
      >
        <Input
          id="link"
          name="link"
          label="Link"
          value={formState.link}
          onChange={(e) => setImageValue("link", e.target.value)}
          placeholder="Enter a link"
          error={linkError}
          bodyBackground="primary"
          style="primary"
        />

        {formState.link && (
          <div className="flex justify-between items-center gap-x-3">
            <label className="shrink-0">Show link button</label>

            <Switch
              checked={formState.show_link_button}
              onChange={() => {
                setImageValue("show_link_button", !formState.show_link_button);
              }}
              style="inverted"
              className="!w-fit"
              bodyBackground="primary"
            />
          </div>
        )}

        {formState.link && formState.show_link_button && (
          <Input
            id="button_text"
            name="button_text"
            label="Button Text"
            value={formState.button_text}
            onChange={(e) => setImageValue("button_text", e.target.value)}
            placeholder="Add display text"
            bodyBackground="primary"
            style="primary"
          />
        )}
      </form>

      <div className="flex gap-4">
        <Button
          form="link-form"
          type="submit"
          label="Update"
          loading={btnLoader}
          style="inverted"
          bodyBackground="primary"
        />

        <Button
          type="button"
          style="transparent"
          label="Cancel"
          onClick={() => setOpen(false)}
          bodyBackground="primary"
        />
      </div>
    </div>
  );
};

export default EditLinkMenu;
