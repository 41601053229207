import React, { useRef } from "react";
import classnames from "classnames";

const FileInput = ({
  name,
  value,
  label = "",
  helpText = "",
  error = "",
  className = "",
  inputRef = useRef(null),
  bodyBackground = "inverted",
  style = "inverted",
  ...otherProps
}) => {
  const STYLES = {
    primary: "bg-primary text-inverted placeholder:text-primary-hover",
    inverted: "bg-inverted text-primary placeholder:text-muted",
    "option-card":
      "bg-option-card-background text-inverted placeholder:text-disabled-background",
  };

  const BORDER_STYLES = {
    primary: "border-muted",
    inverted: "border-primary-border",
    "option-card": "border-muted",
  };

  const RING_STYLES = {
    inverted: "focus-within:shadow-secondary-ring-inverted-bg",
    primary: "focus-within:shadow-secondary-ring-primary-bg",
    "inverted-hover": "focus-within:shadow-secondary-ring-inverted-hover-bg",
    "option-card": "focus-within:shadow-secondary-ring-option-card-bg",
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      {!!label && (
        <label htmlFor={name} className="pb-input-label">
          {label}
        </label>
      )}

      <input
        ref={inputRef}
        id={name}
        name={name}
        type="file"
        value={value}
        className={classnames(
          "w-full border border-solid rounded-sm transition-all duration-300 outline-none p-2",
          STYLES[style],
          BORDER_STYLES[style],
          RING_STYLES[bodyBackground],
          className
        )}
        {...otherProps}
      />

      {!!error && <p className="pb-error-text">{error}</p>}
      {!!helpText && <p className="pb-help-text">{helpText}</p>}
    </div>
  );
};

export default FileInput;
