import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import classnames from "classnames";

const Button = ({
  className = "",
  labelClassName = "",
  label = "Button",
  loading = false,
  disabled = false,
  style = "primary",
  bodyBackground = "inverted",
  icon,
  suffixIcon,
  ...otherProps
}) => {
  const STYLES = {
    primary:
      "bg-primary border-primary text-inverted hover:bg-primary-hover outline-none",
    disabled:
      "bg-disabled-background border-disabled text-disabled hover:bg-disabled-background outline-none",
    inverted:
      "bg-inverted border-inverted text-primary hover:bg-inverted-hover hover:border-inverted-hover outline-none",
    transparent:
      "bg-transparent border-muted hover:text-inverted hover:bg-primary-hover outline-none",
    danger:
      "bg-danger border-danger text-inverted hover:bg-danger-hover outline-none",
    "secondary-outline":
      "bg-inverted border-secondary text-secondary hover:bg-secondary hover:text-inverted outline-none",
  };

  const DISABLED_STYLES = {
    primary:
      "bg-disabled-background border-disabled text-disabled hover:bg-disabled-background outline-none",
    inverted: "text-disabled border-inverted hover:bg-inverted outline-none",
  };

  const RING_STYLES = {
    inverted: {
      primary: "focus:shadow-secondary-ring-inverted-bg",
      disabled: "focus:shadow-secondary-ring-inverted-bg",
      inverted: "focus:shadow-secondary-ring-inverted-bg",
      transparent: "focus:shadow-secondary-ring-inverted-bg",
      danger: "focus:shadow-danger-ring-inverted-bg",
      "secondary-outline": "focus:shadow-secondary-ring-inverted-bg",
    },
    primary: {
      primary: "focus:shadow-secondary-ring-primary-bg",
      disabled: "focus:shadow-secondary-ring-primary-bg",
      inverted: "focus:shadow-secondary-ring-primary-bg",
      transparent: "focus:shadow-secondary-ring-primary-bg",
      danger: "focus:shadow-danger-ring-primary-bg",
      "secondary-outline": "focus:shadow-secondary-ring-primary-bg",
    },
    "inverted-hover": {
      primary: "focus:shadow-secondary-ring-inverted-hover-bg",
      disabled: "focus:shadow-secondary-ring-inverted-hover-bg",
      inverted: "focus:shadow-secondary-ring-inverted-hover-bg",
      transparent: "focus:shadow-secondary-ring-inverted-hover-bg",
      danger: "focus:shadow-danger-ring-inverted-hover-bg",
      "secondary-outline": "focus:shadow-secondary-ring-inverted-hover-bg",
    },
  };

  const renderLoader = () => {
    return (
      <AnimatePresence>
        {loading && (
          <motion.div
            animate={{
              width: "auto",
              scale: 1,
              display: "flex",
              alignItems: "center",
            }}
            exit={{ width: 0, scale: 0, display: "flex", alignItems: "center" }}
            initial={{
              width: 0,
              scale: 0,
              display: "flex",
              alignItems: "center",
            }}
            transition={{ bounce: false }}
          >
            <l-ring
              size="16"
              stroke="2"
              bg-opacity="0.5"
              speed="2"
              color="currentColor"
            ></l-ring>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  return (
    <button
      className={classnames([
        "flex justify-center items-center gap-1.5 px-2 py-1.5 border border-solid rounded-sm w-fit transition-all duration-300",
        { "pointer-events-none": loading || disabled },
        disabled ? DISABLED_STYLES[style] : STYLES[style],
        RING_STYLES[bodyBackground][style],
        className,
      ])}
      {...otherProps}
    >
      {!suffixIcon && renderLoader()}

      {!loading && icon}
      <span className={labelClassName}>{label}</span>
      {!loading && suffixIcon}

      {suffixIcon && renderLoader()}
    </button>
  );
};

export default Button;
