import React from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import { STANDARD_DATE_FORMAT } from "@/lib/constants";

import ItemMenu from "./ItemMenu";

const Table = ({ takes }) => {
  return (
    <div className="overflow-x-auto">
      <table className="pb-data-table">
        <thead>
          <tr>
            <th className="w-[35%] min-w-[300px]">Subject</th>
            <th className="w-[25%] min-w-[120px]">Status</th>
            <th className="w-[25%] min-w-[80px]">Created At</th>
            <th className="w-[15%] min-w-[50px]"></th>
          </tr>
        </thead>
        <tbody>
          {takes.map((take) => {
            return (
              <tr key={take.id}>
                <td>
                  <Link to={`/takes/${take.id}`}>{take.subject}</Link>
                </td>
                <td>{take.status}</td>
                <td>{dayjs(take.created_at).format(STANDARD_DATE_FORMAT)}</td>
                <td className="!text-right">
                  <ItemMenu take={take} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
