import * as Yup from "yup";

import { MAX_STRING_LENGTH, MAX_SLUG_LENGTH } from "@/lib/constants";

export const INITIAL_VALUE = {
  display_name: "",
  url_slug: "",
  personal_url: "",
  notification_enabled: false,
  location: "",
  follower_suggestion_ids: [],
};

export const VALIDATION_SCHEMA = Yup.object({
  display_name: Yup.string()
    .required("Display name can't be blank.")
    .max(
      MAX_STRING_LENGTH,
      `Display name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  url_slug: Yup.string()
    .required("URL can't be blank.")
    .max(MAX_SLUG_LENGTH, `URL must be at most ${MAX_SLUG_LENGTH} characters.`),
});
