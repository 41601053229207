import React from "react";

import Button from "@/components/Button";

const GridCard = ({
  gallery,
  paidView = false,
  image,
  take,
  mobileView = false,
  emailView,
}) => {
  const { has_view_access, paid_content, caption, title, blur_generated } =
    image;

  const isBlur = () => {
    return (
      (paid_content || take.has_paid_content) && (!has_view_access || paidView)
    );
  };

  const isBlurBackground = () => {
    return isBlur() && (!blur_generated || has_view_access);
  };

  const renderImage = (image) => {
    return (
      <div className="relative aspect-square overflow-hidden rounded">
        <img
          src={image.attachment_url}
          alt={image.alt_text}
          className={`h-full w-full object-cover rounded ${
            isBlurBackground() ? "image-filter" : ""
          }`}
        />
      </div>
    );
  };

  return (
    <div
      className={`flex flex-col items-center gap-8 w-full ${
        mobileView ? "px-4" : "px-4 md:px-8"
      }`}
    >
      <div className="w-full max-w-[var(--take-width)] relative">
        <div className="grid grid-cols-3 gap-4">
          {gallery.map((image, index) => {
            return (
              <div className="aspect-square" key={index}>
                {paid_content && paidView ? (
                  <a href={take.generate_redirect_url}>{renderImage(image)}</a>
                ) : image.link ? (
                  <a href={image.link}>{renderImage(image)}</a>
                ) : (
                  renderImage(image)
                )}
              </div>
            );
          })}
        </div>

        {isBlur() && !emailView && (
          <a href={take.generate_redirect_url}>
            <div className="absolute bottom-4 left-4 z-[10] cursor-pointer bg-inverted px-3 py-1.5 rounded-sm shadow">
              🔒 This content is for paid subscribers. Please upgrade to view.
            </div>
          </a>
        )}
      </div>

      {isBlur() && emailView && (
        <a href={take.generate_redirect_url}>
          <Button
            label="🔒 This content is for paid subscribers. Please upgrade to view."
            className="focus:!shadow-none"
          />
        </a>
      )}

      {!isBlur() && (!!caption || !!title) && (
        <div className="flex flex-col gap-4 w-full max-w-[var(--take-width)]">
          {!!title && (
            <div
              className={`font-bold ${
                mobileView ? "text-mobile-heading" : "text-heading"
              }`}
              dangerouslySetInnerHTML={{
                __html: title?.split("\n")?.join("<br/>"),
              }}
            />
          )}

          {!!caption && (
            <div
              dangerouslySetInnerHTML={{
                __html: caption?.split("\n")?.join("<br/>"),
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GridCard;
