import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "@/hooks/useAuth";
import { LOGIN_ROUTE } from "@/lib/routes";
import { LOGIN_REQUIRED_MESSAGE } from "@/lib/constants";
import { showToastrError } from "@/lib/commons";
import AppNavbar from "@/components/AppNavbar";
import Sidebar from "@/components/Sidebar";

const SettingLayout = () => {
  const location = useLocation();
  const { user, setShowBeacon } = useAuth();

  useEffect(() => {
    setShowBeacon(true);

    return () => {
      setShowBeacon(true);
    };
  }, [location.pathname]);

  if (!user) {
    showToastrError(LOGIN_REQUIRED_MESSAGE);
    return <Navigate to={LOGIN_ROUTE} replace={true} />;
  }

  return (
    <div className="bg-inverted">
      <AppNavbar />
      <Sidebar />
      <Outlet />
    </div>
  );
};

export default SettingLayout;
