import React from "react";

import SidebarNavItem from "@/components/SidebarNavItem";
import {
  REVIEW_TAKES_ADMIN_ROUTE,
  NON_FEATURED_TAKES_ADMIN_ROUTE,
  USERS_ADMIN_ROUTE,
  DISCIPLINES_ADMIN_ROUTE,
  DEFAULT_SETTINGS_ADMIN_ROUTE,
} from "@/lib/routes";

const AdminSidebar = () => {
  return (
    <nav className="pb-sidebar flex flex-row lg:flex-col items-center lg:items-start gap-1 w-full bg-inverted lg:bg-inverted-hover px-6 py-2.5 lg:py-6 fixed z-30 top-0 left-0">
      <SidebarNavItem
        path={REVIEW_TAKES_ADMIN_ROUTE}
        label="Under Review Takes"
      />
      <SidebarNavItem
        path={NON_FEATURED_TAKES_ADMIN_ROUTE}
        label="Non Featured Takes"
      />
      <SidebarNavItem path={USERS_ADMIN_ROUTE} label="Users" />
      <SidebarNavItem path={DISCIPLINES_ADMIN_ROUTE} label="Disciplines" />
      <SidebarNavItem
        path={DEFAULT_SETTINGS_ADMIN_ROUTE}
        label="Default Setting"
      />
    </nav>
  );
};

export default AdminSidebar;
