import React from "react";
import { Link } from "react-router-dom";
import pluralize from "pluralize";
import { getProfileUrl } from "@/lib/utils";

const FriendCard = ({ subscription }) => {
  return (
    <Link
      to={getProfileUrl(subscription.url_slug)}
      className="flex flex-col items-center w-full gap-3 bg-inverted-hover rounded-sm px-4 py-6"
    >
      <div className="w-[60px] h-[60px] rounded-full border-2 border-solid border-primary">
        <img
          className="h-full w-full object-cover rounded-full"
          src={subscription.logo_url}
          alt="User Logo"
        />
      </div>

      <div className="flex flex-col items-center w-full gap-1">
        <h5 className="font-bold line-clamp-1 text-center">
          {subscription.name}
        </h5>

        <p className="text-muted text-center">
          {subscription.takes_count}{" "}
          {pluralize("take", subscription.takes_count)}
        </p>
      </div>
    </Link>
  );
};

const Friends = ({ subscriptions, mobileView = false }) => {
  return (
    <div className="flex flex-col items-center gap-6 w-full max-w-[var(--take-width)] pt-12 border-t border-solid border-primary-border">
      <h4
        className={`font-bold ${
          mobileView ? "text-mobile-heading" : "text-heading"
        }`}
      >
        Follow my friends!
      </h4>

      {subscriptions.length === 1 && (
        <div className="grid grid-cols-1 gap-4 w-full">
          {subscriptions.map((subscription) => (
            <FriendCard key={subscription.id} subscription={subscription} />
          ))}
        </div>
      )}

      {(subscriptions.length === 2 || subscriptions.length === 4) && (
        <div className="grid grid-cols-2 gap-4 w-full">
          {subscriptions.map((subscription) => (
            <FriendCard key={subscription.id} subscription={subscription} />
          ))}
        </div>
      )}

      {subscriptions.length === 3 && (
        <div className="flex flex-col w-full space-y-4">
          <div className="grid grid-cols-2 gap-4 w-full">
            {subscriptions.slice(0, 2).map((subscription) => (
              <FriendCard key={subscription.id} subscription={subscription} />
            ))}
          </div>

          <div className="grid grid-cols-1 gap-4 w-full">
            {subscriptions.slice(1, 2).map((subscription) => (
              <FriendCard key={subscription.id} subscription={subscription} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Friends;
