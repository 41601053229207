import React, { createElement } from "react";

import { icons } from "./icons";

export const PBIcons = ({ icon, size = 19.2, className, rotate, ...rest }) => {
  return (
    <div
      className={`flex justify-center items-center ${className}`}
      aria-label={icon}
      role="img"
      style={{
        transform: rotate ? `rotate(${rotate}deg)` : undefined,
      }}
      {...rest}
    >
      {createElement(icons[icon], {
        style: { width: size, height: size },
      })}
    </div>
  );
};
