import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";

const ItemMenu = ({ take }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <Menu open={open} setOpen={setOpen} style="transparent">
      <MenuItem label="Review" onClick={() => navigate(`/takes/${take.id}`)} />
    </Menu>
  );
};

export default ItemMenu;
