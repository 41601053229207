import * as Yup from "yup";

import { MAX_STRING_LENGTH, MAX_SLUG_LENGTH } from "@/lib/constants";

export const INITIAL_VALUE = {
  name: "",
  display_name: "",
  url_slug: "",
  paid_email_count: "",
};

export const VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be blank.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  display_name: Yup.string()
    .required("Display name can't be blank.")
    .max(
      MAX_STRING_LENGTH,
      `Display name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  url_slug: Yup.string()
    .required("URL can't be blank.")
    .max(MAX_SLUG_LENGTH, `URL must be at most ${MAX_SLUG_LENGTH} characters.`),
  paid_email_count: Yup.number()
    .required("Paid email count can't be less than 0.")
    .min(0),
});
