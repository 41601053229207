import React, { useState } from "react";

import { destroyLinkRoll } from "@/apis/link_rolls";
import { showToastrError } from "@/lib/commons";
import Button from "@/components/Button";

const DeleteMenu = ({ setOpen, formState, setValue, linkRoll }) => {
  const [btnLoader, setBtnLoader] = useState(false);

  const destroyLinkRollAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await destroyLinkRoll(formState.id, linkRoll.id);
      setValue("link_rolls_attributes", data.link_rolls);
      setOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 w-full px-2">
      <p>Are you sure you want to delete this link?</p>

      <div className="flex gap-4">
        <Button
          type="submit"
          label="Yes, Delete"
          loading={btnLoader}
          style="inverted"
          onClick={() => destroyLinkRollAPI()}
          bodyBackground="primary"
        />

        <Button
          type="button"
          style="transparent"
          label="Cancel"
          onClick={() => setOpen(false)}
          bodyBackground="primary"
        />
      </div>
    </div>
  );
};

export default DeleteMenu;
