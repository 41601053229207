import React, { useState } from "react";

import { createSubscription } from "@/apis/subscriptions";
import { showToastrError } from "@/lib/commons";

import Modal from "./Modal";
import Button from "./Button";

const FollowYourself = ({
  modalOpen,
  setModalOpen,
  updateState,
  userId,
  email,
}) => {
  const [subscriptionBtnLoader, setSubscriptionBtnLoader] = useState(false);

  const createSubscriptionAPI = async () => {
    try {
      setSubscriptionBtnLoader(true);
      await createSubscription({
        subscription: { email: email, user_id: userId },
      });
      updateState();
      setModalOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setSubscriptionBtnLoader(false);
    }
  };
  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      title="Follow Yourself"
    >
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-col w-full gap-6">
          <p>Are you sure you want to follow yourself?</p>
        </div>

        <div className="flex gap-4">
          <Button
            type="button"
            label="Yes, Follow"
            loading={subscriptionBtnLoader}
            style="inverted"
            onClick={() => createSubscriptionAPI()}
            bodyBackground="primary"
          />

          <Button
            type="button"
            style="transparent"
            label="Cancel"
            onClick={() => setModalOpen(false)}
            bodyBackground="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default FollowYourself;
