import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";

const ItemMenu = ({ user }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <Menu open={open} setOpen={setOpen} style="transparent">
      <MenuItem
        label="Edit"
        onClick={() => {
          navigate(`/super_admin/users/${user.id}`, { replace: true });
        }}
      />
    </Menu>
  );
};

export default ItemMenu;
