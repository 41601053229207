import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { useForm } from "react-hook-form";
import AnimateHeight from "react-animate-height";
import { useNavigate } from "react-router-dom";

import { createTake } from "@/apis/takes";
import { createAttachment, updateAttachment } from "@/apis/take_attachments";
import { showToastrError } from "@/lib/commons";
import Loader from "@/components/Loader";
import Button from "@/components/Button";
import { sanitizeLink } from "@/lib/utils";
import TextEditor from "@/components/TextEditor";
import { TAKE_EDITOR_EXTENSION_LIST } from "@/lib/constants";

import Dropdown from "./Dropdown";
import { IMAGE_INITIAL_VALUE } from "../constants";

const ImageCard = ({ image, setValue, formState }) => {
  const imageRef = useRef();
  const contentRef = useRef();
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [altOpen, setAltOpen] = useState(false);
  const [linkOpen, setLinkOpen] = useState(false);
  const [tagOpen, setTagOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [linkMenuOpen, setLinkMenuOpen] = useState(false);
  const [altMenuOpen, setAltMenuOpen] = useState(false);
  const [deleteMenuOpen, setDeleteMenuOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        imageRef.current &&
        !imageRef.current.contains(event.target) &&
        contentRef.current &&
        !contentRef.current.contains(event.target)
      ) {
        setClicked(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [imageRef, contentRef]);

  const hasContent = () => {
    return image.caption?.length > 0 || image.title?.length > 0 || clicked;
  };

  const IMAGE_WIDTH = {
    full: "w-full",
    wide: "max-w-[1200px]",
    normal: "max-w-[var(--take-width)]",
  };

  const {
    handleSubmit,
    setValue: setImageValue,
    reset,
    watch,
  } = useForm({
    defaultValues: IMAGE_INITIAL_VALUE,
    shouldValidate: true,
    shouldDirty: true,
    mode: "onblur",
  });

  // This to create new attachment
  useEffect(() => {
    reset(image);
    if (!image.id && image.imageLoader) {
      if (!formState.id) {
        createAttachmentWithTake(image);
      } else {
        createAttachmentAPI(image, formState.id, false);
      }
    }
  }, [image]);

  const createTakeAPI = async () => {
    try {
      const { data } = await createTake({ take: formState });
      setValue("id", data.take.id);
      return data.take.id;
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const createAttachmentWithTake = async (attachment) => {
    createTakeAPI().then(async (takeId) => {
      createAttachmentAPI(attachment, takeId, true);
    });
  };

  const createAttachmentAPI = async (attachment, takeId, navigateToEdit) => {
    try {
      const { data } = await createAttachment(
        takeId,
        { attachment: attachment },
        { onUploadProgress: setProgress }
      );
      const updatedAttachments = formState.attachments_attributes.map((att) => {
        if (att.tempId === attachment.tempId) {
          return {
            ...att,
            id: data.attachment.id,
            imageLoader: false,
            in_bottom_position: data.attachment.in_bottom_position,
            in_top_position: data.attachment.in_top_position,
          };
        } else {
          return { ...att, in_bottom_position: false };
        }
      });
      reset(data.attachment);
      setValue("attachments_attributes", updatedAttachments); // ReLoad array of attachments
      if (navigateToEdit)
        navigate(`/takes/${takeId}/edit`, {
          state: {
            pageLoad: false,
          },
        });
    } catch (error) {
      const updatedAttachments = formState.attachments_attributes.filter(
        (att) => att.tempId !== attachment.tempId
      );

      setValue("attachments_attributes", updatedAttachments);
      showToastrError(error.response.data.errors);
    }
  };

  const updateAttachmentAPI = async () => {
    try {
      setBtnLoader(true);

      const { data } = await updateAttachment(formState.id, image.id, {
        attachment: {
          title: watch().title,
          caption: watch().caption,
          alt_text: watch().alt_text,
          link: sanitizeLink(watch().link) || null,
          button_text: watch().button_text,
          show_link_button: watch().show_link_button,
          tag_ids: watch().tag_ids,
          hero_image: watch().hero_image,
          paid_content: watch().paid_content,
          style: watch().style,
        },
      });

      reset(data.attachment);

      const updatedAttachments = formState.attachments_attributes.map((att) => {
        if (att.id === image.id) {
          return { ...data.attachment };
        } else {
          if (data.attachment.hero_image) {
            return { ...att, hero_image: false };
          } else {
            return { ...att };
          }
        }
      });

      setValue("attachments_attributes", updatedAttachments);
      setAltOpen(false);
      setLinkOpen(false);
      setLinkMenuOpen(false);
      setAltMenuOpen(false);
      setDeleteMenuOpen(false);
      setMenuOpen(false);
      setTagOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const updateAttachmentLinkAPI = async () => {
    try {
      const { data } = await updateAttachment(formState.id, image.id, {
        attachment: {
          link: sanitizeLink(watch().link) || null,
          show_link_button: watch().show_link_button,
        },
      });

      reset(data.attachment);

      const updatedAttachments = formState.attachments_attributes.map((att) => {
        if (att.id === image.id) {
          return { ...data.attachment };
        } else {
          return { ...att };
        }
      });

      setValue("attachments_attributes", updatedAttachments);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const onCaptionChange = (value) => {
    setImageValue("caption", value);
  };

  const onTitleChange = (value) => {
    setImageValue("title", value);
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div
        className={classnames("flex flex-col items-center w-full", {
          "px-6 md:px-10": image.style !== "full",
        })}
      >
        <div
          className={classnames(
            "relative transition-all duration-300 cursor-pointer w-full",
            {
              "shadow-secondary-ring-inverted-bg":
                clicked && image.style !== "full",
              rounded: image.style !== "full",
            },
            IMAGE_WIDTH[image.style]
          )}
          onClick={() => setClicked(true)}
          ref={imageRef}
        >
          {image.imageLoader && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 text-inverted backdrop-blur-sm rounded">
              <Button label={`${progress}%...`} loading={image.imageLoader} />
            </div>
          )}

          <img
            src={image.file_data || image.attachment_url}
            alt={image.alt_text}
            className={`w-full h-auto ${
              image.style !== "full" ? "rounded" : ""
            }`}
          />

          <div
            className={`absolute left-3 bottom-3 rounded-sm bg-inverted w-fit px-2 py-1 text-small shadow transition-all duration-200 ${
              hasContent()
                ? "opacity-0 invisible translate-y-3"
                : "opacity-100 visible translate-y-0"
            }`}
          >
            Click image to add title and caption
          </div>

          <div className="absolute right-2 top-2">
            <Dropdown
              takeId={formState.id}
              formState={watch()}
              setTakeValue={setValue}
              setImageValue={setImageValue}
              handleSave={handleSubmit(updateAttachmentAPI)}
              handleLinkSave={handleSubmit(updateAttachmentLinkAPI)}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              altOpen={altOpen}
              setAltOpen={setAltOpen}
              linkOpen={linkOpen}
              setLinkOpen={setLinkOpen}
              linkMenuOpen={linkMenuOpen}
              setLinkMenuOpen={setLinkMenuOpen}
              altMenuOpen={altMenuOpen}
              setAltMenuOpen={setAltMenuOpen}
              deleteMenuOpen={deleteMenuOpen}
              setDeleteMenuOpen={setDeleteMenuOpen}
              tagOpen={tagOpen}
              setTagOpen={setTagOpen}
              deleteOpen={deleteOpen}
              setDeleteOpen={setDeleteOpen}
              btnLoader={btnLoader}
              attachments={formState.attachments_attributes}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center w-full px-6 md:px-10">
        <div
          className="flex flex-col w-full max-w-[var(--take-width)]"
          ref={contentRef}
        >
          <AnimateHeight duration={300} height={hasContent() ? "auto" : 0}>
            <div className="flex flex-col gap-4 w-full mt-8">
              <AnimateHeight
                duration={300}
                height={watch().title || clicked ? "auto" : 0}
              >
                <TextEditor
                  className="text-heading font-bold"
                  placeholder="Headline (optional)"
                  content={image.title}
                  setEditorContent={onTitleChange}
                  extensionList={["TextAlign"]}
                  onBlur={() => handleSubmit(updateAttachmentAPI())}
                  onFocus={() => setClicked(true)}
                />
              </AnimateHeight>

              <TextEditor
                placeholder="Write something about your image"
                content={image.caption}
                setEditorContent={onCaptionChange}
                extensionList={TAKE_EDITOR_EXTENSION_LIST}
                onBlur={() => handleSubmit(updateAttachmentAPI())}
                onFocus={() => setClicked(true)}
              />
            </div>
          </AnimateHeight>
        </div>
      </div>

      {!!watch().link && watch().show_link_button && !!watch().button_text && (
        <a className="flex justify-center pt-[16px]" href={watch().link}>
          <Button label={watch().button_text} />
        </a>
      )}
    </div>
  );
};

export default ImageCard;
