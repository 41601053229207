import React from "react";
import { Link } from "react-router-dom";

import LinkCard from "@/components/LinkCard";
import LockBroll from "@/assets/images/lock-broll.png";

const BRoll = ({ take, mobileView = false, paidView = false }) => {
  const isBlur = () => {
    return (
      (take.mark_link_roll_paid || take.has_paid_content) &&
      (!take.show_paid_content || paidView)
    );
  };

  return (
    <div className="flex flex-col items-center gap-8 w-full max-w-[var(--take-width)] pt-12 border-t border-solid border-primary-border">
      {!isBlur() ? (
        <>
          <div className="flex flex-col items-center gap-4 w-full">
            {!!take.link_roll_title && (
              <h4
                className={`text-center font-bold ${
                  mobileView ? "text-mobile-heading-xl" : "text-heading-xl"
                }`}
              >
                {take.link_roll_title}
              </h4>
            )}

            {!!take.link_roll_intro && (
              <div
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: take.link_roll_intro,
                }}
              />
            )}
          </div>

          {take.link_rolls_attributes.map((linkRoll) => {
            return (
              <div
                key={linkRoll.id}
                className="flex flex-col gap-4 w-full max-w-[var(--link-roll-width)]"
              >
                <LinkCard linkRoll={linkRoll} />

                {!!linkRoll.title && (
                  <div
                    className={`font-bold ${
                      mobileView ? "text-mobile-heading" : "text-heading"
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: linkRoll.title,
                    }}
                  />
                )}

                {!!linkRoll.caption && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: linkRoll.caption,
                    }}
                  />
                )}
              </div>
            );
          })}
        </>
      ) : (
        <Link to={take.generate_redirect_url}>
          <img src={LockBroll} alt="Locked BRoll" />
        </Link>
      )}
    </div>
  );
};

export default BRoll;
