import React from "react";
import classnames from "classnames";

import Button from "@/components/Button";

const ImageCard = ({
  image,
  mobileView = false,
  paidView = false,
  emailView,
  take,
}) => {
  const {
    attachment_url,
    alt_text,
    title,
    caption,
    has_view_access,
    blur_generated,
    paid_content,
    link,
    show_link_button,
    button_text,
    style,
  } = image;

  const IMAGE_WIDTH = {
    full: "w-full",
    wide: emailView ? "max-w-[var(--take-width)]" : "max-w-[1200px]",
    normal: "max-w-[var(--take-width)]",
  };

  const isBlur = () => {
    return (
      (paid_content || take.has_paid_content) && (!has_view_access || paidView)
    );
  };

  const isBlurBackground = () => {
    return isBlur() && (!blur_generated || has_view_access);
  };

  const renderImage = () => {
    return (
      <img
        src={attachment_url}
        className={`w-full h-auto ${style !== "full" ? "rounded" : ""} ${
          isBlurBackground() ? "image-filter" : ""
        }`}
        alt={alt_text || "Take Image"}
      />
    );
  };

  return (
    <div className="flex flex-col items-center gap-8 w-full">
      <div
        className={classnames("flex flex-col items-center w-full", {
          "px-4 md:px-8": style !== "full" && !mobileView,
          "px-4": style !== "full" && mobileView,
        })}
      >
        <div
          className={classnames(
            "relative w-full overflow-hidden rounded",
            IMAGE_WIDTH[style]
          )}
        >
          {paid_content && paidView ? (
            <a href={take.generate_redirect_url}>{renderImage(image)}</a>
          ) : link ? (
            <a href={link}>{renderImage(image)}</a>
          ) : (
            renderImage(image)
          )}

          {isBlur() && !emailView && (
            <a href={take.generate_redirect_url}>
              <div
                className={`absolute bottom-4 left-4 z-[10] cursor-pointer bg-inverted px-3 py-1.5 rounded-sm shadow ${
                  mobileView ? "text-mobile-small" : "text-small"
                }`}
              >
                🔒 This content is for paid subscribers. Please upgrade to view.
              </div>
            </a>
          )}
        </div>
      </div>

      {isBlur() && emailView && (
        <a href={take.generate_redirect_url}>
          <Button
            label="🔒 This content is for paid subscribers. Please upgrade to view."
            className="focus:!shadow-none"
          />
        </a>
      )}

      {!isBlur() &&
        (!!caption ||
          !!title ||
          (!!link && show_link_button && !!button_text)) && (
          <div
            className={`flex flex-col items-center w-full ${
              mobileView ? "px-4" : "px-4 md:px-8"
            }`}
          >
            <div className="flex flex-col gap-4 w-full max-w-[var(--take-width)]">
              {!!title && (
                <div
                  className={`font-bold ${
                    mobileView ? "text-mobile-heading" : "text-heading"
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: title?.split("\n")?.join("<br/>"),
                  }}
                />
              )}

              {!!caption && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: caption?.split("\n")?.join("<br/>"),
                  }}
                />
              )}

              {!!link && show_link_button && !!button_text && (
                <a className="flex justify-center" href={link}>
                  <Button label={button_text} />
                </a>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default ImageCard;
