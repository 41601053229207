import React, { useState } from "react";
import dayjs from "dayjs";
import { useHover } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";

import { STANDARD_DATE_FORMAT } from "@/lib/constants";
import Carousel from "@/components/Carousel";
import TakeSingleImage from "@/components/TakeSingleImage";
import LinkButton from "@/components/LinkButton";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { useAuth } from "@/hooks/useAuth";
import { updateTake } from "@/apis/super_admin/takes";
import { showToastrSuccess, showToastrError } from "@/lib/commons";

const TakeCard = ({
  take,
  archivePage,
  takeUser,
  removeTakeFromList = () => null,
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [ref, hovering] = useHover();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [takeId, setTakeId] = useState();
  const [btnLoader, setBtnLoader] = useState(false);

  const updateTakeAPI = async () => {
    try {
      setBtnLoader(true);
      await updateTake(takeId, { take: { featured: false } });
      removeTakeFromList(takeId);
      showToastrSuccess("Take has been removed successfully.");
      setRemoveModalOpen(false);
      setTakeId(null);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const hasPaidContent = () => {
    return (
      take.has_paid_content ||
      take.attachments_attributes.some((att) => att.paid_content)
    );
  };

  return (
    <div className="flex flex-col gap-2.5" ref={ref}>
      <div className="relative group">
        {take.attachments_attributes.length > 1 ? (
          <Carousel
            autoPlay={hovering}
            images={take.attachments_attributes}
            setDataLoaded={setDataLoaded}
          />
        ) : (
          take.attachments_attributes.length == 1 && (
            <TakeSingleImage
              autoPlay={hovering}
              image={take.attachments_attributes[0]}
              setDataLoaded={setDataLoaded}
            />
          )
        )}

        {dataLoaded &&
          archivePage &&
          hasPaidContent() &&
          !takeUser.subscribed && (
            <a
              className="absolute bottom-2 left-2 z-[30] cursor-pointer bg-inverted px-3 py-1.5 rounded-sm shadow text-small"
              href={`/${takeUser.url_slug}`}
            >
              🔒Contains paid subscriber only content.
            </a>
          )}

        {dataLoaded && (
          <>
            {!archivePage && (
              <div className="absolute z-10 top-2.5 left-2.5">
                <div className="flex items-center gap-2.5">
                  <img
                    src={take.logo_url}
                    className="h-[30px] w-[30px] rounded-full object-cover shrink-0"
                  />

                  <span className="text-small text-inverted line-clamp-1">
                    {take.user_name}
                  </span>
                </div>
              </div>
            )}

            <div className="absolute z-20 top-0 left-0 h-full w-full rounded-[var(--take-card-radius)] flex items-center justify-center bg-black transition-all duration-300 opacity-0 bg-opacity-0 group-hover:opacity-100 group-hover:bg-opacity-30">
              <div className="flex gap-2.5">
                <LinkButton
                  to={
                    hasPaidContent() && !takeUser.subscribed
                      ? `/${takeUser.url_slug}`
                      : `/takes/${take.id}`
                  }
                  label="View"
                  style="inverted"
                />

                {!archivePage && user?.superadmin && (
                  <Button
                    label="Remove"
                    style="danger"
                    onClick={() => {
                      setTakeId(take.id);
                      setRemoveModalOpen(true);
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {dataLoaded && (
        <div className="flex items-center justify-between w-full gap-3">
          <span className="text-title line-clamp-1">{take.subject}</span>
          <span className="text-label text-muted shrink-0">
            {dayjs(take.sent_at).format(STANDARD_DATE_FORMAT)}
          </span>
        </div>
      )}

      <Modal
        open={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        title="Remove Take"
      >
        <div className="flex flex-col w-full gap-10">
          <div className="flex flex-col w-full gap-6">
            <p>Are you sure you want to remove the take from the discovery?</p>
          </div>

          <div className="flex gap-4">
            <Button
              type="button"
              label="Yes, Remove"
              loading={btnLoader}
              style="inverted"
              onClick={() => updateTakeAPI()}
              bodyBackground="primary"
            />

            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => setRemoveModalOpen(false)}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TakeCard;
