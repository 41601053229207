import React, { useEffect } from "react";

import { updateAttachment } from "@/apis/multiple_take_attachments";
import { showToastrError } from "@/lib/commons";
import { useAuth } from "@/hooks/useAuth";
import { PBIcons } from "@/components/PBIcons";
import Menu from "@/components/Menu";
import { swapElement } from "@/lib/utils";

import DefaultMenu from "./DefaultMenu";
import EditAltMenu from "./EditAltMenu";
import EditLinkMenu from "./EditLinkMenu";
import EditTagMenu from "./EditTagMenu";
import DeleteMenu from "./DeleteMenu";

const Dropdown = ({
  takeId,
  formState,
  setImageValue,
  setTakeValue,
  handleSave,
  handleLinkSave,
  menuOpen,
  setMenuOpen,
  altOpen,
  setAltOpen,
  linkMenuOpen,
  setLinkMenuOpen,
  altMenuOpen,
  setAltMenuOpen,
  deleteMenuOpen,
  setDeleteMenuOpen,
  linkOpen,
  setLinkOpen,
  tagOpen,
  setTagOpen,
  deleteOpen,
  setDeleteOpen,
  btnLoader,
  attachments,
}) => {
  const { user } = useAuth();

  const index = attachments.map((x) => x.id).indexOf(formState.id);

  useEffect(() => {
    if (menuOpen) {
      setAltOpen(false);
      setLinkOpen(false);
      setTagOpen(false);
      setLinkMenuOpen(false);
      setDeleteOpen(false);
    }
  }, [menuOpen]);

  const swapAttachments = (destination) => {
    let newlyOrderedAttachments = swapElement(attachments, index, destination);
    setTakeValue("attachments_attributes", newlyOrderedAttachments);
    reorderAttachmentAPI(newlyOrderedAttachments);
  };

  const reorderAttachmentAPI = async (newItems) => {
    try {
      const { data } = await updateAttachment(takeId, {
        multiple_take_attachments: {
          id: takeId,
          attachments_attributes: newItems,
        },
      });
      setTakeValue("attachments_attributes", data.attachments);
    } catch (error) {
      showToastrError(error.response.data.errors);
      setTakeValue("attachments_attributes", attachments);
    }
  };

  const handleWidthClick = (style) => {
    setImageValue("style", style);
    handleSave();
  };

  const handleHeroImageClick = () => {
    setImageValue("hero_image", !formState.hero_image);
    handleSave();
  };

  const handlePaidClick = () => {
    setImageValue("paid_content", !formState.paid_content);
    handleSave();
  };

  const renderMenuContent = () => {
    if (altOpen) {
      return (
        <EditAltMenu
          setOpen={setAltOpen}
          formState={formState}
          setImageValue={setImageValue}
          handleSave={handleSave}
          btnLoader={btnLoader}
        />
      );
    } else if (linkOpen) {
      return (
        <EditLinkMenu
          setOpen={setLinkOpen}
          formState={formState}
          setImageValue={setImageValue}
          handleSave={handleSave}
          btnLoader={btnLoader}
          handleLinkSave={handleLinkSave}
        />
      );
    } else if (tagOpen) {
      return (
        <EditTagMenu
          setOpen={setTagOpen}
          formState={formState}
          setImageValue={setImageValue}
          handleSave={handleSave}
          btnLoader={btnLoader}
        />
      );
    } else if (deleteOpen) {
      return (
        <DeleteMenu
          setOpen={setDeleteOpen}
          takeId={takeId}
          formState={formState}
          setTakeValue={setTakeValue}
        />
      );
    } else {
      return (
        <DefaultMenu
          index={index}
          formState={formState}
          swapElement={swapAttachments}
          setLinkOpen={setLinkOpen}
          setAltOpen={setAltOpen}
          setTagOpen={setTagOpen}
          setDeleteOpen={setDeleteOpen}
          handleWidthClick={handleWidthClick}
          handleHeroImageClick={handleHeroImageClick}
          handlePaidClick={handlePaidClick}
        />
      );
    }
  };

  return (
    <div className="flex divide-x-[1px] divide-x-primary-border rounded-sm border border-solid border-primary-border">
      <Menu
        open={deleteMenuOpen}
        setOpen={setDeleteMenuOpen}
        width="300"
        position="bottom-end"
        menuTrigger={<PBIcons icon="Delete" />}
        style="transparent"
        borderRadius={false}
      >
        <DeleteMenu
          setOpen={setDeleteOpen}
          takeId={takeId}
          formState={formState}
          setTakeValue={setTakeValue}
        />
      </Menu>

      <Menu
        open={altMenuOpen}
        setOpen={setAltMenuOpen}
        width="300"
        position="bottom-end"
        menuTrigger={<PBIcons icon="Alt" />}
        style="transparent"
        borderRadius={false}
      >
        <EditAltMenu
          setOpen={setAltOpen}
          formState={formState}
          setImageValue={setImageValue}
          handleSave={handleSave}
          btnLoader={btnLoader}
        />
      </Menu>

      <Menu
        open={linkMenuOpen}
        setOpen={setLinkMenuOpen}
        width="300"
        position="bottom-end"
        menuTrigger={<PBIcons icon="Link" />}
        style="transparent"
        borderRadius={false}
      >
        <EditLinkMenu
          setOpen={setLinkOpen}
          formState={formState}
          setImageValue={setImageValue}
          handleSave={handleSave}
          btnLoader={btnLoader}
          handleLinkSave={handleLinkSave}
        />
      </Menu>

      {user.paid_subscription_enabled && (
        <div
          className={`flex items-center justify-center p-1 transition-all duration-300 cursor-pointer ${
            formState.paid_content
              ? "text-inverted bg-secondary hover:bg-secondary-hover"
              : "text-primary bg-inverted hover:bg-inverted-hover"
          }`}
        >
          <PBIcons icon="Paid" onClick={() => handlePaidClick()} />
        </div>
      )}

      <Menu
        open={menuOpen}
        setOpen={setMenuOpen}
        width="300"
        position="bottom-end"
        style="transparent"
        borderRadius={false}
      >
        {renderMenuContent()}
      </Menu>
    </div>
  );
};

export default Dropdown;
