import React, { useState, useEffect } from "react";
import CurrencyList from "currency-list";

import Input from "@/components/Input";
import Button from "@/components/Button";
import LinkButton from "@/components/LinkButton";
import Select from "@/components/Select";
import Loader from "@/components/Loader";
import Textarea from "@/components/Textarea";
import Switch from "@/components/Switch";
import { showToastrError } from "@/lib/commons";
import {
  getPaidSubscription,
  createStripeAccount,
} from "@/apis/settings/paid_subscriptions";
import { updateUserPlan } from "@/apis/settings/user_plans";
import { updateUser } from "@/apis/users";
import { useAuth } from "@/hooks/useAuth";
import { PBIcons } from "@/components/PBIcons";

const PiadSubscription = () => {
  const { user } = useAuth();
  const [pageLoader, setPageLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [paidUser, setPaidUser] = useState();
  const [freeUserPlan, setFreeUserPlan] = useState({});
  const [monthlyUserPlan, setMonthlyUserPlan] = useState({});
  const [yearlyUserPlan, setYearlyUserPlan] = useState({});

  const CURRENCY_OPTIONS = Object.keys(CurrencyList.getAll("en_US")).map(
    (currency) => {
      return { label: currency, value: currency };
    }
  );

  useEffect(() => {
    getPaidSubscriptionAPI();
  }, []);

  const getPaidSubscriptionAPI = async () => {
    try {
      const { data } = await getPaidSubscription();
      setPaidUser(data.paid_subscription);

      data.paid_subscription.user_plans.map((plan) => {
        if (plan.interval === "free") {
          setFreeUserPlan(plan);
        } else if (plan.interval === "monthly") {
          setMonthlyUserPlan(plan);
        } else if (plan.interval === "yearly") {
          setYearlyUserPlan(plan);
        }
      });

      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const createStripeAccountAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createStripeAccount();
      window.location.href = data.connect_url;
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const updateUserAPI = async (isEnabled) => {
    try {
      await updateUser(user.id, {
        user: { turn_on_paid: isEnabled },
      });
      await getPaidSubscriptionAPI();
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const createStripePriceAPI = async () => {
    try {
      setBtnLoader(true);
      const payload = {
        user_plan: {
          custom_subscription_message: paidUser.custom_subscription_message,
          user_plans_attributes: [
            freeUserPlan,
            monthlyUserPlan,
            yearlyUserPlan,
          ],
        },
      };
      await updateUserPlan(user.id, payload);
      await getPaidSubscriptionAPI();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const onValueChange = (value, index, plan, setPlan) => {
    const updatedBenefits = plan.benefits.map((item, i) => {
      if (i === index) {
        return value;
      } else {
        return item;
      }
    });

    setPlan({ ...plan, benefits: updatedBenefits });
  };

  const switchDisabled = () => {
    return !paidUser.stripe_account?.active;
  };

  const planDisabled = () => {
    return !paidUser.stripe_account?.active || !paidUser.turn_on_paid;
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-settings-page-wrapper bg-inverted-hover">
      <div className="pb-settings-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-12 w-full max-w-md lg:max-w-3xl">
          {!paidUser.stripe_account ? (
            <div className="flex flex-col gap-6 w-full">
              <div className="flex flex-col gap-2 w-full">
                <h4 className="font-bold">Stripe Integration</h4>

                <span className="text-small text-muted">
                  Connect your Stripe account to begin taking payments.
                </span>
              </div>

              <Button
                label="Connect your Stripe account"
                loading={btnLoader}
                onClick={() => createStripeAccountAPI()}
                bodyBackground="inverted-hover"
                style="inverted"
                className="hover:!bg-inverted hover:!border-inverted"
              />
            </div>
          ) : paidUser.stripe_account.active ? (
            <div className="flex flex-col gap-6 w-full">
              <div className="flex flex-col gap-2 w-full">
                <h4 className="font-bold">Stripe Integration</h4>

                <span className="text-small text-muted">
                  Your Stripe account is connected.
                </span>
              </div>

              <span className="text-small font-bold">
                {paidUser.stripe_email}
              </span>

              <LinkButton
                to="https://dashboard.stripe.com/login"
                target="_blank"
                label="View stripe account"
                bodyBackground="inverted-hover"
                style="inverted"
                className="hover:!bg-inverted hover:!border-inverted"
              />
            </div>
          ) : (
            <div className="flex flex-col gap-6 w-full">
              <div className="flex flex-col gap-2 w-full">
                <h4 className="font-bold">Stripe Integration</h4>

                <span className="text-small text-muted">
                  Your Stripe account onboarding is pending.
                </span>
              </div>

              <div className="flex items-start gap-3 w-full p-3 rounded-sm bg-primary-hover text-inverted">
                <PBIcons icon="Notice" className="shrink-0" />

                <p className="text-small">
                  If you have completed your Stripe onboarding and you still see
                  the 'Resume Onboarding' button, please verify your email and
                  then refresh this page or hit the 'View stripe account' button
                  for more information. For new accounts this can sometimes take
                  between 5 and 10 minutes.
                </p>
              </div>

              <div className="flex flex-col md:flex-row gap-3">
                <Button
                  loading={btnLoader}
                  label="Resume Onboarding"
                  onClick={() => createStripeAccountAPI()}
                  bodyBackground="inverted-hover"
                />

                <LinkButton
                  to="https://dashboard.stripe.com/login"
                  target="_blank"
                  label="View stripe account"
                  style="secondary-outline"
                  bodyBackground="inverted-hover"
                />
              </div>
            </div>
          )}

          <div className={`flex gap-4 ${switchDisabled() && "opacity-50"}`}>
            <Switch
              checked={paidUser.turn_on_paid}
              onChange={() => {
                updateUserAPI(!paidUser.turn_on_paid);
              }}
              disabled={switchDisabled()}
              bodyBackground="inverted-hover"
              className="shrink-0 !w-fit"
            />

            <div className="flex flex-col gap-1 w-full">
              <label>Enable Paid Subscriptions</label>

              <span className="text-small text-muted">
                Allow your subscribers to pay for extra content.
              </span>
            </div>
          </div>

          <div
            className={`flex flex-col gap-6 w-full ${
              planDisabled() && "opacity-50"
            }`}
          >
            <h3 className="text-title font-bold">Subscription Settings</h3>

            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-col gap-2 w-full">
                <h4 className="font-bold">Pricing</h4>

                <span className="text-small text-muted">
                  Your subscribers will have the opportunity to sign up for a
                  monthly or yearly subscription.
                </span>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                <div className="flex flex-col gap-1 w-full">
                  <label className="text-label">Monthly Subscription</label>

                  <div className="grid grid-cols-2 gap-3">
                    <Input
                      id="monthly_price"
                      name="monthly_price"
                      placeholder="Amount"
                      bodyBackground="inverted-hover"
                      type="number"
                      value={monthlyUserPlan.unit_amount}
                      onChange={(e) =>
                        setMonthlyUserPlan({
                          ...monthlyUserPlan,
                          unit_amount: e.target.value,
                        })
                      }
                      disabled={planDisabled()}
                    />

                    <Select
                      name="monthly_currency"
                      id="monthly_currency"
                      placeholder="Currency"
                      bodyBackground="inverted-hover"
                      value={CURRENCY_OPTIONS.find(
                        (currency) =>
                          monthlyUserPlan.currency === currency.value
                      )}
                      onChange={(opt) =>
                        setMonthlyUserPlan({
                          ...monthlyUserPlan,
                          currency: opt.value,
                        })
                      }
                      options={CURRENCY_OPTIONS}
                      isDisabled={planDisabled()}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-1 w-full">
                  <label className="text-label">Yearly Subscription</label>

                  <div className="grid grid-cols-2 gap-3">
                    <Input
                      id="yearly_price"
                      name="yearly_price"
                      placeholder="Amount"
                      bodyBackground="inverted-hover"
                      type="number"
                      value={yearlyUserPlan.unit_amount}
                      onChange={(e) =>
                        setYearlyUserPlan({
                          ...yearlyUserPlan,
                          unit_amount: e.target.value,
                        })
                      }
                      disabled={planDisabled()}
                    />

                    <Select
                      cacheOptions
                      name="yearly_currency"
                      id="yearly_currency"
                      placeholder="Currency"
                      bodyBackground="inverted-hover"
                      value={CURRENCY_OPTIONS.find(
                        (currency) => yearlyUserPlan.currency === currency.value
                      )}
                      onChange={(opt) =>
                        setYearlyUserPlan({
                          ...yearlyUserPlan,
                          currency: opt.value,
                        })
                      }
                      options={CURRENCY_OPTIONS}
                      isDisabled={planDisabled()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-col gap-6 w-full ${
              planDisabled() && "opacity-50"
            }`}
          >
            <div className="flex flex-col gap-2 w-full">
              <h4 className="font-bold">Custom Subscription Message</h4>

              <span className="text-small text-muted">
                Your subscribers will see this when they are prompted to
                subscribe.
              </span>
            </div>

            <Textarea
              id="subscription_message"
              name="subscription_message"
              placeholder="Enter your message"
              bodyBackground="inverted-hover"
              rows={4}
              value={paidUser.custom_subscription_message}
              onChange={(e) =>
                setPaidUser({
                  ...paidUser,
                  custom_subscription_message: e.target.value,
                })
              }
              disabled={planDisabled()}
            />
          </div>

          <div
            className={`flex flex-col gap-6 w-full ${
              planDisabled() && "opacity-50"
            }`}
          >
            <div className="flex flex-col gap-2 w-full">
              <h4 className="font-bold">Subscription Benefits</h4>

              <span className="text-small text-muted">
                Let your subscribers know what they will get by signing up to
                your PencilBooth.
              </span>
            </div>

            <div className="flex flex-col gap-1 w-full">
              <label className="text-label">Free Subscription Benefits</label>

              <div className="flex flex-col w-full">
                {freeUserPlan.benefits?.map((benefit, index) => {
                  return (
                    <Input
                      key={index}
                      id={`free_benefit_${index + 1}`}
                      name={`free_benefit_${index + 1}`}
                      placeholder="Enter a benefit"
                      bodyBackground="inverted-hover"
                      value={benefit}
                      onChange={(e) =>
                        onValueChange(
                          e.target.value,
                          index,
                          freeUserPlan,
                          setFreeUserPlan
                        )
                      }
                      className={`focus-within:!shadow-none ${
                        index != 0 && "!border-t-0"
                      }`}
                      disabled={planDisabled()}
                    />
                  );
                })}
              </div>
            </div>

            <div className="flex flex-col gap-1 w-full">
              <label className="text-label">
                Monthly Subscription Benefits
              </label>

              <div className="flex flex-col w-full">
                {monthlyUserPlan.benefits?.map((benefit, index) => {
                  return (
                    <Input
                      key={index}
                      id={`monthly_benefit_${index + 1}`}
                      name={`monthly_benefit_${index + 1}`}
                      placeholder="Enter a benefit"
                      bodyBackground="inverted-hover"
                      value={benefit}
                      onChange={(e) =>
                        onValueChange(
                          e.target.value,
                          index,
                          monthlyUserPlan,
                          setMonthlyUserPlan
                        )
                      }
                      className={`focus-within:!shadow-none ${
                        index != 0 && "!border-t-0"
                      }`}
                      disabled={planDisabled()}
                    />
                  );
                })}
              </div>
            </div>

            <div className="flex flex-col gap-1 w-full">
              <label className="text-label">Yearly Subscription Benefits</label>

              <div className="flex flex-col w-full">
                {yearlyUserPlan.benefits?.map((benefit, index) => {
                  return (
                    <Input
                      key={index}
                      id={`yearly_benefit_${index + 1}`}
                      name={`yearly_benefit_${index}`}
                      placeholder="Enter a benefit"
                      bodyBackground="inverted-hover"
                      value={benefit}
                      onChange={(e) =>
                        onValueChange(
                          e.target.value,
                          index,
                          yearlyUserPlan,
                          setYearlyUserPlan
                        )
                      }
                      className={`focus-within:!shadow-none ${
                        index != 0 && "!border-t-0"
                      }`}
                      disabled={planDisabled()}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end">
            <Button
              type="submit"
              label="Save changes"
              loading={btnLoader}
              disabled={planDisabled()}
              onClick={() => createStripePriceAPI()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PiadSubscription;
