import React from "react";
import classnames from "classnames";

const MenuItem = ({
  label,
  icon,
  style = "primary",
  selected = false,
  disabled = false,
  helpText = "",
  ...otherProps
}) => {
  const STYLES = {
    primary: "text-inverted bg-primary hover:bg-primary-hover",
    inverted: "text-primary bg-inverted hover:bg-inverted-hover",
    disabled: "text-disabled-background bg-primary bg-inverted",
  };

  return (
    <div
      className={classnames(
        "flex items-center gap-2 py-1 rounded-sm cursor-pointer transition-all duration-300",
        disabled ? STYLES.disabled : STYLES[style],
        {
          "!text-inverted bg-secondary hover:bg-secondary": selected,
          "px-2": label,
          "px-1": !label,
        }
      )}
      {...otherProps}
    >
      {icon && (
        <span className="flex items-center justify-center h-6 w-6 shrink-0">
          {icon}
        </span>
      )}

      {!!label && (
        <div className="flex flex-col">
          <span className="line-clamp-1">{label}</span>
          {!!helpText && <span className="text-label">{helpText}</span>}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
