import React from "react";
import classnames from "classnames";

const Tag = ({
  className = "",
  label = "Tag",
  selected,
  onClick = () => null,
  style = "primary",
  ...otherProps
}) => {
  const STYLES = {
    primary: "border-primary-border",
    transparent: "border-transparent",
  };

  return (
    <span
      className={classnames([
        "px-2 py-1.5 rounded-sm text-small md:text-body cursor-pointer transition-all duration-300 border border-solid bg-inverted hover:shadow hover:bg-disabled hover:border-disabled",
        STYLES[style],
        {
          "bg-primary text-inverted border-primary hover:bg-primary": selected,
        },
        className,
      ])}
      onClick={onClick} // TODO: Need to fix this
      {...otherProps}
    >
      {label}
    </span>
  );
};

export default Tag;
