import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "@/hooks/useAuth";
import { DISCOVERIES_ROUTE, ROOT_ROUTE, JOIN_ROUTE } from "@/lib/routes";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

const HomeLayout = () => {
  const location = useLocation();
  const { user, setShowBeacon } = useAuth();

  useEffect(() => {
    if ([ROOT_ROUTE, JOIN_ROUTE].includes(location.pathname)) {
      setShowBeacon(false);
      window.Beacon && window.Beacon("destroy");
    } else {
      setShowBeacon(true);
    }

    return () => {
      setShowBeacon(true);
    };
  }, [location.pathname]);

  if (user) {
    return <Navigate to={DISCOVERIES_ROUTE} replace={true} />;
  }

  return (
    <>
      <Navbar />
      <Outlet />
      {location.pathname === ROOT_ROUTE && <Footer />}
    </>
  );
};

export default HomeLayout;
