import axios from "axios";

const takeUrl = () => {
  return "/api/v1/super_admin/takes";
};

export const getReviewTakes = (pageIndex) => {
  return axios.get(`${takeUrl()}?page=${pageIndex}`);
};

export const updateTake = (takeId, payload) => {
  return axios.put(`${takeUrl()}/${takeId}`, payload);
};

export const getNonFeaturedTakes = (pageIndex) => {
  return axios.get(`${takeUrl()}/non_featured?page=${pageIndex}`);
};
