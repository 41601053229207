import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import pluralize from "pluralize";

import { getBilling } from "@/apis/settings/billings";
import { showToastrError } from "@/lib/commons";
import Loader from "@/components/Loader";
import Pagination from "@/components/Pagination";
import BlankState from "@/components/BlankState";
import { PBIcons } from "@/components/PBIcons";
import {
  STANDARD_DATE_FORMAT,
  DEFAULT_PAGE_SIZE,
  FREE_EMAIL_COUNT,
} from "@/lib/constants";

const BillingPage = () => {
  const [pageLoader, setPageLoader] = useState(true);
  const [billingUser, setBillingUser] = useState();
  const [invoicePageIndex, setInvoicePageIndex] = useState(1);
  const [logPageIndex, setLogPageIndex] = useState(1);
  const [activeTab, setActiveTab] = useState("purchase_history");

  useEffect(() => {
    getBillingAPI();
  }, [invoicePageIndex, logPageIndex]);

  const getBillingAPI = async () => {
    try {
      const { data } = await getBilling(invoicePageIndex, logPageIndex);
      setBillingUser(data.billing_user);
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-settings-page-wrapper bg-inverted-hover">
      <div className="pb-settings-page-container flex flex-col items-center w-full p-6 md:p-10">
        {billingUser.vip ? (
          <div className="flex flex-col gap-12 w-full max-w-3xl">
            <div className="text-title bg-primary text-inverted px-3 py-2 rounded-sm">
              <p className="m-0">
                You must be someone quite special! You have full and unlimited
                access to PencilBooth for literally zero dollars. Be free and
                email away to your heart's content without restriction!
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-12 w-full max-w-3xl">
            <div className="text-title bg-primary text-inverted px-3 py-2 rounded-sm">
              <p className="m-0">
                You have used {FREE_EMAIL_COUNT - billingUser.free_email_count}{" "}
                of your {FREE_EMAIL_COUNT} free monthly{" "}
                {pluralize("email", FREE_EMAIL_COUNT)}. This will reset on{" "}
                {dayjs(billingUser.reset_date).format(STANDARD_DATE_FORMAT)}.
                You currently have {billingUser.paid_email_count} paid email
                credits in your account.
              </p>
            </div>

            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-row gap-3">
                <span
                  className={`${
                    activeTab == "purchase_history"
                      ? "text-primary"
                      : "text-disabled-background"
                  } cursor-pointer`}
                  onClick={() => setActiveTab("purchase_history")}
                >
                  Purchase History
                </span>

                <span
                  className={`${
                    activeTab == "email_logs"
                      ? "text-primary"
                      : "text-disabled-background"
                  } cursor-pointer`}
                  onClick={() => setActiveTab("email_logs")}
                >
                  Usage Logs
                </span>
              </div>

              <div
                className={`${
                  activeTab == "purchase_history" ? "flex" : "hidden"
                } flex-col gap-4 w-full`}
              >
                {billingUser.stripe_invoices.length > 0 ? (
                  <>
                    <div className="overflow-x-auto">
                      <table className="pb-data-table">
                        <thead>
                          <th className="w-[50%] min-w-[150px]">Number</th>
                          <th className="w-[15%] min-w-[150px]">Date</th>
                          <th className="w-[15%] min-w-[140px]">Amount</th>
                          <th className="w-[5%] min-w-[40px]"></th>
                        </thead>

                        <tbody>
                          {billingUser.stripe_invoices.map((inv) => {
                            return (
                              <tr>
                                <td>
                                  <Link to={inv.stripe_url} target="_blank">
                                    {inv.number}
                                  </Link>
                                </td>
                                <td>
                                  {dayjs(inv.date).format(STANDARD_DATE_FORMAT)}
                                </td>
                                <td>{`$ ${
                                  inv.amount
                                } ${inv.currency.toUpperCase()}`}</td>
                                <td className="!text-right">
                                  <Link
                                    to={inv.stripe_url}
                                    target="_blank"
                                    className="w-fit"
                                  >
                                    <PBIcons icon="ArrowRight" />
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <Pagination
                      count={billingUser.invoice_total_records}
                      pageSize={DEFAULT_PAGE_SIZE}
                      pageNo={invoicePageIndex}
                      navigate={setInvoicePageIndex}
                    />
                  </>
                ) : (
                  <BlankState>
                    <div className="flex flex-col items-center gap-4 w-full">
                      <p className="text-title text-center">
                        There is no history to show yet.
                      </p>
                    </div>
                  </BlankState>
                )}
              </div>

              <div
                className={`${
                  activeTab == "email_logs" ? "flex" : "hidden"
                } flex-col gap-4 w-full`}
              >
                {billingUser.email_usage_logs.length > 0 ? (
                  <>
                    <div className="overflow-x-auto">
                      <table className="pb-data-table">
                        <thead>
                          <th className="w-[45%] min-w-[250px]">Take</th>
                          <th className="w-[20%] min-w-[100px]">Email Count</th>
                          <th className="w-[20%] min-w-[150px]">Email Type</th>
                          <th className="w-[25%] min-w-[50px]">Date</th>
                        </thead>

                        <tbody>
                          {billingUser.email_usage_logs.map((log) => {
                            return (
                              <tr>
                                <td>
                                  {log.take_id ? (
                                    <Link
                                      to={`/takes/${log.take_id}`}
                                      target="_blank"
                                    >
                                      {log.subject}
                                    </Link>
                                  ) : (
                                    "Emails Added"
                                  )}
                                </td>
                                <td>{`${
                                  log.transaction_type === "credit" ? "+" : "-"
                                }${log.email_count}`}</td>
                                <td className="capitalize">{log.email_type}</td>
                                <td>
                                  {dayjs(log.created_at).format(
                                    STANDARD_DATE_FORMAT
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <Pagination
                      count={billingUser.log_total_records}
                      pageSize={DEFAULT_PAGE_SIZE}
                      pageNo={logPageIndex}
                      navigate={setLogPageIndex}
                    />
                  </>
                ) : (
                  <BlankState>
                    <div className="flex flex-col items-center gap-4 w-full">
                      <p className="text-title text-center">
                        There is no log to show yet.
                      </p>
                    </div>
                  </BlankState>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingPage;
