import React from "react";

import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";

const LinkModal = ({
  isOpen,
  url,
  setUrl,
  closeModal,
  onSaveLink,
  linkSaveLoader,
}) => {
  return (
    <Modal
      size="medium"
      open={isOpen}
      onClose={closeModal}
      title="Edit Link"
      className="!z-[10000]"
    >
      <div className="flex flex-col w-full gap-10">
        <form
          id="editor-link-form"
          className="flex flex-col w-full gap-6"
          onSubmit={(e) => {
            e.preventDefault();
            onSaveLink();
          }}
        >
          <Input
            id="url"
            autoFocus
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            bodyBackground="primary"
            style="primary"
            placeholder="Enter a link"
          />
        </form>

        <div className="flex gap-4">
          <Button
            form="editor-link-form"
            type="submit"
            label="Save"
            style="inverted"
            loading={linkSaveLoader}
            bodyBackground="primary"
          />

          <Button
            type="button"
            style="transparent"
            label="Cancel"
            onClick={closeModal}
            bodyBackground="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default LinkModal;
