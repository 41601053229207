import React from "react";
import classnames from "classnames";

import Tag from "@/components/Tag";
import Loader from "@/components/Loader";

const Tags = ({
  tagIds,
  loading,
  tagsCount,
  setTagPageIndex,
  tagList,
  handleTagClick,
}) => {
  return (
    <div className="flex flex-wrap gap-2.5 w-full">
      {tagList.map((tag) => {
        return (
          <Tag
            key={tag.id}
            label={tag.name}
            selected={tagIds.includes(tag.id)}
            onClick={() => handleTagClick(tag)}
          />
        );
      })}

      {tagsCount > tagList.length &&
        (loading ? (
          <Loader />
        ) : (
          <Tag
            label="Load more..."
            style="transparent"
            onClick={() => setTagPageIndex((data) => data + 1)}
          />
        ))}
    </div>
  );
};

export default Tags;
