import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import AnimateHeight from "react-animate-height";
import { useForm } from "react-hook-form";

import { TAKE_EDITOR_EXTENSION_LIST } from "@/lib/constants";
import { updateAttachment } from "@/apis/take_attachments";
import { showToastrError } from "@/lib/commons";
import TextEditor from "@/components/TextEditor";

import GalleryDropdown from "./GalleryDropdown";
import { GALLERY_MAGE_INITIAL_VALUE } from "./constants";

const GridCard = ({ image, setValue, formState, gallery, getTakeAPI }) => {
  const imageRef = useRef();
  const contentRef = useRef();
  const [clicked, setClicked] = useState(false);
  const [editGalleryOpen, setEditGalleryOpen] = useState(false);

  const {
    handleSubmit,
    setValue: setImageValue,
    reset,
    watch,
  } = useForm({
    defaultValues: GALLERY_MAGE_INITIAL_VALUE,
    shouldValidate: true,
    shouldDirty: true,
    mode: "onblur",
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        imageRef.current &&
        !imageRef.current.contains(event.target) &&
        contentRef.current &&
        !contentRef.current.contains(event.target)
      ) {
        setClicked(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [imageRef, contentRef]);

  useEffect(() => {
    reset(image);
  }, [image]);

  const hasContent = () => {
    return image?.caption?.length > 0 || image?.title?.length > 0 || clicked;
  };

  const updateAttachmentAPI = async () => {
    try {
      const { data } = await updateAttachment(formState.id, image.id, {
        attachment: {
          caption: watch().caption,
          title: watch().title,
          paid_content: watch().paid_content,
        },
      });

      reset(data.attachment);

      const updatedAttachments = formState.attachments_attributes.map((att) => {
        if (att.id === image.id) {
          return { ...data.attachment };
        } else {
          return { ...att };
        }
      });

      setValue("attachments_attributes", updatedAttachments);
      setEditGalleryOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const onCaptionChange = (value) => {
    setImageValue("caption", value);
  };

  const onTitleChange = (value) => {
    setImageValue("title", value);
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col items-center w-full px-6 md:px-10">
        <div
          className={classnames(
            "relative transition-all duration-300 cursor-pointer max-w-[var(--take-width)] rounded",
            {
              "shadow-secondary-ring-inverted-bg": clicked,
            }
          )}
          onClick={() => setClicked(true)}
          ref={imageRef}
        >
          <div className="grid grid-cols-3 gap-4">
            {gallery.map((attachment, index) => {
              return (
                <div className="aspect-square" key={index}>
                  <img
                    src={attachment.attachment_url}
                    className="h-full w-full object-cover rounded"
                  />
                </div>
              );
            })}
          </div>

          <div
            className={`absolute left-3 bottom-3 rounded-sm bg-inverted w-fit px-2 py-1 text-small shadow transition-all duration-200 ${
              hasContent()
                ? "opacity-0 invisible translate-y-3"
                : "opacity-100 visible translate-y-0"
            }`}
          >
            Click gallery to add title and caption
          </div>

          <div className="absolute right-2 top-2">
            <GalleryDropdown
              takeId={formState.id}
              formState={watch()}
              setTakeValue={setValue}
              setImageValue={setImageValue}
              handleSave={handleSubmit(updateAttachmentAPI)}
              editGalleryOpen={editGalleryOpen}
              setEditGalleryOpen={setEditGalleryOpen}
              attachments={formState.attachments_attributes}
              getTakeAPI={getTakeAPI}
            />
          </div>
        </div>

        <div
          className="flex flex-col w-full max-w-[var(--take-width)]"
          ref={contentRef}
        >
          <AnimateHeight duration={300} height={hasContent() ? "auto" : 0}>
            <div className="flex flex-col gap-4 w-full mt-8">
              <TextEditor
                className="text-heading font-bold"
                placeholder="Give your gallery a title"
                content={image.title}
                setEditorContent={onTitleChange}
                onBlur={() => handleSubmit(updateAttachmentAPI())}
                extensionList={["TextAlign"]}
                onFocus={() => setClicked(true)}
              />

              <TextEditor
                placeholder="Write something about your gallery"
                content={image.caption}
                setEditorContent={onCaptionChange}
                onBlur={() => handleSubmit(updateAttachmentAPI())}
                extensionList={TAKE_EDITOR_EXTENSION_LIST}
                onFocus={() => setClicked(true)}
              />
            </div>
          </AnimateHeight>
        </div>
      </div>
    </div>
  );
};

export default GridCard;
