import React, { useState, useEffect } from "react";

import Button from "@/components/Button";
import AsyncPaginateSelect from "@/components/AsyncPaginateSelect";
import { getTags, createTag } from "@/apis/tags";
import { showToastrError } from "@/lib/commons";
import { dropDownListGenerator, getUnique } from "@/lib/utils";

const EditTagMenu = ({
  setOpen,
  formState,
  setImageValue,
  handleSave,
  btnLoader,
}) => {
  const [tagList, setTagList] = useState([]);
  const [tagListLoad, setTagListLoad] = useState(false);

  useEffect(() => {
    getTagsAPI();
  }, []);

  const getTagsAPI = async (search, _loadedOptions, additional) => {
    try {
      setTagListLoad(true);
      const pageNumber = additional?.page || 1;
      const { data } = await getTags(search, pageNumber);
      const responseData = dropDownListGenerator(data.tags, "name");
      const dataList = getUnique([...tagList, ...responseData], "value");

      setTagList(dataList);

      return {
        options: responseData,
        hasMore: data.total_records > dataList.length,
        additional: {
          page: pageNumber + 1,
        },
      };
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setTagListLoad(false);
    }
  };

  const createTagAPI = async (tagName) => {
    try {
      const { data } = await createTag({ tag: { name: tagName } });
      setTagList([
        {
          label: data.tag.name,
          value: data.tag.id,
        },
        ...tagList,
      ]);
      setImageValue("tag_ids", [...formState.tag_ids, data.tag.id]);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const tagListWithTags = [...tagList, ...formState.tags].reduce(
    (unique, o) => {
      if (
        !unique.some((obj) => obj.label === o.label && obj.value === o.value)
      ) {
        unique.push(o);
      }
      return unique;
    },
    []
  );

  return (
    <div className="flex flex-col gap-4 w-full px-2">
      <form
        id="tag-form"
        className="flex flex-col w-full gap-y-3"
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <AsyncPaginateSelect
          isMulti
          cacheOptions
          isSearchable
          isClearable
          isCreatable
          debounceTimeout={300}
          isLoading={tagListLoad}
          id="tags"
          name="tags"
          label="Tags"
          placeholder="Select a tag"
          strategy="fixed"
          bodyBackground="primary"
          style="primary"
          value={tagListWithTags?.filter((tag) =>
            formState.tag_ids?.includes(tag.value)
          )}
          onChange={(opt) => {
            if (opt?.length > 0) {
              setImageValue(
                "tag_ids",
                opt.map((tag) => tag.value)
              );
            } else {
              setImageValue("tag_ids", []);
            }
          }}
          loadOptions={getTagsAPI}
          defaultOptions={tagList}
          additional={{ page: 1 }}
          onCreateOption={(option) => createTagAPI(option)}
        />
      </form>

      <div className="flex gap-4">
        <Button
          form="tag-form"
          type="submit"
          label="Update"
          loading={btnLoader}
          style="inverted"
          bodyBackground="primary"
        />

        <Button
          type="button"
          style="transparent"
          label="Cancel"
          onClick={() => setOpen(false)}
          bodyBackground="primary"
        />
      </div>
    </div>
  );
};

export default EditTagMenu;
