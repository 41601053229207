import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Button from "@/components/Button";
import Input from "@/components/Input";
import Loader from "@/components/Loader";
import BlankPlaceholder from "@/components/BlankPlaceholder";
import { getSubscribeUrl, getProfileUrl } from "@/lib/utils";
import { getUser, updateUser } from "@/apis/users";
import { destroyAttachment } from "@/apis/settings/attachments";
import { useAuth } from "@/hooks/useAuth";
import Modal from "@/components/Modal";
import { PBIcons } from "@/components/PBIcons";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import SocialLinks from "@/components/SocialLinks";

import SocialLinkModal from "./SocialLinkModal";
import BioLinkModal from "./BioLinkModal";
import ImageUploader from "./ImageUploader";
import SubscriberWelcomeModal from "./SubscriberWelcomeModal";

const BrandingPage = () => {
  const { user, setUser } = useAuth();
  const coverImageRef = useRef();
  const logoImageRef = useRef();
  const [logoImage, setLogoImage] = useState();
  const [coverImage, setCoverImage] = useState();
  const [logoImageLoader, setLogoImageLoader] = useState();
  const [coverImageLoader, setCoverImageLoader] = useState();
  const [pageLoader, setPageLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [subscriberModalOpen, setSubscriberModalOpen] = useState(false);
  const [socialModalOpen, setSocialModalOpen] = useState(false);
  const [linkTreeModalOpen, setLinkTreeModalOpen] = useState(false);
  const [deletingImageId, setDeletingImageId] = useState();
  const [mobileScreen, setMobileScreen] = useState(false);
  const [hideControls, setHideControls] = useState(false);

  useEffect(() => {
    getUserAPI();
    updateUserAPI();
  }, []);

  const getUserAPI = async () => {
    try {
      const { data } = await getUser(user.id);
      setUser(data.user);
      setLogoImage({ id: data.user.logo_image_id, src: data.user.logo_url });
      setCoverImage({
        id: data.user.cover_image_id,
        src: data.user.cover_image_url,
      });
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateUserAPI = async () => {
    try {
      const { data } = await updateUser(user.id, {
        user: { first_time_enter: false },
      });
      setUser({ ...user, first_time_enter: data.first_time_enter });
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const destroyAttachmentAPI = async () => {
    try {
      setBtnLoader(true);
      await destroyAttachment(deletingImageId);
      showToastrSuccess("Image deleted!");
      setDeleteAlertOpen(false);
      getUserAPI();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-settings-page-wrapper bg-inverted-hover">
      <div className="pb-settings-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col items-center gap-12 w-full">
          <div className="flex flex-col items-center gap-8 w-full max-w-4xl">
            <div className="flex flex-col items-center w-full">
              <p className="text-center">
                Welcome to the Booth designer! Your unique PencilBooth URL is:
              </p>
              <a
                className="underline text-center"
                title="Go to subscription page"
                href={getSubscribeUrl(user.url_slug)}
                target="_blank"
              >
                {getSubscribeUrl(user.url_slug)}
              </a>
            </div>

            <p className="text-center">
              Your booth is where people will subscribe to your newsletter and
              access your visual archive. It's also your Link-in-Bio so you can
              use it as a one-stop-shop across your socials to capture new
              subscribers as well as share any links and additional social media
              accounts that you like.
            </p>
          </div>

          <div
            className={`w-full transition-all duration-300 ${
              mobileScreen ? "max-w-sm" : "max-w-7xl"
            }`}
          >
            <div className="flex items-center justify-between px-4 h-10 bg-disabled-background rounded-t-sm">
              <div className="flex items-center gap-1.5">
                <div className="w-3 h-3 rounded-full bg-[#f3695a]"></div>
                <div className="w-3 h-3 rounded-full bg-[#f3be4e]"></div>
                <div className="w-3 h-3 rounded-full bg-[#61c254]"></div>
              </div>

              <div className="flex items-center gap-4">
                {!hideControls && (
                  <span className="text-label underline line-clamp-1 text-inverted cursor-pointer">
                    {coverImage?.id ? (
                      <span
                        onClick={() => {
                          setDeletingImageId(coverImage.id);
                          setDeleteAlertOpen(true);
                        }}
                      >
                        Remove Background Image
                      </span>
                    ) : (
                      <div
                        className="relative"
                        onClick={() => coverImageRef.current.click()}
                      >
                        <span>Add Background Image</span>
                        <ImageUploader
                          inputRef={coverImageRef}
                          type="cover"
                          position={1}
                          getUserAPI={getUserAPI}
                          setLoader={setCoverImageLoader}
                        />
                      </div>
                    )}
                  </span>
                )}

                <div className="flex items-center gap-2">
                  <PBIcons
                    icon="Eye"
                    size="20"
                    onClick={() => setHideControls(!hideControls)}
                    className={`cursor-pointer transition-all duration-300 ${
                      hideControls ? "text-inverted" : "text-primary-hover"
                    }`}
                  />

                  <PBIcons
                    icon="Mobile"
                    size="20"
                    onClick={() => setMobileScreen(!mobileScreen)}
                    className={`cursor-pointer transition-all duration-300 hidden md:block ${
                      mobileScreen ? "text-inverted" : "text-primary-hover"
                    }`}
                  />
                </div>
              </div>
            </div>

            <div
              className="flex flex-col items-center justify-between bg-muted bg-cover bg-center bg-no-repeat rounded-b-sm"
              style={{
                backgroundImage: `url(${coverImage.src})`,
              }}
            >
              <div className="relative overflow-hidden w-full">
                {coverImageLoader && (
                  <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-30 text-inverted transition-all backdrop-blur-sm">
                    <Loader />
                  </div>
                )}

                <div
                  className={`${
                    mobileScreen
                      ? "h-[180px]"
                      : "h-[180px] sm:h-[200px] md:h-[230px] md:hidden"
                  } w-full bg-cover bg-center bg-no-repeat`}
                  style={{
                    backgroundImage: `url(${coverImage.src})`,
                  }}
                ></div>
              </div>

              <div
                className={`flex flex-col items-center w-full bg-primary rounded-b-sm ${
                  mobileScreen ? "m-0" : "md:bg-transparent md:m-12"
                }`}
              >
                <div
                  className={`flex flex-col items-center gap-6 px-10 md:px-16 py-12 w-full max-w-[520px] bg-primary text-inverted ${
                    mobileScreen ? "px-10" : "px-10 md:px-16 md:rounded-sm"
                  }`}
                >
                  <div className="flex flex-col items-center gap-2 w-full">
                    <div className="relative overflow-hidden rounded-full">
                      {logoImageLoader && (
                        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-30 text-inverted transition-all backdrop-blur-sm rounded-full">
                          <Loader />
                        </div>
                      )}

                      <img
                        src={logoImage?.src}
                        alt="Avatar"
                        className="h-12 w-12 object-cover"
                      />
                    </div>

                    {!hideControls && (
                      <span className="text-small underline cursor-pointer">
                        {logoImage?.id ? (
                          <span
                            onClick={() => {
                              setDeletingImageId(logoImage.id);
                              setDeleteAlertOpen(true);
                            }}
                          >
                            Delete
                          </span>
                        ) : (
                          <div
                            className="relative"
                            onClick={() => logoImageRef.current.click()}
                          >
                            <span>Add Image</span>
                            <ImageUploader
                              inputRef={logoImageRef}
                              type="logo"
                              position={1}
                              getUserAPI={getUserAPI}
                              setLoader={setLogoImageLoader}
                            />
                          </div>
                        )}
                      </span>
                    )}
                  </div>

                  <h2 className="text-heading font-bold text-center">
                    {user.display_name}
                  </h2>

                  {user.welcome_message?.length > 0 ? (
                    <p className="text-center">{user.welcome_message}</p>
                  ) : (
                    <div className="flex flex-col gap-y-2 w-full">
                      <div className="h-[16px] bg-[#F5F5F5] rounded"></div>
                      <div className="h-[16px] bg-[#F5F5F5] rounded"></div>
                      <div className="h-[16px] bg-[#F5F5F5] rounded"></div>
                    </div>
                  )}

                  {!hideControls && (
                    <span
                      className="text-small underline cursor-pointer"
                      onClick={() => setSubscriberModalOpen(true)}
                    >
                      {user.welcome_message?.length > 0
                        ? "Edit subscriber welcome"
                        : "Add subscriber welcome"}
                    </span>
                  )}

                  <div className="flex flex-row items-center gap-2 w-full py-4">
                    <Input
                      placeholder="Enter Email Address"
                      bodyBackground="primary"
                      style="primary"
                    />
                    <Button
                      label="Subscribe"
                      className="h-auto !p-3 w-fit"
                      bodyBackground="primary"
                      style="inverted"
                    />
                  </div>

                  <div className="flex flex-col items-center gap-6 w-full">
                    <div className="flex flex-col items-center gap-3 w-full">
                      {user.link_trees_attributes?.length > 0 ? (
                        user.link_trees_attributes.map((linkTree) => {
                          return (
                            <a
                              className="flex justify-center w-full p-3 min-h-12 rounded-sm bg-inverted text-primary text-center"
                              href={linkTree.url}
                            >
                              {linkTree.name}
                            </a>
                          );
                        })
                      ) : (
                        <div className="flex flex-col gap-3 w-full">
                          <BlankPlaceholder />
                          <BlankPlaceholder />
                        </div>
                      )}
                    </div>

                    {!hideControls && (
                      <span
                        className="text-small underline cursor-pointer"
                        onClick={() => setLinkTreeModalOpen(true)}
                      >
                        Add bio links
                      </span>
                    )}
                  </div>

                  <Link to={getProfileUrl(user.url_slug)}>View archives</Link>

                  <div className="flex flex-col items-center gap-6 w-full pt-5">
                    {user.social_links_present ? (
                      <SocialLinks user={user} />
                    ) : (
                      <div className="flex flex-wrap justify-center gap-3 w-full">
                        <BlankPlaceholder className="max-w-12" />
                        <BlankPlaceholder className="max-w-12" />
                      </div>
                    )}

                    {!hideControls && (
                      <span
                        className="text-small underline cursor-pointer"
                        onClick={() => setSocialModalOpen(true)}
                      >
                        Add social links
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BioLinkModal
          bioModalOpen={linkTreeModalOpen}
          setBioModalOpen={setLinkTreeModalOpen}
          getUserAPI={getUserAPI}
        />

        <SocialLinkModal
          socialModalOpen={socialModalOpen}
          setSocialModalOpen={setSocialModalOpen}
          getUserAPI={getUserAPI}
        />

        <SubscriberWelcomeModal
          subscriberModalOpen={subscriberModalOpen}
          setSubscriberModalOpen={setSubscriberModalOpen}
          getUserAPI={getUserAPI}
        />

        <Modal
          open={deleteAlertOpen}
          onClose={() => setDeleteAlertOpen(false)}
          title="Delete Brand / Avatars"
        >
          <div className="flex flex-col w-full gap-10">
            <div className="flex flex-col w-full gap-6">
              <p>Are you sure you want to delete the image?</p>
            </div>

            <div className="flex gap-4">
              <Button
                type="submit"
                style="danger"
                label="Yes, Delete"
                loading={btnLoader}
                onClick={() => destroyAttachmentAPI()}
                bodyBackground="primary"
              />

              <Button
                label="Cancel"
                style="inverted"
                onClick={() => setDeleteAlertOpen(false)}
                bodyBackground="primary"
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default BrandingPage;
