import React from "react";

import { useAuth } from "@/hooks/useAuth";
import MenuItem from "@/components/MenuItem";
import Divider from "@/components/Divider";
import Switch from "@/components/Switch";
import Tooltip from "@/components/Tooltip";
import { PBIcons } from "@/components/PBIcons";

const DefaultMenu = ({
  index,
  formState,
  swapElement,
  handleEditClick,
  handleDeleteClick,
  handlePaidClick,
}) => {
  const { user } = useAuth();

  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-1">
        <Tooltip message="Move up">
          <MenuItem
            icon={
              <PBIcons
                icon="ArrowUp"
                className={
                  formState.in_top_position
                    ? "text-muted cursor-not-allowed"
                    : ""
                }
              />
            }
            onClick={() =>
              formState.in_top_position ? null : swapElement(index - 1)
            }
          />
        </Tooltip>

        <Tooltip message="Move down">
          <MenuItem
            icon={
              <PBIcons
                icon="ArrowDown"
                className={
                  formState.in_bottom_position
                    ? "text-muted cursor-not-allowed"
                    : ""
                }
              />
            }
            onClick={() =>
              formState.in_bottom_position ? null : swapElement(index + 1)
            }
          />
        </Tooltip>
      </div>

      <Divider />

      <MenuItem
        label="Edit Gallery"
        icon={<PBIcons icon="Tag" />}
        onClick={() => handleEditClick()}
      />

      <MenuItem
        label="Delete"
        icon={<PBIcons icon="Delete" />}
        onClick={() => handleDeleteClick()}
      />

      {user.paid_subscription_enabled && (
        <>
          <Divider />
          <MenuItem
            label={
              <div className="flex items-center justify-between gap-12">
                <span className="shrink-0">Set as paid content</span>

                <Switch
                  checked={formState.paid_content}
                  onChange={() => handlePaidClick()}
                  style="inverted"
                  bodyBackground="primary"
                  noBorder={true}
                />
              </div>
            }
            icon={<PBIcons icon="Paid" />}
          />
        </>
      )}
    </div>
  );
};

export default DefaultMenu;
