import React from "react";

import { useAuth } from "@/hooks/useAuth";
import MenuItem from "@/components/MenuItem";
import Divider from "@/components/Divider";
import Switch from "@/components/Switch";
import Tooltip from "@/components/Tooltip";
import { PBIcons } from "@/components/PBIcons";

const DefaultMenu = ({
  index,
  formState,
  swapElement,
  setLinkOpen,
  setAltOpen,
  setTagOpen,
  setDeleteOpen,
  handleWidthClick,
  handleHeroImageClick,
  handlePaidClick,
}) => {
  const { user } = useAuth();

  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-1">
        <Tooltip message="Move up">
          <MenuItem
            icon={
              <PBIcons
                icon="ArrowUp"
                className={
                  formState.in_top_position
                    ? "text-muted cursor-not-allowed"
                    : ""
                }
              />
            }
            onClick={() =>
              formState.in_top_position ? null : swapElement(index - 1)
            }
          />
        </Tooltip>

        <Tooltip message="Move down">
          <MenuItem
            icon={
              <PBIcons
                icon="ArrowDown"
                className={
                  formState.in_bottom_position
                    ? "text-muted cursor-not-allowed"
                    : ""
                }
              />
            }
            onClick={() =>
              formState.in_bottom_position ? null : swapElement(index + 1)
            }
          />
        </Tooltip>

        <Tooltip message="Inline image">
          <MenuItem
            icon={<PBIcons icon="WidthNormal" />}
            selected={formState.style === "normal"}
            onClick={() => {
              if (formState.style !== "normal") {
                handleWidthClick("normal");
              }
            }}
          />
        </Tooltip>

        <Tooltip message="Wide image">
          <MenuItem
            icon={<PBIcons icon="WidthStreched" />}
            selected={formState.style === "wide"}
            onClick={() => {
              if (formState.style !== "wide") {
                handleWidthClick("wide");
              }
            }}
          />
        </Tooltip>

        <Tooltip message="Full width image">
          <MenuItem
            icon={<PBIcons icon="WidthFull" />}
            selected={formState.style === "full"}
            onClick={() => {
              if (formState.style !== "full") {
                handleWidthClick("full");
              }
            }}
          />
        </Tooltip>
      </div>

      <Divider />

      <MenuItem
        label="Link"
        icon={<PBIcons icon="Link" />}
        onClick={() => setLinkOpen(true)}
      />

      <MenuItem
        label="Alt Text"
        icon={<PBIcons icon="Alt" />}
        onClick={() => setAltOpen(true)}
      />

      <MenuItem
        label="Tags"
        icon={<PBIcons icon="Tag" />}
        onClick={() => setTagOpen(true)}
      />

      <MenuItem
        label={
          formState.hero_image ? "Remove from Hero Image" : "Set as hero image"
        }
        icon={<PBIcons icon="Attachment" />}
        onClick={() => handleHeroImageClick()}
      />

      <Divider />

      <MenuItem
        label="Delete"
        icon={<PBIcons icon="Delete" />}
        onClick={() => setDeleteOpen(true)}
      />

      {user.paid_subscription_enabled && (
        <>
          <Divider />
          <MenuItem
            label={
              <div className="flex items-center justify-between gap-12">
                <span className="shrink-0">Set as paid content</span>

                <Switch
                  checked={formState.paid_content}
                  onChange={() => handlePaidClick()}
                  style="inverted"
                  bodyBackground="primary"
                  noBorder={true}
                />
              </div>
            }
            icon={<PBIcons icon="Paid" />}
          />
        </>
      )}
    </div>
  );
};

export default DefaultMenu;
