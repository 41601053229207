import React from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import pluralize from "pluralize";

import { FREE_EMAIL_COUNT } from "@/lib/constants";
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import OptionCard from "@/components/OptionCard";
import EmailOnlyImage from "@/assets/images/email-only.png";
import WebOnlyImage from "@/assets/images/web-only.png";
import WebAndEmailImage from "@/assets/images/web-and-email.png";
import { PBIcons } from "@/components/PBIcons";

const ShareModal = ({
  formState,
  open,
  setOpen,
  updateTakeAPI,
  setValue,
  takeDetail,
  user,
  updateBtnLoader,
}) => {
  const navigate = useNavigate();

  const handleContinue = async () => {
    await updateTakeAPI();
    navigate(`/takes/${takeDetail.id}/send`);
  };

  const handleCancel = () => {
    setOpen(false);
    setValue("publish_type", "web_and_email");
  };

  const totalEmailCount = FREE_EMAIL_COUNT;

  const usedEmailCount = totalEmailCount - user.free_email_count;

  const remainingEmailCount =
    FREE_EMAIL_COUNT + user.paid_email_count - usedEmailCount;

  const completedPercentage = () => {
    return (usedEmailCount / totalEmailCount) * 100;
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleCancel()}
        size="large"
        title="How would you like to share your take?"
      >
        <div className="flex flex-col w-full gap-10">
          <div className="flex flex-col w-full gap-6">
            <p>
              Choose to publish to your visual archive only, send by email or
              both.
            </p>

            <div className="flex flex-col md:flex-row items-center md:items-start justify-center w-full gap-10">
              <OptionCard
                title="Web and Email"
                subTitle="$0.003c per extra email"
                description="Publish to your visual archive and send to your subscribers."
                image={WebAndEmailImage}
                selected={formState.publish_type === "web_and_email"}
                handleOnClick={() => setValue("publish_type", "web_and_email")}
              />
              <OptionCard
                title="Email Only"
                subTitle="$0.003c per extra email"
                description="Send your take as an email only. It won't be shown in your visual archive."
                image={EmailOnlyImage}
                selected={formState.publish_type === "email_only"}
                handleOnClick={() => setValue("publish_type", "email_only")}
              />
              <OptionCard
                title="Web Only"
                subTitle="Included"
                description="Publish to your visual archive and don't send to your subscribers."
                image={WebOnlyImage}
                selected={formState.publish_type === "web_only"}
                handleOnClick={() => setValue("publish_type", "web_only")}
              />
            </div>

            {formState.publish_type !== "web_only" && !user.vip && (
              <div className="flex flex-col w-full gap-8">
                <div className="flex flex-col gap-2 w-full">
                  {user.free_email_count != 0 && (
                    <p className="w-full text-end">{totalEmailCount}</p>
                  )}

                  <ProgressBar
                    completed={completedPercentage()}
                    bgColor="var(--color-secondary)"
                    baseBgColor="var(--color-muted)"
                    height="8px"
                    isLabelVisible={false}
                  />

                  <p className="w-full">
                    You have {remainingEmailCount} email{" "}
                    {pluralize("credit", remainingEmailCount)} left
                  </p>
                </div>

                <div className="flex items-start gap-2 w-full p-4 rounded bg-primary-hover">
                  <PBIcons icon="Notice" className="shrink-0" />
                  <p>Total price will be calculated in the next step.</p>
                </div>
              </div>
            )}
          </div>

          <div className="flex gap-4 justify-end">
            <Button
              label="Cancel"
              style="transparent"
              onClick={() => handleCancel()}
              bodyBackground="primary"
            />
            <Button
              label="Next"
              suffixIcon={<PBIcons icon="ArrowRight" />}
              style="inverted"
              bodyBackground="primary"
              onClick={() => handleContinue()}
              loading={updateBtnLoader}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ShareModal;
