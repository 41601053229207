import * as Yup from "yup";

import {
  MAX_STRING_LENGTH,
  MAX_SLUG_LENGTH,
  OTP_LENGTH,
} from "@/lib/constants";

export const INITIAL_VALUE = {
  name: "",
  display_name: "",
  url_slug: "",
  email: "",
};

export const VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be blank.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  display_name: Yup.string()
    .required("Display name can't be blank.")
    .max(
      MAX_STRING_LENGTH,
      `Display name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  url_slug: Yup.string()
    .required("URL slug name can't be blank.")
    .max(
      MAX_SLUG_LENGTH,
      `URL slug must be at most ${MAX_SLUG_LENGTH} characters.`
    ),
  email: Yup.string()
    .required("Email can't be blank.")
    .email("Email must be valid.")
    .max(
      MAX_STRING_LENGTH,
      `Email must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});
