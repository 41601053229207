import React, { useState } from "react";

import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";

const ItemMenu = ({ take, setTakeId, setFeatureAlert }) => {
  const [open, setOpen] = useState(false);

  return (
    <Menu open={open} setOpen={setOpen} style="transparent">
      <MenuItem
        label="Feature"
        onClick={() => {
          setTakeId(take.id);
          setFeatureAlert(true);
        }}
      />
    </Menu>
  );
};

export default ItemMenu;
