import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  updateDefaultSetting,
  getDefaultSetting,
} from "@/apis/super_admin/default_settings";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import Loader from "@/components/Loader";
import Button from "@/components/Button";
import Textarea from "@/components/Textarea";

const DefaultSettings = () => {
  const [pageLoader, setPageLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);

  const { handleSubmit, formState, setValue, reset, watch } = useForm({
    defaultValues: { footer_text: "" },
    shouldValidate: true,
    shouldDirty: true,
    mode: "onChange",
  });

  useEffect(() => {
    getDefaultSettingAPI();
  }, []);

  const getDefaultSettingAPI = async () => {
    try {
      const { data } = await getDefaultSetting();
      reset(data.default_setting);
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateDefaultSettingAPI = async () => {
    try {
      setBtnLoader(true);

      const { data } = await updateDefaultSetting({
        default_setting: { footer_text: watch().footer_text },
      });

      showToastrSuccess(data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-settings-page-wrapper bg-inverted-hover">
      <div className="pb-settings-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-6 md:gap-10 w-full max-w-[var(--layout-max-width)]">
          <form
            className="flex flex-col items-end gap-6 w-full"
            onSubmit={handleSubmit(updateDefaultSettingAPI)}
          >
            <Textarea
              id="footer-text"
              label="Footer"
              name="footer-text"
              placeholder="Write your footer text here..."
              helpText="This will be used as the footer description."
              value={watch().footer_text}
              onChange={(e) => setValue("footer_text", e.target.value)}
              error={formState.errors.footer_text?.message}
              bodyBackground="inverted-hover"
              className="field-sizing-content"
            />

            <Button
              type="submit"
              label="Save changes"
              loading={btnLoader}
              bodyBackground="inverted-hover"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default DefaultSettings;
