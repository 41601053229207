import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "@/hooks/useAuth";
import { LOGIN_ROUTE } from "@/lib/routes";
import { showToastrError } from "@/lib/commons";
import AppNavbar from "@/components/AppNavbar";
import { LOGIN_REQUIRED_MESSAGE } from "@/lib/constants";
import { DISCOVERIES_ROUTE } from "@/lib/routes";
import Footer from "@/components/Footer";

const DashboardLayout = () => {
  const location = useLocation();
  const { user, setShowBeacon } = useAuth();

  useEffect(() => {
    if (location.pathname === DISCOVERIES_ROUTE) {
      setShowBeacon(false);
      window.Beacon && window.Beacon("destroy");
    } else {
      setShowBeacon(true);
    }

    return () => {
      setShowBeacon(true);
    };
  }, [location.pathname]);

  if (!user) {
    showToastrError(LOGIN_REQUIRED_MESSAGE);
    return <Navigate to={LOGIN_ROUTE} replace={true} />;
  }

  return (
    <>
      <AppNavbar />
      <Outlet />
      {location.pathname === DISCOVERIES_ROUTE && <Footer />}
    </>
  );
};

export default DashboardLayout;
