import React from "react";
import classnames from "classnames";

import { PBIcons } from "@/components/PBIcons";
import { DEFAULT_ACCEPT_FILES } from "@/lib/constants";

const ImageUploader = ({
  name,
  label,
  imageData,
  imageUrl,
  handleDelete,
  handleChange,
  acceptFiles,
  helpText,
  error,
  destroy,
  bodyBackground = "inverted",
  ...otherProps
}) => {
  const RING_STYLES = {
    inverted: "focus-within:shadow-secondary-ring-inverted-bg",
    primary: "focus-within:shadow-secondary-ring-primary-bg",
    "inverted-hover": "focus-within:shadow-secondary-ring-inverted-hover-bg",
    "option-card": "focus-within:shadow-secondary-ring-option-card-bg",
  };

  const BUTTON_RING_STYLES = {
    inverted: "focus:shadow-secondary-ring-inverted-bg",
    primary: "focus:shadow-secondary-ring-primary-bg",
    "inverted-hover": "focus:shadow-secondary-ring-inverted-hover-bg",
    "option-card": "focus:shadow-secondary-ring-option-card-bg",
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      {!!label && (
        <label htmlFor={name} className="pb-input-label">
          {label}
        </label>
      )}

      {destroy !== 1 && (imageData || imageUrl) ? (
        <div className="p-4 flex justify-center items-center rounded-md border border-dashed border-muted relative">
          <img
            src={imageData || imageUrl}
            className="object-contain max-h-48 w-full"
          />

          <button
            className={classnames(
              "absolute top-2 right-2 cursor-pointer z-50 p-1 bg-inverted rounded-sm shadow-none hover:shadow-md outline-none transition-all duration-300",
              BUTTON_RING_STYLES[bodyBackground]
            )}
            onClick={() => handleDelete()}
            title="Remove image"
          >
            <PBIcons icon="Close" />
          </button>
        </div>
      ) : (
        <div
          className={classnames(
            "flex justify-center items-center rounded-md border border-dashed border-muted relative transition-all duration-300",
            RING_STYLES[bodyBackground]
          )}
        >
          <div className="flex flex-col items-center gap-4 text-muted p-8">
            <PBIcons icon="Plus" />
            <span>Add image</span>
          </div>

          <input
            id={name}
            name={name}
            type="file"
            className="h-full w-full absolute cursor-pointer opacity-0 top-0 left-0"
            onChange={(event) => handleChange(event)}
            accept={acceptFiles || DEFAULT_ACCEPT_FILES}
            {...otherProps}
          />
        </div>
      )}

      {!!error && <p className="pb-error-text">{error}</p>}
      {!!helpText && <p className="pb-help-text">{helpText}</p>}
    </div>
  );
};

export default ImageUploader;
