import axios from "axios";

export const createAttachment = (takeId, payload) => {
  return axios.post(`/api/v1/takes/${takeId}/take_header_attachments`, payload);
};

export const updateAttachment = (takeId, attachmentId, payload) => {
  return axios.put(
    `/api/v1/takes/${takeId}/take_header_attachments/${attachmentId}`,
    payload
  );
};
