import React from "react";

const ChromeWindow = ({ children }) => {
  return (
    <div className="flex flex-col items-center w-full p-1.5 bg-primary rounded-[36px]">
      <span className="block w-3 h-3 rounded-full bg-muted"></span>

      <div className="flex flex-col w-full mt-1.5 mb-[18px] px-[18px]">
        <div className="flex items-center justify-between px-4 py-3 bg-inverted-hover rounded-t-[20px] border-b border-solid border-primary-border">
          <div className="flex items-center gap-1.5">
            <div className="w-3 h-3 rounded-full bg-[#f3695a]"></div>
            <div className="w-3 h-3 rounded-full bg-[#f3be4e]"></div>
            <div className="w-3 h-3 rounded-full bg-[#61c254]"></div>
          </div>
        </div>

        <div className="flex items-center w-full rounded-b-[20px] overflow-hidden">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ChromeWindow;
