export const SUBSCRIBER_FORM_INITIAL_VALUE = {
  show_avatar: "",
  show_display_name: "",
  use_website_font: "",
  display_name_color: false,
  message_color: "",
  background_color: "",
  email_input_placeholder: "",
  submit_button_label: "",
  submit_button_color: "",
  submit_button_text_color: "",
  confirmation_message_title: "",
  message: "",
  confirmation_message: "",
};
