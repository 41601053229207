import React from "react";

import Textarea from "@/components/Textarea";
import Button from "@/components/Button";

const EditAltMenu = ({
  setOpen,
  formState,
  setImageValue,
  handleSave,
  btnLoader,
}) => {
  return (
    <div className="flex flex-col gap-4 w-full px-2">
      <form
        id="alt-text-form"
        className="flex flex-col w-full gap-y-3"
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <Textarea
          id="alt-text"
          label="Alt Text"
          value={formState.alt_text}
          onChange={(e) => setImageValue("alt_text", e.target.value)}
          placeholder="Enter a alt text"
          bodyBackground="primary"
          style="primary"
          className="field-sizing-content"
        />

        <p className="text-muted text-small">
          Add a short written description of your image. This allows your images
          to be accessible to those who are using a screen reader, are vision
          impaired or blind.
        </p>
      </form>

      <div className="flex gap-4">
        <Button
          form="alt-text-form"
          type="submit"
          label="Update"
          loading={btnLoader}
          style="inverted"
          bodyBackground="primary"
        />

        <Button
          type="button"
          style="transparent"
          label="Cancel"
          onClick={() => setOpen(false)}
          bodyBackground="primary"
        />
      </div>
    </div>
  );
};

export default EditAltMenu;
