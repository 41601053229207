import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useMeasure } from "@uidotdev/usehooks";

import {
  SUBSCRIBER_ROUTE,
  TAKE_CREATE_ROUTE,
  DISCOVERIES_ROUTE,
  PREVIOUS_TAKE_ROUTE,
  SEARCH_ROUTE,
  ABOUT_ROUTE,
} from "@/lib/routes";

import { showToastrSuccess } from "@/lib/commons";
import NavItem from "@/components/NavItem";
import TakeNavItem from "@/components/TakeNavItem";
import { PBIcons } from "@/components/PBIcons";
import { DESKTOP_WIDTH } from "@/lib/constants";
import Divider from "@/components/Divider";
import { useAuth } from "@/hooks/useAuth";
import FeedbackForm from "./FeedbackForm";
import UserDropdown from "./UserDropdown";

const AppNavbar = () => {
  const { user } = useAuth();
  const [ref, { width, height }] = useMeasure();
  const [open, setOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [emailCopied, setEmailCopied] = useState();

  useEffect(() => {
    if (emailCopied) {
      showToastrSuccess("Link copied to clipboard!");
      setEmailCopied(false);
    }
  }, [emailCopied]);

  const showSidebar = () => {
    setOpen(true);
    // document.body.classList.add("!overflow-hidden");
  };

  const hideSidebar = () => {
    setOpen(false);
    // document.body.classList.remove("!overflow-hidden");
  };

  const topNavLinks = (style) => {
    return (
      <>
        <NavItem
          link={DISCOVERIES_ROUTE}
          label="Discover"
          style={style}
          onClick={() => hideSidebar()}
        />
        <NavItem
          link={ABOUT_ROUTE}
          label="About"
          style={style}
          onClick={() => hideSidebar()}
        />
        <NavItem
          link={`/archive/${user.url_slug}`}
          label="Archive"
          style={style}
          onClick={() => hideSidebar()}
        />
        <NavItem
          link={SEARCH_ROUTE}
          label="Search"
          style={style}
          onClick={() => hideSidebar()}
        />
      </>
    );
  };

  const bottomNavLinks = (style) => {
    return (
      <>
        <NavItem
          link={SUBSCRIBER_ROUTE}
          label="Subscribers"
          style={style}
          onClick={() => hideSidebar()}
        />
        <NavItem
          link={PREVIOUS_TAKE_ROUTE}
          label="All Takes"
          style={style}
          onClick={() => hideSidebar()}
        />
        <TakeNavItem
          link={TAKE_CREATE_ROUTE}
          style={style}
          onClick={() => hideSidebar()}
        />
      </>
    );
  };

  const isTabScreen = () => {
    return width && width < DESKTOP_WIDTH;
  };

  return (
    <nav
      className="flex items-center w-full h-[var(--header-height)] px-6 bg-inverted fixed z-[100] top-0 right-0 left-0"
      ref={ref}
    >
      {!isTabScreen() && (
        <div className="flex items-center justify-between w-full gap-2.5">
          <div className="flex items-center gap-2.5">
            {topNavLinks("primary")}
          </div>

          <div className="flex items-center gap-2.5">
            {bottomNavLinks("primary")}

            <UserDropdown
              style="primary"
              setFeedbackModalOpen={setFeedbackModalOpen}
              setEmailCopied={setEmailCopied}
            />
          </div>
        </div>
      )}

      {isTabScreen() && (
        <div className="flex lg:hidden items-center justify-between w-full gap-2.5">
          <PBIcons icon="Hamburger" size="28" onClick={() => showSidebar()} />

          <TakeNavItem
            link={TAKE_CREATE_ROUTE}
            style="primary"
            onClick={() => hideSidebar()}
          />
        </div>
      )}

      {isTabScreen() && (
        <div
          className={classnames(
            "absolute z-50 top-0 px-6 text-inverted bg-primary h-dvh w-screen transition-all duration-300",
            { "-left-[calc(100vw)] opacity-0": !open },
            { "left-0 opacity-100": open }
          )}
        >
          <div className="flex items-center justify-between gap-2.5 w-full h-[var(--header-height)]">
            <PBIcons icon="Close" size="28" onClick={() => hideSidebar()} />

            <UserDropdown
              style="inverted"
              setFeedbackModalOpen={setFeedbackModalOpen}
              setEmailCopied={setEmailCopied}
            />
          </div>

          <div className="pb-mobile-navbar-container flex flex-col gap-2.5">
            {topNavLinks("inverted")}
            <Divider />
            {bottomNavLinks("inverted")}
          </div>
        </div>
      )}

      <FeedbackForm
        modalOpen={feedbackModalOpen}
        setModalOpen={setFeedbackModalOpen}
      />
    </nav>
  );
};

export default AppNavbar;
