import React, { useState, useRef } from "react";

import { PBIcons } from "@/components/PBIcons";
import {
  createAttachment,
  destroyAttachment,
} from "@/apis/settings/attachments";
import { useAuth } from "@/hooks/useAuth";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import Loader from "@/components/Loader";
import { MAX_IMAGE_SIZE } from "@/lib/constants";

const Header = ({ takeUser, setTakeUser }) => {
  const { user } = useAuth();
  const fileInputRef = useRef();
  const [coverImage, setCoverImage] = useState();

  const handleChange = (event) => {
    const file = event.target.files[0];
    let reader = new FileReader();

    reader.onloadend = (e) => {
      if (file.size > MAX_IMAGE_SIZE) {
        showToastrError("Attachment size exceeds the allowable limit (5 MB).");
      } else {
        let imageData = {
          file_data: e.target.result,
          file_name: file.name,
          imageLoader: true,
          position: 3,
          caption: file.name,
          attachable_kind: "archive_cover",
        };

        setCoverImage(imageData);
        createAttachmentAPI(imageData);
      }
    };
    reader.readAsDataURL(file);
  };

  const createAttachmentAPI = async (imageData) => {
    try {
      if (takeUser.archive_cover_image_url) {
        await destroyAttachment(takeUser.archive_cover_image_id);
      }

      const { data } = await createAttachment({
        attachment: {
          ...imageData,
        },
      });
      showToastrSuccess("Cover image has been successfully uploaded.");
      setTakeUser(data.user);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setCoverImage();
    }
  };

  return (
    <div className="relative h-[170px] md:h-[290px] w-full object-cover">
      {coverImage?.imageLoader && (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-30 text-inverted transition-all backdrop-blur-sm rounded">
          <Loader />
        </div>
      )}

      <img
        src={
          coverImage?.file_data ||
          takeUser.archive_cover_image_url ||
          takeUser.cover_image_url
        }
        className="h-full w-full object-cover"
      />

      {user?.id === takeUser.id && (
        <div className="absolute right-3 bottom-3 cursor-pointer">
          <div
            className="flex items-center gap-1 px-2 py-1.5 rounded-sm bg-inverted hover:bg-inverted-hover shadow-lg transition-all duration-300"
            onClick={() => fileInputRef.current.click()}
          >
            <PBIcons icon="Attachment" />

            <span className="hidden sm:block">Edit cover image</span>
            <span className="sm:hidden">Edit</span>
          </div>

          <input
            ref={fileInputRef}
            id="file_data"
            name="file_data"
            type="file"
            className="h-full w-full absolute opacity-0 top-0 left-0 -z-10"
            accept="image/png, image/gif, image/jpeg, image/webp, image/jpg, image/avif"
            onChange={(e) => handleChange(e)}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
