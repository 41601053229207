import React, { useState } from "react";

import Modal from "@/components/Modal";

import UploadForm from "./UploadForm";
import OptionForm from "./OptionForm";

const OptionModal = ({
  takeId,
  take,
  open,
  setOpen,
  images,
  setImages,
  setValue,
  editGallery,
  attachments,
  setEditGallery,
  attachmentId = null,
  getTakeAPI,
  galleryView = "single",
}) => {
  const [selectedOption, setSelectedOption] = useState(galleryView);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setSelectedOption("single");
        setEditGallery(false);
      }}
      size={editGallery ? "full" : "large"}
      title={!editGallery && "Image upload"}
      hideClose={editGallery}
    >
      {editGallery ? (
        <UploadForm
          takeId={takeId}
          take={take}
          attachmentId={attachmentId}
          galleryView={galleryView}
          setOpen={setOpen}
          images={images}
          setImages={setImages}
          setValue={setValue}
          setEditGallery={setEditGallery}
          attachments={attachments}
          getTakeAPI={getTakeAPI}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      ) : (
        <OptionForm
          setOpen={setOpen}
          setValue={setValue}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setEditGallery={setEditGallery}
        />
      )}
    </Modal>
  );
};

export default OptionModal;
