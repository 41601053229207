import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMeasure } from "@uidotdev/usehooks";

import { TAB_WIDTH } from "@/lib/constants";
import { getTake, fetchStripeSubscription } from "@/apis/takes";
import { useAuth } from "@/hooks/useAuth";
import { showToastrError } from "@/lib/commons";
import Loader from "@/components/Loader";

import Navbar from "./Navbar";
import BrowserView from "./BrowserView";
import EmailView from "./EmailView";

const PreviewTakePage = () => {
  const { takeId } = useParams();
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const [ref, { width, height }] = useMeasure();

  const [deviceType, setDeviceType] = useState("desktop");
  const [renderType, setRenderType] = useState("browser");
  const [subscriberType, setSubscriberType] = useState("free");

  const [takeDetail, setTakeDetail] = useState();
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    if (takeId) getTakeAPI();
  }, [takeId]);

  useEffect(() => {
    if (isMobileScreen()) {
      setDeviceType("mobile");
    } else {
      setDeviceType("desktop");
    }
  }, [width]);

  const getTakeAPI = async () => {
    try {
      // TODO: Why need to send true in the api call
      const { data } = await getTake(takeId, true);
      setTakeDetail(data.take);
      await getSubscriptionAPI();
      setPageLoader(false);
    } catch (error) {
      if (error.response.status === 404) navigate("/not_found");
      showToastrError(error.response.data.errors);
    }
  };

  const getSubscriptionAPI = async () => {
    try {
      const { data } = await fetchStripeSubscription();

      setUser((user) => {
        return {
          ...user,
          takes_count: data.takes_count,
          free_subscriber_size: data.free_subscriber_size,
          paid_subscriber_size: data.paid_subscriber_size,
        };
      });
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const isMobileScreen = () => {
    return width && width < TAB_WIDTH;
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-page-wrapper bg-inverted-hover" ref={ref}>
      <Navbar
        take={takeDetail}
        deviceType={deviceType}
        setDeviceType={setDeviceType}
        renderType={renderType}
        setRenderType={setRenderType}
        subscriberType={subscriberType}
        setSubscriberType={setSubscriberType}
        desktopDisabled={isMobileScreen()}
      />

      <div className="pb-page-container flex flex-col items-center w-full p-6 md:p-10">
        {renderType === "browser" ? (
          <BrowserView
            take={takeDetail}
            mobileView={deviceType === "mobile"}
            paidView={subscriberType === "free"}
          />
        ) : (
          <EmailView
            take={takeDetail}
            mobileView={deviceType === "mobile"}
            paidView={subscriberType === "free"}
          />
        )}
      </div>
    </div>
  );
};

export default PreviewTakePage;
