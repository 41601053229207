import React, { useState } from "react";

import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";
import { useAuth } from "@/hooks/useAuth";

import { PBIcons } from "@/components/PBIcons";

const Dropdown = ({
  formState,
  setValue,
  handleSubmitWithStatus,
  createTakeAPI,
}) => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const renderIcon = (filled) => {
    return <PBIcons icon={filled ? "RadioFill" : "Radio"} />;
  };

  const isSelected = (type) => {
    return formState.header_image_type == type;
  };

  return (
    <Menu open={open} setOpen={setOpen}>
      <MenuItem
        label="Logo header"
        icon={renderIcon(isSelected("logo"))}
        selected={isSelected("logo")}
        onClick={() => {
          setValue("header_image_type", "logo");
          handleSubmitWithStatus();
          setOpen(false);
        }}
      />

      <MenuItem
        label="Image header"
        icon={renderIcon(isSelected("cover"))}
        selected={isSelected("cover")}
        onClick={() => {
          setOpen(false);
          setValue("header_image_type", "cover");
          setValue(
            "cover_image_attributes",
            formState.cover_image_attributes
              ? formState.cover_image_attributes
              : user.default_take_header_attachment_attributes
          );

          if (formState.id) {
            handleSubmitWithStatus();
          } else {
            createTakeAPI();
          }
        }}
      />
    </Menu>
  );
};

export default Dropdown;
