import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center h-dvh gap-8 p-6 bg-inverted">
      <h1 className="text-heading-xxl font-bold text-center">
        404 - Page not found
      </h1>
      <p className="max-w-[446px] text-center">
        The bad news is you're falling through the air, nothing to hang on to,
        no parachute. The good news is there's no ground.
      </p>
    </div>
  );
};

export default NotFound;
