import React, { useState } from "react";

import { getLinkRollsPreview, createLinkRoll } from "@/apis/link_rolls";
import { showToastrError } from "@/lib/commons";
import { isValidUrl, withoutProtocol } from "@/lib/utils";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { PBIcons } from "@/components/PBIcons";
import Menu from "@/components/Menu";

const AddLinkDropdown = ({ formState, setValue }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [link, setLink] = useState("");
  const [linkError, setLinkError] = useState();
  const [btnLoader, setBtnLoader] = useState(false);

  const handleCancel = () => {
    setLink("");
    setLinkError("");
    setBtnLoader(false);
    setMenuOpen(false);
  };

  const getLinkRollsPreviewAPI = async () => {
    try {
      setBtnLoader(true);

      let website = link;
      if (withoutProtocol(website)) website = `http://${website}`;

      const {
        data: { link_rolls_preview },
      } = await getLinkRollsPreview(formState.id, website);

      let serial =
        formState.link_rolls_attributes[
          formState.link_rolls_attributes.length - 1
        ]?.position || 0;

      let payload = {
        position: serial + 1,
        link: link_rolls_preview.link,
        title: link_rolls_preview.title || "Enter Title",
        description: link_rolls_preview.description,
        image_src: link_rolls_preview.image_src,
        username: link_rolls_preview.username,
        instagram_post: link_rolls_preview.instagram_post,
        twitter_post: link_rolls_preview.twitter_post,
      };

      const { data } = await createLinkRoll(formState.id, {
        link_roll: payload,
      });

      setValue("link_rolls_attributes", [
        ...formState.link_rolls_attributes,
        data.link_roll,
      ]);

      setLink("");
      setMenuOpen(false);
    } catch (error) {
      showToastrError(error.response.data.message);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <Menu
      open={menuOpen}
      setOpen={setMenuOpen}
      style="transparent"
      width="300"
      position="bottom-start"
      menuTrigger={
        <div className="flex items-center justify-center gap-1 text-muted">
          <PBIcons icon="Plus" />
          <span className="">Add link</span>
        </div>
      }
    >
      <div className="flex flex-col gap-4 w-full px-2">
        <form
          id="b-link-form"
          className="flex flex-col w-full gap-y-3"
          onSubmit={(e) => {
            e.preventDefault();
            if (!isValidUrl(link)) {
              setLinkError("Link is not valid.");
            } else {
              setLinkError("");
              getLinkRollsPreviewAPI();
            }
          }}
        >
          {" "}
          <Input
            label="Link"
            id="link"
            name="link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="Enter a link"
            error={linkError}
            bodyBackground="primary"
            style="primary"
            required
          />
        </form>

        <div className="flex gap-4">
          <Button
            form="b-link-form"
            type="submit"
            label="Insert"
            loading={btnLoader}
            style="inverted"
            bodyBackground="primary"
          />

          <Button
            type="button"
            style="transparent"
            label="Cancel"
            onClick={() => handleCancel()}
            bodyBackground="primary"
          />
        </div>
      </div>
    </Menu>
  );
};

export default AddLinkDropdown;
