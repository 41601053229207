import React, { useState } from "react";

import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";
import { SUBSCRIBER_TYPES } from "@/lib/constants";

import { PBIcons } from "@/components/PBIcons";

const RecipientDropdown = ({
  takeUser,
  formState,
  setValue,
  handleSubmitWithStatus,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const allText = `All subscribers (${
    takeUser.free_subscriber_size + takeUser.paid_subscriber_size
  })`;
  const freeText = `Free subscribers (${takeUser.free_subscriber_size})`;
  const paidText = `Paid subscribers (${takeUser.paid_subscriber_size})`;

  const buttonText = () => {
    if (formState.subscribers_type === SUBSCRIBER_TYPES.free) {
      return freeText;
    } else if (formState.subscribers_type === SUBSCRIBER_TYPES.paid) {
      return paidText;
    } else {
      return allText;
    }
  };

  const handleOptionSelect = (type) => {
    setValue("subscribers_type", type);
    handleSubmitWithStatus();
    setMenuOpen(false);
  };

  return (
    <Menu
      open={menuOpen}
      setOpen={setMenuOpen}
      width="250"
      position="bottom-start"
      style="transparent"
      className="!p-0"
      hoverEffect={false}
      menuTrigger={
        <div
          className="flex items-center gap-1"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <h3 className="text-title lg:text-heading font-bold">
            {buttonText()}
          </h3>

          <div
            className={`pb-dropdown-trigger-icon ${
              menuOpen ? "active" : "inactive"
            }`}
          >
            <PBIcons icon="ChevronDown" />
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-1">
        <MenuItem
          label={allText}
          icon={<PBIcons icon="People" />}
          onClick={() => handleOptionSelect(SUBSCRIBER_TYPES.all)}
          selected={formState.subscribers_type === SUBSCRIBER_TYPES.all}
        />

        <MenuItem
          label={freeText}
          icon={<PBIcons icon="Free" />}
          onClick={() => handleOptionSelect(SUBSCRIBER_TYPES.free)}
          selected={formState.subscribers_type === SUBSCRIBER_TYPES.free}
        />

        <MenuItem
          label={paidText}
          icon={<PBIcons icon="Paid" />}
          onClick={() => handleOptionSelect(SUBSCRIBER_TYPES.paid)}
          selected={formState.subscribers_type === SUBSCRIBER_TYPES.paid}
        />
      </div>
    </Menu>
  );
};

export default RecipientDropdown;
