import React, { useEffect } from "react";

const HelpScoutBeacon = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML =
      '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});';
    document.body.appendChild(script);

    window.Beacon("init", "d0684acd-482d-47a3-999d-8de0b81df429");
  }, []);

  return null;
};

export default HelpScoutBeacon;
