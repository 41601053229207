import * as Yup from "yup";

import { MAX_STRING_LENGTH } from "@/lib/constants";

export const USER_INITIAL_VALUE = {
  name: "",
  email: "",
};

export const USER_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be blank.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  email: Yup.string()
    .required("Email can't be blank.")
    .email("Email must be valid.")
    .max(
      MAX_STRING_LENGTH,
      `Email must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});
