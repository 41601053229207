import React from "react";
import { lineSpinner } from "ldrs";

const Loader = () => {
  lineSpinner.register();

  return (
    <l-line-spinner size="20" stroke="2" color="currentColor"></l-line-spinner>
  );
};

export default Loader;
