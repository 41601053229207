import React, { useState, useRef } from "react";
import browserImageSize from "browser-image-size";
import { v4 as uuid } from "uuid";

import { MAX_IMAGE_SIZE } from "@/lib/constants";
import { showToastrError } from "@/lib/commons";
import { PBIcons } from "@/components/PBIcons";
import Button from "@/components/Button";

import OptionModal from "./OptionModal";

const GalleryUploader = ({
  takeId,
  take,
  setValue,
  getTakeAPI,
  attachments,
}) => {
  const inputFile = useRef(null);
  const [images, setImages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editGallery, setEditGallery] = useState(false);

  const handleChange = (e) => {
    setImages([]);
    const files = Array.from(e.target.files);
    let imageList = [];
    let serial = attachments[attachments.length - 1]?.position || 0;

    files.map((file) => {
      let reader = new FileReader();
      const newUuid = uuid();

      browserImageSize(file).then((size) => {
        if (file.size > MAX_IMAGE_SIZE) {
          showToastrError(
            "Attachment size exceeds the allowable limit (5 MB)."
          );
        } else {
          reader.onload = (e) => {
            let image = {
              file_data: e.target.result,
              file_name: file.name,
              imageLoader: true,
              tempId: newUuid,
              height: size.height,
              width: size.width,
              style: "normal",
              view_type: "single",
            };
            imageList.push(image);

            if (files.length > 1 && files.length === imageList.length) {
              setImages([
                ...images,
                ...imageList.map((image, index) => {
                  return { ...image, position: serial + index + 1 };
                }),
              ]);
            }

            if (files.length === 1 && files.length === imageList.length) {
              setValue("attachments_attributes", [
                ...attachments,
                ...imageList.map((image, index) => {
                  return { ...image, position: serial + index + 1 };
                }),
              ]);
            }
          };
        }

        reader.readAsDataURL(file);
      });
    });

    if (files.length > 1) {
      setEditGallery(true);
      setModalOpen(true);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2 w-full max-w-[var(--take-width)]">
        <div className="flex justify-center items-center w-full py-3 h-40 rounded-sm border border-dashed border-muted relative">
          <div className="flex flex-col gap-3 w-full">
            <p className="text-center text-muted">
              Select an option to upload images
            </p>

            <div className="flex flex-col md:flex-row justify-center items-center gap-2">
              <Button
                icon={<PBIcons icon="Attachment" />}
                label="Add a single image"
                style="inverted"
                className="z-[20]"
                onClick={() => {
                  setImages([]);
                  inputFile.current.click();
                }}
              />

              <Button
                icon={<PBIcons icon="Gallery" />}
                label="Add a gallery"
                style="inverted"
                className="z-[20]"
                onClick={() => {
                  setImages([]);
                  setModalOpen(true);
                }}
              />
            </div>
          </div>

          <input
            multiple
            ref={inputFile}
            type="file"
            accept="image/jpg, image/jpeg, image/gif, image/png"
            className="h-full w-full absolute opacity-0 top-0 left-0 -z-10"
            onChange={(event) => {
              handleChange(event);
            }}
          />
        </div>
      </div>

      <OptionModal
        takeId={takeId}
        take={take}
        open={modalOpen}
        images={images}
        setImages={setImages}
        setValue={setValue}
        setOpen={setModalOpen}
        editGallery={editGallery}
        attachments={attachments}
        getTakeAPI={getTakeAPI}
        setEditGallery={setEditGallery}
      />
    </>
  );
};

export default GalleryUploader;
