import React from "react";

import { PBIcons } from "@/components/PBIcons";
import { DEFAULT_ACCEPT_FILES } from "@/lib/constants";

const SingleUploader = ({ handleChange }) => {
  return (
    <div className="flex justify-center items-center aspect-square rounded-sm border border-dashed border-muted relative">
      <div className="flex flex-col items-center gap-1 sm:gap-4 p-2">
        <PBIcons icon="Plus" className="text-inverted" />
        <div className="text-center text-muted">
          <span className="hidden sm:inline-block">Add</span> images
        </div>
      </div>

      <input
        type="file"
        className="h-full w-full absolute cursor-pointer opacity-0 top-0 left-0"
        onChange={(event) => handleChange(event.target.files)}
        accept={DEFAULT_ACCEPT_FILES}
        multiple
      />
    </div>
  );
};

export default SingleUploader;
