import * as R from "ramda";

export const isPresent = R.pipe(R.either(R.isNil, R.isEmpty), R.not);

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const getProfileUrl = (slug) => {
  return `${window.location.origin}/archive/${slug}`;
};

export const getSubscribeUrl = (slug) => {
  return `${window.location.origin}/${slug}`;
};

export const amountInCurrency = (amount) => {
  return `$${amount} USD`;
};

export const getUnique = (arr, attr = "id") => {
  const unique = arr
    .map((e) => e[attr])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
};

export const dropDownListGenerator = (data, attr) => {
  return data.map((item) => {
    return {
      ...item,
      label: item[attr],
      value: item.id,
    };
  });
};

export const deepEqual = (x, y) => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === "object" && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
};

export const isValidUrl = (url) => {
  let http_regex = new RegExp(
    "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
  );
  let without_http_regex = new RegExp(
    "^([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
  );

  if (http_regex.test(url) || without_http_regex.test(url)) {
    return true;
  } else {
    return false;
  }
};

export const withoutProtocol = (url = "") => {
  let urlProtocol = url.split("://")[0];
  if (["http", "https"].includes(urlProtocol)) {
    return false;
  } else {
    return true;
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const sanitizeLink = (website) => {
  if (website?.length > 0 && isValidUrl(website)) {
    if (withoutProtocol(website)) {
      return `http://${website}`;
    } else {
      return website;
    }
  }
};

export const swapElement = (array, start, destination) => {
  const index2 = destination;
  const newItems = array.slice();
  const temp = array[start];
  const oldPosition = array[start].position;
  const newPosition = array[index2].position;
  newItems[start] = { ...array[index2], position: oldPosition };
  newItems[index2] = { ...temp, position: newPosition };
  return newItems;
};
