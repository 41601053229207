import React from "react";

const MobileWindow = ({ children }) => {
  return (
    <div className="flex flex-col items-center w-full max-w-[var(--mobile-screen-width)] p-2.5 bg-primary rounded-[36px]">
      <div className="flex flex-col items-center w-full rounded-[30px] overflow-hidden bg-inverted">
        <div className="flex items-center rounded-3xl w-20 px-4 py-3 bg-primary my-1.5"></div>

        {children}
      </div>
    </div>
  );
};

export default MobileWindow;
