import React from "react";

const Outro = ({ take }) => {
  return (
    <div className="flex flex-col w-full max-w-[var(--take-width)] pt-12 border-t border-solid border-primary-border">
      <p
        dangerouslySetInnerHTML={{
          __html: take.outro?.split("\n")?.join("<br/>"),
        }}
      />
    </div>
  );
};

export default Outro;
