import React from "react";
import { Link } from "react-router-dom";

const BoothCard = ({ booth }) => {
  return (
    <Link to={`/archive/${booth.url_slug}`}>
      <div className="flex flex-col gap-2.5">
        <div className="relative">
          <img
            src={booth.attachments[0].thumb_src}
            className="aspect-[3/2] object-cover rounded-[var(--take-card-radius)]"
          />

          <div className="absolute z-30 top-2.5 left-2.5">
            <div className="flex items-center gap-2.5">
              <img
                src={booth.logo_url}
                className="h-5 w-5 md:h-8 md:w-8 rounded-full object-cover shrink-0"
              />

              <span className="text-small text-inverted line-clamp-1">
                {booth.name}
              </span>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between w-full gap-3">
          <span className="text-body line-clamp-1">{booth.name}</span>
          <span className="hidden md:block text-label text-muted shrink-0">
            {booth.location}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default BoothCard;
