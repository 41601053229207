import React from "react";

import LinkButton from "@/components/LinkButton";
import { JOIN_ROUTE, ABOUT_ROUTE } from "@/lib/routes";

const CTACard = () => {
  return (
    <div className="flex flex-col gap-10 p-7 bg-secondary text-inverted rounded-sm">
      <p className="font-bold">
        PencilBooth is a minimalist email newsletter and publishing service
        designed specifically for creative types to share images and links.
      </p>
      <div className="flex items-center gap-4">
        <LinkButton
          to={JOIN_ROUTE}
          label="Sign up"
          style="inverted"
          bodyBackground="primary"
        />

        <LinkButton
          to={ABOUT_ROUTE}
          label="Learn More"
          style="transparent"
          bodyBackground="primary"
        />
      </div>
      <div className="flex flex-col gap-6 font-normal">
        <p>
          Using a no-frills, visuals-first approach, PencilBooth makes it easy
          to keep people up-to-date with your creative practice without losing
          your mind.
        </p>
        <p>
          All accounts include 500 free emails per month, and there are no
          subscription plans to sign up for or pay monthly. You simply pay for
          what you use, when you use it.
        </p>
        <p>
          You're currently viewing our discovery page, which features some of
          the latest newsletters and posts from our members.
        </p>
      </div>
    </div>
  );
};

export default CTACard;
