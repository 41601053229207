import axios from "axios";

export const getBilling = (invoicePageIndex, logPageIdex) => {
  return axios.get(
    `/api/v1/settings/billing?invoice_page_index=${invoicePageIndex}&log_page_index=${logPageIdex}`
  );
};

export const createBilling = (payload) => {
  return axios.post(`/api/v1/settings/billing`, payload);
};
