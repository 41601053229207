import React from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import { ROOT_ROUTE } from "@/lib/routes";

const SidebarNavItem = ({
  path = ROOT_ROUTE,
  label = "Home",
  ...otherProps
}) => {
  return (
    <NavLink
      to={path}
      className={({ isActive, isPending }) =>
        classnames(
          "px-2 py-1.5 lg:w-full rounded-sm cursor-pointer hover:bg-inverted-hover lg:hover:bg-inverted shrink-0 h-fit transition-all duration-300",
          { "bg-inverted-hover lg:bg-inverted": isActive }
        )
      }
      {...otherProps}
    >
      {label}
    </NavLink>
  );
};

export default SidebarNavItem;
