import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { getDefaultSetting } from "@/apis/default_settings";
import Loader from "./Loader";
import { PRIVACY_ROUTE, TERMS_ROUTE } from "@/lib/routes";
import { PBIcons } from "./PBIcons";
import PBLogo from "@/assets/images/pb-logo.png";

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [defaultSetting, setDefaultSetting] = useState();
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    getDefaultSettingResponse();
  }, []);

  const getDefaultSettingResponse = async () => {
    try {
      setPageLoader(true);
      const { data } = await getDefaultSetting();
      setDefaultSetting(data.default_setting);
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <footer className="flex items-center w-full lg:px-6 fixed z-[1000] bottom-0 right-0 left-0">
      <div className="flex flex-col w-full bg-secondary text-inverted">
        <div
          className="flex justify-between items-center px-6 py-3.5 cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          <span>PencilBooth</span>

          <div className="flex items-center gap-5">
            <span className="opacity-50 hidden md:block">
              PencilBooth 3.0 – See what's new
            </span>

            <div className="flex items-center gap-2 opacity-50 md:opacity-100">
              <span>News</span>

              <span className={classnames("pb-footer-arrow", { active: open })}>
                <PBIcons icon="ArrowDown" />
              </span>
            </div>
          </div>
        </div>

        <div
          className={classnames("pb-footer-content relative", { active: open })}
        >
          <div className="hidden md:flex flex-col items-end justify-end w-full p-6 h-[80dvh]">
            <div class="flex flex-col gap-4 w-72 lg:w-96">
              <div className="flex items-center gap-2 w-full">
                <Link to={TERMS_ROUTE}>Terms & Conditions</Link>
                <span>•</span>
                <Link to={PRIVACY_ROUTE}>Privacy Policy</Link>
              </div>

              <img src={PBLogo} className="w-72 lg:w-96" />
            </div>
          </div>

          <div className="absolute w-full md:w-1/2 h-full top-0 left-0 p-6 overflow-y-auto no-scrollbar">
            <div className="flex w-full md:justify-end">
              <div className="flex flex-col items-center gap-6 w-full md:max-w-[450px]">
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: defaultSetting.footer_text
                      ?.split("\n")
                      .join("<br />"),
                  }}
                />

                <div className="flex flex-col gap-4 w-full md:hidden">
                  <div className="flex items-center gap-2">
                    <Link to={TERMS_ROUTE}>Terms & Conditions</Link>
                    <span>•</span>
                    <Link to={PRIVACY_ROUTE}>Privacy Policy</Link>
                  </div>

                  <img src={PBLogo} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
