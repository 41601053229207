// Semi-polished

import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "@uidotdev/usehooks";

import {
  getUsers,
  getUserPreview,
  getUserDisciplines,
  getUserLocations,
} from "@/apis/searches";
import Tag from "@/components/Tag";
import Loader from "@/components/Loader";
import { showToastrError } from "@/lib/commons";
import { PBIcons } from "@/components/PBIcons";

import BoothCard from "./BoothCard";

const SearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("query"));
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [searchLocation, setSearchLocation] = useState(
    searchParams.get("location")
  );
  const [searchDiscipline, setSearchDiscipline] = useState(
    searchParams.get("discipline")
  );
  const [locationList, setLocationList] = useState([]);
  const [disciplineList, setDisciplineList] = useState([]);
  const [boothUsers, setBoothUsers] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [searchLoader, setSearchLoader] = useState(false);
  const [pageTitle, setPageTitle] = useState("Featured");

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    if (searchLocation || searchDiscipline || searchTerm) {
      let queryData = {};
      searchTerm && (queryData.query = searchTerm);
      searchLocation && (queryData.location = searchLocation);
      searchDiscipline && (queryData.discipline = searchDiscipline);

      setSearchParams({ ...queryData });
      setPageTitle("Search Result");
      setSearchLoader(true);
      getBoothUsersAPI();
    } else {
      setSearchParams();
      setPageTitle("Featured");
      setSearchLoader(true);
      getUserPreviewAPI();
    }
  }, [debouncedSearchTerm, searchLocation, searchDiscipline]);

  const loadInitialData = async () => {
    await getUserPreviewAPI();
    await getLocationListAPI();
    await getDisciplineListAPI();
    setPageLoader(false);
  };

  const getLocationListAPI = async () => {
    try {
      const { data } = await getUserLocations();
      setLocationList(data.locations);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getDisciplineListAPI = async () => {
    try {
      const { data } = await getUserDisciplines();
      setDisciplineList(data.disciplines);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getUserPreviewAPI = async () => {
    try {
      const { data } = await getUserPreview();
      setBoothUsers(data.users);
      setSearchLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getBoothUsersAPI = async () => {
    try {
      const { data } = await getUsers(
        searchTerm,
        searchLocation,
        searchDiscipline
      );
      setBoothUsers(data.users);
      setSearchLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-page-wrapper bg-inverted-hover">
      <div className="pb-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col items-center w-full gap-7 md:gap-14 max-w-[var(--layout-max-width)]">
          <div className="flex items-center justify-between gap-3 w-full py-2 border-b-2 border-solid border-disabled">
            <input
              type="text"
              placeholder="Search for Booths, locations, disiplines"
              className="block w-full p-0 outline-none text-title bg-transparent placeholder:text-muted"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <div className="shrink-0 h-4 w-4 text-muted">
              <PBIcons icon="Search" size="24" />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-5 gap-10 md:gap-15 lg:gap-20 w-full">
            <div className="md:col-span-3 flex flex-col gap-6">
              <h4 className="text-title">{pageTitle}</h4>

              {searchLoader ? (
                <div className="flex justify-center items-center h-[200px] w-full">
                  <Loader />
                </div>
              ) : (
                <div className="grid grid-cols-2 md:grid-cols-2 gap-5 md:gap-10">
                  {boothUsers.map((booth) => (
                    <BoothCard key={booth.id} booth={booth} />
                  ))}
                </div>
              )}
            </div>

            <div className="flex flex-col gap-6">
              <h4 className="text-title">Disciplines</h4>

              <div className="flex flex-wrap gap-4">
                {disciplineList.map((item, index) => (
                  <Tag
                    key={index}
                    label={item.name}
                    selected={item.name === searchDiscipline}
                    onClick={() => {
                      if (item.name === searchDiscipline) {
                        setSearchDiscipline();
                      } else {
                        setSearchDiscipline(item.name);
                      }
                    }}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-6">
              <h4 className="text-title">Locations</h4>

              <div className="flex flex-wrap gap-4">
                {locationList.map((item, index) => (
                  <Tag
                    key={index}
                    label={item[0]}
                    selected={item[1] === searchLocation}
                    onClick={() => {
                      if (item[1] === searchLocation) {
                        setSearchLocation();
                      } else {
                        setSearchLocation(item[1]);
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
