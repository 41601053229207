import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { getProfileUrl, validateEmail } from "@/lib/utils";
import { createSubscription } from "@/apis/subscriptions";
import { showToastrSuccess, showToastrError } from "@/lib/commons";

import Input from "@/components/Input";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import SignUpAlert from "@/components/SignUpAlert";
import UserPlanModal from "@/components/UserPlanModal";

const Follow = ({ take, mobileView = false, emailView }) => {
  const user = take.user;
  const [searchParams, setSearchParams] = useSearchParams();
  const [btnLoader, setBtnLoader] = useState(false);
  const [email, setEmail] = useState();
  const [showPlans, setShowPlans] = useState(false);
  const [signUpAlert, setSignUpAlert] = useState(false);
  const [upgradedToPaid, setUpgradedToPaid] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState();

  useEffect(() => {
    if (searchParams.get("upgraded_to_paid")) {
      setUpgradedToPaid(true);
    }

    if (searchParams.get("show_plans") && searchParams.get("failure")) {
      setShowPlans(true);
    }

    if (
      searchParams.get("new_user") === "true" &&
      searchParams.get("success")
    ) {
      setSignUpAlert(true);
    }
  }, []);

  const createSubscriptionAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createSubscription({
        subscription: { email: email, user_id: user.id },
      });

      setEmail("");
      setUpgradedToPaid(data.upgraded_to_paid);
      setSubscriptionId(data.id);

      if (user.paid_subscription_enabled && !data.upgraded_to_paid) {
        setShowPlans(true);
      }
      showToastrSuccess(data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex flex-col items-center gap-6 w-full max-w-[var(--take-width)] pt-12 border-t border-solid border-primary-border">
      {!!user.personal_url && (
        <div className="flex flex-col items-center gap-6 w-full">
          <p className="text-center">Want more from {user.name}?</p>
          <a href={user.personal_url} className="text-center underline">
            {user.personal_url}
          </a>
        </div>
      )}

      {!emailView && !take.subscribed && !upgradedToPaid && (
        <form
          className={`flex ${
            mobileView ? "flex-col" : "flex-col md:flex-row"
          } gap-2 w-full max-w-[390px]`}
          onSubmit={(e) => {
            e.preventDefault();
            if (!!email && validateEmail(email)) {
              createSubscriptionAPI();
            } else {
              showToastrError("Please enter a valid email!");
            }
          }}
        >
          <Input
            value={email}
            placeholder="Enter email address"
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <Button
            type="submit"
            label="Subscribe"
            loading={btnLoader}
            className={`h-auto p-3 w-full ${
              mobileView ? "w-auto" : "md:w-fit"
            }`}
          />
        </form>
      )}

      {(take.subscribed || upgradedToPaid) && (
        <Button
          label={"Subscribed!"}
          className={`h-auto p-3 w-full ${mobileView ? "w-auto" : "md:w-fit"}`}
        />
      )}

      <Link to={getProfileUrl(user.url_slug)} className="text-center underline">
        View previous takes
      </Link>

      <Modal
        size="planModal"
        open={showPlans}
        onClose={() => setShowPlans(false)}
      >
        <UserPlanModal
          user={user}
          subscriptionId={subscriptionId}
          setShowPlans={setShowPlans}
        />
      </Modal>

      <SignUpAlert modalOpen={signUpAlert} setModalOpen={setSignUpAlert} />
    </div>
  );
};

export default Follow;
