import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getTakeList } from "@/apis/takes";
import { showToastrError } from "@/lib/commons";
import Pagination from "@/components/Pagination";
import Loader from "@/components/Loader";
import BlankState from "@/components/BlankState";
import Button from "@/components/Button";
import { TAKE_CREATE_ROUTE } from "@/lib/routes";

import Table from "./Table";

const PreviousTakesPage = () => {
  const navigate = useNavigate();
  const [takeList, setTakeList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState();

  useEffect(() => {
    if (pageIndex) {
      getTakeListResponse();
    }
  }, [pageIndex]);

  const getTakeListResponse = async () => {
    try {
      const { data } = await getTakeList(pageIndex);
      setTakeList(data.takes);
      setTotalRecords(data.total_records);
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-page-wrapper bg-inverted-hover">
      <div className="pb-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-6 md:gap-10 w-full max-w-[var(--layout-max-width)]">
          <h1 className="text-heading font-bold">All Takes</h1>

          {takeList.length > 0 ? (
            <Table takes={takeList} getTakeListResponse={getTakeListResponse} />
          ) : (
            <BlankState>
              <div className="flex flex-col items-center gap-4 w-full">
                <h5 className="text-title text-center">
                  There are no takes to show.
                </h5>
                <Button
                  label="Create a new take"
                  bodyBackground="inverted-hover"
                  onClick={() => navigate(TAKE_CREATE_ROUTE, { replace: true })}
                />
              </div>
            </BlankState>
          )}

          <Pagination
            count={totalRecords}
            pageSize={10}
            pageNo={pageIndex}
            navigate={setPageIndex}
          />
        </div>
      </div>
    </div>
  );
};

export default PreviousTakesPage;
