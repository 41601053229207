import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import { useClickAway } from "@uidotdev/usehooks";
import { useForm } from "react-hook-form";

import { TAKE_EDITOR_EXTENSION_LIST } from "@/lib/constants";
import { updateLinkRoll } from "@/apis/link_rolls";
import { showToastrError } from "@/lib/commons";
import LinkCard from "@/components/LinkCard";
import TextEditor from "@/components/TextEditor";

import Dropdown from "./Dropdown";

const LinkRollCard = ({ linkRoll, formState, setValue }) => {
  const [clicked, setClicked] = useState(false);

  const {
    handleSubmit,
    setValue: setLinkRollValue,
    watch,
  } = useForm({
    defaultValues: linkRoll,
    shouldValidate: true,
    shouldDirty: true,
    mode: "onblur",
  });

  const linkRollRef = useClickAway(() => {
    setClicked(false);
  });

  const hasContent = () => {
    return watch().caption?.length > 0 || watch().title?.length > 0 || clicked;
  };

  const updateLinkRollResponse = async () => {
    try {
      await updateLinkRoll(formState.id, linkRoll.id, {
        link_roll: {
          title: watch().title,
          caption: watch().caption,
        },
      });
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const onTitleChange = (value) => {
    setLinkRollValue("title", value);
  };

  const onCaptionChange = (value) => {
    setLinkRollValue("caption", value);
  };

  return (
    <div
      className="flex flex-col w-full max-w-[var(--link-roll-width)]"
      ref={linkRollRef}
    >
      <div
        className={`relative rounded cursor-pointer transition-all duration-300 ${
          clicked ? "shadow-secondary-ring-inverted-bg" : ""
        }`}
        onClick={() => setClicked(true)}
      >
        <LinkCard linkRoll={linkRoll} />

        <div className="absolute right-2 top-2">
          <Dropdown
            formState={formState}
            setValue={setValue}
            linkRoll={linkRoll}
          />
        </div>
      </div>

      <AnimateHeight duration={300} height={hasContent() ? "auto" : 0}>
        <div className="flex flex-col gap-4 w-full mt-4">
          <TextEditor
            className="text-heading font-bold"
            placeholder="Give your link a title"
            content={watch().title}
            setEditorContent={onTitleChange}
            onBlur={() => handleSubmit(updateLinkRollResponse())}
            extensionList={["TextAlign"]}
            onFocus={() => setClicked(true)}
          />

          <TextEditor
            placeholder="Write something about your link (optional)"
            content={watch().caption}
            setEditorContent={onCaptionChange}
            onBlur={() => handleSubmit(updateLinkRollResponse())}
            extensionList={TAKE_EDITOR_EXTENSION_LIST}
            onFocus={() => setClicked(true)}
          />
        </div>
      </AnimateHeight>
    </div>
  );
};

export default LinkRollCard;
