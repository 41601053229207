import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import Slider from "react-slick";
import AnimateHeight from "react-animate-height";
import { useForm } from "react-hook-form";

import { updateAttachment } from "@/apis/take_attachments";
import { showToastrError } from "@/lib/commons";
import TextEditor from "@/components/TextEditor";
import { PBIcons } from "@/components/PBIcons";
import { TAKE_EDITOR_EXTENSION_LIST } from "@/lib/constants";

import GalleryDropdown from "./GalleryDropdown";
import { GALLERY_MAGE_INITIAL_VALUE } from "./constants";

const ThumbnailForm = ({ image, setValue, formState, gallery, getTakeAPI }) => {
  const imageRef = useRef();
  const contentRef = useRef();
  const [clicked, setClicked] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [editGalleryOpen, setEditGalleryOpen] = useState(false);

  const {
    handleSubmit,
    setValue: setImageValue,
    reset,
    watch,
  } = useForm({
    defaultValues: GALLERY_MAGE_INITIAL_VALUE,
    shouldValidate: true,
    shouldDirty: true,
    mode: "onblur",
  });

  useEffect(() => {
    reset(image);
  }, [image]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        imageRef.current &&
        !imageRef.current.contains(event.target) &&
        contentRef.current &&
        !contentRef.current.contains(event.target)
      ) {
        setClicked(false);
        setOpenInfo(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [imageRef, contentRef]);

  const hasContent = () => {
    return image?.caption?.length > 0 || image?.title?.length > 0 || clicked;
  };

  var settings = {
    infinite: false,
    speed: 300,
    customPaging: function (i) {
      return (
        <img
          src={gallery[i]?.attachment_url}
          className="h-14 w-14 object-cover rounded-sm cursor-pointer"
        />
      );
    },
    dots: true,
    dotsClass: "pb-slick-thumb",
    autoplay: true,
    arrows: false,
  };

  const updateAttachmentAPI = async () => {
    try {
      const { data } = await updateAttachment(formState.id, image.id, {
        attachment: {
          caption: watch().caption,
          title: watch().title,
          paid_content: watch().paid_content,
        },
      });

      reset(data.attachment);

      const updatedAttachments = formState.attachments_attributes.map((att) => {
        if (att.id === image.id) {
          return { ...data.attachment };
        } else {
          return { ...att };
        }
      });

      setValue("attachments_attributes", updatedAttachments);
      setEditGalleryOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const onCaptionChange = (value) => {
    setImageValue("caption", value);
  };

  const onTitleChange = (value) => {
    setImageValue("title", value);
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col items-center w-full px-6 md:px-10">
        <div
          className={classnames(
            "relative slider-container transition-all duration-300 cursor-pointer w-full max-w-[var(--take-width)] rounded",
            {
              "shadow-secondary-ring-inverted-bg": clicked,
            }
          )}
          onClick={() => setClicked(true)}
          ref={imageRef}
        >
          <Slider {...settings}>
            {gallery.map((image, index) => {
              return (
                <div
                  key={index}
                  className="rounded focus:outline-none focus-visible:outline-none overflow-hidden"
                >
                  <img
                    src={image.attachment_url}
                    className="w-full object-contain aspect-[3/2] focus:outline-none focus-visible:outline-none"
                  />
                </div>
              );
            })}
          </Slider>

          <div
            className={`absolute left-3 bottom-[90px] rounded-sm bg-inverted w-fit px-2 py-1 text-small shadow transition-all duration-200 ${
              hasContent()
                ? "opacity-0 invisible translate-y-3"
                : "opacity-100 visible translate-y-0"
            }`}
          >
            Click gallery to add title and caption
          </div>

          <div className="absolute left-3 top-3 rounded-sm bg-inverted w-[180px] md:w-[235px] px-2 py-1 shadow">
            <div className="flex flex-col w-full rounded-sm bg-inverted text-small">
              <div
                className="flex items-center gap-2 cursor-pointer font-bold"
                onClick={() => setOpenInfo(!openInfo)}
              >
                <PBIcons icon="Notice" size="18" />
                <span className="md:hidden">Not compatible</span>
                <span className="hidden md:block">
                  Not compatible with email
                </span>
                <PBIcons icon="ChevronDown" size="18" />
              </div>

              <AnimateHeight duration={300} height={openInfo ? "auto" : 0}>
                <p className="text-muted text-label md:text-small px-7 py-3">
                  When displayed in email, this gallery will default to the grid
                  gallery. Check the preview to see how your images will display
                  across different devices.
                </p>
              </AnimateHeight>
            </div>
          </div>

          <div className="absolute right-2 top-2">
            <GalleryDropdown
              takeId={formState.id}
              formState={watch()}
              setTakeValue={setValue}
              setImageValue={setImageValue}
              handleSave={handleSubmit(updateAttachmentAPI)}
              editGalleryOpen={editGalleryOpen}
              setEditGalleryOpen={setEditGalleryOpen}
              attachments={formState.attachments_attributes}
              getTakeAPI={getTakeAPI}
            />
          </div>
        </div>

        <div
          className="flex flex-col w-full max-w-[var(--take-width)]"
          ref={contentRef}
        >
          <AnimateHeight duration={300} height={hasContent() ? "auto" : 0}>
            <div className="flex flex-col gap-4 w-full mt-8">
              <TextEditor
                className="text-heading font-bold"
                placeholder="Give your gallery a title"
                content={image.title}
                setEditorContent={onTitleChange}
                onBlur={() => handleSubmit(updateAttachmentAPI())}
                extensionList={["TextAlign"]}
                onFocus={() => setClicked(true)}
              />

              <TextEditor
                placeholder="Write something about your gallery"
                content={image.caption}
                setEditorContent={onCaptionChange}
                onBlur={() => handleSubmit(updateAttachmentAPI())}
                extensionList={TAKE_EDITOR_EXTENSION_LIST}
                onFocus={() => setClicked(true)}
              />
            </div>
          </AnimateHeight>
        </div>
      </div>
    </div>
  );
};

export default ThumbnailForm;
