import React, { useState } from "react";
import classnames from "classnames";

import { PBIcons } from "@/components/PBIcons";
import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";

const DeviceMenu = ({ value, setValue, desktopDisabled }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const ACTIVE_MENU_ICONS = {
    desktop: <PBIcons icon="Desktop" />,
    mobile: <PBIcons icon="Mobile" />,
  };

  const handleClick = (type) => {
    setValue(type);
    setMenuOpen(false);
  };

  return (
    <Menu
      open={menuOpen}
      setOpen={setMenuOpen}
      width="200"
      position="bottom-end"
      style="transparent"
      borderRadius={false}
      menuTrigger={
        <div
          className="flex items-center gap-2 px-1 py-0.5"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {ACTIVE_MENU_ICONS[value]}

          <div
            className={classnames("pb-dropdown-trigger-icon", {
              active: menuOpen,
              inactive: !menuOpen,
            })}
          >
            <PBIcons icon="ChevronDown" />
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-1">
        <MenuItem
          icon={<PBIcons icon="Desktop" />}
          selected={value === "desktop"}
          onClick={() => (desktopDisabled ? null : handleClick("desktop"))}
          label="Desktop"
          helpText={desktopDisabled ? "Not available on this screen size." : ""}
          disabled={desktopDisabled}
        />

        <MenuItem
          icon={<PBIcons icon="Mobile" />}
          selected={value === "mobile"}
          onClick={() => handleClick("mobile")}
          label="Mobile"
        />
      </div>
    </Menu>
  );
};

export default DeviceMenu;
