import React, { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";

const Input = ({
  name,
  value,
  setValue,
  placeholder,
  handleSubmitWithStatus,
  ...otherProps
}) => {
  const [focused, setFocused] = useState(false);
  const debouncedValue = useDebounce(value, 500);
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    if (!initial) {
      handleSubmitWithStatus();
      setFocused(false);
    }
  }, [debouncedValue]);

  return (
    <input
      id={name}
      name={name}
      type="text"
      value={value}
      onChange={(e) => {
        setInitial(false);
        setValue(name, e.target.value);
      }}
      onFocus={() => setFocused(true)}
      placeholder={!focused && !value && placeholder}
      className="text-title lg:text-heading font-bold outline-none placeholder:text-disabled-background"
      autoComplete="off"
      {...otherProps}
    />
  );
};

export default Input;
