import React from "react";

const EmailFooter = ({ mobileView }) => {
  return (
    <div
      className={`flex flex-col items-center w-full pt-12 pb-6 ${
        mobileView ? "bg-primary-border" : ""
      }`}
    >
      <span>
        Forwarded this email? <span className="underline">Subscribe here</span>
      </span>
      <span className="underline">Unsubscribe</span>
    </div>
  );
};

export default EmailFooter;
