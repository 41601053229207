import axios from "axios";

const getSubscriptionsUrl = (id) => {
  if (id) {
    return `/api/v1/subscriptions/${id}`;
  }

  return `/api/v1/subscriptions`;
};

export const getSubscriptionList = (
  pageIndex,
  searchTerm,
  status,
  emailStatus
) => {
  // TODO: Build search query in backend
  let searchQuery = "";

  if (searchTerm?.length > 0)
    searchQuery = `search_term=${encodeURIComponent(searchTerm)}`;
  if (status?.length > 0) searchQuery += `&email_status=${status}`;
  if (emailStatus?.length > 0)
    searchQuery += `&last_email_status=${emailStatus}`;

  return axios.get(`${getSubscriptionsUrl()}?page=${pageIndex}&${searchQuery}`);
};

export const createSubscription = (payload) => {
  return axios.post(getSubscriptionsUrl(), payload);
};

export const updateSubscription = (subscriptionId, payload) => {
  return axios.put(getSubscriptionsUrl(subscriptionId), payload);
};

export const exportSubscriptionList = () => {
  return axios.get(`${getSubscriptionsUrl()}/export`);
};

export const importSubscriptionList = (payload) => {
  return axios.post(`${getSubscriptionsUrl()}/import`, payload);
};

export const downloadCsv = () => {
  return axios.get(`${getSubscriptionsUrl()}/csv_download`);
};
