import React from "react";

import LinkButton from "@/components/LinkButton";
import { JOIN_ROUTE } from "@/lib/routes";

const TakeFooter = ({ mobileView }) => {
  return (
    <footer className="flex items-center w-full pt-12">
      <div className="flex flex-col w-full bg-secondary text-inverted">
        <div className="flex justify-between items-center px-5 py-3.5">
          <span>PencilBooth</span>

          <div className="flex items-center gap-5">
            <span className={`${mobileView ? "hidden" : "hidden md:block"}`}>
              Want to get your own booth?
            </span>

            <LinkButton to={JOIN_ROUTE} label="Join Now" style="inverted" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default TakeFooter;
