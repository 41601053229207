import React from "react";
import { Link } from "react-router-dom";

import { PBIcons } from "@/components/PBIcons";

const SocialLink = ({ link, icon }) => {
  return (
    <Link
      to={link}
      className="flex justify-center items-center p-3 rounded-full border border-solid border-muted"
    >
      <div className="h-6 w-6 flex justify-center items-center">{icon}</div>
    </Link>
  );
};

const SocialLinks = ({ user }) => {
  return (
    <div className="flex flex-col items-center gap-y-4">
      <div className="flex justify-center flex-wrap gap-6">
        {user.facebook_url && (
          <SocialLink
            link={`https://facebook.com/${user.facebook_url}`}
            icon={<PBIcons icon="Facebook" size="24" />}
          />
        )}
        {user.instagram_url && (
          <SocialLink
            link={`https://instagram.com/${user.instagram_url}`}
            icon={<PBIcons icon="Instagram" size="24" />}
          />
        )}
        {user.you_tube_url && (
          <SocialLink
            link={`https://youtube.com/channel/${user.you_tube_url}`}
            icon={<PBIcons icon="Youtube" size="24" />}
          />
        )}
        {user.dribble_url && (
          <SocialLink
            link={`https://dribbble.com/${user.dribble_url}`}
            icon={<PBIcons icon="Dribble" size="24" />}
          />
        )}
        {user.twitter_url && (
          <SocialLink
            link={`https://twitter.com/${user.twitter_url}`}
            icon={<PBIcons icon="Twitter" size="24" />}
          />
        )}
        {user.behance_url && (
          <SocialLink
            link={`https://behance.net/${user.behance_url}`}
            icon={<PBIcons icon="Behance" size="24" />}
          />
        )}
        {user.threads_url && (
          <SocialLink
            link={`https://threads.net/${user.threads_url}`}
            icon={<PBIcons icon="Threads" size="24" />}
          />
        )}
        {user.tiktok_url && (
          <SocialLink
            link={`https://tiktok.com/${user.tiktok_url}`}
            icon={<PBIcons icon="Tiktok" size="24" />}
          />
        )}
        {user.linkedin_url && (
          <SocialLink
            link={`https://www.linkedin.com/in/${user.linkedin_url}`}
            icon={<PBIcons icon="Linkedin" size="24" />}
          />
        )}
        {user.mastodon_url && (
          <SocialLink
            link={`https://mastodon.social/${user.mastodon_url}`}
            icon={<PBIcons icon="Mastodon" size="24" />}
          />
        )}

        {user.bluesky_url && (
          <SocialLink
            link={`https://bsky.app/profile/${user.bluesky_url}`}
            icon={<PBIcons icon="Bluesky" size="24" />}
          />
        )}
      </div>
    </div>
  );
};

export default SocialLinks;
