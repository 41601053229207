import * as Yup from "yup";

import { MAX_STRING_LENGTH } from "@/lib/constants";

export const INITIAL_VALUE = {
  subject: "",
  subject_preview: "",
  update: false,
  draft: false,
  schedule: false,
  sent: false,
  scheduled_at: "",
  subscribers_type: "all",
  exclude_from_visually_archive: false,
};

export const VALIDATION_SCHEMA = Yup.object({
  subject: Yup.string()
    .required("Subject can't be blank.")
    .max(
      MAX_STRING_LENGTH,
      `Subject must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  subject_preview: Yup.string().max(
    MAX_STRING_LENGTH,
    `Preview must be at most ${MAX_STRING_LENGTH} characters.`
  ),
  draft: Yup.boolean(),
  schedule: Yup.boolean(),
  scheduled_at: Yup.mixed().when("schedule", {
    is: (val) => val == true,
    then: (schema) => schema.required("Schedule date can't be blank."),
    otherwise: (schema) => schema.notRequired(),
  }),
  subscribers_type: Yup.string()
    .required("Subscribers type can't be blank.")
    .max(
      MAX_STRING_LENGTH,
      `Subscribers type must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});
