import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@/components/Button";
import { PBIcons } from "@/components/PBIcons";
import { showToastrError } from "@/lib/commons";

import ConfirmationModal from "./ConfirmationModal";

const Navbar = ({
  formState,
  handleSubmitWithStatus,
  setShareModalOpen,
  setValue,
  updateTakeAPI,
  updateBtnLoader,
}) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const handleCancel = async () => {
    setValue("goToListPage", true);
    await handleSubmitWithStatus();
  };

  const handlePreview = () => {
    navigate(`/takes/${formState?.id}/preview`);
  };

  const handleEdit = () => {
    navigate(`/takes/${formState?.id}/send`);
  };

  const handleConfirmationCancel = () => {
    setModalOpen(false);
  };

  const handleConfirmationSubmit = async () => {
    setValue("sent_at", null);
    setValue("scheduled_at", null);
    setValue("draft", true);
    setValue("publish_type", "web_and_email");
    await updateTakeAPI();
    handleEdit();
  };

  return (
    <nav className="flex items-center w-full px-6 bg-inverted fixed z-[100] top-0 right-0 left-0">
      <div className="flex justify-between w-full gap-2.5 py-4">
        <div className="flex items-center gap-4">
          <Button
            label="Save and close"
            icon={<PBIcons icon="Close" />}
            style="inverted"
            labelClassName="hidden md:block"
            loading={updateBtnLoader}
            onClick={() => handleCancel()}
          />

          {!formState?.id && (
            <Button
              label="Discard"
              icon={<PBIcons icon="Delete" />}
              style="inverted"
              labelClassName="hidden md:block"
              className="!text-danger"
              onClick={() => navigate(`/takes`)}
            />
          )}
        </div>

        <div className="flex items-center gap-1 md:gap-4">
          {formState?.id && (
            <Button
              label="Preview"
              icon={<PBIcons icon="Eye" />}
              style="inverted"
              labelClassName="hidden md:block"
              onClick={() => handlePreview()}
            />
          )}

          {formState?.id && formState?.sent && (
            <Button
              label="Edit"
              onClick={() => {
                if (formState?.attachments_attributes.length > 0) {
                  handleEdit();
                } else {
                  showToastrError("Please add an image to proceed!");
                }
              }}
            />
          )}

          {formState?.id && !formState?.sent && !formState?.schedule && (
            <Button
              label="Send"
              onClick={() => {
                if (formState?.attachments_attributes.length > 0) {
                  setShareModalOpen(true);
                } else {
                  showToastrError("Please add an image to proceed!");
                }
              }}
            />
          )}
        </div>
      </div>

      <ConfirmationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleContinue={handleConfirmationSubmit}
        handleCancel={handleConfirmationCancel}
        btnLoader={updateBtnLoader}
      />
    </nav>
  );
};

export default Navbar;
