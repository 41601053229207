import React from "react";
import classNames from "classnames";

import { PBIcons } from "@/components/PBIcons";
import { swapElement } from "@/lib/utils";

const ImageCard = ({
  index,
  image,
  selectedImage,
  setSelectedImage,
  images,
  setImages,
  setDeleteImage,
}) => {
  const leftClickDisabled = () => {
    return index === 0;
  };

  const rightClickDisabled = () => {
    return index + 1 === images.length;
  };

  const swapGalleryAttachments = (destination) => {
    let newlyOrderedAttachments = swapElement(images, index, destination);
    setImages(newlyOrderedAttachments);
  };

  const handleDestroy = (serial) => {
    setImages(
      images.map((image) => {
        if (image.position === serial) {
          return { ...image, _destroy: 1 };
        } else {
          return image;
        }
      })
    );
  };

  return (
    <div
      className={`flex justify-center items-center aspect-square rounded-sm relative group transition-all duration-300 ${
        index == selectedImage ? "shadow-secondary-ring-primary-bg" : ""
      }`}
      onClick={() => {
        setSelectedImage(index);
      }}
    >
      <img
        src={image.file_data || image.attachment_url}
        className="w-full h-full object-cover rounded-sm"
      />

      <div
        className="absolute inset-0 hidden group-hover:flex flex-col items-center justify-center bg-black bg-opacity-30 text-primary backdrop-blur-sm rounded-sm"
        onClick={() => setSelectedImage(index)}
      >
        <div className="flex rounded-sm bg-inverted divide-x divide-inverted-hover border border-solid border-inverted-hover">
          <PBIcons
            icon="ArrowLeft"
            className={classNames("p-1 lg:p-2 cursor-pointer", {
              "text-muted !cursor-not-allowed": leftClickDisabled(),
            })}
            onClick={() => {
              if (!leftClickDisabled()) {
                swapGalleryAttachments(index - 1);
              }
            }}
          />
          <PBIcons
            icon="ArrowRight"
            className={classNames("p-1 lg:p-2 cursor-pointer", {
              "text-muted !cursor-not-allowed": rightClickDisabled(),
            })}
            onClick={() => {
              if (!rightClickDisabled()) {
                swapGalleryAttachments(index + 1);
              }
            }}
          />
          {images.filter((img) => img._destroy !== 1).length != 1 && (
            <PBIcons
              icon="Delete"
              className="p-1 lg:p-2 cursor-pointer"
              onClick={() => {
                setDeleteImage(index);
                handleDestroy(image.position);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
