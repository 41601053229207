import axios from "axios";

const getDisciplinesUrl = (id) => {
  if (id) {
    return `/api/v1/super_admin/disciplines/${id}`;
  }

  return `/api/v1/super_admin/disciplines`;
};

export const getDisciplines = (pageIndex, searchTerm) => {
  let searchQuery = "";

  if (searchTerm?.length > 0) searchQuery = `search=${searchTerm}`;

  return axios.get(`${getDisciplinesUrl()}?page=${pageIndex}&${searchQuery}`);
};

export const getDiscipline = (disciplineId) => {
  return axios.get(getDisciplinesUrl(disciplineId));
};

export const createDiscipline = (payload) => {
  return axios.post(getDisciplinesUrl(), payload);
};

export const updateDiscipline = (disciplineId, payload) => {
  return axios.put(getDisciplinesUrl(disciplineId), payload);
};

export const destroyDiscipline = (disciplineId) => {
  return axios.delete(getDisciplinesUrl(disciplineId));
};
