import React, { useEffect } from "react";
import classnames from "classnames";

import { PBIcons } from "@/components/PBIcons";

const Modal = ({
  open,
  onClose,
  size = "small",
  title,
  hideClose = false,
  children,
  className,
}) => {
  // useEffect(() => {
  //   if (open) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [open]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const SIZE_STYLES = {
    small: "w-[420px]",
    medium: "w-[540px]",
    large: "w-screen lg:w-[900px]",
    full: "w-screen h-dvh",
    planModal: "w-screen lg:w-[928px]",
  };

  return (
    <div
      onClick={onClose}
      className={classnames(
        "fixed inset-0 z-[100] flex justify-center items-center transition-colors",
        {
          "visible bg-white bg-opacity-90": open,
        },
        { invisible: !open },
        className
      )}
    >
      <div
        className={classnames(
          "bg-primary text-inverted rounded-sm shadow p-10 max-h-[calc(100dvh-48px)] overflow-y-auto transition-all m-6",
          SIZE_STYLES[size],
          `${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {!hideClose && (
          <button
            onClick={onClose}
            className="absolute top-3 right-3 p-1 rounded-sm bg-transparent hover:bg-primary-hover transition-all"
          >
            <PBIcons icon="Close" />
          </button>
        )}

        <div className="flex flex-col w-full gap-7">
          {!!title && <h4 className="text-title font-bold">{title}</h4>}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
