import React, { useState, useEffect, useRef } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import Flatpickr from "react-flatpickr";
import dayjs from "dayjs";

import { getUsers } from "@/apis/super_admin/users";
import { showToastrError } from "@/lib/commons";
import Pagination from "@/components/Pagination";
import Loader from "@/components/Loader";
import BlankState from "@/components/BlankState";
import Input from "@/components/Input";
import { SEARCH_DATE_FORMAT } from "@/lib/constants";

import Table from "./Table";

const UserPage = () => {
  const flatpickr = useRef(null);
  const [userList, setUserList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [searchNumber, setSearchNumber] = useState();
  const [minSearchDate, setMinSearchDate] = useState();
  const [maxSearchDate, setMaxSearchDate] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const debouncedSearchNumber = useDebounce(searchNumber, 500);
  const [initial, setInitial] = useState(true);

  const flatPickrOption = {
    mode: "range",
    dateFormat: "Y-m-d",
    wrap: true,
    allowInput: true,
    disableMobile: true,
  };

  useEffect(() => {
    if (pageIndex) {
      getUserListAPI();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (!initial) {
      getUserListAPI();
    }
  }, [minSearchDate, maxSearchDate]);

  useEffect(() => {
    if (debouncedSearchTerm || debouncedSearchTerm === "") {
      getUserListAPI();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (debouncedSearchNumber || debouncedSearchNumber === "") {
      getUserListAPI();
    }
  }, [debouncedSearchNumber]);

  const getUserListAPI = async () => {
    try {
      let minDate =
        minSearchDate && dayjs(minSearchDate).format(SEARCH_DATE_FORMAT);
      let maxDate =
        maxSearchDate && dayjs(maxSearchDate).format(SEARCH_DATE_FORMAT);
      const { data } = await getUsers(
        pageIndex,
        searchTerm,
        searchNumber,
        minDate,
        maxDate
      );
      setUserList(data.users);
      setTotalRecords(data.total_records);
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const clearDate = () => {
    flatpickr.current.flatpickr.clear();
    setMaxSearchDate();
    setMinSearchDate();
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-settings-page-wrapper bg-inverted-hover">
      <div className="pb-settings-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-6 md:gap-10 w-full max-w-[var(--layout-max-width)]">
          <h1 className="text-heading font-bold">Users</h1>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
            <Input
              placeholder="Search for name, email, url"
              value={searchTerm}
              onChange={(e) => {
                setPageIndex(1);
                setSearchTerm(e.target.value);
              }}
              bodyBackground="inverted-hover"
            />

            <Input
              placeholder="Search for subscibers or takes number"
              value={searchNumber}
              onChange={(e) => {
                setPageIndex(1);
                setSearchNumber(e.target.value);
              }}
              bodyBackground="inverted-hover"
            />

            <Flatpickr
              ref={flatpickr}
              data-input
              options={flatPickrOption}
              onValueUpdate={([date1, date2]) => {
                setInitial(false);
                setPageIndex(1);
                setMinSearchDate(date1);
                setMaxSearchDate(date2);
              }}
            >
              <Input
                data-input
                name="date-range"
                id="date-range"
                placeholder="Enter last take sent date range"
                bodyBackground="inverted-hover"
                onChange={(e) => {
                  if (e.target.value == "") {
                    setMinSearchDate();
                    setMaxSearchDate();
                  }
                }}
              />

              <button type="button" onClick={() => clearDate()}>
                Clear
              </button>
            </Flatpickr>
          </div>

          {userList.length > 0 ? (
            <Table users={userList} />
          ) : (
            <BlankState>
              <div className="flex flex-col items-center gap-4 w-full">
                <h5 className="text-title text-center">
                  There are no users to show.
                </h5>
              </div>
            </BlankState>
          )}

          <Pagination
            count={totalRecords}
            pageSize={10}
            pageNo={pageIndex}
            navigate={setPageIndex}
          />
        </div>
      </div>
    </div>
  );
};

export default UserPage;
