import React, { useState } from "react";

import { PBIcons } from "@/components/PBIcons";
import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";

import LogoHeader from "./LogoHeader";
import CoverHeader from "./CoverHeader";
import Dropdown from "./Dropdown";

const Banner = ({
  formState,
  setValue,
  handleSubmitWithStatus,
  ...otherProps
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userTitleOpen, setUserTitleOpen] = useState(
    formState.user_title?.length > 0
  );

  const onTitleChange = (value) => {
    setValue("user_title", value);
  };

  const handleDelete = async () => {
    setValue("user_title", null);
    await handleSubmitWithStatus();
    setUserTitleOpen(false);
    setMenuOpen(false);
  };

  return (
    <div className="flex flex-col items-center w-full max-w-[var(--take-width)]">
      <div className="flex flex-col items-center gap-6 w-full relative">
        {formState.header_image_type == "logo" ? (
          <LogoHeader />
        ) : (
          <CoverHeader formState={formState} setValue={setValue} />
        )}

        <div
          className={`absolute ${
            formState.header_image_type == "logo"
              ? "right-0 top-0"
              : "right-2 top-2"
          }`}
        >
          <Dropdown formState={formState} setValue={setValue} {...otherProps} />
        </div>

        {userTitleOpen ? (
          <div className="relative w-full px-16">
            <input
              className="w-full outline-none placeholder:text-disabled focus:shadow-none text-heading font-bold text-center"
              placeholder="Give your take a title"
              maxLength={255}
              value={formState.user_title}
              onChange={(e) => onTitleChange(e.target.value)}
              onBlur={handleSubmitWithStatus}
            />

            <div className="absolute right-0 top-0">
              <Menu open={menuOpen} setOpen={setMenuOpen} width="200">
                <MenuItem
                  label="Delete"
                  icon={<PBIcons icon="Delete" />}
                  onClick={() => handleDelete()}
                />
              </Menu>
            </div>
          </div>
        ) : (
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => setUserTitleOpen(true)}
          >
            <PBIcons icon="Plus" />
            <span>Add title (optional)</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
