import React, { useState } from "react";

import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";
import { PBIcons } from "@/components/PBIcons";

const ItemMenu = ({
  handleUpdate,
  handleCancel,
  subscription,
  handleUnsubscribe,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Menu open={open} setOpen={setOpen} width="220" style="transparent">
      {subscription.plan_type !== "Free" && !subscription.plan_ending && (
        <>
          <MenuItem label="Update payment details" onClick={handleUpdate} />
          <MenuItem label="Cancel subscription" onClick={handleCancel} />
        </>
      )}

      {(subscription.plan_type === "Free" ||
        (subscription.plan_type !== "Free" && subscription.plan_ending)) && (
        <MenuItem label="Unsubscribe" onClick={handleUnsubscribe} />
      )}
    </Menu>
  );
};

export default ItemMenu;
