import React, { useState } from "react";

import { getLinkRollsPreview, updateLinkRoll } from "@/apis/link_rolls";
import { showToastrError } from "@/lib/commons";
import { isValidUrl, withoutProtocol } from "@/lib/utils";
import Input from "@/components/Input";
import Button from "@/components/Button";

const EditMenu = ({ setOpen, formState, setValue, linkRoll }) => {
  const [link, setLink] = useState(linkRoll.link);
  const [linkError, setLinkError] = useState();
  const [btnLoader, setBtnLoader] = useState(false);

  const getLinkRollsPreviewAPI = async () => {
    try {
      setBtnLoader(true);

      let website = link;
      if (withoutProtocol(website)) website = `http://${website}`;

      const {
        data: { link_rolls_preview },
      } = await getLinkRollsPreview(formState.id, website);

      let payload = {
        link: link_rolls_preview.link,
        title: link_rolls_preview.title,
        description: link_rolls_preview.description,
        image_src: link_rolls_preview.image_src,
        username: link_rolls_preview.username,
        instagram_post: link_rolls_preview.instagram_post,
        twitter_post: link_rolls_preview.twitter_post,
      };

      const { data } = await updateLinkRoll(formState.id, linkRoll.id, {
        link_roll: payload,
      });

      const newArray = formState.link_rolls_attributes.map((el) => {
        if (el.id == linkRoll.id) {
          return { ...data.link_roll };
        } else {
          return { ...el };
        }
      });

      setValue("link_rolls_attributes", newArray);
      setOpen(false);
    } catch (error) {
      showToastrError(error.response.data.message);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 w-full px-2">
      <form
        id="edit-b-link-form"
        className="flex flex-col w-full gap-y-3"
        onSubmit={(e) => {
          e.preventDefault();
          if (!isValidUrl(link)) {
            setLinkError("Link is not valid.");
          } else {
            setLinkError("");
            getLinkRollsPreviewAPI();
          }
        }}
      >
        <Input
          label="Link"
          id="link"
          name="link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="Enter a link"
          error={linkError}
          bodyBackground="primary"
          style="primary"
        />
      </form>

      <div className="flex gap-4">
        <Button
          form="edit-b-link-form"
          type="submit"
          label="Update"
          loading={btnLoader}
          style="inverted"
          bodyBackground="primary"
        />

        <Button
          type="button"
          style="transparent"
          label="Cancel"
          onClick={() => setOpen(false)}
          bodyBackground="primary"
        />
      </div>
    </div>
  );
};

export default EditMenu;
