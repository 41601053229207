// Routes
export const ROOT_ROUTE = "/";
export const DISCOVERIES_ROUTE = "/discoveries";
export const LOGIN_ROUTE = "/login";
export const JOIN_ROUTE = "/join";
export const ACCOUNT_SETTINGS_ROUTE = "/settings/account";
export const PROFILE_SETTINGS_ROUTE = "/settings/profile";
export const BRANDING_SETTINGS_ROUTE = "/settings/branding";
export const BILLING_SETTINGS_ROUTE = "/settings/billing";
export const EMBED_SETTINGS_ROUTE = "/settings/embeded";
export const PAID_SUBSCRIPTION_ROUTE = "/settings/paid_subscription";
export const PAID_BOOTH_ROUTE = "/settings/paid_booths";
export const SEARCH_ROUTE = "/search";
export const ABOUT_ROUTE = "/about";
export const PRIVACY_ROUTE = "/privacy";
export const TERMS_ROUTE = "/terms";
export const PRIVACY_PRO_FORMA_ROUTE = "/privacy_pro_forma";
export const SUBSCRIBER_ROUTE = "/subscribers";
export const PREVIOUS_TAKE_ROUTE = "/takes";
export const TAKE_CREATE_ROUTE = "/takes/new";
export const TAKE_DETAILS_ROUTE = "/takes/:takeId";
export const TAKE_EDIT_ROUTE = "/takes/:takeId/edit";
export const TAKE_PREVIEW_ROUTE = "/takes/:takeId/preview";
export const TAKE_SEND_ROUTE = "/takes/:takeId/send";
export const ARCHIVE_ROUTE = "/archive/:urlSlug";
export const REVIEW_TAKES_ADMIN_ROUTE = "/super_admin/review_takes";
export const NON_FEATURED_TAKES_ADMIN_ROUTE = "/super_admin/non_featured_takes";
export const USERS_ADMIN_ROUTE = "/super_admin/users";
export const USERS_EDIT_ADMIN_ROUTE = "/super_admin/users/:id";
export const DISCIPLINES_ADMIN_ROUTE = "/super_admin/disciplines";
export const DEFAULT_SETTINGS_ADMIN_ROUTE = "/super_admin/default_settings";
export const SUBSCRIBER_FORM_ROUTE = "/:urlSlug";
export const NOT_FOUND_ROUTE = "/not_found";
