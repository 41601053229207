import React from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/hooks/useAuth";
import Button from "@/components/Button";
import { PBIcons } from "@/components/PBIcons";

import DeviceMenu from "./DeviceMenu";
import RenderMenu from "./RenderMenu";
import SubscriberMenu from "./SubscriberMenu";

const Navbar = ({
  take,
  deviceType,
  setDeviceType,
  renderType,
  setRenderType,
  subscriberType,
  setSubscriberType,
  desktopDisabled,
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleCancel = () => {
    navigate(`/takes/${take.id}/edit`);
  };

  return (
    <nav className="flex items-center w-full px-6 bg-inverted fixed z-[100] top-0 right-0 left-0">
      <div className="flex justify-between w-full gap-2.5 py-4">
        <div className="flex items-center gap-4">
          <Button
            label={
              <div className="flex items-center gap-1">
                <PBIcons icon="Back" />
                <span className="hidden md:block">Continue editing</span>
              </div>
            }
            style="inverted"
            onClick={handleCancel}
          />
        </div>

        <div className="flex divide-x-[1px] divide-x-primary-border rounded-sm border border-solid border-primary-border">
          <DeviceMenu
            value={deviceType}
            setValue={setDeviceType}
            desktopDisabled={desktopDisabled}
          />
          <RenderMenu value={renderType} setValue={setRenderType} />
          {user.paid_subscription_enabled && (
            <SubscriberMenu
              value={subscriberType}
              setValue={setSubscriberType}
            />
          )}
        </div>

        <div className="hidden md:flex items-center gap-1 md:gap-4">
          <Button label="Close preview" onClick={handleCancel} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
