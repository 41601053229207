import React, { useState } from "react";
import AnimateHeight from "react-animate-height";

import { PBIcons } from "@/components/PBIcons";

const Accordion = ({ title, children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className="w-full mt-6 pb-6 border-b border-solid border-primary-border cursor-pointer"
      onClick={() => setVisible(!visible)}
    >
      <div className="flex justify-between items-start gap-2">
        <h5 className="font-bold">{title}</h5>
        <PBIcons
          icon={visible ? "Minus" : "Plus"}
          className="shrink-0 cursor-pointer"
        />
      </div>

      <AnimateHeight duration={300} height={visible ? "auto" : 0}>
        <div className="mt-6">{children}</div>
      </AnimateHeight>
    </div>
  );
};

export default Accordion;
