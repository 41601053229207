import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { MAX_STRING_LENGTH, MAX_SLUG_LENGTH } from "@/lib/constants";
import { USERS_ADMIN_ROUTE } from "@/lib/routes";
import { getUser, updateUser, destroyUser } from "@/apis/super_admin/users";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { PBIcons } from "@/components/PBIcons";
import Loader from "@/components/Loader";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";
import Switch from "@/components/Switch";

import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";

const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState();
  const [pageLoader, setPageLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const [emailAdded, setEmailAdded] = useState(false);

  const { handleSubmit, formState, setValue, reset, watch } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: INITIAL_VALUE,
    shouldValidate: true,
    shouldDirty: true,
    mode: "onChange",
  });

  useEffect(() => {
    if (id) {
      getUserAPI();
    }
  }, [id]);

  useEffect(() => {
    if (textCopied) {
      showToastrSuccess("Text copied to clipboard!");
      setTextCopied(false);
    }
  }, [textCopied]);

  const getUserAPI = async () => {
    try {
      const { data } = await getUser(id);
      setUser({ ...user, ...data.user });
      reset(data.user);
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateUserResponse = async (formState) => {
    try {
      setBtnLoader(true);
      const emailAddedCount =
        formState.paid_email_count - user.paid_email_count;
      const { data } = await updateUser(user.id, {
        user: {
          name: formState.name,
          display_name: formState.display_name,
          url_slug: formState.url_slug,
          vip: formState.vip,
          featured: formState.featured,
          under_review: formState.under_review,
          paid_email_count: formState.paid_email_count,
          connect_application_fee_percentage:
            formState.connect_application_fee_percentage,
          email_usage_logs_attributes: emailAdded
            ? [
                {
                  email_count:
                    emailAddedCount > 0 ? emailAddedCount : -emailAddedCount,
                  email_type: "paid",
                  transaction_type: emailAddedCount > 0 ? "credit" : "debit",
                },
              ]
            : null,
        },
      });
      await getUserAPI();
      showToastrSuccess(data.message);
      setEmailAdded(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const destroyUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await destroyUser(user.id);
      setDeleteModal(false);
      showToastrSuccess(data.message);
      navigate(USERS_ADMIN_ROUTE, { replace: true });
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-settings-page-wrapper bg-inverted-hover">
      <div className="pb-settings-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-12 w-full max-w-3xl">
          <form
            className="flex flex-col items-end gap-6 w-full max-w-md mx-auto"
            onSubmit={handleSubmit(updateUserResponse)}
          >
            <Input
              id="name"
              label="Name"
              name="name"
              placeholder="Name"
              helpText="This is the name of your booth that your subscribers will see."
              value={watch().name}
              onChange={(e) => setValue("name", e.target.value)}
              error={formState.errors.name?.message}
              bodyBackground="inverted-hover"
              maxLength={MAX_STRING_LENGTH}
              required
            />

            <Input
              id="display_name"
              label="Display Name"
              name="display_name"
              placeholder="Display Name"
              helpText="This is the display name of your booth that your subscribers will see."
              value={watch().display_name}
              onChange={(e) => setValue("display_name", e.target.value)}
              error={formState.errors.display_name?.message}
              bodyBackground="inverted-hover"
              maxLength={MAX_STRING_LENGTH}
              required
            />

            <Input
              id="email"
              label="Email"
              name="email"
              placeholder="email"
              helpText="This is the email of your booth that your subscribers will see."
              value={watch().email}
              onChange={(e) => setValue("email", e.target.value)}
              error={formState.errors.email?.message}
              bodyBackground="inverted-hover"
              disabled={true}
            />

            <Input
              id="url_slug"
              label="URL Slug"
              name="url_slug"
              placeholder="URL Slug"
              helpText="This is the URL people can subscribe to your booth."
              value={watch().url_slug}
              onChange={(e) => {
                setValue("url_slug", e.target.value);
              }}
              error={formState.errors.url_slug?.message}
              bodyBackground="inverted-hover"
              maxLength={MAX_SLUG_LENGTH}
              required
            />

            <Input
              id="personal_url"
              label="Personal URL"
              name="personal_url"
              placeholder="Please enter full URL, including https://"
              helpText="Enter a link to an external site where people can find out more about you and your work. This goes at the footer of your takes and is separate to your bio and social links in your booth."
              value={watch().personal_url}
              onChange={(e) => {
                setValue("personal_url", e.target.value);
              }}
              error={formState.errors.personal_url?.message}
              bodyBackground="inverted-hover"
              disabled={true}
            />

            <Input
              type="number"
              id="paid_email_count"
              min={0}
              step={1}
              label="Paid Email Count"
              name="paid_email_count"
              placeholder="Please enter email credit"
              helpText="Enter email credit to be added in this booth."
              value={watch().paid_email_count}
              onChange={(e) => {
                setEmailAdded(true);
                setValue("paid_email_count", e.target.value);
              }}
              error={formState.errors.paid_email_count?.message}
              bodyBackground="inverted-hover"
            />

            <Input
              type="number"
              id="connect_application_fee_percentage"
              min={0}
              step={0.01}
              label="Connect Application Fee Percentage"
              name="connect_application_fee_percentage"
              placeholder="Please enter percentage"
              helpText="Enter application fee percentage to be added in this booth for paid subscription."
              value={watch().connect_application_fee_percentage}
              onChange={(e) => {
                setValue("connect_application_fee_percentage", e.target.value);
              }}
              error={
                formState.errors.connect_application_fee_percentage?.message
              }
              bodyBackground="inverted-hover"
            />

            <Switch
              id="vip"
              name="vip"
              label="VIP"
              helpText="Activate this if the Organisation is a VIP and will not be curbed by the constraints of capital. They will not be asked to input their Credit Card details. However, if they are already subscribed, this does not change that."
              checked={watch().vip}
              onChange={() => setValue("vip", !watch().vip)}
              bodyBackground="inverted-hover"
            />

            <Switch
              id="under_review"
              name="under_review"
              label="Under Review"
              helpText="Toggle off the switch when account review is done."
              checked={watch().under_review}
              onChange={() => setValue("under_review", !watch().under_review)}
              bodyBackground="inverted-hover"
            />

            <Switch
              id="featured"
              name="featured"
              label="Featured"
              helpText="Activate this if this booth needs to be featured in featured booth page."
              checked={watch().featured}
              onChange={() => setValue("featured", !watch().featured)}
              bodyBackground="inverted-hover"
            />

            {user.stripe_customer_id && (
              <div className="flex flex-col gap-2 w-full">
                <p className="text-label">
                  Use Stripe Customer ID to search for any associated Customers
                  in Stripe.
                </p>

                {user.stripe_customer_id && (
                  <div className="flex items-center gap-2">
                    <span>{user.stripe_customer_id}</span>
                    <CopyToClipboard
                      text={user.stripe_customer_id}
                      onCopy={() => setTextCopied(true)}
                    >
                      <PBIcons
                        icon="Clipboard"
                        size={16}
                        className="cursor-pointer"
                      />
                    </CopyToClipboard>
                  </div>
                )}
              </div>
            )}

            <Button
              type="submit"
              label="Save changes"
              loading={btnLoader}
              bodyBackground="inverted-hover"
            />
          </form>

          <div className="flex flex-col w-full gap-5 bg-inverted border border-solid border-inverted-hover rounded-sm p-5">
            <h4 className="text-title">Delete account</h4>

            <p className="text-small">
              By deleting PencilBooth account, all data is removed from our
              system and cannot be recovered.
            </p>

            <Button
              style="danger"
              label="Delete account"
              onClick={() => setDeleteModal(true)}
            />
          </div>
        </div>

        <Modal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          title="Delete your account"
        >
          <div className="flex flex-col w-full gap-10">
            <div className="flex flex-col w-full gap-6">
              Are you sure you want to delete the account?
            </div>

            <div className="flex gap-4">
              <Button
                type="submit"
                style="danger"
                label="Delete away!"
                loading={btnLoader}
                onClick={() => destroyUserResponse()}
                bodyBackground="primary"
              />

              <Button
                label="Cancel"
                style="transparent"
                onClick={() => setDeleteModal(false)}
                bodyBackground="primary"
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default EditUser;
