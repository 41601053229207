import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "@/hooks/useAuth";
import { updateUser } from "@/apis/users";
import { showToastrSuccess, showToastrError } from "@/lib/commons";

import Modal from "@/components/Modal";
import Button from "@/components/Button";
import Input from "@/components/Input";

import { SOCIAL_LINKS_INITIAL_VALUE } from "./constants";

const SocialLinkModal = ({
  socialModalOpen,
  setSocialModalOpen,
  getUserAPI,
}) => {
  const { user } = useAuth();
  const [btnLoader, setBtnLoader] = useState(false);
  const { handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: SOCIAL_LINKS_INITIAL_VALUE,
  });

  useEffect(() => {
    reset({
      facebook_url: user.facebook_url,
      instagram_url: user.instagram_url,
      you_tube_url: user.you_tube_url,
      twitter_url: user.twitter_url,
      dribble_url: user.dribble_url,
      behance_url: user.behance_url,
      threads_url: user.threads_url,
      tiktok_url: user.tiktok_url,
      mastodon_url: user.mastodon_url,
      linkedin_url: user.linkedin_url,
      bluesky_url: user.bluesky_url,
    });
  }, [user]);

  const updateUserResponse = async (formState) => {
    try {
      setBtnLoader(true);
      const { data } = await updateUser(user.id, {
        user: {
          facebook_url: formState.facebook_url,
          instagram_url: formState.instagram_url,
          you_tube_url: formState.you_tube_url,
          twitter_url: formState.twitter_url,
          dribble_url: formState.dribble_url,
          behance_url: formState.behance_url,
          threads_url: formState.threads_url,
          tiktok_url: formState.tiktok_url,
          mastodon_url: formState.mastodon_url,
          linkedin_url: formState.linkedin_url,
          bluesky_url: formState.bluesky_url,
        },
      });
      showToastrSuccess(data.message);
      getUserAPI();
      setSocialModalOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };
  return (
    <Modal
      size="large"
      open={socialModalOpen}
      onClose={() => setSocialModalOpen(false)}
      title="Social Links"
    >
      <div className="flex flex-col w-full gap-10">
        <p className="pb-4 border-b border-solid border-primary-hover">
          Enter the URL for your other social accounts and they will
          automatically appear along with their respective icon at the footer of
          your booth. Anything left blank will not appear.
        </p>

        <form
          id="social-link-form"
          onSubmit={handleSubmit(updateUserResponse)}
          className="flex flex-col w-full gap-6"
        >
          <Input
            id="facebook_url"
            name="facebook_url"
            label="Facebook URL"
            className="bg-inverted-hover"
            value={watch().facebook_url}
            onChange={(e) => setValue("facebook_url", e.target.value)}
            prefix="https://facebook.com/"
            bodyBackground="primary"
            style="primary"
          />
          <Input
            id="instagram_url"
            name="instagram_url"
            label="Instagram URL"
            className="bg-inverted-hover"
            value={watch().instagram_url}
            onChange={(e) => setValue("instagram_url", e.target.value)}
            prefix="https://instagram.com/"
            bodyBackground="primary"
            style="primary"
          />
          <Input
            id="you_tube_url"
            name="you_tube_url"
            label="Youtube URL"
            className="bg-inverted-hover"
            value={watch().you_tube_url}
            onChange={(e) => setValue("you_tube_url", e.target.value)}
            prefix="https://youtube.com/channel/"
            bodyBackground="primary"
            style="primary"
          />
          <Input
            id="twitter_url"
            name="twitter_url"
            label="X URL"
            className="bg-inverted-hover"
            value={watch().twitter_url}
            onChange={(e) => setValue("twitter_url", e.target.value)}
            prefix="https://twitter.com/"
            bodyBackground="primary"
            style="primary"
          />
          <Input
            id="dribble_url"
            name="dribble_url"
            label="Dribble URL"
            className="bg-inverted-hover"
            value={watch().dribble_url}
            onChange={(e) => setValue("dribble_url", e.target.value)}
            prefix="https://dribbble.com/"
            bodyBackground="primary"
            style="primary"
          />
          <Input
            id="behance_url"
            name="behance_url"
            label="Behance URL"
            className="bg-inverted-hover"
            value={watch().behance_url}
            onChange={(e) => setValue("behance_url", e.target.value)}
            prefix="https://behance.net/"
            bodyBackground="primary"
            style="primary"
          />
          <Input
            id="threads_url"
            name="threads_url"
            label="Threads URL"
            className="bg-inverted-hover"
            value={watch().threads_url}
            onChange={(e) => setValue("threads_url", e.target.value)}
            prefix="https://threads.net/"
            bodyBackground="primary"
            style="primary"
          />
          <Input
            id="tiktok_url"
            name="tiktok_url"
            label="Tiktok URL"
            className="bg-inverted-hover"
            value={watch().tiktok_url}
            onChange={(e) => setValue("tiktok_url", e.target.value)}
            prefix="https://tiktok.com/"
            bodyBackground="primary"
            style="primary"
          />
          <Input
            id="linkedin_url"
            name="linkedin_url"
            label="Linkedin URL"
            className="bg-inverted-hover"
            value={watch().linkedin_url}
            onChange={(e) => setValue("linkedin_url", e.target.value)}
            prefix="https://www.linkedin.com/in/"
            bodyBackground="primary"
            style="primary"
          />
          <Input
            id="mastodon_url"
            name="mastodon_url"
            label="Mastodon URL"
            className="bg-inverted-hover"
            value={watch().mastodon_url}
            onChange={(e) => setValue("mastodon_url", e.target.value)}
            prefix="https://mastodon.social/"
            bodyBackground="primary"
            style="primary"
          />
          <Input
            id="bluesky_url"
            name="bluesky_url"
            label="Bluesky URL"
            className="bg-inverted-hover"
            value={watch().bluesky_url}
            onChange={(e) => setValue("bluesky_url", e.target.value)}
            prefix="https://bsky.app/profile/"
            bodyBackground="primary"
            style="primary"
          />
        </form>

        <div className="flex gap-4">
          <Button
            type="submit"
            form="social-link-form"
            label="Save changes"
            style="inverted"
            loading={btnLoader}
            bodyBackground="primary"
          />
          <Button
            type="button"
            label="Cancel"
            style="transparent"
            onClick={() => setSocialModalOpen(false)}
            bodyBackground="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default SocialLinkModal;
