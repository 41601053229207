import React from "react";
import GreenTick from "@/assets/images/green-tick.png";

const SubscribeAcknowledgement = () => {
  return (
    <div className="flex flex-col justify-center items-center h-dvh">
      <img src={GreenTick} className="mb-3" />
      <h3>You have been successfully subscribed to the user booth!</h3>
    </div>
  );
};

export default SubscribeAcknowledgement;
