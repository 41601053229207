import axios from "axios";

export const getUsers = (
  searchTerm,
  searchLocationTerm,
  searchDisciplineTerm
) => {
  let searchQuery = "";
  if (searchTerm?.length > 0) searchQuery += `booth_search_term=${searchTerm}`;
  if (searchLocationTerm?.length > 0)
    searchQuery += `&location_name=${searchLocationTerm}`;
  if (searchDisciplineTerm?.length > 0)
    searchQuery += `&discipline_name=${searchDisciplineTerm}`;
  return axios.get(`/api/v1/searches?${searchQuery}`);
};

export const getUserPreview = () => {
  return axios.get("/api/v1/preview_users");
};

export const getUserDisciplines = () => {
  return axios.get("/api/v1/searches/disciplines");
};

export const getUserLocations = () => {
  return axios.get("/api/v1/searches/locations");
};
