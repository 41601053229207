import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import {
  getTake,
  createTake,
  updateTake,
  fetchStripeSubscription,
} from "@/apis/takes";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { useAuth } from "@/hooks/useAuth";
import Loader from "@/components/Loader";
import { PREVIOUS_TAKE_ROUTE } from "@/lib/routes";
import { TAKE_EDITOR_EXTENSION_LIST } from "@/lib/constants";
import TextEditor from "@/components/TextEditor";

import Notifications from "./Notifications";
import Navbar from "./Navbar";
import Title from "./Title";
import Banner from "./Banner";
import GalleryUploader from "./GalleryUploader";
import ImageCard from "./ImageCard";
import ThumbnailCard from "./ThumbnailCard";
import GridCard from "./GridCard";
import LinkRoll from "./LinkRoll";
import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";
import ShareModal from "./ShareModal";

const CreateTakePage = () => {
  dayjs.extend(utc);
  dayjs.extend(advancedFormat);

  const { takeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser } = useAuth();
  const [takeDetail, setTakeDetail] = useState();
  const [pageLoader, setPageLoader] = useState(true);
  const [takeDetailLoad, setTakeDetailLoad] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [updateBtnLoader, setUpdateBtnLoader] = useState(false);
  const [titleModalOpen, setTitleModalOpen] = useState();

  const { handleSubmit, formState, setValue, reset, watch } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: INITIAL_VALUE,
    shouldValidate: true,
    shouldDirty: true,
    mode: "onChange",
  });

  useEffect(() => {
    getSubscriptionAPI();
  }, []);

  useEffect(() => {
    if (takeId) {
      if (location.state?.pageLoad !== false) setTakeDetailLoad(true);
      getTakeAPI();
    }
  }, [takeId]);

  useEffect(() => {
    if (takeDetail?.draft) {
      setValue("drafted_at", dayjs().utc().format());
    }
  }, [takeDetail]);

  const getSubscriptionAPI = async () => {
    try {
      const { data } = await fetchStripeSubscription();
      setUser((user) => {
        return {
          ...user,
          takes_count: data.takes_count,
          free_subscriber_size: data.free_subscriber_size,
          paid_subscriber_size: data.paid_subscriber_size,
          free_email_count: data.user_free_email_count,
          paid_email_count: data.user_paid_email_count,
        };
      });

      if (!takeId) {
        setValue(
          "title",
          `${user.display_name} - PencilBooth Take #${data.takes_count + 1}`
        );
        setValue("user_title", user.display_name);
      }

      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getTakeAPI = async (id = takeId) => {
    try {
      // TODO: Why need to send true in the api call
      const { data } = await getTake(id, true);
      setTakeDetail(data.take);
      reset(data.take);

      if (data.take.non_editable) {
        navigate(PREVIOUS_TAKE_ROUTE);
        showToastrError("You can't edit this take.");
      }

      setTakeDetailLoad(false);
    } catch (error) {
      if (error.response.status === 404) navigate("/not_found");
      showToastrError(error.response.data.errors);
    }
  };

  const createTakeAPI = async () => {
    try {
      setUpdateBtnLoader(true);

      const { data } = await createTake({ take: watch() });
      setValue("id", data.take.id);
      setTakeDetail(data.take);

      if (watch().goToListPage) {
        navigate(PREVIOUS_TAKE_ROUTE);
        showToastrSuccess("Take Saved!");
      } else {
        navigate(`/takes/${data.take.id}/edit`, {
          state: {
            pageLoad: false,
          },
        });
      }
      setTitleModalOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setUpdateBtnLoader(false);
    }
  };

  const updateTakeAPI = async () => {
    try {
      setUpdateBtnLoader(true);

      await updateTake(takeDetail?.id, {
        take: { ...watch(), cover_image_attributes: {} },
      });

      if (watch().goToListPage) {
        navigate(PREVIOUS_TAKE_ROUTE);
        showToastrSuccess("Take Saved!");
      }
      setTitleModalOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setUpdateBtnLoader(false);
    }
  };

  // Need to verify
  const handleSubmitWithStatus = () => {
    if (watch().schedule) {
      handleTakeSubmit("schedule");
    } else if (watch().sent_at) {
      handleTakeSubmit("update");
    } else {
      handleTakeSubmit("draft");
    }
  };

  // Need to verify
  const handleTakeSubmit = async (action) => {
    switch (action) {
      case "update":
        setValue("draft", false);
        setValue("schedule", false);
        setValue("update", true);
        break;
      case "schedule":
        setValue("draft", false);
        setValue("schedule", true);
        setValue("update", false);
        break;
      default:
        setValue("draft", true);
        setValue("schedule", false);
        setValue("update", false);
    }

    handleSubmit(takeId ? updateTakeAPI() : createTakeAPI());
  };

  const onIntroChange = (value) => {
    setValue("intro", value);
  };

  const onOutroChange = (value) => {
    setValue("outro", value);
  };

  if (takeDetailLoad || pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-page-wrapper bg-inverted">
      <Navbar
        formState={watch()}
        handleSubmitWithStatus={handleSubmitWithStatus}
        setShareModalOpen={setShareModalOpen}
        setValue={setValue}
        updateBtnLoader={updateBtnLoader}
        updateTakeAPI={updateTakeAPI}
      />
      <div className="pb-page-container flex flex-col items-center gap-12 w-full pt-6 md:pt-10 pb-24">
        <div className="flex flex-col items-center gap-12 w-full px-6 md:px-10">
          {(watch()?.schedule ||
            watch()?.under_review ||
            user.under_review ||
            (formState.sent &&
              !formState.attachments_attributes?.length > 0)) && (
            <Notifications
              user={user}
              formState={watch()}
              getTakeAPI={getTakeAPI}
            />
          )}

          <Title
            formWatch={watch()}
            handleSubmitWithStatus={handleSubmitWithStatus}
            setValue={setValue}
            draftBtnLoader={updateBtnLoader}
            titleModalOpen={titleModalOpen}
            setTitleModalOpen={setTitleModalOpen}
          />

          <Banner
            formState={watch()}
            setValue={setValue}
            handleSubmitWithStatus={handleSubmitWithStatus}
            createTakeAPI={createTakeAPI}
          />

          <div className="flex flex-col w-full max-w-[var(--take-width)]">
            <TextEditor
              placeholder="Add your intro... (optional)"
              content={watch().intro}
              setEditorContent={onIntroChange}
              onBlur={handleSubmitWithStatus}
              extensionList={TAKE_EDITOR_EXTENSION_LIST}
            />
          </div>
        </div>

        {watch().attachments_attributes.map((attachment) => {
          if (attachment.view_type == "single") {
            return (
              <ImageCard
                key={attachment.id}
                image={attachment}
                style="normal"
                formState={watch()}
                setValue={setValue}
              />
            );
          } else if (attachment.view_type == "thumbnail") {
            return (
              <ThumbnailCard
                key={attachment.id}
                image={attachment}
                gallery={attachment.gallery_attachments_attributes}
                formState={watch()}
                setValue={setValue}
                getTakeAPI={getTakeAPI}
              />
            );
          } else if (attachment.view_type == "grid") {
            return (
              <GridCard
                key={attachment.id}
                image={attachment}
                gallery={attachment.gallery_attachments_attributes}
                formState={watch()}
                setValue={setValue}
                getTakeAPI={getTakeAPI}
              />
            );
          }
        })}

        <div className="flex flex-col items-center gap-12 w-full px-6 md:px-10">
          <GalleryUploader
            take={watch()}
            takeId={takeDetail?.id}
            setValue={setValue}
            getTakeAPI={getTakeAPI}
            attachments={watch().attachments_attributes}
          />

          <LinkRoll
            setValue={setValue}
            formState={watch()}
            handleSubmitWithStatus={handleSubmitWithStatus}
          />

          <div className="flex flex-col w-full max-w-[var(--take-width)]">
            <TextEditor
              placeholder="Sign off your post here... (optional)"
              content={watch().outro}
              setEditorContent={onOutroChange}
              onBlur={handleSubmitWithStatus}
              extensionList={TAKE_EDITOR_EXTENSION_LIST}
            />
          </div>
        </div>
      </div>

      <ShareModal
        open={shareModalOpen}
        setOpen={setShareModalOpen}
        formState={watch()}
        takeDetail={takeDetail}
        updateTakeAPI={updateTakeAPI}
        setValue={setValue}
        user={user}
        updateBtnLoader={updateBtnLoader}
      />
    </div>
  );
};

export default CreateTakePage;
