import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from "query-string";

import { getTakeList, getTagList, getUser } from "@/apis/visual_archives";
import { showToastrError } from "@/lib/commons";
import TakeCard from "@/components/TakeCard";
import Loader from "@/components/Loader";
import InfiniteLoader from "@/components/InfiniteLoader";
import { useAuth } from "@/hooks/useAuth";
import BlankState from "@/components/BlankState";
import LinkButton from "@/components/LinkButton";
import { TAKE_CREATE_ROUTE } from "@/lib/routes";

import Header from "./Header";
import Profile from "./Profile";
import Tags from "./Tags";

const ArchivePage = () => {
  const { urlSlug } = useParams();
  const { user } = useAuth();
  const queryParams = queryString.parse(location.search);

  const [takeUser, setTakeUser] = useState();
  const [pageLoader, setPageLoader] = useState(true);
  const [signUpAlert, setSignUpAlert] = useState(false);

  const [takeList, setTakeList] = useState([]);
  const [takesCount, setTakesCount] = useState(0);
  const [takePageIndex, setTakePageIndex] = useState(1);

  const [tagList, setTagList] = useState([]);
  const [tagListLoader, setTagListLoader] = useState(true);
  const [tagsCount, setTagsCount] = useState(0);
  const [tagPageIndex, setTagPageIndex] = useState();

  const [tagIds, setTagIds] = useState([]);

  useEffect(() => {
    if (queryParams.show_plans && queryParams.failure) {
      setShowPlans(true);
    }

    if (queryParams.new_user === "true" && queryParams.success) {
      setSignUpAlert(true);
    }
  }, []);

  useEffect(() => {
    if (urlSlug?.length > 0) {
      loadWhenUrlSlugFetched();
    }
  }, [urlSlug]);

  const loadWhenUrlSlugFetched = async () => {
    setPageLoader(true);
    setTakeList([]);
    setTakesCount(0);
    setTakePageIndex(1);
    setTagList([]);
    setTagListLoader(true);
    setTagsCount(0);
    setTagPageIndex(1);
    setTagIds([]);
    await getUserAPI();
    await getTakeListAPI([], 1, true); // TODO: Need to fix this
    setPageLoader(false);
  };

  useEffect(() => {
    if (tagPageIndex) {
      getTagListAPI(false);
    }
  }, [tagPageIndex]);

  const getTakeListAPI = async (selectedTagIds, pageIndex, freshLoad) => {
    try {
      const { data } = await getTakeList(urlSlug, selectedTagIds, pageIndex);

      if (freshLoad) {
        setTakeList(data.takes);
      } else {
        setTakeList([...takeList, ...data.takes]);
      }

      setTakesCount(data.total_records);
    } catch (error) {
      if (error.response.status === 404) {
        navigate("/not_found");
      }
      showToastrError(error.response.data.errors);
    }
  };

  const getUserAPI = async () => {
    try {
      const { data } = await getUser(urlSlug);
      setTakeUser(data.user);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getTagListAPI = async (freshLoad) => {
    try {
      setTagListLoader(true);
      const { data } = await getTagList(urlSlug, tagPageIndex);

      if (freshLoad) {
        setTagList(data.tags);
      } else {
        setTagList([...tagList, ...data.tags]);
      }

      setTagsCount(data.total_records);
      setTagListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const loadNext = () => {
    if (takeList.length > 0 && takesCount > takeList.length) {
      setTakePageIndex(takePageIndex + 1);
      getTakeListAPI(tagIds, takePageIndex + 1, false);
    }
  };

  const handleTagClick = (tag) => {
    let selectedTagIds = [];

    if (tagIds.includes(tag.id)) {
      selectedTagIds = tagIds.filter((id) => id !== tag.id);
    } else {
      selectedTagIds = [...tagIds, tag.id];
    }

    setTagIds(selectedTagIds);
    setTakePageIndex(1);
    getTakeListAPI(selectedTagIds, 1, true);
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-page-wrapper bg-inverted">
      <div className="pb-page-container pb-6 px-6 md:pb-10">
        <div className="flex flex-col items-center gap-10 w-full">
          <div className="flex flex-col items-center gap-5 w-full">
            <Header takeUser={takeUser} setTakeUser={setTakeUser} />
            <Profile
              takeUser={takeUser}
              takesCount={takesCount}
              getUserAPI={getUserAPI}
              signUpAlert={signUpAlert}
              setSignUpAlert={setSignUpAlert}
            />
          </div>

          <div className="flex flex-col items-center gap-5 w-full">
            {tagsCount > 0 && (
              <Tags
                tagIds={tagIds}
                loading={tagListLoader}
                tagsCount={tagsCount}
                setTagPageIndex={setTagPageIndex}
                tagList={tagList}
                handleTagClick={handleTagClick}
              />
            )}

            <div className="w-full">
              <InfiniteScroll
                dataLength={takeList.length}
                next={() => loadNext()}
                hasMore={takesCount > takeList.length}
                loader={<InfiniteLoader />}
                scrollThreshold={0.5}
                className="!overflow-hidden"
              >
                {takeList.length === 0 ? (
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 1: 1, 767: 2, 1279: 3, 1535: 4 }}
                  >
                    <Masonry gutter="1.75rem">
                      <BlankState>
                        <div className="flex flex-col items-center gap-4 w-full">
                          <p className="text-title text-center">
                            There are no takes to show.
                          </p>
                          {user?.id === takeUser.id && (
                            <LinkButton
                              to={TAKE_CREATE_ROUTE}
                              label="Create new take"
                            />
                          )}
                        </div>
                      </BlankState>
                    </Masonry>
                  </ResponsiveMasonry>
                ) : (
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 1: 1, 767: 2, 1279: 3, 1535: 4 }}
                  >
                    <Masonry gutter="1.75rem">
                      {takeList.map((take) => (
                        <TakeCard
                          key={take.id}
                          take={take}
                          takeUser={takeUser}
                          archivePage
                        />
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchivePage;
