import React, { useState, useEffect } from "react";

import { getPaidBooths } from "@/apis/settings/paid_booths";
import { showToastrError } from "@/lib/commons";
import Loader from "@/components/Loader";
import BlankState from "@/components/BlankState";
import Pagination from "@/components/Pagination";

import Table from "./Table";

const PaidBooths = () => {
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState();

  useEffect(() => {
    if (pageIndex) {
      getPaidBoothsResponse();
    }
  }, [pageIndex]);

  const getPaidBoothsResponse = async () => {
    try {
      const { data } = await getPaidBooths(pageIndex);
      setSubscriptionsList(data.subscriptions);
      setTotalRecords(data.total_records);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  if (listLoader) {
    return (
      <div className="flex items-center justify-center h-dvh">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-settings-page-wrapper bg-inverted-hover">
      <div className="pb-settings-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-12 w-full max-w-3xl">
          {subscriptionsList.length > 0 ? (
            <Table
              subscriptionsList={subscriptionsList}
              totalRecords={totalRecords}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              getPaidBoothsResponse={getPaidBoothsResponse}
            />
          ) : (
            <BlankState>
              <div className="flex flex-col items-center gap-4 w-full">
                <h5 className="text-title text-center">
                  There are no paid booths to show.
                </h5>
              </div>
            </BlankState>
          )}

          <Pagination
            count={totalRecords}
            pageSize={10}
            pageNo={pageIndex}
            navigate={setPageIndex}
          />
        </div>
      </div>
    </div>
  );
};

export default PaidBooths;
