import React from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import { PBIcons } from "./PBIcons";

const TakeNavItem = ({ link = "/", style = "primary", ...otherProps }) => {
  const STYLES = {
    primary: "text-primary bg-inverted hover:bg-inverted-hover",
    inverted: "text-inverted bg-primary hover:bg-primary-hover",
  };

  const ICON_STYLES = {
    primary: "bg-primary text-inverted",
    inverted: "bg-inverted text-primary",
  };

  return (
    <NavLink
      to={link}
      className={({ isActive, isPending }) =>
        classnames(
          "flex justify-center items-center transition-all duration-300 rounded-sm cursor-pointer w-fit",
          STYLES[style],
          { "bg-inverted-hover": isActive }
        )
      }
      {...otherProps}
    >
      <div className="flex items-center">
        <span className="px-2 py-1.5">Create Take</span>
        <span className={classnames("p-[3.6px]", ICON_STYLES[style])}>
          <PBIcons icon="Plus" size={24} />
        </span>
      </div>
    </NavLink>
  );
};

export default TakeNavItem;
