import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "@/components/Input";
import Button from "@/components/Button";
import VerificationToken from "@/components/TokenVerification";
import { createUser } from "@/apis/users";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { MAX_STRING_LENGTH } from "@/lib/constants";
import { PRIVACY_ROUTE, TERMS_ROUTE } from "@/lib/routes";

import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";

const JoinPage = () => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [verificationToken, setVerificationToken] = useState();

  const { handleSubmit, formState, setValue, watch } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: INITIAL_VALUE,
  });

  const verifyOtpAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createUser({
        user: {
          ...watch(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
      showToastrSuccess(data.message);
      setVerificationToken(data.token);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  if (verificationToken) {
    return <VerificationToken token={verificationToken} />;
  }

  return (
    <div className="pb-page-wrapper bg-inverted">
      <div className="pb-page-container flex justify-center items-center w-full p-6">
        <div className="flex flex-col items-center gap-6 w-full max-w-[var(--auth-screen-width)]">
          <h1 className="text-title">Join</h1>
          <form
            onSubmit={handleSubmit(verifyOtpAPI)}
            className="flex flex-col items-end gap-6 w-full"
          >
            <Input
              id="email"
              label="Email address"
              name="email"
              placeholder="Email address"
              maxLength={MAX_STRING_LENGTH}
              error={formState.errors.email?.message}
              onChange={(e) => setValue("email", e.target.value)}
              required
            />

            <Input
              id="name"
              label="Full Name"
              name="name"
              placeholder="Full Name"
              maxLength={MAX_STRING_LENGTH}
              error={formState.errors.name?.message}
              onChange={(e) => setValue("name", e.target.value)}
              required
            />

            <Input
              id="display_name"
              label="Display Name"
              name="display_name"
              placeholder="Display Name"
              maxLength={MAX_STRING_LENGTH}
              error={formState.errors.display_name?.message}
              onChange={(e) => setValue("display_name", e.target.value)}
              required
            />

            <Input
              id="url_slug"
              label="URL Slug"
              name="url_slug"
              prefix="https://pencilbooth.com/"
              maxLength={MAX_STRING_LENGTH}
              error={formState.errors.url_slug?.message}
              onChange={(e) => setValue("url_slug", e.target.value)}
              required
            />

            <p className="text-muted text-label">
              By creating an account, you agree to our{" "}
              <Link
                to={TERMS_ROUTE}
                rel="noopener noreferrer"
                className="underline"
                onClick={() => window.scrollTo(0, 0)}
              >
                Terms of Service
              </Link>{" "}
              and have read and understood the{" "}
              <Link
                to={PRIVACY_ROUTE}
                rel="noopener noreferrer"
                className="underline"
                onClick={() => window.scrollTo(0, 0)}
              >
                Privacy Policy
              </Link>
              .
            </p>

            <Button type="submit" label="Join" loading={btnLoader} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default JoinPage;
