import React, { useState } from "react";
import classnames from "classnames";

import { PBIcons } from "@/components/PBIcons";
import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";

const SubscriberMenu = ({ value, setValue }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const ACTIVE_MENU_ICONS = {
    free: <PBIcons icon="Free" />,
    paid: <PBIcons icon="Paid" />,
  };

  const handleClick = (type) => {
    setValue(type);
    setMenuOpen(false);
  };

  return (
    <Menu
      open={menuOpen}
      setOpen={setMenuOpen}
      width="200"
      position="bottom-end"
      style="transparent"
      borderRadius={false}
      menuTrigger={
        <div
          className="flex items-center gap-2 px-1 py-0.5"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {ACTIVE_MENU_ICONS[value]}

          <div
            className={classnames("pb-dropdown-trigger-icon", {
              active: menuOpen,
              inactive: !menuOpen,
            })}
          >
            <PBIcons icon="ChevronDown" />
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-1">
        <MenuItem
          icon={<PBIcons icon="Free" />}
          selected={value === "free"}
          onClick={() => handleClick("free")}
          label="Free"
        />

        {true && (
          <MenuItem
            icon={<PBIcons icon="Paid" />}
            selected={value === "paid"}
            onClick={() => handleClick("paid")}
            label="Paid"
          />
        )}
      </div>
    </Menu>
  );
};

export default SubscriberMenu;
