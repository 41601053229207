import React from "react";

const Divider = ({ style = "primary", className = "" }) => {
  const STYLES = {
    primary: "bg-muted",
    inverted: "bg-primary-border",
    "secondary-hover": "bg-secondary-hover",
  };

  return <div className={`h-[1px] w-full ${STYLES[style]} ${className}`}></div>;
};

export default Divider;
