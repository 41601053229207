import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import { Tooltip } from "react-tooltip";

import Menu from "@/components/Menu";
import MenuItem from "@/components/MenuItem";
import Switch from "@/components/Switch";
import Divider from "@/components/Divider";
import TextEditor from "@/components/TextEditor";
import { PBIcons } from "@/components/PBIcons";
import { useAuth } from "@/hooks/useAuth";

import AddLinkDropdown from "./AddLinkDropdown";
import LinkRollCard from "./LinkRollCard";

const LinkRoll = ({ formState, setValue, handleSubmitWithStatus }) => {
  const { user } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleDelete = async () => {
    setValue("link_rolls_enabled", false);
    await handleSubmitWithStatus();
    setMenuOpen(false);
  };

  const handlePaidContent = async () => {
    setValue("mark_link_roll_paid", !formState.mark_link_roll_paid);
    await handleSubmitWithStatus();
  };

  const onIntroChange = (value) => {
    setValue("link_roll_intro", value);
  };

  return (
    <div className="flex flex-col w-full max-w-[var(--take-width)]">
      {!formState.link_rolls_enabled && (
        <div className="flex items-center gap-2 text-muted ">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => {
              setValue("link_rolls_enabled", true);
              handleSubmitWithStatus();
            }}
          >
            <PBIcons icon="Plus" />
            <span>Add the B-Roll</span>
          </div>

          <PBIcons
            icon="QuestionMark"
            size="16"
            className="cursor-pointer"
            data-tooltip-id="about-broll"
          />
        </div>
      )}

      <AnimateHeight
        duration={300}
        height={formState.link_rolls_enabled ? "auto" : 0}
      >
        <div className="flex flex-col gap-8 w-full">
          <div className="flex flex-col gap-4 w-full">
            <div className="relative w-full px-16">
              <input
                className="w-full outline-none placeholder:text-disabled focus:shadow-none text-heading font-bold text-center"
                placeholder="Give your B-Roll a header"
                maxLength={255}
                value={formState.link_roll_title}
                onChange={(e) => setValue("link_roll_title", e.target.value)}
                onBlur={handleSubmitWithStatus}
              />

              <div className="absolute right-0 top-0">
                <Menu open={menuOpen} setOpen={setMenuOpen} width="290">
                  {user.paid_subscription_enabled && (
                    <>
                      <MenuItem
                        label={
                          <div className="flex items-center justify-between gap-10">
                            <span className="shrink-0">
                              Set as paid content
                            </span>

                            <Switch
                              checked={formState.mark_link_roll_paid}
                              onChange={() => handlePaidContent()}
                              style="inverted"
                              bodyBackground="primary"
                              noBorder={true}
                            />
                          </div>
                        }
                        icon={<PBIcons icon="Paid" />}
                      />

                      <Divider />
                    </>
                  )}

                  <MenuItem
                    label="Delete"
                    icon={<PBIcons icon="Delete" />}
                    onClick={() => handleDelete()}
                  />
                </Menu>
              </div>
            </div>

            <TextEditor
              placeholder="Write something about your B-Roll"
              content={formState.link_roll_intro}
              setEditorContent={onIntroChange}
              onBlur={handleSubmitWithStatus}
              textClass="text-center"
              extensionList={["Bold", "Underline", "Italic", "Link"]}
            />
          </div>

          {formState.link_rolls_attributes.length > 0 && (
            <div className="flex flex-col items-center w-full gap-8">
              {formState.link_rolls_attributes.map((linkRoll) => {
                return (
                  <LinkRollCard
                    key={linkRoll.id}
                    formState={formState}
                    setValue={setValue}
                    linkRoll={linkRoll}
                  />
                );
              })}
            </div>
          )}

          <AddLinkDropdown formState={formState} setValue={setValue} />
        </div>
      </AnimateHeight>

      <Tooltip
        id="about-broll"
        place="right"
        style={{ maxWidth: "300px", zIndex: 99 }}
        content="Got links to share? The B-Roll section allows you to easily and
              automatically bring in opengraph data (i.e, images and headlines)
              from your favourite sites like Youtube, Instagram, TikTok, etc.
              into a more bite-sized digest form. Edit the name and description
              for this section as well if you like."
      />
    </div>
  );
};

export default LinkRoll;
