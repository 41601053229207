import React, { useState, useEffect } from "react";

import Menu from "@/components/Menu";
import { updateAttachment } from "@/apis/multiple_take_attachments";
import { showToastrError } from "@/lib/commons";
import { swapElement } from "@/lib/utils";

import DefaultMenu from "./DefaultMenu";
import EditMenu from "./EditMenu";
import DeleteMenu from "./DeleteMenu";

const Dropdown = ({ formState, setValue, linkRoll }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [editMenuOpen, setEditMenuOpen] = useState(false);
  const [deleteMenuOpen, setDeleteMenuOpen] = useState(false);

  const index = formState.link_rolls_attributes
    .map((x) => x.id)
    .indexOf(linkRoll.id);

  useEffect(() => {
    if (menuOpen) {
      setEditMenuOpen(false);
      setDeleteMenuOpen(false);
    }
  }, [menuOpen]);

  const swapLinkRolls = (destination) => {
    let newlyOrderedLinkRolls = swapElement(
      formState.link_rolls_attributes,
      index,
      destination
    );
    setValue("link_rolls_attributes", newlyOrderedLinkRolls);
    reorderAttachmentAPI(newlyOrderedLinkRolls);
  };

  const reorderAttachmentAPI = async (newItems) => {
    try {
      const { data } = await updateAttachment(formState.id, {
        multiple_take_attachments: {
          id: formState.id,
          link_rolls_attributes: newItems,
        },
      });
      setValue("link_rolls_attributes", data.link_rolls);
    } catch (error) {
      showToastrError(error.response.data.errors);
      setValue("link_rolls_attributes", formState.link_rolls_attributes);
    }
  };

  const handleEditClick = () => {
    setEditMenuOpen(true);
    setDeleteMenuOpen(false);
  };

  const handleDeleteClick = () => {
    setEditMenuOpen(false);
    setDeleteMenuOpen(true);
  };

  const renderMenuContent = () => {
    if (editMenuOpen) {
      return (
        <EditMenu
          setOpen={setMenuOpen}
          formState={formState}
          setValue={setValue}
          linkRoll={linkRoll}
        />
      );
    } else if (deleteMenuOpen) {
      return (
        <DeleteMenu
          setOpen={setMenuOpen}
          formState={formState}
          setValue={setValue}
          linkRoll={linkRoll}
        />
      );
    } else {
      return (
        <DefaultMenu
          index={index}
          linkRoll={linkRoll}
          swapElement={swapLinkRolls}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      );
    }
  };

  return (
    <Menu
      open={menuOpen}
      setOpen={setMenuOpen}
      width="300"
      position="bottom-end"
    >
      {renderMenuContent()}
    </Menu>
  );
};

export default Dropdown;
