import React from "react";

import Modal from "@/components/Modal";
import Button from "@/components/Button";

const ConfirmationModal = ({
  modalOpen,
  setModalOpen,
  btnLoader,
  handleCancel,
  handleContinue,
}) => {
  return (
    <Modal
      size="medium"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      title="Send Web Only Take"
    >
      <div className="flex flex-col w-full gap-10">
        <p>
          Are you sure you want to send this take to the selected recipients? If
          you continue, the take's status will be changed to Draft and will be
          removed from the Visaual Archive. If you want to send and show the
          take in the archive again, please contine and follow steps in next
          page.
        </p>

        <div className="flex gap-4">
          <Button
            type="submit"
            label="Yes, Proceed"
            loading={btnLoader}
            style="inverted"
            onClick={() => handleContinue()}
            bodyBackground="primary"
          />
          <Button
            type="button"
            style="transparent"
            label="Cancel"
            onClick={() => handleCancel()}
            bodyBackground="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
