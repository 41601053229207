import React, { useState, useEffect } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import pluralize from "pluralize";

import {
  getSubscriptionList,
  exportSubscriptionList,
} from "@/apis/subscriptions";
import { showToastrError } from "@/lib/commons";
import { useAuth } from "@/hooks/useAuth";
import {
  DEFAULT_PAGE_SIZE,
  EXPORT_DATE_FORMAT,
  SUBSCRIPTION_TYPES,
  EMAIL_STATUS_LIST,
} from "@/lib/constants";
import { BRANDING_SETTINGS_ROUTE, EMBED_SETTINGS_ROUTE } from "@/lib/routes";
import Loader from "@/components/Loader";
import BlankState from "@/components/BlankState";
import Pagination from "@/components/Pagination";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { PBIcons } from "@/components/PBIcons";
import Select from "@/components/Select";
import AudienceImportModal from "@/components/AudienceImportModal";
import FollowYourself from "@/components/FollowYourSelf";

import Table from "./Table";

const AudiencePage = () => {
  const { user, setUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [audienceList, setAudienceList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [activeCount, setActiveCount] = useState();
  const [exportBtnLoader, setExportBtnLoader] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [followConfirmAlertOpen, setFollowConfirmAlertOpen] = useState(false);
  const [status, setStatus] = useState();
  const [emailStatus, setEmailStatus] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (pageIndex) {
      getAudienceListAPI();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (status || status === "") {
      getAudienceListAPI();
    }
  }, [status]);

  useEffect(() => {
    if (emailStatus || emailStatus === "") {
      getAudienceListAPI();
    }
  }, [emailStatus]);

  useEffect(() => {
    if (debouncedSearchTerm || debouncedSearchTerm === "") {
      getAudienceListAPI();
    }
  }, [debouncedSearchTerm]);

  const getAudienceListAPI = async () => {
    try {
      const { data } = await getSubscriptionList(
        pageIndex,
        searchTerm,
        status,
        emailStatus
      );
      setAudienceList(data.subscriptions);
      setActiveCount(data.active_subscriptions_count);
      setUser({ ...user, subscriberSize: data.subscriberSize });
      setTotalRecords(data.total_records);
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const exportAudienceListResponse = async () => {
    try {
      setExportBtnLoader(true);
      const { data } = await exportSubscriptionList();
      const url = window.URL.createObjectURL(
        new Blob([data.subscription_data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = `audiences_${dayjs().format(EXPORT_DATE_FORMAT)}.csv`;
      link.click();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setExportBtnLoader(false);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-page-wrapper bg-inverted-hover">
      <div className="pb-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-6 md:gap-10 w-full max-w-[var(--layout-max-width)]">
          <div className="flex items-center justify-between">
            <h1 className="text-heading font-bold">Audience</h1>

            <div className="flex gap-3 shrink-0">
              <Button
                className="hover:!bg-disabled hover:!border-disabled shrink-0"
                icon={<PBIcons icon="Import" />}
                style="inverted"
                label="Import"
                onClick={() => setUploadModalOpen(true)}
                bodyBackground="inverted-hover"
              />

              <Button
                className="hover:!bg-disabled hover:!border-disabled shrink-0"
                icon={
                  exportBtnLoader ? <Loader /> : <PBIcons icon="Download" />
                }
                style="inverted"
                label="Export"
                onClick={() => exportAudienceListResponse()}
                bodyBackground="inverted-hover"
              />
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <p>
              You currently have {activeCount}{" "}
              {pluralize("subscriber", activeCount)} in your booth.
            </p>
            <p>
              If you want to manually unsubscribe someone for any reason, you
              can do that here. Are you migrating from another newsletter tool?
              Import a CSV file of your existing subscribers using our handy
              importer tool. Starting from scratch? Go on and{" "}
              <Link
                to={BRANDING_SETTINGS_ROUTE}
                className="cursor-pointer underline"
              >
                customize your booth
              </Link>{" "}
              or use our{" "}
              <Link
                to={EMBED_SETTINGS_ROUTE}
                className="cursor-pointer underline"
              >
                embed widget
              </Link>{" "}
              then start spreading the word!
              {!user.own_subscription_present && (
                <span>
                  {" "}
                  If you want to get the take to your own email,{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => setFollowConfirmAlertOpen(true)}
                  >
                    follow yourself
                  </span>
                  .
                </span>
              )}
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
            <Input
              label="Search by email"
              placeholder="Search by email"
              value={searchTerm}
              onChange={(e) => {
                setPageIndex(1);
                setSearchTerm(e.target.value);
              }}
              bodyBackground="inverted-hover"
            />

            <Select
              label="Filter by last email status"
              bodyBackground="inverted-hover"
              placeholder="Select a email status"
              options={EMAIL_STATUS_LIST}
              onChange={(opt) => {
                setPageIndex(1);

                if (opt) {
                  setEmailStatus(opt?.value);
                } else {
                  setEmailStatus("");
                }
              }}
              isClearable
            />

            <Select
              label="Filter by status"
              bodyBackground="inverted-hover"
              placeholder="Select a status"
              options={SUBSCRIPTION_TYPES}
              onChange={(opt) => {
                setPageIndex(1);

                if (opt) {
                  setStatus(opt?.value);
                } else {
                  setStatus("");
                }
              }}
              isClearable
            />
          </div>

          {audienceList.length > 0 ? (
            <Table
              audiences={audienceList}
              getAudienceListAPI={getAudienceListAPI}
            />
          ) : (
            <BlankState>
              <div className="flex flex-col gap-2 w-full">
                <p className="text-title font-bold text-center">
                  There are currently no subscribers to show.
                </p>
                <p className="text-muted text-center">
                  Please use our{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => setUploadModalOpen(true)}
                  >
                    import tool
                  </span>{" "}
                  to add new subscribers.
                </p>
              </div>
            </BlankState>
          )}

          <Pagination
            count={totalRecords}
            pageSize={DEFAULT_PAGE_SIZE}
            pageNo={pageIndex}
            navigate={setPageIndex}
          />

          <AudienceImportModal
            uploadModalOpen={uploadModalOpen}
            setUploadModalOpen={setUploadModalOpen}
          />

          <FollowYourself
            modalOpen={followConfirmAlertOpen}
            setModalOpen={setFollowConfirmAlertOpen}
            updateState={() => {
              setUser({ ...user, own_subscription_present: true });
              getAudienceListAPI();
            }}
            userId={user.id}
            email={user.email}
          />
        </div>
      </div>
    </div>
  );
};

export default AudiencePage;
