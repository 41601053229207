import React from "react";
import classNames from "classnames";

import * as Icons from "./Icons";

const BubbleMenuOptions = ({ editor, openModal, extensionList }) => {
  return (
    <div className="bubble-menu">
      {extensionList.includes("Link") && (
        <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("link"),
          })}
          onClick={openModal}
        >
          <Icons.Link />
        </button>
      )}
      {extensionList.includes("Bold") && (
        <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("bold"),
          })}
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <Icons.Bold />
        </button>
      )}
      {extensionList.includes("Underline") && (
        <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("underline"),
          })}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
        >
          <Icons.Underline />
        </button>
      )}
      {extensionList.includes("Italic") && (
        <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("italic"),
          })}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <Icons.Italic />
        </button>
      )}
      {extensionList.includes("TextAlign") && (
        <button
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          className={classNames("menu-button", {
            "is-active": editor.isActive({ textAlign: "left" }),
          })}
        >
          <Icons.TextLeft />
        </button>
      )}
      {extensionList.includes("TextAlign") && (
        <button
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          className={classNames("menu-button", {
            "is-active": editor.isActive({ textAlign: "center" }),
          })}
        >
          <Icons.TextCenter />
        </button>
      )}
      {extensionList.includes("TextAlign") && (
        <button
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          className={classNames("menu-button", {
            "is-active": editor.isActive({ textAlign: "right" }),
          })}
        >
          <Icons.TextRight />
        </button>
      )}
      {extensionList.includes("TextAlign") && (
        <button
          onClick={() => editor.chain().focus().setTextAlign("justify").run()}
          className={classNames("menu-button", {
            "is-active": editor.isActive({ textAlign: "justify" }),
          })}
        >
          <Icons.TextJustify />
        </button>
      )}
      {extensionList.includes("BulletList") && (
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={classNames("menu-button", {
            "is-active": editor.isActive("bulletList"),
          })}
        >
          <Icons.BulletList />
        </button>
      )}
      {extensionList.includes("OrderedList") && (
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={classNames("menu-button", {
            "is-active": editor.isActive("orderedList"),
          })}
        >
          <Icons.NumberedList />
        </button>
      )}
    </div>
  );
};

export default BubbleMenuOptions;
