import React, { useState } from "react";
import classnames from "classnames";
import { useMeasure } from "@uidotdev/usehooks";

import {
  LOGIN_ROUTE,
  ROOT_ROUTE,
  ABOUT_ROUTE,
  SEARCH_ROUTE,
  JOIN_ROUTE,
} from "@/lib/routes";

import NavItem from "@/components/NavItem";
import { PBIcons } from "@/components/PBIcons";
import { TAB_WIDTH } from "@/lib/constants";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [ref, { width, height }] = useMeasure();

  const showSidebar = () => {
    setOpen(true);
    // document.body.classList.add("!overflow-hidden");
  };

  const hideSidebar = () => {
    setOpen(false);
    // document.body.classList.remove("!overflow-hidden");
  };

  const isMobileScreen = () => {
    return width && width < TAB_WIDTH;
  };

  const renderLeftNavLinks = (style) => {
    return (
      <>
        <NavItem
          link={ROOT_ROUTE}
          label="Discover"
          style={style}
          onClick={() => hideSidebar()}
        />
        <NavItem
          link={ABOUT_ROUTE}
          label="About"
          style={style}
          onClick={() => hideSidebar()}
        />
        <NavItem
          link={SEARCH_ROUTE}
          label="Search"
          style={style}
          onClick={() => hideSidebar()}
        />
      </>
    );
  };

  return (
    <nav
      className="flex items-center w-full px-6 bg-inverted fixed z-[100] top-0 right-0 left-0"
      ref={ref}
    >
      <div className="flex justify-between items-center w-full gap-2.5 py-4">
        {isMobileScreen() && (
          <PBIcons icon="Hamburger" size="28" onClick={() => showSidebar()} />
        )}

        {!isMobileScreen() && (
          <div className="flex items-center gap-2.5">
            {renderLeftNavLinks("primary")}
          </div>
        )}

        <div className="flex items-center gap-2.5">
          <NavItem link={LOGIN_ROUTE} label="Login" />
          <NavItem link={JOIN_ROUTE} label="Join free" style="inverted" />
        </div>
      </div>

      {isMobileScreen() && (
        <div
          className={classnames(
            "absolute z-50 top-0 px-6 text-inverted bg-primary h-dvh w-screen transition-all duration-300",
            { "-left-[calc(100vw)] opacity-0": !open },
            { "left-0 opacity-100": open }
          )}
        >
          <div className="flex justify-between w-full gap-2.5 py-4">
            <PBIcons icon="Close" size="28" onClick={() => hideSidebar()} />

            <div className="flex items-center gap-2.5">
              <NavItem
                link={LOGIN_ROUTE}
                label="Login"
                style="inverted"
                onClick={() => hideSidebar()}
              />
              <NavItem
                link={JOIN_ROUTE}
                label="Join free"
                onClick={() => hideSidebar()}
              />
            </div>
          </div>

          <div className="pb-mobile-navbar-container flex flex-col gap-2.5">
            {renderLeftNavLinks("inverted")}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
