import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import queryString from "query-string";

import { useAuth } from "@/hooks/useAuth";
import { createSubscription } from "@/apis/subscriptions";
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import LinkButton from "@/components/LinkButton";
import Input from "@/components/Input";
import { PBIcons } from "@/components/PBIcons";
import { showToastrError, showToastrSuccess } from "@/lib/commons";
import UserPlanModal from "@/components/UserPlanModal";
import SignUpAlert from "@/components/SignUpAlert";

const Profile = ({
  takeUser,
  takesCount,
  getUserAPI,
  signUpAlert,
  setSignUpAlert,
}) => {
  const { user } = useAuth();
  const queryParams = queryString.parse(location.search);
  const [btnLoader, setBtnLoader] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [showPlans, setShowPlans] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState();
  const [upgradedToPaid, setUpgradedToPaid] = useState(false);
  const { handleSubmit, formState, setValue, reset, watch } = useForm({
    defaultValues: { email: "" },
  });

  useEffect(() => {
    if (queryParams.upgraded_to_paid) {
      setUpgradedToPaid(true);
    }
  }, []);

  const createSubscriptionAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createSubscription({
        subscription: { email: watch().email, user_id: takeUser.id },
      });
      setSubscriptionId(data.id);
      setUpgradedToPaid(data.upgraded_to_paid);
      getUserAPI();
      setEmailModal(false);
      if (takeUser.paid_subscription_enabled && !data.upgraded_to_paid) {
        setShowPlans(true);
      }
      reset();
      showToastrSuccess(data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-between items-center gap-5 w-full">
      <div className="flex flex-col md:flex-row items-center gap-5 w-full">
        <img
          src={takeUser.logo_url}
          className="h-20 w-20 object-cover rounded-full"
        />

        <h1 className="text-heading-xl">
          {takeUser.name} <sup className="text-body">({takesCount})</sup>
        </h1>
      </div>

      <div className="flex flex-row items-center justify-center md:justify-end gap-5 md:gap-2.5 w-full">
        {takeUser.personal_url && (
          <LinkButton
            to={takeUser.personal_url}
            label="Website"
            style="secondary-outline"
            suffixIcon={<PBIcons icon="ArrowUpRight" size="18" />}
          />
        )}

        {upgradedToPaid ? (
          <Button label="Upgraded to Paid" />
        ) : (
          <>
            {takeUser.id != user?.id && (
              <Button
                label={takeUser.subscribed ? "Subscribed" : "Subscribe"}
                loading={btnLoader}
                onClick={() => {
                  if (!takeUser.subscribed) {
                    if (user) {
                      setValue("email", user.email);
                      createSubscriptionAPI();
                    } else {
                      setEmailModal(true);
                    }
                  }
                }}
              />
            )}
          </>
        )}
      </div>

      <Modal
        size="medium"
        open={emailModal}
        onClose={() => setEmailModal(false)}
        title="Subscribe to booth"
      >
        <div className="flex flex-col w-full gap-10">
          <form
            id="email-form"
            onSubmit={handleSubmit(createSubscriptionAPI)}
            className="flex flex-col w-full gap-6"
          >
            <Input
              id="email"
              name="email"
              label="Your email address"
              value={watch().email}
              onChange={(e) => setValue("email", e.target.value)}
              error={formState.errors.message?.email}
              bodyBackground="primary"
              style="primary"
              placeholder="Enter an email"
            />
          </form>

          <div className="flex gap-4">
            <Button
              form="email-form"
              type="submit"
              label="Subscribe"
              loading={btnLoader}
              style="inverted"
              bodyBackground="primary"
            />

            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => setEmailModal(false)}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>

      <Modal
        size="planModal"
        open={showPlans}
        onClose={() => setShowPlans(false)}
      >
        <UserPlanModal
          user={takeUser}
          subscriptionId={subscriptionId}
          setShowPlans={setShowPlans}
        />
      </Modal>

      <SignUpAlert modalOpen={signUpAlert} setModalOpen={setSignUpAlert} />
    </div>
  );
};

export default Profile;
