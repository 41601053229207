import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import pluralize from "pluralize";

import { PBIcons } from "@/components/PBIcons";
import LinkButton from "@/components/LinkButton";
import Divider from "@/components/Divider";
import { JOIN_ROUTE } from "@/lib/routes";
import { FREE_EMAIL_COUNT, MINIMUM_EMAIL_COUNT } from "@/lib/constants";

import PriceInfo from "./PriceInfo";
import FeatureCard from "./FeatureCard";
import Accordion from "./Accordion";

const AboutPage = () => {
  const [subscriber, setSubscriber] = useState(1);
  const [email, setEmail] = useState(1);

  const totalCost = () => {
    return (
      (FREE_EMAIL_COUNT * subscriber * email - FREE_EMAIL_COUNT) *
      0.003
    ).toFixed(2);
  };

  useEffect(() => {
    document.getElementById("beacon-container")?.remove();
  }, []);

  return (
    <div className="pb-page-wrapper bg-inverted">
      <div className="pb-page-container flex flex-col items-center gap-24 lg:gap-48 w-full pt-12 lg:py-36 mb-[47px] lg:mb-0">
        <div className="flex justify-center gap-[50px] w-full max-w-[1154px] px-6">
          <div className="flex flex-col items-center lg:items-start gap-8 w-full max-w-2xl">
            <h1 className="text-heading-xxl font-bold w-full text-center lg:text-left">
              The easiest way to keep people up-to-date with your art.
            </h1>

            <video autoPlay loop muted playsInline className="lg:hidden">
              <source
                src="https://res.cloudinary.com/jackywinter/video/upload/v1738118027/0182_JackyWinter_PencilBooth_LandingPage_16x9_DarkMode_UpdatesJan2025_v01_wpv9xp.mp4"
                type="video/mp4"
              />
            </video>

            <p className="text-center lg:text-left text-body leading-heading">
              PencilBooth is a different kind of email newsletter platform
              designed specifically for visual artists. We provide you with a
              functional, image-first composer that's easy to use and simple to
              send, delighting your subscribers each time it lands in their
              inbox.
              <br />
              <br /> With a focused set of features tailor-made for image makers
              and creatives, we've cut out all the unnecessary bloat and expense
              to make it easy and fun to share a visual snapshot of what you've
              been up to and what you're loving.
            </p>

            <LinkButton
              to={JOIN_ROUTE}
              label="Get started"
              suffixIcon={<PBIcons icon="ArrowRight" />}
            />
          </div>

          <div className="max-w-[619px] w-full shrink-0 grow hidden lg:block bg-secondary aspect-[1.78] h-fit">
            <video autoPlay loop muted playsInline>
              <source
                src="https://res.cloudinary.com/jackywinter/video/upload/v1738118027/0182_JackyWinter_PencilBooth_LandingPage_16x9_DarkMode_UpdatesJan2025_v01_wpv9xp.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>

        <div className="flex flex-col items-center gap-[50px] w-full px-6 py-16 bg-inverted-hover">
          <div className="flex flex-col items-center gap-6 w-full">
            <div className="flex flex-col items-center gap-2 w-full">
              <h1 className="text-heading-xxl font-bold w-full text-center">
                Only pay for what you need.
              </h1>

              <p className="text-center">
                No lock in contracts, all features all the time, only pay for
                the emails you send.
              </p>
            </div>

            <LinkButton
              to={JOIN_ROUTE}
              label="Get started"
              suffixIcon={<PBIcons icon="ArrowRight" />}
            />
          </div>

          <div className="flex flex-col items-center gap-8 w-full max-w-2xl lg:max-w-[967px] bg-primary text-inverted p-8 lg:mb-12 rounded">
            <div className="flex flex-col lg:flex-row gap-8 w-full bg-secondary rounded px-4 py-6">
              <div className="flex flex-col gap-2 w-full lg:max-w-[270px]">
                <span className="text-small font-bold px-3 py-1 rounded-[99px] bg-inverted text-primary w-fit">
                  No monthly fee
                </span>

                <div className="flex items-end gap-1 w-full font-bold !leading-none pt-1">
                  <div>
                    <sup
                      style={{
                        fontFeatureSettings: "sups",
                        fontSize: "30px",
                      }}
                    >
                      $
                    </sup>
                    <span className="text-[44px]">{totalCost()}</span>
                  </div>

                  <span className="pb-1">pay as you go</span>
                </div>

                {subscriber > 1 || email > 1 ? (
                  <span>
                    Price for{" "}
                    {(
                      subscriber * email * FREE_EMAIL_COUNT -
                      FREE_EMAIL_COUNT
                    ).toLocaleString()}{" "}
                    emails
                  </span>
                ) : (
                  <span>500 emails per month included</span>
                )}
              </div>

              <Divider style="secondary-hover" className="lg:hidden" />

              <div className="hidden lg:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1"
                  height="114"
                  viewBox="0 0 1 114"
                  fill="none"
                >
                  <path d="M0.5 0L0.499995 114" stroke="#3744B1" />
                </svg>
              </div>

              <div className="flex flex-col gap-6 grow">
                <div className="flex flex-col gap-2">
                  <p>
                    <span className="font-bold">
                      {(subscriber * FREE_EMAIL_COUNT).toLocaleString()}
                    </span>{" "}
                    subscribers
                  </p>
                  <Slider
                    min={1}
                    max={20}
                    onChange={(val) => setSubscriber(val)}
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <p>
                    <span className="font-bold">{email}</span>{" "}
                    {pluralize("email", email)}
                  </p>
                  <Slider min={1} max={10} onChange={(val) => setEmail(val)} />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full">
              <div className="flex flex-col items-center gap-8 w-full">
                <PriceInfo title="No monthly subscription fees." />
                <PriceInfo title="500 emails per month included." />
                <PriceInfo title="Pay as you go for extra emails @ .003c per email. (Min. 110 emails (.33c) in first transaction)" />
              </div>
              <div className="flex flex-col items-center gap-8 w-full">
                <PriceInfo title="Fully-featured link-in-bio tool." />
                <PriceInfo title="Unlimited web-only takes." />
                <PriceInfo title="Paid Subscriptions: Stripe Fees + 5% platform fee." />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center gap-[50px] w-full max-w-[1154px] px-6">
          <div className="flex flex-col items-center lg:items-start gap-8 w-full max-w-2xl">
            <h1 className="text-heading-xxl font-bold w-full text-center lg:text-left">
              A composing experience that puts images first.
            </h1>

            <video autoPlay loop muted playsInline className="lg:hidden">
              <source
                src="https://res.cloudinary.com/pencilbooth-production/video/upload/v1737955016/1_v0hoix.mp4"
                type="video/mp4"
              />
            </video>

            <p className="text-center lg:text-left text-body leading-heading">
              Full-screen, medium, or narrow? Thumbnail or grid gallery? From
              custom image headers and avatars to alt text and caption styles,
              PencilBooth's composer has been designed with images in mind from
              the start so you can let your visuals do the talking.
            </p>

            <LinkButton
              to={JOIN_ROUTE}
              label="Get started"
              suffixIcon={<PBIcons icon="ArrowRight" />}
            />
          </div>

          <div className="max-w-[619px] w-full shrink-0 grow hidden lg:block">
            <video autoPlay loop muted playsInline>
              <source
                src="https://res.cloudinary.com/pencilbooth-production/video/upload/v1737955016/1_v0hoix.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>

        <div className="flex justify-center gap-[50px] w-full max-w-[1154px] px-6">
          <div className="max-w-[619px] w-full shrink-0 grow hidden lg:block">
            <video autoPlay loop muted playsInline>
              <source
                src="https://res.cloudinary.com/pencilbooth-production/video/upload/v1737955016/2_uzuvaw.mp4"
                type="video/mp4"
              />
            </video>
          </div>

          <div className="flex flex-col items-center lg:items-start gap-8 w-full max-w-2xl">
            <h1 className="text-heading-xxl font-bold w-full text-center lg:text-left">
              Turn links into visual snacks with The B-Roll.
            </h1>

            <video autoPlay loop muted playsInline className="lg:hidden">
              <source
                src="https://res.cloudinary.com/pencilbooth-production/video/upload/v1737955016/2_uzuvaw.mp4"
                type="video/mp4"
              />
            </video>

            <p className="text-center lg:text-left text-body leading-heading">
              In addition to your own work, you can easily share what else you
              are loving through our B-roll feature. Simply copy and paste any
              URL into the composer and it'll automatically get converted into
              bite-size visual nuggets to bookend your take
            </p>

            <LinkButton
              to={JOIN_ROUTE}
              label="Get started"
              suffixIcon={<PBIcons icon="ArrowRight" />}
            />
          </div>
        </div>

        <div className="flex justify-center gap-[50px] w-full max-w-[1154px] px-6">
          <div className="flex flex-col items-center lg:items-start gap-8 w-full max-w-2xl">
            <h1 className="text-heading-xxl font-bold w-full text-center lg:text-left">
              A link-in-bio to rule them all.
            </h1>

            <video autoPlay loop muted playsInline className="lg:hidden">
              <source
                src="https://res.cloudinary.com/pencilbooth-production/video/upload/v1737955016/3_kf2sac.mp4"
                type="video/mp4"
              />
            </video>

            <p className="text-center lg:text-left text-body leading-heading">
              All accounts come standard with a small but mighty link-in-bio
              tool. Easily turn your subscriber form into a centralised hub
              where you can manage all your social links in one place.
            </p>

            <LinkButton
              to={JOIN_ROUTE}
              label="Get started"
              suffixIcon={<PBIcons icon="ArrowRight" />}
            />
          </div>

          <div className="max-w-[619px] w-full shrink-0 grow hidden lg:block">
            <video autoPlay loop muted playsInline>
              <source
                src="https://res.cloudinary.com/pencilbooth-production/video/upload/v1737955016/3_kf2sac.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>

        <div className="flex justify-center gap-[50px] w-full max-w-[1154px] px-6">
          <div className="max-w-[619px] w-full shrink-0 grow hidden lg:block">
            <video autoPlay loop muted playsInline>
              <source
                src="https://res.cloudinary.com/pencilbooth-production/video/upload/v1737955016/4_ipzodu.mp4"
                type="video/mp4"
              />
            </video>
          </div>

          <div className="flex flex-col items-center lg:items-start gap-8 w-full max-w-2xl">
            <h1 className="text-heading-xxl font-bold w-full text-center lg:text-left">
              Opengraph everything.
            </h1>

            <video autoPlay loop muted playsInline className="lg:hidden">
              <source
                src="https://res.cloudinary.com/pencilbooth-production/video/upload/v1737955016/4_ipzodu.mp4"
                type="video/mp4"
              />
            </video>

            <p className="text-center lg:text-left text-body leading-heading">
              Your booth URL and each individual will unfurl in any social media
              platform with your own imagery.
            </p>

            <LinkButton
              to={JOIN_ROUTE}
              label="Get started"
              suffixIcon={<PBIcons icon="ArrowRight" />}
            />
          </div>
        </div>

        <div className="flex justify-center gap-[50px] w-full max-w-[1154px] px-6">
          <div className="flex flex-col items-center lg:items-start gap-8 w-full max-w-2xl">
            <h1 className="text-heading-xxl font-bold w-full text-center lg:text-left">
              No email? No Problem.
            </h1>

            <video autoPlay loop muted playsInline className="lg:hidden">
              <source
                src="https://res.cloudinary.com/pencilbooth-production/video/upload/v1737955016/5_kazc7r.mp4"
                type="video/mp4"
              />
            </video>

            <p className="text-center lg:text-left text-body leading-heading">
              Feel like creating a take but don't want to email it to your list?
              All accounts allow for unlimited web-only posts which can be
              shared via URL.
            </p>

            <LinkButton
              to={JOIN_ROUTE}
              label="Get started"
              suffixIcon={<PBIcons icon="ArrowRight" />}
            />
          </div>

          <div className="max-w-[619px] w-full shrink-0 grow hidden lg:block">
            <video autoPlay loop muted playsInline>
              <source
                src="https://res.cloudinary.com/pencilbooth-production/video/upload/v1737955016/5_kazc7r.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>

        <div className="flex flex-col items-center gap-8 md:gap-16 w-full max-w-[1154px] px-6">
          <h2 className="text-heading-xl font-bold w-full text-center">
            Features
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-y-16 md:gap-x-24">
            <FeatureCard
              title="💾 Your audience, your data."
              description="Whether you're migrating to PencilBooth from another newsletter platform (hello!) or want to move on (goodbye), we make it easy to bring or take your subscriber list with you."
            />

            <FeatureCard
              title="📬 GDPR compliance and spam protection"
              description="Double-opt in features available for all newsletters plus enhanced Spam protection to ensure your emails make it to your recipient's inboxes, where they rightfully belong."
            />

            <FeatureCard
              title="💸 Paid Subscriptions*"
              description={
                <p>
                  Turn on paid subscriptions and paywall certain content or an
                  entire take.
                  <br />
                  <br /> *Paid subscriptions incur Stripe credit card surcharges
                  and a 5% platform fee See FAQ for more details
                </p>
              }
            />

            <FeatureCard
              title="📈 Analytics"
              description="Get general and user-specific analytics with full privacy protection for your audience. Track open, clicks and unsubscribes."
            />

            <FeatureCard
              title="⏲️ Schedule Send"
              description="Create your takes in advance and have them sent when you want them to be there."
            />

            <FeatureCard
              title="🕵 Get seen"
              description="If opted-in, your takes can be shared to our evolving homepage which is also searchable by location and discipline for further discovery."
            />

            <FeatureCard
              title="💏 Recommendations"
              description="PencilBooth is better with friends... Recommend and link to their booths on your own takes."
            />

            <FeatureCard
              title="📒 The Visual Archive"
              description="All the images from your takes are stored in your visual archive. Use it as an ad-hoc portfolio or scrapbook. Apply tags to filter your work."
            />

            <FeatureCard
              title="🛑 Zero tolerance for Nazis and AI slop/scraping."
              description="This one should be pretty self explanatory."
            />
          </div>
        </div>

        <div className="flex flex-col items-center gap-12 w-full max-w-[682px] px-6">
          <h2 className="text-heading-xl font-bold w-full text-center">
            Frequently asked questions
          </h2>

          <div className="flex flex-col w-full border-t border-solid border-primary-border">
            <Accordion title="What exactly is PencilBooth?">
              <div className="flex flex-col gap-4">
                <p>
                  PencilBooth is an email newsletter and publishing service
                  which has been made specifically for visual artists and other
                  creative types. Fed up by systems and social media platforms
                  that don't allow work to be seen by the people who actually
                  want to see it, PencilBooth gets your stuff directly in front
                  of your clients and followers directly by putting it straight
                  in their email inbox without any middlemen or algorithms.
                </p>

                <p>
                  While most other newsletter platforms cater for writers or
                  content creators, PencilBooth takes a visuals-first approach
                  to the newsletter ecosystem, giving you an easy way to share
                  your work as simply and elegantly as possible with those who
                  want to see it.
                </p>
                <p>
                  Each newsletter in PencilBooth is called a Take, and consists
                  of a series of images with optional headlines, captions and
                  outlinks. In your B-roll section, you can also add links to
                  other things you're reading/watching/loving and have it be
                  automatically converted into a visual nugget to bookend your
                  Take.{" "}
                </p>
                <p>
                  If you don't want to email a take, you can simply publish it
                  as a web-only post and share that with people as you wish.
                </p>
                <p>
                  Your Takes are saved to your visual archive and potentially
                  featured on our homepage as well.
                </p>
                <p>
                  Your Booth is your unique page where you sign up new
                  subscribers. It also acts as a handy link-in-bio tool that can
                  accommodate all your other links.
                </p>
                <p>
                  All PencilBooth accounts are free and contain all features.
                  There are no recurring monthly subscription fees or contracts.
                  All users get 500 free emails a month. You then pay a small
                  price per email after that.
                </p>
                <p>
                  Claim your booth now and get started by clicking{" "}
                  <a href={JOIN_ROUTE} className="underline">
                    here
                  </a>
                  .
                </p>
              </div>
            </Accordion>

            <Accordion title="How do I set up my Booth and send my first take?">
              <p>
                Our help docs are very straightforward and will get you set up
                in no time. Click the speech bubble to open things up and get
                started or visit our help page{" "}
                <a
                  href="https://pencilbooth.helpscoutdocs.com/"
                  className="underline"
                >
                  here
                </a>
                .
              </p>
            </Accordion>

            <Accordion title="Is it just for visual artists?">
              <p>
                There are lots of email newsletter platforms out there, but
                Artists are who we know and what we know, so we really are
                focussing on this small subset of users and their needs. That
                doesn't mean it can't be used by anyone and everyone. In fact,
                we're really interested to see what other folks might do with
                this.
              </p>
            </Accordion>

            <Accordion title="Does it cost anything?">
              <div className="flex flex-col gap-4">
                <p>
                  All PencilBooth plans are free forever and include all
                  features and 500 free emails per month. After that each email
                  costs .003c per email to send. You only pay for the emails you
                  send and don't need to keep an active monthly account or
                  anything.
                </p>
                <p>
                  If you are opening your booth to paid subscribers, PencilBooth
                  charges a 5% platform fee in addition to Stripe fees.
                </p>
                <p>
                  We're not a VC backed business looking for an exit, nor do we
                  have any investors to satisfy. We made this as something we
                  wanted to use ourselves and it seems like a whole lot of other
                  people like it as well which is great.
                </p>
                <p>
                  Your email fees pay for the hard costs of actually sending
                  emails as well as covering our development fees for
                  maintenance and new features.
                </p>
              </div>
            </Accordion>

            <Accordion title="What is the Visual Archive?">
              <div className="flex flex-col gap-4">
                <p>
                  All images from your Takes will be automatically posted to
                  your visual archive, a more image-friendly way explore all
                  your previous takes. It's a also great way to give subscribers
                  a birds-eye view of what kind of stuff you'll be sending them.
                  Your takes may also get cross-posted to the discover feed on
                  our homepage, giving you a new avenue to get discovered by
                  potential clients and peers.
                </p>
                <p>
                  If you don't want your images or Takes appearing on the
                  archive or home page, you can easily opt-out when composing
                  them.
                </p>
              </div>
            </Accordion>

            <Accordion title="I have a feature request / something is broken / can I just speak to someone please?">
              <div className="flex flex-col gap-4">
                <p>
                  If you're a current user, just drop us a line in our Slack
                  group. The invite link will be in your welcome email. You can
                  also use the form in the navigation bar when logged in.
                </p>
                <p>
                  For everything else, just email us at{" "}
                  <a href="mailto:team@pencilbooth.com" className="underline">
                    team@pencilbooth.com!
                  </a>
                </p>
              </div>
            </Accordion>

            <Accordion title="Who made this?">
              <div className="flex flex-col gap-4">
                <p>
                  PencilBooth is a venture from The{" "}
                  <a href="https://jackywinter.com" className="underline">
                    Jacky Winter Group
                  </a>
                  , a creative representation and production agency with offices
                  in Australia, the US, and the UK. We have been in business for
                  over eighteen years and we know a thing or two about promoting
                  creative people and getting their work seen. PencilBooth was
                  originally launched in 2022 as a custom app to promote our own
                  artists to our clients and to try to solve our own biggest
                  challenge of continually shouting into the abyss of social
                  media, but we figured it had more potential in the hands of
                  artists themselves, so here we are!
                </p>
                <p>
                  You can find out more about us at{" "}
                  <a href="https://jackywinter.com" className="underline">
                    jackywinter.com
                  </a>{" "}
                  or hey, maybe{" "}
                  <a
                    href="https://www.pencilbooth.com/jackywinter"
                    className="underline"
                  >
                    subscribe to our PencilBooth
                  </a>{" "}
                  to find out more!
                </p>
              </div>
            </Accordion>

            <Accordion title="Can I import my subscribers from another app?">
              <div className="flex flex-col gap-4">
                <p>
                  Yes! Use our in-built audience importer or email us at{" "}
                  <a href="mailto:import@pencilbooth.com" className="underline">
                    import@pencilbooth.com
                  </a>{" "}
                  !
                </p>
              </div>
            </Accordion>

            <Accordion title="Can I import my posts or archive from another email newsletter service like Substack?">
              <div className="flex flex-col gap-4">
                <p>
                  If you are an creative with a significant number of followers
                  we do offer a simply hands-on migration service. Email us at{" "}
                  <a href="mailto:team@pencilbooth.com" className="underline">
                    team@pencilbooth.com
                  </a>{" "}
                  to enquire.
                </p>
              </div>
            </Accordion>

            <Accordion title="Can I have some more technical information on how to prepare my images for my booth or my takes?">
              <div className="flex flex-col gap-4">
                <p>
                  For your Booth background we suggest a landscape image that
                  doesn't have a central focus (as it will be covered up by your
                  sign-up box). When creating your Booth you will be able to
                  preview both mobile and desktop versions to see how it will
                  display.
                </p>

                <p>
                  Your avatar should be a square ratio. The larger the
                  file-size, the longer your booth will take to load, so we
                  suggest optimizing your image before uploading.
                </p>

                <p>Animated gifs are supported in both booths and takes.</p>

                <p>
                  Images and gifs in takes should be under 5mb if possible.
                  There are no required pixel dimensions. PencilBooth supports
                  square, landscape or portrait formats and will never crop your
                  images.
                </p>

                <p>
                  There is no limit on pixel dimensions, however we suggest a
                  minimum width of 800px if possible.
                </p>
              </div>
            </Accordion>

            <Accordion title="Can I take my subscribers with me if it's not for me?">
              <div className="flex flex-col gap-4">
                <p>
                  Yup! You can export your subscribers anytime and take them to
                  another platform. They are your audience, not ours.
                </p>
              </div>
            </Accordion>

            <Accordion title="Can I trust you with my data?">
              <div className="flex flex-col gap-4">
                <p>
                  Yes. We take your data and privacy very seriously. Our whole
                  business is built on this foundation and we have a pretty good
                  track record in that area. If you want to get into the nitty
                  gritty, check out our privacy policy{" "}
                  <a
                    href="https://pencilbooth.com/privacy"
                    className="underline"
                  >
                    here
                  </a>
                  .
                </p>
              </div>
            </Accordion>

            <Accordion title="What is the first noble truth?">
              <div className="flex flex-col gap-4">
                <p>
                  There is no relief or relaxation when we are in the samsaric
                  world; there is always some kind of struggle going on. Even
                  when we are supposedly enjoying life, there is still struggle
                  and all kinds of discomfort. We may try to solve that problem
                  by going out to restaurants or the cinema, or by enjoying our
                  friends; nonetheless, nothing really helps. That is what is
                  called the first noble truth, the truth of suffering.
                  Seemingly we are trapped without hope or any way out. And once
                  we are in that situation, we are always in that situation: we
                  are in pain all the time…the more we understand our state of
                  being, the more we will understand why we are in pain. What we
                  find is that the more into ourselves we are, the more we
                  suffer, and the less into ourselves we are, the less we
                  suffer…the reality is that we are all trapped in samsaric
                  neurosis, everybody, without exception. It is best that you
                  work with reality rather than with ideals. That is a good
                  place to begin.
                </p>
                <p>—Chögyam Trungpa Rinpoche</p>
              </div>
            </Accordion>
          </div>
        </div>

        <div className="flex flex-col gap-12 w-full px-6 items-center py-16 bg-inverted-hover">
          <div className="max-w-[619px] rounded" style={{ height: 445 }}>
            <img
              src="https://res.cloudinary.com/pencilbooth-production/image/upload/v1737957625/JWHQ_auitud.jpg"
              className="object-contain h-full"
            />
          </div>

          <div className="flex flex-col items-center gap-8 w-full max-w-[1154px]">
            <h1 className="text-heading-xxl font-bold w-full text-center">
              Made by people who actually send newsletters.
            </h1>

            <p className="text-center text-title leading-heading px-6">
              PencilBooth is a venture from{" "}
              <a
                className="underline"
                href="https://www.jackywinter.com/"
                target="_blank"
              >
                The Jacky Winter Group
              </a>
              , a creative representation and production agency with offices in
              Australia, the US, and the UK. We have been in business for over
              eighteen years and we know a thing or two about promoting creative
              people and getting their work seen. PencilBooth was originally
              designed as a custom app to promote our own artists to our clients
              and to try to solve our own biggest challenge of continually
              shouting into the abyss of social media, but we figured it had
              more potential in the hands of creatives themselves, so here we
              are!
              <br />
              <br />
              You can find out more about us at{" "}
              <a
                className="underline"
                href="https://www.jackywinter.com/"
                target="_blank"
              >
                jackywinter.com
              </a>{" "}
              or hey, maybe{" "}
              <a
                className="underline"
                href="https://pencilbooth.com/jackywinter"
                target="_blank"
              >
                subscribe to our PencilBooth
              </a>{" "}
              to find out more!
            </p>

            <LinkButton
              to={JOIN_ROUTE}
              label="Get started"
              suffixIcon={<PBIcons icon="ArrowRight" />}
            />
          </div>
        </div>

        <div className="flex flex-col items-center w-full lg:px-6">
          <div className="flex flex-col items-center gap-8 w-full lg:rounded max-w-[1154px] px-6 py-8 bg-primary text-inverted">
            <h1 className="text-heading-xl font-bold w-full text-center">
              Claim your booth
            </h1>

            <p className="leading-[170%] max-w-[704px] text-center">
              Getting started with PencilBooth is free, easy, and bound to make
              you forget about the fleeting impermanence of life for at least
              five to seven minutes.
            </p>

            <LinkButton
              label="Sign Up"
              suffixIcon={<PBIcons icon="ArrowRight" />}
              style="inverted"
              bodyBackground="primary"
              to={JOIN_ROUTE}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
