import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAuth } from "@/hooks/useAuth";
import { updateUser } from "@/apis/users";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import Textarea from "@/components/Textarea";
import Button from "@/components/Button";
import Modal from "@/components/Modal";

import { MESSAGE_INITIAL_VALUE, MESSAGE_VALIDATION_SCHEMA } from "./constants";

const SubscriberWelcomeModal = ({
  subscriberModalOpen,
  setSubscriberModalOpen,
  getUserAPI,
}) => {
  const { user } = useAuth();
  const [btnLoader, setBtnLoader] = useState(false);

  const { formState, setValue, watch, reset } = useForm({
    resolver: yupResolver(MESSAGE_VALIDATION_SCHEMA),
    defaultValues: user.id ? user : MESSAGE_INITIAL_VALUE,
  });

  useEffect(() => {
    reset(user);
  }, [user]);

  const updateUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateUser(user.id, {
        user: { welcome_message: watch().welcome_message },
      });
      showToastrSuccess(data.message);
      getUserAPI();
      setSubscriberModalOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <Modal
      size="medium"
      open={subscriberModalOpen}
      onClose={() => setSubscriberModalOpen(false)}
      title="Subscriber welcome message"
    >
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-col w-full gap-6">
          <Textarea
            id="welcome_message"
            name="welcome_message"
            onChange={(e) => setValue("welcome_message", e.target.value)}
            value={watch().welcome_message}
            placeholder="Write message here..."
            className="bg-inverted-hover"
            error={formState.errors.email?.welcome_message}
            bodyBackground="primary"
            style="primary"
          />
        </div>

        <div className="flex gap-4">
          <Button
            type="submit"
            label="Save changes"
            style="inverted"
            loading={btnLoader}
            onClick={() => updateUserResponse()}
            bodyBackground="primary"
          />
          <Button
            label="Cancel"
            style="transparent"
            onClick={() => setSubscriberModalOpen(false)}
            bodyBackground="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default SubscriberWelcomeModal;
