import React from "react";
import Slider from "react-slick";

const ThumbnailCard = ({
  gallery,
  image,
  mobileView = false,
  paidView = false,
  take,
}) => {
  const { has_view_access, paid_content, caption, title, blur_generated } =
    image;

  var settings = {
    infinite: false,
    speed: 300,
    customPaging: function (i) {
      return (
        <div className="h-14 relative">
          <img
            src={gallery[i].attachment_url}
            className="h-full w-full object-cover rounded-sm cursor-pointer"
          />

          {isBlurBackground() && (
            <div className="absolute inset-0 rounded-sm backdrop-blur-md"></div>
          )}
        </div>
      );
    },
    dots: true,
    dotsClass: "pb-slick-thumb",
    autoplay: true,
    arrows: false,
  };

  const isBlur = () => {
    return (
      (paid_content || take.has_paid_content) && (!has_view_access || paidView)
    );
  };

  const isBlurBackground = () => {
    return isBlur() && (!blur_generated || has_view_access);
  };

  const renderImage = (image) => {
    return (
      <img
        src={image.attachment_url}
        alt={image.alt_text}
        className={`w-full rounded object-contain aspect-[3/2] focus:outline-none focus-visible:outline-none ${
          isBlurBackground() ? "image-filter" : ""
        }`}
      />
    );
  };

  return (
    <div
      className={`flex flex-col items-center gap-8 w-full ${
        mobileView ? "px-4" : "px-4 md:px-8"
      }`}
    >
      <div className="relative slider-container w-full max-w-[var(--take-width)] rounded overflow-hidden">
        <Slider {...settings}>
          {gallery.map((image, index) => {
            return (
              <div
                key={index}
                className="rounded focus:outline-none focus-visible:outline-none overflow-hidden relative"
              >
                {paid_content && paidView ? (
                  <a href={take.generate_redirect_url}>{renderImage(image)}</a>
                ) : image.link ? (
                  <a href={image.link}>{renderImage(image)}</a>
                ) : (
                  renderImage(image)
                )}
              </div>
            );
          })}
        </Slider>

        {isBlur() && (
          <a href={take.generate_redirect_url}>
            <div className="absolute left-4 bottom-[90px] z-[10] cursor-pointer bg-inverted px-3 py-1.5 rounded-sm shadow">
              🔒 This content is for paid subscribers. Please upgrade to view.
            </div>
          </a>
        )}
      </div>

      {!isBlur() && (!!title || !!caption) && (
        <div className="flex flex-col gap-4 w-full max-w-[var(--take-width)]">
          {!!title && (
            <div
              className={`font-bold ${
                mobileView ? "text-mobile-heading" : "text-heading"
              }`}
              dangerouslySetInnerHTML={{
                __html: title?.split("\n")?.join("<br/>"),
              }}
            />
          )}

          {!!caption && (
            <div
              dangerouslySetInnerHTML={{
                __html: caption?.split("\n")?.join("<br/>"),
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ThumbnailCard;
