import React, { useState } from "react";
import classnames from "classnames";
import AnimateHeight from "react-animate-height";

import { PBIcons } from "@/components/PBIcons";

const Callout = ({
  message = "Callout Message",
  style = "primary",
  dismissable = false,
}) => {
  const [visible, setVisible] = useState(true);

  const BUTTON_STYLES = {
    primary: "bg-secondary text-inverted",
    secondary: "bg-inverted-hover text-primary",
    danger: "bg-danger text-inverted",
  };

  const CLOSE_BUTTON_STYLES = {
    primary: "hover:bg-secondary-hover",
    secondary: "hover:bg-disabled",
    danger: "hover:bg-danger-hover",
  };

  return (
    <AnimateHeight duration={300} height={visible ? "auto" : 0}>
      <div
        className={classnames(
          "flex justify-between items-center gap-4 w-full px-3 py-2 rounded-sm",
          BUTTON_STYLES[style]
        )}
      >
        <p>{message}</p>

        {dismissable && (
          <div
            className={classnames(
              "flex justify-center items-center shrink-0 p-1 rounded-sm cursor-pointer bg-transparent transition-all duration-300",
              CLOSE_BUTTON_STYLES[style]
            )}
            onClick={() => setVisible(false)}
          >
            <PBIcons icon="Close" />
          </div>
        )}
      </div>
    </AnimateHeight>
  );
};

export default Callout;
