import React, { useState } from "react";
import classnames from "classnames";
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";
import TransparentIcon from "@/assets/images/transparent-icon.png";

import Menu from "@/components/Menu";
import { PBIcons } from "@/components/PBIcons";

const ColorPicker = ({
  name,
  label,
  helpText,
  error,
  value,
  onChange = () => {},
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="flex flex-col gap-1 w-full">
      {!!label && (
        <label htmlFor={name} className="pb-input-label">
          {label}
        </label>
      )}

      <Menu
        open={menuOpen}
        setOpen={setMenuOpen}
        width="200"
        position="bottom-start"
        menuTrigger={
          <div
            className="flex items-center gap-2 p-2"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {value == "inherit" ? (
              <img
                className="w-[22.4px] h-[22.4px] rounded-sm border border-solid border-primary-border"
                src={TransparentIcon}
              />
            ) : (
              <div
                className="w-[22.4px] h-[22.4px] rounded-sm border border-solid border-primary-border"
                style={{ backgroundColor: value }}
              />
            )}

            <span>{value}</span>
            <span
              className={classnames("pb-dropdown-trigger-icon", {
                active: menuOpen,
                inactive: !menuOpen,
              })}
            >
              <PBIcons icon="ChevronDown" />
            </span>
          </div>
        }
      >
        <div className="flex flex-col gap-2.5">
          <HexAlphaColorPicker color={value} onChange={onChange} />

          <HexColorInput
            color={value === "inherit" ? "#00000000" : value}
            onChange={onChange}
            placeholder="Type a color"
            prefixed
            alpha
            className="block w-full rounded-sm px-2 py-1.5 text-primary border border-solid border-primary-border outline-none transition-all duration-300 placeholder:text-muted"
          />
        </div>
      </Menu>

      {!!error && <p className="pb-error-text">{error}</p>}
      {!!helpText && <p className="pb-help-text">{helpText}</p>}
    </div>
  );
};

export default ColorPicker;
