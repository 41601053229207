import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { getTake } from "@/apis/takes";
import Loader from "@/components/Loader";
import { useAuth } from "@/hooks/useAuth";

import Header from "./Header";
import Banner from "./Banner";
import Intro from "./Intro";
import ImageCard from "./ImageCard";
import ThumbnailCard from "./ThumbnailCard";
import GridCard from "./GridCard";
import BRoll from "./BRoll";
import Outro from "./Outro";
import Follow from "./Follow";
import Friends from "./Friends";
import TakeFooter from "./TakeFooter";
import ApprovalBanner from "./ApprovalBanner";

const TakeDetailsPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { takeId } = useParams();
  const [takeDetail, setTakeDetail] = useState();
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    if (takeId) getTakeAPI();
  }, [takeId]);

  const getTakeAPI = async () => {
    try {
      const { data } = await getTake(takeId);
      setTakeDetail(data.take);
      setPageLoader(false);
    } catch (error) {
      if (error.response.status === 404) {
        navigate("/not_found");
      }
      showToastrError(error.response.data.errors);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-page-wrapper bg-inverted">
      <div className="pb-page-container flex flex-col items-center gap-12 w-full pt-6 md:pt-8">
        <div className="flex flex-col items-center gap-12 w-full px-4 md:px-8">
          <Header take={takeDetail} />
          <Banner take={takeDetail} />
          {takeDetail.intro?.length > 0 && <Intro take={takeDetail} />}
        </div>

        {takeDetail.attachments_attributes.map((image) => {
          if (image.view_type == "single") {
            return <ImageCard key={image.id} image={image} take={takeDetail} />;
          } else if (image.view_type == "thumbnail") {
            return (
              <ThumbnailCard
                key={image.id}
                image={image}
                gallery={image.gallery_attachments_attributes}
                take={takeDetail}
              />
            );
          } else if (image.view_type == "grid") {
            return (
              <GridCard
                key={image.id}
                image={image}
                gallery={image.gallery_attachments_attributes}
                take={takeDetail}
              />
            );
          }
        })}

        <div className="flex flex-col items-center gap-12 w-full px-4 md:px-8">
          {takeDetail.link_rolls_enabled && <BRoll take={takeDetail} />}
          {takeDetail.outro?.length > 0 && <Outro take={takeDetail} />}

          <Follow take={takeDetail} />

          {takeDetail.subscriptions.length > 0 && (
            <Friends subscriptions={takeDetail.subscriptions} />
          )}
        </div>

        {user && user.superadmin && takeDetail.under_review && (
          <ApprovalBanner
            takeDetail={takeDetail}
            setTakeDetail={setTakeDetail}
          />
        )}

        <TakeFooter />
      </div>
    </div>
  );
};

export default TakeDetailsPage;
