import React from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { ORDINALIZED_DATE_FORMAT } from "@/lib/constants";

const Header = ({ take }) => {
  dayjs.extend(advancedFormat);

  return (
    <div className="flex flex-col items-center w-full max-w-[var(--take-width)] bg-take-header-background rounded-sm px-2 py-4 gap-1">
      <p className="text-center">
        {`${take.title} — ${dayjs(take.display_date || take.drafted_at).format(
          ORDINALIZED_DATE_FORMAT
        )}`}
      </p>

      <a className="text-cente underline" href={`/takes/${take.id}`}>
        View in browser
      </a>
    </div>
  );
};

export default Header;
