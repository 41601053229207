import React, { useState, useEffect } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { getProfileUrl } from "@/lib/utils";
import { getSubscriberFormSetting } from "@/apis/subscriber_form_settings";
import { createSubscription } from "@/apis/subscriptions";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Loader from "@/components/Loader";
import SocialLinks from "@/components/SocialLinks";
import SignUpAlert from "@/components/SignUpAlert";
import UserPlanModal from "@/components/UserPlanModal";

import { VALIDATION_SCHEMA, INITIAL_VALUE } from "./constants";

const SubscriberForm = () => {
  const navigate = useNavigate();
  const { urlSlug } = useParams();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [showPlans, setShowPlans] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState();
  const [subscribed, setSubscribed] = useState(false);
  const [signUpAlert, setSignUpAlert] = useState(false);
  const [upgradedToPaid, setUpgradedToPaid] = useState(false);

  const { handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: INITIAL_VALUE,
    shouldValidate: true,
    shouldDirty: true,
    mode: "onChange",
  });

  useEffect(() => {
    getSubscriberFormSettingAPI();

    if (searchParams.get("upgraded_to_paid") === "true") {
      setUpgradedToPaid(true);
    }

    if (searchParams.get("show_plans")) {
      setShowPlans(true);
    }

    if (
      searchParams.get("new_user") === "true" &&
      searchParams.get("success")
    ) {
      setSignUpAlert(true);
    }

    document.getElementById("beacon-container")?.remove();
  }, []);

  useEffect(() => {
    if (
      subscriptionId &&
      user.paid_subscription_enabled &&
      !user.double_opt_in_subscription
    ) {
      setShowPlans(true);
    } else if (subscriptionId && !user.paid_subscription_enabled) {
      setSubscribed(true);
    }
  }, [user, subscriptionId]);

  const getSubscriberFormSettingAPI = async () => {
    try {
      const { data } = await getSubscriberFormSetting(urlSlug);
      setUser(data.user);
      setPageLoader(false);
    } catch (error) {
      navigate("/not_found");
      showToastrError(error.response.data.errors);
    }
  };

  const createSubscriptionAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createSubscription({
        subscription: { email: watch().email, user_id: user.id },
      });
      setSubscriptionId(data.id);
      showToastrSuccess(data.message);
      setSubscribed(true);

      if (user.paid_subscription_enabled && !data.upgraded_to_paid) {
        setShowPlans(true);
      }
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col items-center justify-top md:justify-center bg-muted bg-cover bg-center bg-no-repeat min-h-dvh w-full md:p-12"
      style={{
        backgroundImage: `url(${user.cover_image_url})`,
      }}
    >
      <div
        className={
          "h-[180px] sm:h-[200px] md:hidden w-full bg-cover bg-center bg-no-repeat"
        }
        style={{
          backgroundImage: `url(${user.cover_image_url})`,
        }}
      ></div>

      <div className="flex flex-col items-center w-full bg-primary md:bg-transparent grow md:grow-0">
        <div className="flex flex-col items-center gap-6 px-10 md:px-16 py-12 w-full max-w-[520px] bg-primary text-inverted md:rounded-sm grow md:grow-0">
          <div className="flex flex-col items-center gap-2 w-full">
            <div className="overflow-hidden rounded-full">
              <img
                src={user.logo_url}
                alt="Avatar"
                className="h-12 w-12 object-cover"
              />
            </div>
          </div>

          <h2 className="text-heading font-bold text-center">
            {user.display_name}
          </h2>

          {user.welcome_message?.length > 0 && (
            <p className="text-center">{user.welcome_message}</p>
          )}

          {subscribed || upgradedToPaid ? (
            <p>Thanks for subscribing!</p>
          ) : (
            <form
              className="flex flex-row items-center gap-2 w-full py-4"
              onSubmit={(e) => {
                e.preventDefault();

                if (watch().email) {
                  handleSubmit(createSubscriptionAPI());
                } else {
                  showToastrError("Please enter an email!");
                }
              }}
            >
              <Input
                placeholder="Enter Email Address"
                value={watch().email}
                onChange={(e) => setValue("email", e.target.value)}
                bodyBackground="primary"
                style="primary"
              />
              <Button
                type="submit"
                loading={btnLoader}
                label="Subscribe"
                className="h-auto !p-3 w-fit"
                style="inverted"
                bodyBackground="primary"
              />
            </form>
          )}

          <div className="flex flex-col items-center gap-6 w-full bg-primary text-inverted">
            {user.link_trees.length > 0 && (
              <div className="flex flex-col items-center gap-3 w-full">
                {user.link_trees.map((linkTree) => {
                  return (
                    <a
                      className="flex justify-center w-full p-3 min-h-12 rounded-sm bg-inverted text-primary text-center"
                      href={linkTree.url}
                    >
                      {linkTree.name}
                    </a>
                  );
                })}
              </div>
            )}

            <Link to={getProfileUrl(user.url_slug)}>View archives</Link>

            {user.social_links_present && (
              <div className="flex flex-col items-center gap-6 w-full pt-5">
                <SocialLinks user={user} />
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        size="planModal"
        open={showPlans}
        onClose={() => setShowPlans(false)}
      >
        <UserPlanModal
          user={user}
          subscriptionId={subscriptionId}
          signUpAlert={signUpAlert}
        />
      </Modal>

      <SignUpAlert modalOpen={signUpAlert} setModalOpen={setSignUpAlert} />
    </div>
  );
};

export default SubscriberForm;
