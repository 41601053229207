import { useState, useEffect } from "react";
import * as R from "ramda";
import { toast, Flip } from "react-toastify";

export const showToastrError = (error) => {
  let message;
  if (typeof error === "string") {
    message = error;
  } else if (typeof error === "object") {
    message = R.join("\n", error);
  } else if (error?.data && error?.data?.errors) {
    message = R.join("\n", error?.data?.errors);
  } else {
    message = "Something went wrong! Please try after sometimes.";
  }

  toast.error(message, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
    transition: Flip,
  });
};

export const showToastrSuccess = (success) => {
  let message;
  if (typeof success === "string") {
    message = success;
  } else if (typeof success === "object") {
    message = R.join("\n", success);
  } else {
    message = "Data has been saved!";
  }

  toast.success(message, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
    transition: Flip,
  });
};
