import React, { useState } from "react";
import pluralize from "pluralize";
import { useLocation } from "react-router-dom";

import { createBilling } from "@/apis/settings/billings";
import Modal from "@/components/Modal";
import Button from "@/components/Button";

const BundleModal = ({ open, setOpen, emailCount, totalAmount }) => {
  const location = useLocation();
  const [btnLoader, setButtonLoader] = useState(false);

  const createBillingAPI = async () => {
    try {
      setButtonLoader(true);
      const { data } = await createBilling({
        billing: { email_count: emailCount, redirect_to: location.pathname },
      });

      window.location.href = data.checkout_url;
    } catch (error) {
      showToastrError(error.data.response.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title="Pay as you go to send take:"
      size="medium"
    >
      <div className="flex flex-col w-full gap-10">
        <p>
          Buying {emailCount} {pluralize("email", emailCount)} for $
          {totalAmount}
        </p>

        <div className="flex gap-4">
          <Button
            label="Pay Now"
            style="inverted"
            bodyBackground="primary"
            loading={btnLoader}
            onClick={() => createBillingAPI()}
          />

          <Button
            label="Cancel"
            style="transparent"
            onClick={() => setOpen(false)}
            bodyBackground="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default BundleModal;
