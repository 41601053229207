import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { ORDINALIZED_DATE_FORMAT } from "@/lib/constants";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { PBIcons } from "@/components/PBIcons";

const Title = ({
  formWatch,
  setValue,
  handleSubmitWithStatus,
  draftBtnLoader,
  titleModalOpen,
  setTitleModalOpen,
}) => {
  const [title, setTitle] = useState(formWatch.title);

  useEffect(() => {
    if (titleModalOpen) {
      setTitle(formWatch.title);
    }
  }, [titleModalOpen]);

  return (
    <div className="flex flex-col items-center w-full max-w-[var(--take-width)]">
      <div className="flex flex-col items-center w-full bg-take-header-background rounded-sm px-2 py-4 gap-1">
        <p className="flex items-center gap-1">
          <span
            className="flex items-center gap-1 cursor-pointer"
            onClick={() => setTitleModalOpen(true)}
          >
            <PBIcons icon="Pencil" size="12" />
            {formWatch.title}
          </span>

          {` — ${dayjs(formWatch.display_date || formWatch.drafted_at).format(
            ORDINALIZED_DATE_FORMAT
          )}`}
        </p>

        <a className="text-cente underline" href={`/takes/${formWatch.id}`}>
          View in browser
        </a>
      </div>

      <Modal
        open={titleModalOpen}
        onClose={() => setTitleModalOpen(false)}
        title="Edit Title"
      >
        <div className="flex flex-col w-full gap-10">
          <form
            id="title-form"
            onSubmit={(e) => {
              e.preventDefault();
              setValue("title", title);
              handleSubmitWithStatus();
            }}
            className="flex flex-col w-full gap-6"
          >
            <Input
              id="title"
              name="title"
              value={title}
              placeholder="Enter a title"
              onChange={(e) => setTitle(e.target.value)}
              bodyBackground="primary"
              style="primary"
              required
            />
          </form>

          <div className="flex gap-4">
            <Button
              form="title-form"
              type="submit"
              label="Save"
              loading={draftBtnLoader}
              style="inverted"
              bodyBackground="primary"
            />
            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => setTitleModalOpen(false)}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Title;
