import React from "react";

import { Link } from "react-router-dom";

import { PRIVACY_ROUTE } from "@/lib/routes";

const PrivacyProformaPage = () => {
  return (
    <div className="pb-page-wrapper bg-inverted-hover">
      <div className="pb-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-12 w-full max-w-[var(--layout-max-width)]">
          <h2 className="text-heading font-bold">Privacy Policy (PRO FORMA)</h2>

          <div className="flex flex-col gap-4">
            <p>
              This Privacy Policy details how we use, collect, store, and
              disclose information supplied by you to us when you subscribe to
              our newsletter and associated mailing lists (the{" "}
              <b>"Subscription Service"</b>). Specifically, this Privacy Policy
              details how we comply with the Privacy Act 1988 (Cth) (
              <b>"Privacy Act"</b>), the Australian Privacy Principles ("APPs"),
              and, to the extent that it applies, the EU General Data Protection
              Regulation 2016/679 (<b>"GDPR"</b>).
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h5 className="text-title">
              <strong>WHAT INFORMATION DO WE COLLECT</strong>
            </h5>

            <p>
              We will collect different types of information from you to provide
              the Subscription Service, including:
            </p>

            <p>
              <b>Personal Information</b> – this is information that can be used
              to personally identify you such as your name and email address. We
              will not knowingly collect or process the Personal Information of
              anyone under the age of 18 without their parent or guardian's
              consent.
            </p>

            <p>
              <b>Usage Information</b> – this is information that may be
              collected automatically through your use of the Subscription
              Service, such as information that identifies your operating system
              and IP address.
            </p>

            <p>
              The GDPR recognises that Usage Information, whilst for the most
              part anonymous, can be cumulatively used to directly or indirectly
              identify you. Usage Information that can be used to identify you
              in any way, together with your Personal Information, will
              collectively be referred to in this Privacy Policy as{" "}
              <b>"Personal Data"</b>.
            </p>

            <p>
              We recognise your right under the Spam Act 2003 (Cth) and the GDPR
              to opt out from direct marketing, and as such these consents can
              be modified at any time by contacting us or clicking unsubscribe
              on any direct marketing communications.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h5 className="text-title">
              <strong>HOW WE COLLECT YOUR INFORMATION</strong>
            </h5>

            <p>
              We will collect Personal Information from you when you:
              <ul className="pl-6 text-small list-disc">
                <li className="py-0.5 md:py-1">
                  Sign up to a Subscription Service;
                </li>
                <li className="py-0.5 md:py-1">
                  Contact us by phone, email, or otherwise.
                </li>
              </ul>
              It is your choice to provide Personal Information to us. Wherever
              it is lawful and practicable, you have the option not to identify
              yourself when interacting with us. However, if you do not wish to
              provide your Personal Information, this may limit our ability to
              provide the Subscription Service to you.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h5 className="text-title">
              <strong>THIRD PARTY SITES</strong>
            </h5>

            <p>
              We may use third party sites, software, and plug-ins (each{" "}
              <b>"Third Party Platform"</b>) to provide the Subscription
              Service. We do not have access to, or control over, the
              technologies that Third Party Platforms may use to collect
              information about you. Please refer directly to the privacy
              policies and statements of the operator of any Third Party
              Platforms to obtain information regarding their data collection,
              use, and disclosure policies. For a full list of Third Party
              Platforms we are utilising, please contact us.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h5 className="text-title">
              <strong>COOKIE POLICY</strong>
            </h5>

            <p>
              We may, or Third Party Platforms that we use may, from time to
              time collect anonymous Usage Information in connection with your
              use of the Subscription Service, by utilising cookies, pixel tags
              and other tracking technologies (collectively <b>"Cookies"</b>).
              Cookies are small packets of data that are downloaded onto your
              device when you access a website. Cookies hold specific
              information that helps a website 'remember' your actions and
              preferences over time.
            </p>

            <p>
              Cookies can stay on your device temporarily ("Session Cookies") or
              until you manually delete them ("Persistent Cookies"). You can
              adjust your browser settings at any time to block Cookies, however
              please note that doing so may limit our ability to provide the
              Subscription Service to you in a fully operational form.
            </p>

            <p>
              To request a full list of the individual Cookies and tracking
              technologies we use, please contact us.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h5 className="text-title">
              <strong>HOW WE USE YOUR INFORMATION</strong>
            </h5>

            <p>
              We may use your Personal Data to provide the Subscription Service
              to you, including to contact you via email or other messaging
              platforms to provide you with periodical promotional messages
              about new products and brand, or other information which we think
              you may find interesting, only where you have opted in to
              receiving such communication and until you 'opt out'.
            </p>

            <p>
              We will only use your Personal Data for purposes that you would
              reasonably expect us to use your Personal Data for in connection
              with providing the Subscription Service to you, or where we are
              required by law to collect your Personal Data.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h5 className="text-title">
              <strong>WHO WE DISCLOSE YOUR INFORMATION TO</strong>
            </h5>

            <p>
              You agree and consent to us disclosing your Personal Data to
              service providers with whom we have entered into an agreement with
              to help us provide the Subscription Service, including marketing
              agencies, financial service providers, and technical support. We
              currently use PencilBooth to provide the Subscription Service,
              whose privacy policy may be found{" "}
              <Link
                to={PRIVACY_ROUTE}
                rel="noopener noreferrer"
                className="underline"
                onClick={() => window.scrollTo(0, 0)}
              >
                here
              </Link>
              . You expressly consent to us sharing your Personal Data with
              PencilBooth in connection with the Subscription Service.
            </p>

            <p>
              You can withdraw your consent for us to share your Personal Data
              with third parties at any time by contacting us, but please note
              that withdrawal of such consents may affect your ability to access
              and use the Subscription Service.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h5 className="text-title">
              <strong>YOUR RIGHTS TO YOUR INFORMATION</strong>
            </h5>

            <p>
              You have a general right to access any Personal Information that
              is held about you by us, unless a valid exception applies. You can
              request this at any time by contacting us.
            </p>

            <p>
              You are solely responsible for maintaining the truth, accuracy,
              and completeness of your information at all times, and we shall
              have no liability to you or any third party arising from your
              failure to do the same. Please note that we cannot modify your
              Personal Information. You can modify your own information at any
              time by contacting us.
            </p>

            <p>
              In accordance with the GDPR, we acknowledge the additional rights
              of EU subjects to:
              <ul className="pl-6 text-small list-disc">
                <li className="py-0.5 md:py-1">
                  have their data erased that is no longer being used for a
                  legitimate purpose;
                </li>
                <li className="py-0.5 md:py-1">
                  request a copy of all Personal Data held about them in a
                  readable format, along with supplementary information to
                  verify that such Personal Data is being processed lawfully;
                  and
                </li>
                <li className="py-0.5 md:py-1">
                  request restricted processing of their Personal Data whilst
                  any complaints or concerns are being resolved.
                </li>
              </ul>
              To erase, request, or restrict processing of your Personal Data,
              please contact us.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h5 className="text-title">
              <strong>SECURITY</strong>
            </h5>

            <p>
              We will take reasonable steps to protect your Personal Data.
              However, no security measures are 100% secure so we cannot
              guarantee the security of your information or data at any time. To
              the extent permitted by law, we accept no liability for any breach
              of security or any unintentional disclosure, loss or misuse of any
              information or data. We acknowledge our obligation to report any
              data breach that is likely to risk the rights and freedoms of
              natural persons to the Australian Information Commissioner and,
              where our data breach involves the information of EU subjects,
              report to the European Data Protection Supervisor. We will also
              inform you, where possible, if your data has been breached in the
              circumstance where it poses a risk of serious harm or your rights
              and freedoms.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h5 className="text-title">
              <strong>OVERSEAS DISCLOSURE</strong>
            </h5>

            <p>
              We may, or a Third Party Platform may, in the course of providing
              the services to you, transfer your Personal Data to overseas
              countries that are deemed by the EU Commission as having an
              'adequate' level of Personal Data protection. Where we transfer
              data to a third party in a country where no adequacy decision has
              been made, we will take reasonable steps to ensure person or
              entity handling your data in those countries are bound under
              contract to meet the requirements of the Privacy Act, APPs, and
              GDPR (as applicable).
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <h5 className="text-title">
              <strong>CONTACT</strong>
            </h5>

            <p>
              Thank you for taking the time to read our Privacy Policy. If you
              have any questions regarding our Privacy Policy, you can contact
              us by the method stipulated in our communications to you. If
              you're unsure who to contact, please{" "}
              <a
                className="underline"
                href="mailto:team@pencilbooth.com"
                target="undefined"
                rel="noopener noreferrer"
              >
                team@pencilbooth.com
              </a>
              .
            </p>

            <p>
              If you are not satisfied with our handling of your Personal Data,
              or have any other concern over our Privacy Policy, then you may
              lodge a formal complaint with the Office of the Australian
              Information Commissioner (for more information, please see{" "}
              <a
                className="underline"
                href="http://www.oaic.gov.au"
                target="undefined"
                rel="noopener noreferrer"
              >
                www.oaic.gov.au
              </a>
              ) or with the European Data Protection Supervisor (for more
              information, please see{" "}
              <a
                className="underline"
                href="https://edps.europa.eu"
                target="undefined"
                rel="noopener noreferrer"
              >
                https://edps.europa.eu
              </a>
              ).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyProformaPage;
