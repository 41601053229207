import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { useAuth } from "@/hooks/useAuth";
import { authenticate } from "@/apis/sessions";
import { setAuthHeaders, registerIntercepts } from "@/apis/axios";
import { AUTH_TOKEN } from "@/lib/constants";
import HelpScoutBeacon from "@/components/HelpScoutBeacon";

const AppLayout = () => {
  const { setUser, showBeacon, logout } = useAuth();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    registerIntercepts(logout);

    const authToken = localStorage.getItem(AUTH_TOKEN);

    if (authToken) {
      authenticateUser(authToken);
    } else {
      document.getElementById("global-loader").classList.add("hidden");
      setLoader(false);
    }
  }, []);

  const authenticateUser = async () => {
    await setAuthHeaders();

    try {
      const { data } = await authenticate();
      setUser(data.user);
    } catch (error) {
      localStorage.removeItem(AUTH_TOKEN);
      setUser(null);
      localStorage.removeItem("user");
    } finally {
      document.getElementById("global-loader").classList.add("hidden");
      setLoader(false);
    }
  };

  if (loader) return null;

  return (
    <>
      <Outlet />
      {showBeacon && <HelpScoutBeacon />}
    </>
  );
};

export default AppLayout;
