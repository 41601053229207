import React from "react";

import { createAttachment } from "@/apis/settings/attachments";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { MAX_IMAGE_SIZE } from "@/lib/constants";

const ImageUploader = ({ inputRef, type, position, setLoader, getUserAPI }) => {
  const handleChange = (event) => {
    const file = event.target.files[0];
    let reader = new FileReader();

    reader.onloadend = (e) => {
      if (file.size > MAX_IMAGE_SIZE) {
        showToastrError("Attachment size exceeds the allowable limit (5 MB).");
      } else {
        createAttachmentAPI({
          file_data: e.target.result,
          file_name: file.name,
          position: position,
          caption: file.name,
          attachable_kind: type,
        });
      }
    };

    reader.readAsDataURL(file);
  };

  const createAttachmentAPI = async (imageData) => {
    try {
      setLoader(true);
      await createAttachment({
        attachment: { ...imageData },
      });

      if (type === "cover") {
        showToastrSuccess("Background image has been successfully uploaded.");
      } else if (type === "logo") {
        showToastrSuccess("Avatar image has been successfully uploaded.");
      } else {
        showToastrSuccess("Image uploaded!");
      }

      await getUserAPI();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setLoader(false);
    }
  };

  return (
    <input
      ref={inputRef}
      type="file"
      accept="image/jpg, image/jpeg, image/gif, image/png"
      className="h-full w-full absolute opacity-0 top-0 left-0 -z-10"
      onChange={(event) => handleChange(event)}
    />
  );
};

export default ImageUploader;
