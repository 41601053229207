import React, { useEffect, useState } from "react";

import { updateAttachment } from "@/apis/multiple_take_attachments";
import { showToastrError } from "@/lib/commons";
import { useAuth } from "@/hooks/useAuth";
import Menu from "@/components/Menu";
import { PBIcons } from "@/components/PBIcons";
import { swapElement } from "@/lib/utils";

import DefaultMenu from "./DefaultMenu";
import DeleteMenu from "./DeleteMenu";
import OptionModal from "../GalleryUploader/OptionModal";

const GalleryDropdown = ({
  takeId,
  formState,
  setImageValue,
  setTakeValue,
  handleSave,
  editGalleryOpen,
  setEditGalleryOpen,
  attachments,
  getTakeAPI,
}) => {
  const { user } = useAuth();
  const [images, setImages] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteMenuOpen, setDeleteMenuOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);

  const index = attachments.map((x) => x.id).indexOf(formState.id);

  useEffect(() => {
    setImages(formState.gallery_attachments_attributes);
  }, [formState]);

  useEffect(() => {
    if (menuOpen) {
      setDeleteAlertOpen(false);
    }
  }, [menuOpen]);

  const swapAttachments = (destination) => {
    let newlyOrderedAttachments = swapElement(attachments, index, destination);
    setTakeValue("attachments_attributes", newlyOrderedAttachments);
    reorderAttachmentAPI(newlyOrderedAttachments);
  };

  const reorderAttachmentAPI = async (newItems) => {
    try {
      const { data } = await updateAttachment(takeId, {
        multiple_take_attachments: {
          id: takeId,
          attachments_attributes: newItems,
        },
      });
      setTakeValue("attachments_attributes", data.attachments);
    } catch (error) {
      showToastrError(error.response.data.errors);
      setTakeValue("attachments_attributes", attachments);
    }
  };

  const handleEditClick = () => {
    setEditGalleryOpen(true);
    setMenuOpen(false);
  };

  const handleDeleteClick = () => {
    setDeleteAlertOpen(true);
  };

  const handlePaidClick = () => {
    setImageValue("paid_content", !formState.paid_content);
    handleSave();
  };

  return (
    <div>
      <div className="flex divide-x-[1px] divide-x-primary-border rounded-sm border border-solid border-primary-border">
        <Menu
          open={deleteMenuOpen}
          setOpen={setDeleteMenuOpen}
          width="300"
          position="bottom-end"
          menuTrigger={<PBIcons icon="Delete" />}
          style="transparent"
          borderRadius={false}
        >
          <DeleteMenu
            setOpen={setDeleteMenuOpen}
            takeId={takeId}
            formState={formState}
            setTakeValue={setTakeValue}
          />
        </Menu>

        {user.paid_subscription_enabled && (
          <div
            className={`flex items-center justify-center p-1 transition-all duration-300 cursor-pointer ${
              formState.paid_content
                ? "text-inverted bg-secondary hover:bg-secondary-hover"
                : "text-primary bg-inverted hover:bg-inverted-hover"
            }`}
          >
            <PBIcons icon="Paid" onClick={() => handlePaidClick()} />
          </div>
        )}

        <Menu
          open={menuOpen}
          setOpen={setMenuOpen}
          width="300"
          position="bottom-end"
          style="transparent"
          borderRadius={false}
        >
          {deleteAlertOpen ? (
            <DeleteMenu
              setOpen={setDeleteAlertOpen}
              takeId={takeId}
              formState={formState}
              setTakeValue={setTakeValue}
            />
          ) : (
            <DefaultMenu
              index={index}
              formState={formState}
              swapElement={swapAttachments}
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              handlePaidClick={handlePaidClick}
            />
          )}
        </Menu>
      </div>

      <OptionModal
        takeId={takeId}
        attachmentId={formState.id}
        open={editGalleryOpen}
        setOpen={setEditGalleryOpen}
        images={images}
        setImages={setImages}
        setValue={setTakeValue}
        attachments={attachments}
        editGallery={editGalleryOpen}
        setEditGallery={setEditGalleryOpen}
        galleryView={formState.view_type}
        getTakeAPI={getTakeAPI}
      />
    </div>
  );
};

export default GalleryDropdown;
