import React from "react";

import { PBIcons } from "@/components/PBIcons";

const StepCard = ({ step, active, completed, title, disabled, children }) => {
  const getBackgroundClass = () => {
    if (disabled) {
      return "bg-disabled";
    } else if (completed) {
      return "bg-primary";
    } else if (active) {
      return "bg-disabled";
    } else {
      return "bg-primary-border";
    }
  };

  const getTitleClass = () => {
    if (disabled) {
      return "text-disabled";
    } else if (active || completed) {
      return "text-primary";
    } else {
      return "text-disabled";
    }
  };

  return (
    <div className="flex gap-6 p-6 w-full border-t border-solid border-primary-border">
      <span
        className={`flex justify-center items-center h-8 w-8 rounded-full text-title font-bold shrink-0 text-inverted ${getBackgroundClass()}`}
      >
        {completed ? <PBIcons icon="Check" /> : step}
      </span>

      <div className={`flex flex-col gap-1 w-full ${getTitleClass()}`}>
        <p className="text-title">{title}</p>

        <div className="flex flex-col w-full">{children}</div>
      </div>
    </div>
  );
};

export default StepCard;
