import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useNavigate, Link } from "react-router-dom";
import countryList from "react-select-country-list";

import { dropDownListGenerator, getUnique } from "@/lib/utils";
import { EXPORT_DATE_FORMAT } from "@/lib/constants";
import { ROOT_ROUTE } from "@/lib/routes";
import { useAuth } from "@/hooks/useAuth";
import { getUsers, getUser, updateUser, destroyUser } from "@/apis/users";
import { exportSubscriptionList } from "@/apis/subscriptions";
import { getDisciplines } from "@/apis/settings/disciplines";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";

import Loader from "@/components/Loader";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";
import Switch from "@/components/Switch";
import Textarea from "@/components/Textarea";
import Select from "@/components/Select";
import ImageUploader from "@/components/ImageUploader";
import AsyncPaginateSelect from "@/components/AsyncPaginateSelect";

const ProfilePage = () => {
  const navigate = useNavigate();
  const locationOptions = useMemo(() => countryList().getData(), []);
  const { user, logout, setUser } = useAuth();
  const [pageLoader, setPageLoader] = useState(true);
  const [disciplineList, setDisciplineList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [subscriptionListLoad, setSubscriptionListLoad] = useState(false);
  const [exportBtnLoader, setExportBtnLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [accountClosureReason, setAccountClosureReason] = useState();

  const { handleSubmit, formState, setValue, reset, watch } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: INITIAL_VALUE,
    shouldValidate: true,
    shouldDirty: true,
    mode: "onChange",
  });

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    await getUserResponse();
    await getDisciplineListAPI();
    await getSubscriptionListAPI();
    setPageLoader(false);
  };

  const getUserResponse = async () => {
    try {
      const { data } = await getUser(user.id);
      setUser({ ...user, ...data.user });
      reset(data.user);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getDisciplineListAPI = async () => {
    try {
      const { data } = await getDisciplines();
      setDisciplineList(dropDownListGenerator(data.disciplines, "name"));
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getSubscriptionListAPI = async (
    search = "",
    loadedOptions,
    additional
  ) => {
    try {
      setSubscriptionListLoad(true);
      const pageNumber = additional?.page || 1;

      const { data } = await getUsers(search, pageNumber);
      const responseData = dropDownListGenerator(data.users, "name");
      const dataList = getUnique(
        [...subscriptionList, ...responseData],
        "value"
      );

      setSubscriptionList(dataList);

      return {
        options: responseData,
        hasMore: data.total_records > dataList.length,
        additional: {
          page: pageNumber + 1,
        },
      };
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setSubscriptionListLoad(false);
    }
  };

  const updateUserResponse = async (formState) => {
    try {
      setBtnLoader(true);
      await updateUser(user.id, {
        user: {
          display_name: formState.display_name,
          url_slug: formState.url_slug,
          personal_url: formState.personal_url,
          notification_enabled: formState.notification_enabled,
          location: formState.location,
          location_short_name: formState.location_short_name,
          discipline_ids: formState.discipline_ids,
          double_opt_in_subscription: formState.double_opt_in_subscription,
          follower_ids: formState.follower_ids,
          og_description: formState.og_description,
          og_title: formState.og_title,
          default_take_header_attachment_attributes:
            formState.default_take_header_attachment_attributes,
        },
      });
      showToastrSuccess("Profile has been updated successfully.");
      getUserResponse();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const updateUserAccountClosureResponse = async () => {
    try {
      setBtnLoader(true);
      await updateUser(user.id, {
        user: {
          account_closure_reason: accountClosureReason,
        },
      });
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const destroyUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await destroyUser(user.id);
      setDeleteModal(false);
      showToastrSuccess(data.message);
      logout();
      navigate(ROOT_ROUTE, { replace: true });
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const exportAudienceListResponse = async () => {
    try {
      setExportBtnLoader(true);
      const { data } = await exportSubscriptionList();
      const url = window.URL.createObjectURL(
        new Blob([data.subscription_data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = `audiences_${dayjs().format(EXPORT_DATE_FORMAT)}.csv`;
      link.click();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setExportBtnLoader(false);
    }
  };

  const handleDestroy = async () => {
    if (accountClosureReason) await updateUserAccountClosureResponse();
    await destroyUserResponse();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];

    let reader = new FileReader();
    var image = new Image();

    reader.onloadend = (e) => {
      image.src = reader.result;

      image.onload = function () {
        setValue("default_take_header_attachment_attributes", {
          ...formState.defaultValues.default_take_header_attachment_attributes,
          file_data: e.target.result,
          file_name: file.name,
          imageLoaded: true,
          height: image["height"],
          width: image["width"],
          _destroy: false,
        });
      };
    };
    reader.readAsDataURL(file);
  };

  const handleImageDestroy = () => {
    setValue(
      "default_take_header_attachment_attributes",
      {
        ...watch().default_take_header_attachment_attributes,
        _destroy: 1,
      },
      true
    );
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-dvh bg-inverted-hover">
        <Loader />
      </div>
    );
  }

  return (
    <div className="pb-settings-page-wrapper bg-inverted-hover">
      <div className="pb-settings-page-container flex flex-col items-center w-full p-6 md:p-10">
        <div className="flex flex-col gap-12 w-full max-w-3xl">
          <form
            className="flex flex-col items-end gap-6 w-full max-w-md mx-auto"
            onSubmit={handleSubmit(updateUserResponse)}
          >
            <Input
              id="display_name"
              label="Display Name"
              name="display_name"
              placeholder="Display Name"
              helpText="This is the name of your booth that your subscribers will see."
              value={watch().display_name}
              onChange={(e) => setValue("display_name", e.target.value)}
              error={formState.errors.display_name?.message}
              bodyBackground="inverted-hover"
            />

            <Select
              id="location"
              label="Location"
              name="location"
              placeholder="Location"
              helpText="Enter your country if you wish to be found via location search."
              bodyBackground="inverted-hover"
              value={locationOptions.find(
                (location) => watch().location_short_name == location.value
              )}
              onChange={(e) => {
                setValue("location", e.label);
                setValue("location_short_name", e.value);
              }}
              options={locationOptions}
              error={formState.errors.location?.message}
            />

            <Select
              id="discipline_ids"
              label="Discipline"
              name="discipline_ids"
              placeholder="Select Disciplines"
              bodyBackground="inverted-hover"
              value={disciplineList.filter((discipline) =>
                watch().discipline_ids?.includes(discipline.value)
              )}
              onChange={(opt) => {
                setValue(
                  "discipline_ids",
                  opt.map((discipline) => discipline.value)
                );
              }}
              options={disciplineList}
              isMulti
              helpText="Select up to 3 disciplines that relate to your practice."
              error={formState.errors.discipline_ids?.message}
            />

            <Input
              id="url_slug"
              label="URL Slug"
              name="url_slug"
              placeholder="URL Slug"
              helpText="The URL that comes after pencilbooth.com/ that people will use to subscribe to your booth."
              value={watch().url_slug}
              onChange={(e) => {
                setValue("url_slug", e.target.value);
              }}
              error={formState.errors.url_slug?.message}
              bodyBackground="inverted-hover"
            />

            <Input
              id="personal_url"
              label="Personal URL"
              name="personal_url"
              placeholder="Please enter full URL, including https://"
              helpText="Enter a link to an external site where people can find out more about you and your work. This goes at the footer of your takes and is separate to your bio and social links in your booth."
              value={watch().personal_url}
              onChange={(e) => {
                setValue("personal_url", e.target.value);
              }}
              error={formState.errors.personal_url?.message}
              bodyBackground="inverted-hover"
            />

            <Textarea
              id="og_title"
              name="og_title"
              label="Opengraph Title"
              placeholder={`${user.display_name} is on PencilBooth - a visual newsletter platform for creative types.`}
              helpText="Override your default opengraph title (250 characters max)."
              value={watch().og_title}
              onChange={(e) => {
                setValue("og_title", e.target.value);
              }}
              error={formState.errors.og_title?.message}
              bodyBackground="inverted-hover"
            />

            <Textarea
              id="og_description"
              name="og_description"
              label="Opengraph Description"
              placeholder={`Sign up now to receive updates directly from ${user.display_name} in your inbox.`}
              helpText="Override your default opengraph description (250 characters max)."
              value={watch().og_description}
              onChange={(e) => {
                setValue("og_description", e.target.value);
              }}
              error={formState.errors.og_description?.message}
              bodyBackground="inverted-hover"
            />

            <Switch
              id="notification_enabled"
              label="New Subscriber Notification"
              name="notification_enabled"
              helpText="Get an email alert when you have a new subscriber."
              checked={watch().notification_enabled}
              onChange={() =>
                setValue("notification_enabled", !watch().notification_enabled)
              }
              bodyBackground="inverted-hover"
            />

            <Switch
              id="double_opt_in_subscription"
              name="double_opt_in_subscription"
              label="Double Opt-In Subscription"
              helpText="Toggle this on if you want new subscribers to confirm via email after initially signing up from your booth."
              checked={watch().double_opt_in_subscription}
              onChange={() =>
                setValue(
                  "double_opt_in_subscription",
                  !watch().double_opt_in_subscription
                )
              }
              bodyBackground="inverted-hover"
            />

            <AsyncPaginateSelect
              isMulti
              cacheOptions
              isSearchable
              isClearable
              debounceTimeout={300}
              isLoading={subscriptionListLoad}
              id="follower_ids"
              name="follower_ids"
              label="Follow my Friends"
              placeholder="Select Followers"
              strategy="fixed"
              bodyBackground="inverted-hover"
              value={subscriptionList.filter((subscription) =>
                watch().follower_ids?.includes(subscription.value)
              )}
              onChange={(opt) => {
                if (opt?.length > 0) {
                  setValue(
                    "follower_ids",
                    opt.map((subscription) => subscription.value)
                  );
                } else {
                  setValue("follower_ids", []);
                }
              }}
              loadOptions={getSubscriptionListAPI}
              additional={{ page: 1 }}
              helpText="Choose up to four other booths to be featured at the bottom of your takes."
            />

            <ImageUploader
              label="Default Take Header"
              name="take_header"
              helpText="If you wish to use an image for the header of your takes, please upload it here. You can override the default header image on each individual take if you like."
              handleChange={handleChange}
              handleDelete={handleImageDestroy}
              imageData={
                watch().default_take_header_attachment_attributes?.file_data
              }
              imageUrl={
                watch().default_take_header_attachment_attributes
                  ?.attachment_url
              }
              destroy={
                watch().default_take_header_attachment_attributes?._destroy
              }
              bodyBackground="inverted-hover"
            />

            <Button
              type="submit"
              label="Save changes"
              loading={btnLoader}
              bodyBackground="inverted-hover"
            />
          </form>

          <div className="flex flex-col w-full gap-5 bg-inverted border border-solid border-inverted-hover rounded-sm p-5">
            <h4 className="text-title">Delete your account</h4>

            <p className="text-small">
              By deleting your PencilBooth account, you will not be able to
              access your current subscriber list, existing takes and any
              associated assets. All data is removed from our system and cannot
              be recovered. We'd suggest archiving your subscriber list{" "}
              <span
                className="underline cursor-pointer"
                onClick={() => setDeleteModal(true)}
              >
                here
              </span>{" "}
              before closing your PencilBooth account.
            </p>

            <Button
              style="danger"
              label="Close account"
              onClick={() => setDeleteModal(true)}
            />
          </div>
        </div>

        <Modal
          size="large"
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          title="Delete your account"
        >
          <div className="flex flex-col w-full gap-10">
            <div className="flex flex-col w-full gap-6">
              <p>
                We'll be sad to see you go, but we get it! Before you take off,
                just confirming that you've downloaded your current subscriber
                list? If not, click{" "}
                {exportBtnLoader ? (
                  <Loader />
                ) : (
                  <a
                    className="underline cursor-pointer"
                    onClick={() => exportAudienceListResponse()}
                  >
                    here
                  </a>
                )}{" "}
                to do it. Also just a reminder that deleting your account is
                permanent. Once you hit that big red button, we can't get
                anything back for you.
              </p>

              <p>
                Finally, it would be really helpful to know why you are leaving
                so we can take any feedback or experiences on board to help
                shape the future of PencilBooth. We know it's annoying and you
                just want to get out of here, but anything you can share would
                be incredibly helpful for our team to know!
              </p>

              <Textarea
                id="account_closure_reason"
                name="account_closure_reason"
                className="bg-inverted-hover"
                placeholder="Write something here..."
                value={accountClosureReason}
                onChange={(e) => setAccountClosureReason(e.target.value)}
                style="primary"
                bodyBackground="primary"
              />
            </div>

            <div className="flex gap-4">
              <Button
                type="submit"
                style="danger"
                label="I understand, delete away!"
                loading={btnLoader}
                onClick={() => handleDestroy()}
                bodyBackground="primary"
              />

              <Button
                label="Cancel"
                style="transparent"
                onClick={() => setDeleteModal(false)}
                bodyBackground="primary"
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ProfilePage;
