import React from "react";

import { PBIcons } from "@/components/PBIcons";

const PostCard = ({ linkRoll, cardType }) => {
  return (
    <div className="flex flex-col w-full bg-inverted-hover rounded-sm">
      <div className="flex items-center p-3">
        <div className="flex items-center gap-2">
          <PBIcons icon={cardType} className="shrink-0" />
          <strong className="text-title line-clamp-1">{cardType}</strong>
        </div>
      </div>

      {linkRoll.instagram_post || linkRoll.twitter_post ? (
        <img src={linkRoll.image_src} className="w-full h-auto bg-white" />
      ) : (
        <div className="flex flex-col p-3 bg-inverted mx-1.5">
          <p className="text-small">{linkRoll.description}</p>
        </div>
      )}

      <a
        href={linkRoll.formatted_link}
        target="_blank"
        className="text-center p-3"
      >
        <span className="text-[#356fc6]">{`View more on ${cardType}`}</span>
      </a>
    </div>
  );
};

export default PostCard;
