export const MAX_STRING_LENGTH = 250;
export const MAX_SLUG_LENGTH = 20;
export const OTP_LENGTH = 6;
export const AUTH_TOKEN = "PBToken";
export const TAKE_IMAGES_COUNT = 4;
export const DEFAULT_PAGE_SIZE = 10;
export const ORDINALIZED_DATE_FORMAT = "MMMM Do YYYY";
export const SEARCH_DATE_FORMAT = "YYYY-MM-DD";
export const EXPORT_DATE_FORMAT = "DD_MM_YYYY";
export const STANDARD_DATE_FORMAT = "DD/MM/YYYY";
export const SCHEDULED_DATE_FORMAT = "YYYY-MM-DD h:mm A";
export const DEFAULT_TAGS_COUNT = 20;
export const TAB_WIDTH = 768;
export const DESKTOP_WIDTH = 992;
export const MAX_IMAGE_SIZE = 10e6;
export const MINIMUM_EMAIL_COUNT = 110;
export const DEFAULT_ACCEPT_FILES =
  "image/png, image/gif, image/jpeg, image/webp, image/jpg, image/avif";
export const FREE_EMAIL_COUNT = 500;

const WARNING_STATUSES = [];
const SUCCESS_STATUSED = ["Sent", "Subscribed"];
const INFO_STATUSES = ["Scheduled"];
const SECONDARY_STATUSES = ["Draft"];
const DANGER_STATUSES = [];

export const getStyle = (resource, column) => {
  if (WARNING_STATUSES.includes(resource[column])) {
    return "warning";
  } else if (SUCCESS_STATUSED.includes(resource[column])) {
    return "success";
  } else if (INFO_STATUSES.includes(resource[column])) {
    return "info";
  } else if (SECONDARY_STATUSES.includes(resource[column])) {
    return "secondary";
  } else if (DANGER_STATUSES.includes(resource[column])) {
    return "danger";
  } else {
    return "danger";
  }
};

export const CURRENCY_SYMBOL = {
  AUD: "A$", // AU Dollar
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};

export const SUBSCRIBER_TYPES = {
  all: "all",
  free: "free",
  paid: "paid",
};

export const SUBSCRIPTION_TYPES = [
  { label: "Subscribed", value: "subscribed" },
  { label: "Unsubscribed", value: "unsubscribed" },
  { label: "Cleaned", value: "cleaned" },
];

export const EMAIL_STATUS_LIST = [
  { label: "Bounced", value: "last_email_status_bounced" },
  { label: "Sent", value: "last_email_status_sent" },
  { label: "Opened", value: "last_email_status_opened" },
  { label: "Spam Complaint", value: "last_email_status_spammed" },
  { label: "Clicked", value: "last_email_status_clicked" },
  { label: "Unsubscribed", value: "last_email_status_unsubscribed" },
];

export const TAKE_EDITOR_EXTENSION_LIST = [
  "TextAlign",
  "Bold",
  "Underline",
  "Italic",
  "Link",
  "HardBreak",
  "ListItem",
  "BulletList",
  "OrderedList",
];

export const LOGIN_REQUIRED_MESSAGE = "Oops! You need to login to continue.";
export const ACCESS_REQUIRED_MESSAGE =
  "Oops! You don't have permission to this page.";
