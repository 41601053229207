import React from "react";

import { JOIN_ROUTE } from "@/lib/routes";

import Modal from "@/components/Modal";
import Button from "@/components/Button";
import LinkButton from "@/components/LinkButton";

const SignUpAlert = ({ modalOpen, setModalOpen }) => {
  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)} title="Psst!">
      <div className="flex flex-col w-full gap-10">
        It looks like you don't have your own PencilBooth account, in which case
        you won't be able to view paid content on the web - You'll still receive
        it via email though. Please sign up for a free account here to ensure
        you have full access. You can also do this later..
        <div className="flex gap-4">
          <LinkButton
            to={JOIN_ROUTE}
            label="Sign Up"
            style="inverted"
            bodyBackground="primary"
          />
          <Button
            label="Cancel"
            onClick={() => setModalOpen(false)}
            style="transparent"
            bodyBackground="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default SignUpAlert;
