import React, { useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import { updateTake } from "@/apis/super_admin/takes";
import { showToastrSuccess, showToastrError } from "@/lib/commons";
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import { STANDARD_DATE_FORMAT } from "@/lib/constants";

import ItemMenu from "./ItemMenu";

const Table = ({ takes, getTakeListAPI }) => {
  const [takeId, setTakeId] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [featureAlert, setFeatureAlert] = useState(false);

  const updateTakeAPI = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateTake(takeId, { take: { featured: true } });
      showToastrSuccess("Take has been featured successfully.");
      getTakeListAPI();
      setFeatureAlert(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="pb-data-table">
        <thead>
          <tr>
            <th className="w-[35%] min-w-[300px]">Subject</th>
            <th className="w-[25%] min-w-[120px]">Status</th>
            <th className="w-[25%] min-w-[80px]">Sent Date</th>
            <th className="w-[15%] min-w-[50px]"></th>
          </tr>
        </thead>
        <tbody>
          {takes.map((take) => {
            return (
              <tr key={take.id}>
                <td>
                  <Link to={`/takes/${take.id}`}>{take.subject}</Link>
                </td>
                <td>{take.status}</td>
                <td>{dayjs(take.sent_at).format(STANDARD_DATE_FORMAT)}</td>
                <td className="!text-right">
                  <ItemMenu
                    take={take}
                    setTakeId={setTakeId}
                    setFeatureAlert={setFeatureAlert}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal
        open={featureAlert}
        onClose={() => setFeatureAlert(false)}
        title="Feature Take"
      >
        <div className="flex flex-col w-full gap-10">
          <div className="flex flex-col w-full gap-6">
            <p>Are you sure you want to feature the take to discovery page?</p>
          </div>

          <div className="flex gap-4">
            <Button
              type="button"
              label="Yes, Feature"
              loading={btnLoader}
              style="inverted"
              onClick={() => updateTakeAPI()}
              bodyBackground="primary"
            />

            <Button
              type="button"
              style="transparent"
              label="Cancel"
              onClick={() => setFeatureAlert(false)}
              bodyBackground="primary"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Table;
